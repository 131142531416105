export const AUTHENTICATION_FEATURE_KEY = 'authentication'

export interface AuthState {
  username: string | null
  name: string | null
  authenticated: boolean
  redirectURL: string | null
  security: string[] | null
  token: string | null
  authenticatingUser: string | null
}

export const initialState: AuthState = {
  username: null,
  name: null,
  authenticated: false,
  redirectURL: null,
  security: null,
  token: null,
  authenticatingUser: null,
}
