import { REGEX_DUPE_DECIMALS } from '../../constants/constants'
import { convertFromSINumber } from './convertFromSINumber'
import { stripNonNumeric } from './stripNonNumeric'

export function stripNumberFormat(n: number | string): number {
  const digitsAndDot = stripNonNumeric(n)
  const withoutExtraDecimals = digitsAndDot.replace(REGEX_DUPE_DECIMALS, '$1')
  const withoutSuffix = convertFromSINumber(withoutExtraDecimals)
  return parseFloat(withoutSuffix)
}
