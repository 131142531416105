<div class="app-wrapper">
  <div class="header">
    <app-fac-header
      [isLoggedIn]="isLoggedIn$ | async"
      [userName]="userName$ | async"
      [title]="appHeaderTitle$ | async"
      [subtitle]="appHeaderSubtitle$ | async"
      [userPermissions]="userPermissions$ | async"
      (logout)="logout()"
    ></app-fac-header>
  </div>

  <div class="main">
    <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
    <router-outlet *ngIf="!isIframe"></router-outlet>
  </div>
</div>
