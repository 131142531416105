import { FacDealType, ReinsuranceData } from '../models'
import { formatParticipationPercentage } from './formatParticipationPercentage'
import { formatThousandsCommas } from './formatThousandsCommas'
import { stripNonNumeric } from './stripNonNumeric'

export function formatFacCertificateTotal(
  reinsuranceData: readonly ReinsuranceData[] | undefined,
  currencySymbol: string,
  fieldType: Exclude<keyof ReinsuranceData, 'mgaArray' | 'isMGA'>,
  dealType: FacDealType
) {
  const sum = (reinsuranceData ?? []).reduce(
    (acc, d) => acc + (fieldType !== 'index' ? Number(stripNonNumeric(d[fieldType])) : 0),
    0
  )
  switch (fieldType) {
    case 'participationPercentage':
      return formatParticipationPercentage(sum, dealType)
    case 'grossPremium':
    case 'netPremium':
    case 'quotedPrice':
    case 'participationAmount':
      return currencySymbol + formatThousandsCommas(sum)
    default:
      return sum
  }
}
