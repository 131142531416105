import { createAction, props } from '@ngrx/store'
import { UserPermissionsResponse } from './permissions.model'

const featureAreaName = 'User Permissions'

export const getUserPermissions = createAction(
  `[${featureAreaName}] Get User Permissions`
)

export const getUserPermissionsSuccess = createAction(
  `[${featureAreaName}] Get User Permissions Success`,
  props<{ response: UserPermissionsResponse }>()
)

export const getUserPermissionsFail = createAction(
  `[${featureAreaName}] Get User Permissions Fail`,
  props<{ errorMessage: string }>()
)
