import {
  CoverageReinsured,
  FacDealType,
  ReinsuredLayer
} from '../models'
import { extractRetentionLayer } from './extractRetentionLayer'
import { FacFieldService } from '../services'

export function formatRetention(
  fieldMap: ReadonlyMap<string, string>,
  dealType: FacDealType,
  fieldService?: FacFieldService
) {
  const coverage = fieldMap.get('coverageReinsured')
  if (!coverage || dealType !== 'casualty') {
    if (fieldService) {
      fieldService.setFieldValuesMapValue('retention', '')
    }
    return ''
  }
  const layerStrings = formatRetentionAsArray(fieldMap, dealType)
  const rteValue = layerStrings.join('<br /><br />')
  if (fieldService) {
    fieldService.setFieldValuesMapValue('retention', rteValue)
  }
  return rteValue
}

export function formatRetentionAsArray(
  fieldMap: ReadonlyMap<string, string>,
  dealType: FacDealType
): string[] {

  const layerStrings: string[] = []
  const coverage = fieldMap.get('coverageReinsured')
  if (!coverage || dealType !== 'casualty') {
    return []
  }

  const layersString = fieldMap.get('reinsuredLayers')
  const layers = JSON.parse(layersString ?? '[]') as ReinsuredLayer[]

  if (layers?.length > 0) {
    const coverageExpression = fieldMap.get('coverageExpression') ?? 'Occurrence'
    const coverageText = coverage === CoverageReinsured.auto
      ? `${coverageExpression} Bodily Injury and Property Damage Combined Single Limit`
      : coverageExpression
    const cessionType = fieldMap.get('cessionType')
    const isQuotaShare = fieldMap.get('isQuotaShare')
    const partOfCoverageLimit = fieldMap.get('partOfCoverageLimit')
    const partOfGeneralAggregate = fieldMap.get('partOfGeneralAggregate')
    const partOfProductOperationAggregate = fieldMap.get('partOfProductOperationAggregate')
    layers.forEach((layer: ReinsuredLayer, index: number) => {
      layerStrings.push(
        extractRetentionLayer(
          index,
          layer,
          coverage,
          cessionType,
          coverageText,
          isQuotaShare,
          partOfCoverageLimit,
          partOfGeneralAggregate,
          partOfProductOperationAggregate
        )
      )
    })
  }
  return layerStrings.filter(Boolean)
}
