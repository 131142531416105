import { FieldInterface } from '../../../models'

export const responsesFields: FieldInterface =  {
  name: 'responses',
  fields: [
    [
      {
        id: 'marketResponses',
        type: 'input',
        label: 'Market Responses',
        inputType: 'text',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Please enter value less than or equal to 20%',
          },
        ],
        value: '',
        defaultValue: '[]',
        hiddenToggle: false,
        hiddenToggleValue: true,
      },
    ],
  ],
}
