import {CoverageReinsured, RateAdjustabilityDefaults} from '../models'

export function getRateAdjustabilityDefault(coverageType: CoverageReinsured): string {
  switch (coverageType) {
    case CoverageReinsured.auto:
    case CoverageReinsured.general:
      return RateAdjustabilityDefaults.Adjustable
    case CoverageReinsured.umbrella:
    case CoverageReinsured.umbrellaGeneral:
    case CoverageReinsured.umbrellaAuto:
    case CoverageReinsured.cyber:
    case CoverageReinsured.directorsOfficers:
    case CoverageReinsured.errorsOmissions:
    case CoverageReinsured.miscellaneous:
      return RateAdjustabilityDefaults.Flat
    default:
      return ''
  }
}
