import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators'
import { select, Store } from '@ngrx/store'
import { selectCurrentSearchCriteria } from './facultative.selectors'
import { AppState } from '../../core/store'
import { BackendService } from '../../api/backend/backend.service'
import { DealSummariesResultDto, DealSummaryDto } from '../../api/model/backend.model'

import * as facActions from './facultative.actions'
import { DealFormCategoryStatus, DealSummariesResult, DealSummary } from '@deals/models'
import { parseISODateOnly } from '@utils'
import { of } from 'rxjs'
import { getErrorMessage } from '@deals/utils'

@Injectable()
export class DealSummariesEffects {
  constructor(
    private actions$: Actions,
    private backendService: BackendService,
    private store: Store<AppState>
  ) {}

  loadDealSummaries$ = createEffect(() => this.actions$.pipe(
      ofType(facActions.dealSummariesLoad),
      withLatestFrom(this.store.pipe(select(selectCurrentSearchCriteria))),
      switchMap(([_, searchCriteria]) => {
        return this.backendService.getFacDealSummaries(searchCriteria).pipe(
          map((result: DealSummariesResultDto) => facActions.dealSummariesLoadSuccess({ data: this.buildDealSummariesResult(result) })),
          catchError(error => of(facActions.dealSummariesLoadFail({ errorMessage: getErrorMessage(error) })))
        )
      })
    )
  )

  buildDealSummariesResult(resultDto: DealSummariesResultDto): DealSummariesResult {
    return {
      dealSummaries: resultDto.dealSummaries.map(dto => this.builidDealySummaryFromDto(dto)),
      pagination: {
        currentPage: resultDto.pagination.currentPage,
        totalPages: resultDto.pagination.totalPages
      }
    }
  }

  builidDealySummaryFromDto(dto: DealSummaryDto): DealSummary {
    return {
      brokerId: dto.brokerId,
      brokerName: dto.brokerName,
      coverageReinsured: dto.coverageReinsured,
      dealId: dto.dealId,
      dealName: dto.dealName,
      dealType: dto.dealType,
      isNtu: dto.isNtu,
      lastModifiedBy: dto.lastModifiedBy,
      lastModifiedDate: parseISODateOnly(dto.lastModifiedDate),
      lineOfBusiness: dto.dealType === 'property' ? 'Property' : dto.coverageReinsured,
      ntuComment: dto.ntuComment ?? null,
      reinsuredId: dto.reinsuredId,
      reinsuredName: dto.reinsuredName,
      reinsuredUnderwriterId: dto.reinsuredUnderwriterId,
      reinsuredUnderwriterName: dto.reinsuredUnderwriterName,
      renewal: dto.renewal,
      versionStatuses: dto.versionStatuses as DealFormCategoryStatus[],
      policyPeriodFrom: parseISODateOnly(dto.policyPeriodFrom),
      streetAddress: dto.streetAddress
    }
  }

}
