import { coerceBooleanProperty } from '@angular/cdk/coercion'
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'fac-filter-input',
  styleUrls: ['./filter-input.component.scss'],
  templateUrl: './filter-input.component.html'
})
export class FilterInputComponent implements OnInit {
  private _destroy$ = new Subject<void>()
  private _change$ = new BehaviorSubject<string | null>(null)

  private _filter: string = ''
  public get filter(): string {
    return this._filter
  }
  @Input()
  public set filter(value: string) {
    this._filter = value ?? ''
  }

  @Input() debounceTime = 500

  @Input() set transparent(value: any) {
    this._transparent = coerceBooleanProperty(value)
  }
  get transparent() {
    return this._transparent
  }
  @HostBinding('class.transparent') _transparent = false

  @Input() set handleTab(value: any) {
    this._handleTab = coerceBooleanProperty(value)
  }
  get handleTab() {
    return this._handleTab
  }
  _handleTab = false

  @Output() filterChange = new EventEmitter<string>()
  @Output() debouncedChange = new EventEmitter<string>()
  @Output() navKeydown = new EventEmitter<NavKey>()

  @ViewChild('filterInput') filterInput: ElementRef<HTMLInputElement>

  get value(): string {
    return this.filter ?? ''
  }

  ngOnInit(): void {
    this._change$.pipe(
      debounceTime(this.debounceTime),
      distinctUntilChanged(),
      filter(value => value !== null),
      takeUntil(this._destroy$),
    )
    .subscribe(value => this.debouncedChange.emit(value))
  }

  clear(): void {
    this.handleChange('')
    if (this.filterInput?.nativeElement) {
      this.filterInput.nativeElement.value = ''
    }
  }

  focus(): void {
    if (this.filterInput?.nativeElement) {
      this.filterInput.nativeElement.focus()
      this.filterInput.nativeElement.select()
    }
  }

  onEscape($event: Event): void {
    if (($event as KeyboardEvent)?.key === 'Escape' && this.value) {
      $event.preventDefault()
      $event.stopPropagation()
      return this.handleChange('')
    }
  }

  onNavKey($event: Event, key: NavKey) {
    if (!this.handleTab && ($event as KeyboardEvent)?.key === 'Tab') {
      return
    }
    this.navKeydown.emit(key)
    $event.preventDefault()
    $event.stopPropagation()
    return false
  }

  onChange($event: Event): void {
    if (($event as KeyboardEvent)?.key === 'Escape') {
      return
    }
    return this.handleChange(($event.target as HTMLInputElement).value)
  }

  onClear($event: MouseEvent | TouchEvent): void {
    $event.preventDefault()
    $event.stopPropagation()
    this.handleChange('')
  }

  private handleChange(value: string): void {
    this.filter = value
    this._change$.next(value)
    this.filterChange.emit(value)
  }
}

export type NavKey = 'up' | 'down' | 'enter' | 'tab'
