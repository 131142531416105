import { FacUser } from "@deals/models"

export function formatLocktonContactLines(isUK: boolean, broker: FacUser): string[] {
  return isUK
    ? ['Lockton Re LLP', ...(formatStreet(broker?.street ?? '')), `${broker?.city ? broker?.city + ',' : ''} ${broker?.statecode ?? ''} ${broker?.postalcode ?? ''}`]
    : ['Lockton Re LLC', ...(formatStreet(broker?.street ?? '')), `${broker?.city ? broker?.city + ',' : ''} ${broker?.statecode ?? ''} ${broker?.postalcode ?? ''}`]
}

function formatStreet(street: string) {
  return street?.replace('\r', '')?.split('\n') ?? ''
}
