import { jsPDF } from 'jspdf'
import { PDFDocument } from 'pdf-lib'
import { FacFormID } from '../../models'
import { saveAs } from 'file-saver'
import { FilenameBuilder } from '@deals/services'
import {
  BOX_IMG,
  Coord,
  ImageInfo,
  Rect,
  SIGNATURE_DICT
} from './pdf-generator.constants'
import { toArray } from '@utils'

export interface FacultativePDFPageOptions {
  /** Orientation of the PDF page. */
  orientation: 'portrait' | 'landscape'
  /** Width in millimeters of the PDF page. */
  width: number
  /** Height in millimeters of the PDF page. */
  height: number
  /** Margin in millimeters of the PDF page. */
  margin: number
  /** Gutter between columns in millimeters of the PDF page. */
  gutter: number
}

export interface FacPdfHeader {
  title: string
  subtitle?: string
  nameAndAddress?: string
  fields?: [string, string][]
  height: number
}

export interface FontOptions {
  fontSize?: number
  fontStyle?: 'normal' | 'bold' | 'italic' | 'bolditalic'
  charSpace?: number
}

export interface Line extends FontOptions {
  text: string
  lineHeight: number
}

export interface BaseItem extends FontOptions {
  value: string | string[]
  colWidths?: number[]
  gutter?: number
  lineHeight?: number
  marginBottom?: number
  marginTop?: number
  asTable?: boolean
  noTrim?: boolean
  borders?: boolean
}

export interface FacPdfText extends BaseItem {
  type: 'text'
}

export interface FacPdfField extends BaseItem {
  type: 'field'
  label: string
  id?: string
  hidden?: boolean
}

export interface FacPdfHeading extends BaseItem {
  type: 'heading'
  allCaps?: boolean
}

export type FacPdfItem = FacPdfText | FacPdfField | FacPdfHeading

type Type = 'title' | 'body' | 'header-field' | 'field'
type Color = 'white' | 'black' | 'gray-700' | 'gray-500' | 'gray-300' | 'red'

interface TextOptions {
  charSpace?: number
  align?: 'left' | 'center' | 'right'
}

interface TextRow {
  type: 'text'
  items: FacPdfText[]
}

interface FieldRow {
  type: 'field'
  items: FacPdfField[]
}

interface HeadingRow {
  type: 'heading'
  items: FacPdfHeading[]
}

type ItemRow = TextRow | FieldRow | HeadingRow

interface ItemBoxOptions {
  lineWidth?: number
  showImage?: boolean
}

const LINE_REGEX = /\r\n|\r|\n/g

const DEFAULT_PAGE_OPTIONS: FacultativePDFPageOptions = {
  orientation: 'portrait',
  width: 210,
  height: 297,
  margin: 12.7,
  gutter: 6,
}
/** Height in millimeters for a line of text. */
const HEADING_H = 4
/** Height in millimeters for a line of text. */
const TEXT_H = 4.5
/** Width in millimeters for a field label. */
const FIELD_LABEL_RATIO = 0.45

const HEADER_LHS_IMG = { src: 'assets/bg/lockton_R.png', w: 1038, h: 711 }
const HEADER_RHS_IMG = {
  src: 'assets/logos/corp-logo-white.png',
  w: 481,
  h: 240,
}
const HEADER_END_IMG = {
  src: 'assets/facultative/fac-bottom.png',
  w: 6068,
  h: 40,
}

export class FacultativePDF {
  private doc: jsPDF
  private pg: FacultativePDFPageOptions
  private color: Color = 'black'
  private y: number
  private isFirstPage = true
  name: FacFormID = 'submission'
  containsRichText = false
  currentPage = 0
  changeCursor = 0
  attachedFile: ArrayBuffer
  pdfName = ''
  formType: string

  constructor(pageOptions: Partial<FacultativePDFPageOptions> = {}) {
    this.doc = new jsPDF({ orientation: 'portrait' })
    this.pg = { ...DEFAULT_PAGE_OPTIONS, ...pageOptions }
  }

  getBase64Contents() {
    return this.doc.output('datauristring')
  }

  getArrayBuffer() {
    return this.doc.output('arraybuffer')
  }

  buildPdfFileName(data: any, formID: FacFormID): string {
    const fileNameBuilder = new FilenameBuilder()
      .setDealName(data.fieldMap.get('name'))
      .setDealType(data.dealType)
      .setFormType(formID)
      .setCedentName(data.fieldMap.get('reinsured'))
      .setCoverageType(data.fieldMap.get('coverageReinsured'))
    return fileNameBuilder.build()
  }

  addItems(
    itemsOrText: string | (string | FacPdfItem | (FacPdfItem | string)[])[],
    formID: FacFormID,
    data?: any
  ): number {
    this.name = formID
    if (data && data.fieldMap) {
      this.pdfName = this.buildPdfFileName(data, formID)
    }
    this.setFont('body', { color: 'black' })
    const cursor: Coord = { x: this.pg.margin, y: this.y }
    const { y } = toItemRows(itemsOrText).reduce((acc, row) => {
      acc.y = this.addItemRow(row, acc)
      return acc
    }, cursor)
    this.y = y
    return y
  }

  addBoxItems(
    itemsOrText: string | (string | FacPdfItem | (FacPdfItem | string)[])[],
    { lineWidth = 0.5, showImage }: ItemBoxOptions = {},
    brokerName: string
  ): number {
    const items = toItemRows(itemsOrText)
    // Temporarily adjust the margin to account for the box margin
    const prevMargin = this.pg.margin
    this.pg.margin += this.pg.gutter
    // Perform a dry-run of drawing items to get the total height
    const dryRunCursor: Coord = { x: this.pg.margin, y: 0 }
    const { y: itemsHeight } = items.reduce((acc, row) => {
      acc.y = this.addItemRow(row, acc, true)
      return acc
    }, dryRunCursor)
    // Add a page if the height of the items exceeds the page height
    const cursor: Coord = { x: this.pg.margin, y: this.y }
    if (this.pg.height - 2 * prevMargin - (itemsHeight + 45) < cursor.y) {
      this.addPage()
      cursor.y = prevMargin
    }
    // Draw the box
    this.doc.setLineWidth(lineWidth)
    const w = this.pg.width - 2 * prevMargin
    const lastItems = items[items.length - 1].items as FacPdfItem[]
    const marginBottom =
      extract(lastItems, 'marginBottom') ??
      (lastItems[0]?.type === 'heading' ? HEADING_H : TEXT_H)
    // const marginTop =
    //   extract(lastItems, 'marginTop') ??
    //   (lastItems[0]?.type === 'heading' ? HEADING_H : TEXT_H)
    let h = itemsHeight + this.pg.gutter - marginBottom
    const imgHeight = 20
    if (showImage) {
      const imgX = -prevMargin - this.pg.gutter
      const imgY = cursor.y + h - this.pg.gutter
      this.addImage(BOX_IMG, { h: imgHeight, y: imgY, x: imgX })
      Object.keys(SIGNATURE_DICT).forEach(name => {
        if (brokerName === name) {
          this.addImage(SIGNATURE_DICT[name], { h: 10, y: imgY - 25, x: imgX - 115 })
        }
      })
      h += imgHeight
    }
    this.doc.rect(prevMargin, cursor.y, w, h)
    // Draw the items
    cursor.y += this.pg.gutter + lineWidth
    const { y } = items.reduce((acc, row) => {
      acc.y = this.addItemRow(row, acc)
      return acc
    }, cursor)
    // Restore previous page margin
    this.pg.margin = prevMargin
    // Update the overall page y position accounting for the box margin
    let nextY = y + this.pg.gutter + lineWidth
    if (showImage) {
      nextY += imgHeight
    }
    this.y = nextY
    return nextY
  }

  addTextCentered(text: string, fontStyle: string): void {
    const x = (this.doc.internal.pageSize.getWidth() - this.doc.getTextWidth(text))/2
    this.doc.setFont('Helvetica', fontStyle)
    this.doc.text(text, x, this.y);
    this.y += TEXT_H
  }

  addPageNumber(): void {
    this.doc.text((this.currentPage).toString(), 200, 290)
    this.y += TEXT_H
  }

  addPage(header?: FacPdfHeader, isPalms?: boolean): void {
    this.currentPage++
    if (!this.isFirstPage) {
      this.doc.text((this.currentPage - 1).toString(), 200, 290)
      this.doc.addPage()
    } else {
      this.isFirstPage = false
    }
    if (header) {
      this.addHeader(header, isPalms)
    }
  }

  async save(pdfName: string, attachedFile?: ArrayBuffer, additionalFiles: ArrayBuffer[] = []): Promise<void> {
    this.doc.text(this.currentPage.toString(), 200, 290)
    if (!this.containsRichText) {
      if (attachedFile && attachedFile.byteLength > 0) {
        const arrayBuffer = this.doc.output('arraybuffer')
        await this.mergeAndSavePdfs(pdfName, [arrayBuffer, attachedFile, ...additionalFiles])
      } else {
        this.doc.save(pdfName)
      }
    } else if (attachedFile && attachedFile.byteLength > 0) {
      this.attachedFile = attachedFile
    }
  }

  async mergeAndSavePdfs(pdfName: string, pdfsToMerges: ArrayBuffer[]): Promise<Uint8Array> {
    const mergedPdf = await PDFDocument.create()
    for (let i = 0; i < pdfsToMerges.length; i++) {
      const pdf = await PDFDocument.load(pdfsToMerges[i], { ignoreEncryption: true })
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices())
      copiedPages.forEach(page => {
        mergedPdf.addPage(page)
      })
    }
    const mergedPdfBytes = await mergedPdf.save()
    const blob = new Blob([mergedPdfBytes.buffer], { type: 'application/pdf' })
    saveAs(blob, pdfName)
    return mergedPdfBytes
  }

  async mergePdfs(pdfsToMerges: ArrayBuffer[]): Promise<ArrayBuffer> {
    const mergedPdf = await PDFDocument.create()
    for (let i = 0; i < pdfsToMerges.length; i++) {
      const pdf = await PDFDocument.load(pdfsToMerges[i])
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices())
      copiedPages.forEach(page => {
        mergedPdf.addPage(page)
      })
    }
    const mergedPdfBytes = await mergedPdf.save()
    return await mergedPdfBytes.buffer
  }

  private addHeader({
    title,
    subtitle,
    nameAndAddress,
    fields = [],
    height,
  }: FacPdfHeader, isPalms?: boolean): void {
    this.doc.setFontSize(16)
    if (fields.length) {
      fields.forEach(f => {
        const numRows = Math.floor(this.doc.getTextWidth(f[1]) / 110)
        height += (numRows * 6) / 2
      })
    }
    const cursor: Coord = { x: this.pg.margin, y: this.pg.margin + 6 }
    this.doc.setFillColor(0, 0, 0)
    this.doc.rect(0, 0, this.pg.width, height, 'F')
    this.addImage(HEADER_LHS_IMG, { h: 20, y: 5, x: 4 })
    this.addImage(HEADER_RHS_IMG, { h: 20, y: 5, x: -5 })
    if (title) {
      this.setFont('title', { color: 'white' })
      this.addText(title, { x: cursor.x + 12, y: cursor.y })
    }
    if (subtitle) {
      const subtitleOffset = 19 + (height - 68) + (isPalms ? 20 : 0)
      cursor.y = height - subtitleOffset
      this.addHeaderFieldText(subtitle, cursor, {
        charSpace: 0,
        color: 'gray-300',
      })
    }
    this.setFont('body', { color: 'white' })
    if (nameAndAddress) {
      const addressOffset = 6 + (height - 68)
      if (!subtitle) {
        cursor.x += 12
        cursor.y = height - addressOffset
      } else {
        cursor.y += 6
      }
      this.addNameAndAddressText(nameAndAddress, cursor)
    }

    if (isPalms) {
      const subtitlePalms = "PROGRAM ADMINISTRATOR AND ADDRESS"
      cursor.y += subtitle ? 20 : 8
      this.addHeaderFieldText(subtitlePalms, cursor, {
        charSpace: 0,
        color: 'gray-300',
      })
      cursor.y += 6
      this.addNameAndAddressText("Insurisk\n5555 Triangle Parkway, Suite 400\nNorcross, GA 30092", cursor)
    }

    let minOffset =
      this.doc.getTextWidth('Attaching to and forming part of Facultative') + 30
    let textWidth = 0
    if (fields.length) {
      fields.forEach(f => {
        textWidth = this.doc.getTextWidth(f[0]) + this.doc.getTextWidth(f[1])
        if (textWidth > minOffset) {
          minOffset = textWidth
        }
      })

      if (minOffset > 97) {
        minOffset = 97
      }

      cursor.x = this.pg.width - minOffset
      cursor.y = this.pg.margin + 21.5
      fields.forEach(([label, text]) => {
        this.addHeaderFieldText(label, cursor, {
          color: 'gray-300',
          align: 'right',
          charSpace: 0,
        })
        let lineHeight = 5.7
        if (this.doc.getTextWidth(text) > 75) {
          const numRows = Math.floor(this.doc.getTextWidth(text) / 75)
          lineHeight += numRows * lineHeight
        }
        this.addText(
          text,
          { ...cursor, x: cursor.x + this.pg.gutter },
          'header'
        )
        cursor.y += lineHeight
      })
    }
    const { y, h } = this.addImage(HEADER_END_IMG, { y: height })
    this.y = y + h + this.pg.margin
  }

  private addImage(img: ImageInfo, rect: Partial<Rect> = {}): Rect {
    const i = new Image()
    i.src = img.src
    let w: number
    let h: number
    if (rect.w != null && rect.h != null) {
      w = rect.w
      h = rect.h
    } else if (rect.w == null && rect.h != null) {
      w = img.w * (rect.h / img.h)
      h = rect.h
    } else if (rect.w != null && rect.h == null) {
      w = rect.w
      h = img.h * (rect.w / img.w)
    } else {
      w = img.w
      h = img.w
      if (w > this.pg.width) {
        w = this.pg.width
        h = img.h * (this.pg.width / img.w)
      } else if (h > this.pg.height) {
        w = img.w * (this.pg.height / img.h)
        h = this.pg.height
      }
    }
    let x = rect.x ?? 0
    if (x < 0) {
      x = this.pg.width - w + x
    }
    let y = rect.y ?? 0
    if (y < 0) {
      y = this.pg.height - h + y
    }
    this.doc.addImage(i, 'png', x, y, w, h)
    return { x, y, w, h }
  }

  private applyFontOptions(options: FontOptions, callback: () => void) {
    if (options.fontStyle != null && options.fontStyle !== 'normal') {
      this.doc.setFont('helvetica', options.fontStyle)
    }
    if (options.fontSize) {
      this.doc.setFontSize(options.fontSize)
    }
    callback()
    if (
      options.fontSize ||
      (options.fontStyle != null && options.fontStyle !== 'normal')
    ) {
      this.setFont('body')
    }
  }

  private addText(
    value: string | Line,
    { x = 0, y = 0 }: Partial<Coord> = {},
    section?: string
  ) {
    let line: Line
    let line2: Line
    if (typeof value === 'string' && value.slice(0, 5) === 'Layer') {
      line =
        typeof value === 'string'
          ? { text: value.slice(0, 8), lineHeight: TEXT_H, fontStyle: 'bold' }
          : value
      line2 =
        typeof value === 'string'
          ? { text: ' ' + value.slice(8), lineHeight: TEXT_H }
          : value
      this.applyFontOptions(line, () => {
        this.doc.text(line.text, x, y)
      })
      this.applyFontOptions(line2, () => {
        this.doc.text(line2.text, x + 13, y)
      })
    } else {
        line = typeof value === 'string' ? { text: value, lineHeight: TEXT_H } : value
        const options = section === 'header' ? { maxWidth: 88 } : {}
        this.applyFontOptions(line, () => {
          this.doc.text(line.text, x, y, options)
        })
    }
  }

  private addNameAndAddressText(nameAndAddress: string, pos: Coord): void {
    const line: Line = { text: nameAndAddress, lineHeight: TEXT_H }
    this.applyFontOptions(line, () => {
      this.doc.text(line.text, pos.x, pos.y, { maxWidth: 80 })
    })
  }

  private addHeaderFieldText(
    value: string | Line,
    coord: Partial<Coord> = {},
    {
      color = 'gray-700',
      ...options
    }: TextOptions & { color?: Color; allCaps?: boolean } = {}
  ) {
    const line =
      typeof value === 'string' ? { text: value, lineHeight: TEXT_H } : value
    const prevColor = this.color
    this.setFont('header-field', { color })
    this.applyFontOptions(line, () => {
      const t = options.allCaps ? line.text.toUpperCase() : line.text
      this.doc.text(t, coord.x ?? 0, coord.y ?? 0, {
        charSpace: line.charSpace ?? 0.2,
        ...options,
      })
    })
    this.setFont('body', { color: prevColor })
  }

  private addItemRow(row: ItemRow, cursor: Coord, dryRun?: boolean): number {
    switch (row.type) {
      case 'heading': {
        return this.addHeadingRow(row, cursor, dryRun)
      }
      case 'field': {
        return this.addFieldRow(row, cursor, dryRun)
      }
      case 'text': {
        return this.addTextRow(row, cursor, dryRun)
      }
    }
  }

  private addHeadingRow(
    row: HeadingRow,
    cursor: Coord,
    dryRun?: boolean
  ): number {
    const rowCursor = { ...cursor }
    // Parse text items into array of lines per column
    const { linesPerCol, colWidths, gutter, ...rowInfo } = this.parseRow(row)
    const h = rowInfo.totalHeight + 0.2
    if (!dryRun && this.pg.height - 2 * this.pg.margin - h < rowCursor.y) {
      this.addPage()
      rowCursor.y = this.pg.margin
    }
    row.items[0].value[0] === 'Lockton Re LLC' ||
    row.items[0].value[0] === 'Lockton Re LLP'
      ? (rowCursor.y = 270)
      // eslint-disable-next-line no-self-assign
      : (rowCursor.y = rowCursor.y)
    let color: Color = 'white'
    if (
      row.items[0].value ===
      'The undersigned does hereby acknowledge and confirm its PARTICIPATION as set forth below and authorizes the Intermediary to notify the Company of its PARTICIPATION, and agrees that this signed Certificate may be retained by the Intermediary or may be delivered by it to the Company at the Company’s request.'
    ) {
      color = 'red'
      rowCursor.y -= HEADING_H
    }
    // Add the heading black background
    this.doc.setFillColor(0, 0, 0)
    this.doc.rect(0, rowCursor.y - HEADING_H + 1.2, this.pg.width, h, 'F')
    // Print each line in the column
    linesPerCol.forEach((colLines, i) => {
      const allCaps = row.items[i].allCaps ?? true
      const colCursor = createColumnCursor(rowCursor, colWidths, i, {
        ...this.pg,
        gutter,
      })
      colLines.forEach(line => {
        if (!dryRun) {
          this.addHeaderFieldText(line, colCursor, { color, allCaps })
        }
        colCursor.y += line.lineHeight
      })
    })
    // Update the y-pos to be a line below the heading
    return rowCursor.y + (rowInfo.marginBottom ?? HEADING_H) + h
  }

  private addFieldRow(row: FieldRow, cursor: Coord, dryRun?: boolean): number {
    if (row.items.length > 0 && row.items[0].hidden) {
      return cursor.y
    }
    const visibleFields: FacPdfField[] = row.items.filter(f => !f.hidden)
    const rowCursor = { ...cursor }
    let colWidths = parseColWidths(visibleFields, this.pg, 2)

    if ( row.items.some(i => i.label === "City")) {
      colWidths = [91, 30, 45]
    }
    // Parse field items into array of label & value lines per column
    const linesPerCol = visibleFields.map((it, i) => {
      // Split the lines by line break and split further if
      // needed to fit within column width
      const wLabel = colWidths[i] * FIELD_LABEL_RATIO
      const wRemainder =
        visibleFields.length === 1
          ? this.pg.width - 2 * this.pg.margin
          : colWidths[i]
      const wValue = wRemainder - wLabel - this.pg.gutter
      const labelLines = it.label
        .split(LINE_REGEX)
        .flatMap(v => this.doc.splitTextToSize(v, wLabel) as string[])
      const valueLines: string[] = toArray(it.value)
        .flatMap(v => v.split(LINE_REGEX))
        .flatMap(v => this.doc.splitTextToSize(v, wValue) as string[])
        .map(v => (it.noTrim ? v : v.trim()))
      return { labelLines, valueLines }
    })
    // Determine the max height needed across columns and add a new
    // page if necessary to keeps all lines together
    const maxLines = linesPerCol.reduce((acc, c) => {
      let max = acc
      if (c.labelLines.length > max) {
        max = c.labelLines.length
      }
      if (c.valueLines.length > max) {
        max = c.valueLines.length
      }
      return max
    }, 0)
    const h = maxLines * TEXT_H
    if (!dryRun && this.pg.height - 2 * this.pg.margin - h < rowCursor.y) {
      if (this.changeCursor < 0) {
        rowCursor.y = this.changeCursor * -1
      } else {
        this.addPage()
        rowCursor.y = this.pg.margin
      }
    }
    // Print each label and value line in the column
    const gutter = extract(visibleFields, 'gutter') ?? this.pg.gutter
    linesPerCol.forEach((colLines, i) => {
      const labelCursor = createColumnCursor(rowCursor, colWidths, i, {
        ...this.pg,
        gutter,
      })
      const valueCursor = { ...labelCursor }
      valueCursor.x += FIELD_LABEL_RATIO * colWidths[i] + gutter
      this.setFont('field')
      colLines.labelLines.forEach(line => {
        if (!dryRun) {
          if (line === 'State') {
            labelCursor.x = 108
            valueCursor.x = 128
          }
          if (line === 'City') {
            valueCursor.x = 58.885000000000005
          }
          if (line === 'Zip Code') {
            labelCursor.x = 154.185
            valueCursor.x += 5
          }
          if (line === 'Hired Non Owned') {
            labelCursor.y += 5
            valueCursor.y += 5
            rowCursor.y += 5
          }
          if (line === 'Additional Information') {
            valueCursor.x = labelCursor.x;
            valueCursor.y += 10;
          }
          this.addText(line, labelCursor)
        }
        labelCursor.y += TEXT_H
      })
      this.setFont('body')
      if (
        colLines.valueLines &&
        colLines.valueLines[0] &&
        (colLines.valueLines[0].includes('<p') ||
          colLines.valueLines[0].includes('<ol') ||
          colLines.valueLines[0].includes('<ul') ||
          colLines.valueLines[0].includes('<div'))
      ) {
        const numLinesReplaced = colLines.valueLines.length
        colLines.valueLines = [colLines.valueLines.join(' ')]
        colLines.valueLines = colLines.valueLines
          .concat(Array(numLinesReplaced).fill(' '))
          .slice(0, numLinesReplaced)
        const text = document.createElement('span')
        document.body.appendChild(text)

        text.style.font = 'Helvetica'
        text.style.fontSize = 8 + 'px'
        text.style.height = 'auto'
        text.style.width = 'auto'
        text.style.position = 'absolute'
        text.style.whiteSpace = 'no-wrap'
        text.innerHTML = colLines.valueLines[0]
        const height = Math.ceil(text.clientHeight)
        rowCursor.y += height / 2
        this.changeCursor = rowCursor.y - height
      }
      colLines.valueLines.forEach((line: string) => {
        if (!dryRun) {
          if (
            colLines.valueLines[0].includes('<p') ||
            colLines.valueLines[0].includes('<ul') ||
            colLines.valueLines[0].includes('<ol')
          ) {
            valueCursor.y += TEXT_H
          }
          this.addText(line, valueCursor)
        }
        valueCursor.y += TEXT_H
      })
      if (rowCursor.y > this.pg.height) {
        this.addPage()
        if (this.changeCursor > 0) {
          rowCursor.y = this.changeCursor
          this.changeCursor = 0
        } else if (this.changeCursor < 0) {
          rowCursor.y = rowCursor.y - this.pg.height
          this.changeCursor = 0
        } else {
          rowCursor.y = this.pg.margin
        }
      }
    })
    // Update the y-pos to be one line below the longest column
    const marginBottom = extract(visibleFields, 'marginBottom') ?? TEXT_H
    // const marginTop = extract(visibleFields, 'marginTop') ?? TEXT_H
    return rowCursor.y + h + marginBottom
  }

  private addTextRow(
    row: TextRow,
    cursor: Coord,
    dryRun?: boolean,
    header?: FacPdfText
  ): number {
    const rowCursor = { ...cursor }
    const { linesPerCol, colWidths, gutter, ...rowInfo } = this.parseRow(row)
    // Treat as table if widths defined and every item has a value array
    if (
      extract(row.items, 'asTable') === true ||
      (row.items.every(it => Array.isArray(it.value)) &&
        extract(row.items, 'colWidths') != null)
    ) {
      const borders = row.items[0].value[0] === 'LOB'
      const fontSize = extract(row.items, 'fontSize')
      const fontStyle = extract(row.items, 'fontStyle')
      const lineHeight = extract(row.items, 'lineHeight')
      const marginBottom = extract(row.items, 'marginBottom')
      const marginTop = extract(row.items, 'marginTop')

      // Add a page and reset y position if the text overflows the page height
      if (
        !dryRun &&
        this.pg.height - 2 * this.pg.margin - rowInfo.totalHeight <
        rowCursor.y + (this.doc.getNumberOfPages() > 1 ? 0 : 30)
      ) {
        const borders = header?.value[0] === 'LOB'
        const items = toArray(header?.value).map(
          (value): FacPdfText => ({
            type: 'text',
            value,
            colWidths,
            gutter: header?.gutter ?? gutter,
            fontSize: header?.fontSize,
            fontStyle: header?.fontStyle,
            lineHeight: header?.lineHeight,
            marginBottom: header?.marginBottom,
            marginTop: header?.marginTop,
            borders,
          })
        )
        this.addPage()
        rowCursor.y = this.pg.margin
        if (row.items[0].fontStyle !== 'bold') {
          rowCursor.y = this.addTextRow(
            { type: 'text', items },
            rowCursor,
            dryRun,
            items[0]
          )
        }
      }

      const { y } = row.items
        .filter(i => {
          let defaultLength = 1
          if (row.items[0].value.includes('Val Date')) {
            defaultLength++
          }
          return toArray(i.value).filter(Boolean).length > defaultLength
        })
        .reduce((acc, it) => {
          // Convert each value element to a text item
          const items = toArray(it.value).map(
            (value): FacPdfText => ({
              type: 'text',
              value: value === '' ? ' ' : value,
              colWidths,
              gutter: it.gutter ?? gutter,
              fontSize: it.fontSize ?? fontSize,
              fontStyle: it.fontStyle ?? fontStyle,
              lineHeight: it.lineHeight ?? lineHeight,
              marginBottom: it.marginBottom ?? marginBottom,
              marginTop: it.marginTop ?? marginTop,
              borders,
            })
          )
          // Add the text item and update the cursor y position
          acc.y = this.addTextRow(
            { type: 'text', items },
            acc,
            dryRun,
            row.items[0]
          )
          if (it.value[0] === 'Total') {
            acc.y += TEXT_H
          }
          return acc
        }, rowCursor)
      // Return the final y position
      return y
    }

    // Add a page and reset y position if the text overflows the page height
    if (
      !dryRun &&
      this.pg.height - 2 * this.pg.margin - rowInfo.totalHeight <
      rowCursor.y + (this.doc.getNumberOfPages() > 1 ? 0 : 30) - 17
    ) {
      const borders = header?.value[0] === 'LOB'
      const items = toArray(header?.value).map(
        (value): FacPdfText => ({
          type: 'text',
          value,
          colWidths,
          gutter: header?.gutter ?? gutter,
          fontSize: header?.fontSize,
          fontStyle: header?.fontStyle,
          lineHeight: header?.lineHeight,
          marginBottom: header?.marginBottom,
          marginTop: header?.marginTop,
          borders,
        })
      )
      this.addPage()
      rowCursor.y = this.pg.margin
      if (row.items[0].fontStyle !== 'bold') {
        rowCursor.y = this.addTextRow(
          { type: 'text', items },
          rowCursor,
          dryRun,
          items[0]
        )
      }
    }

    // Print each line in the column
    linesPerCol.forEach((colLines, i) => {
      const colCursor = createColumnCursor(rowCursor, colWidths, i, {
        ...this.pg,
        gutter,
      })
      const maxH = Math.max(...linesPerCol.map(a => a.length))
      colLines.forEach(line => {
        if (!dryRun) {
          Object.keys(SIGNATURE_DICT).forEach(name => {
            if (line.text === name) {
              this.addImage(SIGNATURE_DICT[name], {
                h: 15,
                y: colCursor.y - line.lineHeight * 2,
                x: colCursor.x,
              })
              colCursor.y += line.lineHeight * 2
            }
          })
          if (
            (this.name === 'submission' || line.borders) &&
            line.lineHeight !== 4.5 &&
            line === colLines[0]
          ) {
            this.doc.setDrawColor(128, 128, 128)
            this.doc.rect(
              colCursor.x - 1,
              colCursor.y - TEXT_H,
              colWidths[i] + 1,
              line.fontStyle === 'bold'
                ? line.lineHeight * maxH + 3
                : line.lineHeight * maxH + 2
            )
          }
          this.addText(line, colCursor)
        }
        colCursor.y += line.lineHeight
      })
    })
    // Update the y-pos to be one line below the longest column
    return rowCursor.y + rowInfo.totalHeight + (rowInfo.marginBottom ?? TEXT_H)
  }

  private parseRow({ items }: { items: (FacPdfHeading | FacPdfText)[] }) {
    const colWidths = parseColWidths(items, this.pg)
    // Parse text items into array of lines per column
    const linesPerCol = items.map((it, i) => {
      const charSpace = it.charSpace
      const fontSize = it.fontSize
      const fontStyle = it.fontStyle
      const borders = it.borders
      const lineHeight =
        it.lineHeight ?? (it.type === 'heading' ? HEADING_H : TEXT_H)
      // Split the column's lines by line break and split further if
      // needed to fit within column width
      return toArray(it.value)
        .flatMap(v => (v ? v.toString().split(LINE_REGEX) : []))
        .flatMap(
          v =>
            this.doc.splitTextToSize(
              v,
              it.type === 'heading' ? colWidths[i] - 20 : colWidths[i]
            ) as string[]
        )
        .map(v => {
          const text = it.noTrim ? v : v.trim()
          const line = {
            text,
            charSpace,
            fontSize,
            fontStyle,
            lineHeight,
            borders,
          }
          return line
        })
    })
    // Determine the max height needed across columns and add a new
    // page if necessary to keeps all lines together
    const maxLines = linesPerCol.reduce((acc, c) => {
      return c.length > acc ? c.length : acc
    }, 0)
    const totalHeight = linesPerCol.reduce((acc, c) => {
      const lineHeight = c.reduce((a, line) => a + line.lineHeight, 0)
      return lineHeight > acc ? lineHeight : acc
    }, 0)
    const gutter = extract(items, 'gutter') ?? this.pg.gutter
    const marginBottom = extract(items, 'marginBottom')
    const marginTop = extract(items, 'marginTop')
    const rowInfo = { totalHeight, maxLines, marginBottom, marginTop }
    return { colWidths, linesPerCol, gutter, ...rowInfo }
  }

  private setFont(type: Type, { color }: { color?: Color } = {}) {
    if (color) {
      this.setTextColor(color)
    }
    switch (type) {
      case 'title':
        this.doc.setFontSize(16)
        this.doc.setFont('Georgia')
        return
      case 'header-field':
        this.doc.setFontSize(8)
        this.doc.setFont('helvetica', 'normal')
        return
      case 'field':
        this.doc.setFontSize(10)
        this.doc.setFont('helvetica', 'bold')
        return
      case 'body':
      default:
        this.doc.setFontSize(10)
        this.doc.setFont('helvetica', 'normal')
        return
    }
  }

  private setTextColor(color: Color) {
    switch (color) {
      case 'white':
        this.color = 'white'
        return this.doc.setTextColor(255, 255, 255)
      case 'red':
        this.color = 'red'
        return this.doc.setTextColor(255, 0, 0)
      case 'gray-700':
        this.color = 'gray-700'
        return this.doc.setTextColor(78, 78, 78)
      case 'gray-500':
        this.color = 'gray-500'
        return this.doc.setTextColor(128, 128, 128)
      case 'gray-300':
        this.color = 'gray-300'
        return this.doc.setTextColor(178, 178, 178)
      case 'black':
      default:
        this.color = 'black'
        return this.doc.setTextColor(0, 0, 0)
    }
  }
}

function areItemsValid(items: FacPdfItem[]): boolean {
  if (items.length <= 1) {
    return true
  }
  const type = items[0].type
  return items.slice(1).every(it => it.type === type)
}

function createTextItem(value: string): FacPdfText {
  const text: FacPdfText = { type: 'text', value }
  return text
}

function toItemsRow(
  itemsOrText: string | FacPdfItem | (FacPdfItem | string)[]
): ItemRow {
  if (typeof itemsOrText === 'string') {
    return { type: 'text', items: [createTextItem(itemsOrText)] }
  }
  const items = toArray(itemsOrText).map(it =>
    typeof it === 'string' ? createTextItem(it) : it
  )
  if (items.length === 0) {
    return { type: 'text', items: [createTextItem('')] }
  }
  if (!areItemsValid(items)) {
    throw new Error('All items must be the same type.')
  }
  return { type: items[0].type, items } as ItemRow
}

function toItemRows(
  itemsOrText: string | (string | FacPdfItem | (FacPdfItem | string)[])[]
): ItemRow[] {
  if (typeof itemsOrText === 'string') {
    const text: FacPdfText = { type: 'text', value: itemsOrText }
    return [{ type: 'text', items: [text] }]
  }
  return itemsOrText.map(it => toItemsRow(it))
}

function extract<T, K extends keyof T>(
  items: T[],
  key: K
): NonNullable<T>[K] | undefined {
  return items.find(it => it[key] != null)?.[key]
}

function parseColWidths(
  items: FacPdfItem[],
  pg: FacultativePDFPageOptions,
  minItems = 0
) {
  const gutter = extract(items, 'gutter') ?? pg.gutter
  const colWidths = extract(items, 'colWidths') ?? []
  if (!colWidths.length) {
    const n = Math.max(items.length, minItems)
    const w = (pg.width - pg.margin * 2 - gutter * (n - 1)) / n
    return items.map(() => w)
  }
  // If every defined width is between 0-1, treat as percentages
  if (colWidths.every(w => 0 <= w && w <= 1)) {
    const wAvailable =
      pg.width - 2 * pg.margin - (colWidths.length - 1) * gutter
    return colWidths.map(w => w * wAvailable)
  }
  return colWidths
}

function createColumnCursor(
  cursor: Coord,
  colWidths: number[],
  colIndex: number,
  pg: FacultativePDFPageOptions
): Coord {
  const width = colWidths
    .slice(0, colIndex)
    .reduce((acc, w) => acc + w + pg.gutter, 0)
  return {
    x: pg.margin + width,
    y: cursor.y,
  }
}
