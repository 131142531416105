import { Injectable } from '@angular/core';
import { BackendService } from '@api/backend/backend.service'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class Html2PdfService {

  constructor(private backendService: BackendService) { }

  convertHtml2Pdf(label: string, innerHtmlText: string): Observable<ArrayBuffer> {
    const htmlText = this.buildHtmlPageForConversion(label, innerHtmlText)
    return this.backendService.postHtml2Pdf(htmlText)
  }

  private buildHtmlPageForConversion(label: string, innerHtml: string): string {
    const htmlTemplate =
      `<!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <title></title>
          <style>
            body {
                font-family: Helvetica, sans-serif;
                margin: 0;
            }
            .label {
                font-size: 16px;
                font-weight: bold;
            }
        </style>
      </head>
      <body>
        <div class="container">
            <div class="main-content">
                <span class="label">${label}</span>
                ${innerHtml}
            </div>
        </div>
      </body>
      </html>`
    return htmlTemplate
  }
}
