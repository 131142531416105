import { AppState } from '../index'
import { createSelector } from '@ngrx/store'

export const selectAuthState = (state: AppState) => state.authentication

export const selectAuthenticatingUser = createSelector(
  selectAuthState,
  state => state.authenticatingUser
)

export const selectIsAuthenticated = createSelector(
  selectAuthState,
  state => state.authenticated
)

export const selectUsername = createSelector(
  selectAuthState,
  state => state.username
)
