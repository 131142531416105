import { FacDataVersion } from '../models'

export function updateFacDataVersion(updatedVersion: FacDataVersion, versionList: readonly FacDataVersion[]): FacDataVersion[] {
  const updatedList = [...versionList]
  const index = versionList.findIndex(v => v.id === updatedVersion.id)
  if (index < 0) {
    return updatedList
  }

  updatedList[index] = updatedVersion
  return updatedList
}
