const StarrNames: string[] = [
    'Starr Specialty Lines Insurance Agency, LLC',
    'Starr Specialty Lines Insurance Company',
    'Starr Surplus Lines Insurance Company'
]

export function isStarrPropertyDeal(companyName: string, dealType: string): boolean {
    if (dealType === 'property' && StarrNames.includes(companyName))
        return true
    else
        return false
}