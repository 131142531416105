const TravelersNames : string[] = [
    'The Charter Oak Fire Insurance Company',
    'The Phoenix Insurance Company',
    'Travelers Excess and Surplus Lines Company',
    'The Travelers Indemnity Company',
    'The Travelers Indemnity Company of America',
    'The Travelers Lloyds Insurance Company',
    'Travelers Property Casualty Company of America'
]

export function isTravelersPropertyDeal(companyName: string, dealType: string) : boolean {
    if (dealType === 'property' && TravelersNames.includes(companyName))
        return true
    else
        return false
}

export function getTravelersReinsuranceConditions(): string[] {
    return [
        "Travelers Endorsement to Funding Clause-Property",
        "Travelers Endt to Service of Suit Clause for Lloyd's-CP-6067",
        "Travelers Funding Clause 16 - Reciprocal Jurisdiction Endorsement",
        "DX TI 00 11 12 (Deluxe)",
        "MS C1 00 10 18 (Property Coverage Form)",
        "XS P3 25 02 07 (Excess Property)",
        "MP T1 02 02 05 or CP T1 00 02 17 with Form ILT3 82 05 13 (Attached or included)",
        "S2976-IL, S2976-IL NY, CP 01 75 07 06 or CP 01 40 07 06 (Northfield)"        
    ]
}

export function getTravelersAdditionalTermsDefault(): string[] {

    return [
        'As respects the perils of flood and/or earthquake to which the annual aggregate(s) apply, it is agreed that this reinsurance steps down in the event that the underlying limit(s) are totally or partially exhausted. If the policy(ies) reinsured hereunder include flood and/or earthquake coverage, then regardless of whether or not flood and/or earthquake sublimits are within the Company Retention as set forth in this Declarations, flood and or/earthquake losses shall erode the Company Retention whether occurring in conjunction with an otherwise covered peril hereunder or not.',
        'Subject to The Travelers Certificate wording CP-6067 (New 3/99)',
        'Cyber exclusion as per Company\'s original policy per Travelers Policy Form',
        'Subject to Condition 21 - Travelers Standard Sanctions Clause for CP-6067'
    ]
}

export function getTravelersSubmissionDefaultConditions(): string[] {
    return [
        'Terrorism Exclusion per NMA 2921',  
        'Travelers Certificate Wording CP – 6067 New 3-99',
        'Cyber Exclusion per Travelers Standard',
        'Certificate to exclude virus or bacteria per Travelers Policy form – TBD',
        'Communicable Disease excluded per Travelers Virus or Bacteria Exclusion'
    ]
}