import { Injectable } from '@angular/core'
import { ZipFilesService } from './zip-files.service'
import {
  StoredFile,
  StoredFileBlob
} from '../../models'
import { BackendService } from '../../../api/backend/backend.service'

@Injectable({ providedIn: 'root' })
export class FileDownloadService {
  constructor(
    private zipService: ZipFilesService,
    private backEndService: BackendService
  ) {
  }

  async downloadToZip(storedFileItemList: ReadonlyArray<Readonly<StoredFile>>, zipFileName: string) {
    if (!storedFileItemList || storedFileItemList?.length === 0) {
      return
    }
    const files: StoredFileBlob[] = []
    const downloadPromises: Promise<Blob>[] = storedFileItemList.map(f => this.backEndService.downloadFacFile(f).toPromise())
    const blobs: Blob[] = await Promise.all(downloadPromises)
    for (let i = 0; i < storedFileItemList.length; i++) {
      files.push({
        name: storedFileItemList[i].name,
        blob: blobs[i],
      })
    }
    await this.zipService.createAndSaveZip(zipFileName, files)
  }
}
