import { Action, createReducer, on } from '@ngrx/store'
import { initialState, AuthState } from './auth.state'
import * as fromAuthActions from './auth.actions'

const authReducer = createReducer(
  initialState,
  on(
    fromAuthActions.loginSuccess,
    (state, { token, username, redirectURL, name }) => {
      return {
        ...state,
        username,
        authenticatingUser: name,
        name,
        token,
        authenticated: true,
        redirectURL,
      }
    }
  ),
  on(fromAuthActions.updateAuthToken, (state, { token }) => ({
    ...state,
    token,
  })),
)

export function reducer(state: AuthState | undefined, action: Action): AuthState {
  return authReducer(state, action)
}
