import {HttpErrorResponse} from '@angular/common/http'

export function getErrorMessage(e: unknown): string {
  if (e instanceof HttpErrorResponse) {
    return e?.error?.text ?? ''
  } else if (e instanceof Error) {
    return e.message
  }
  return ''
}
