import { Injectable } from '@angular/core'

import {
  BrowserCacheLocation,
  Configuration, InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser'
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular'
import { environment } from '@environments/environment'

@Injectable({
  providedIn: 'root'
})
export class MsalBootstrapService {

  static loggerCallback(logLevel: LogLevel, message: string): void {
    switch (logLevel) {
      case LogLevel.Error:
        console.error(message)
        break
      case LogLevel.Warning:
        console.warn(message)
        break
      case LogLevel.Info:
        console.info(message)
        break
      case LogLevel.Verbose:
      case LogLevel.Trace:
        console.debug(message)
        break
    }
  }

  static msalInstanceFactory(msalBootstrapService: MsalBootstrapService): IPublicClientApplication {
    return msalBootstrapService.createMsalInstance()
  }

  static msalInterceptorConfigFactory(msalBootstrapService: MsalBootstrapService): MsalInterceptorConfiguration {
    return msalBootstrapService.getMsalInterceptorConfiguration()
  }

  static msalGuardConfigFactory(msalBootstrapService: MsalBootstrapService): MsalGuardConfiguration {
    return msalBootstrapService.getMsalGuardConfiguration()
  }

  createMsalInstance(): IPublicClientApplication {
    const msalConfig: Configuration = this.getMsalConfiguration()
    return new PublicClientApplication(msalConfig)
  }

  getMsalConfiguration(): Configuration {
    return {
      auth: {
        clientId: environment.auth.clientId,
        authority: environment.auth.authority,
        knownAuthorities: environment.auth.knownAuthorities,
        redirectUri: window.location.origin,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
      },
      system: {
        allowNativeBroker: false,
        loggerOptions: {
          loggerCallback: MsalBootstrapService.loggerCallback,
          logLevel: LogLevel.Warning,
          piiLoggingEnabled: false
        }
      }
    }
  }

  getMsalGuardConfiguration(): MsalGuardConfiguration {
    return {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: this.getScopes()
      },
      loginFailedRoute: '/login/login-failed.html'
    }
  }

  getMsalInterceptorConfiguration(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>()
    protectedResourceMap.set(environment.internalApi.base, this.getScopes())
    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap
    }
  }

  private getScopes(): string[] {
    return [environment.auth.clientId, 'openid', 'profile', 'offline_access']
  }
}
