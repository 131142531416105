import { LayerWithQuotes, ReinsuredLayer } from '../../models'
import {
  formatFacPremiumRIString,
  formatSmartCurrency,
  formatSmartPercent,
  stripNonNumeric
} from '../../formatters'
import { FacFieldService } from '../../services'
import { REGEX_NON_NUMERIC } from '@constants'

export function getReinsurerLayers(fieldService: FacFieldService): LayerWithQuotes[] {
  const reinsuranceLayers: {
    reinsuranceLayer: ReinsuredLayer
    filteredQuotes: any[]
  }[] = fieldService.fetchFieldArray('customReinsuredQuote')
  if (reinsuranceLayers == null || reinsuranceLayers.length === 0) {
    return []
  }
  const result: LayerWithQuotes[] = []
  for (const layer of reinsuranceLayers) {
    if (!layer.reinsuranceLayer) {
      continue
    }
    let totalShares = 0
    let blendedPremium = 0
    let totalFacSpend = 0
    let grossShare = 0
    let capacityOffered = 0
    if (layer.filteredQuotes[0].qtcCheck) {
      // TODO: Fix any typing to correct type
      totalShares = layer.filteredQuotes.reduce((acc: number, next: any) => {
        return acc + next.cession
      }, 0)
      for (const layerQuote of layer.filteredQuotes) {
        grossShare +=
          (layerQuote.quotedPrice / (1 - layerQuote.commission / 100)) *
          (layerQuote.cession / 100)
        capacityOffered += (layerQuote.cession / 100) * layerQuote.limit
      }
      blendedPremium =
        (grossShare / capacityOffered) *
        Number(
          layer.reinsuranceLayer.reinsuranceLayer.replace(
            REGEX_NON_NUMERIC,
            ''
          )
        )
      // TODO: Fix any typing to correct type
      totalFacSpend = layer.filteredQuotes.reduce(
        (acc: number, next: any) => {
          return acc + formatFacPremiumRIString(next.quotedPrice, next.cession)
        },
        0
      )
    } else {
      // TODO: Fix any typing to correct type
      totalShares = layer.filteredQuotes.reduce((acc: number, next: any) => {
        return acc + next.Layer.cession
      }, 0)
      for (const layerQuote of layer.filteredQuotes) {
        grossShare += layerQuote.grossShare
        capacityOffered += layerQuote.Capacity
      }
      blendedPremium =
        (Number(stripNonNumeric(grossShare.toString())) /
          Number(stripNonNumeric(capacityOffered.toString()))) *
        Number(
          layer.reinsuranceLayer.reinsuranceLayer.replace(
            REGEX_NON_NUMERIC,
            ''
          )
        )
      totalFacSpend = layer.filteredQuotes.reduce(
        // TODO: Fix any typing to correct type
        (acc: number, next: any) => {
          return acc + formatFacPremiumRIString(next.netPrice, next.Layer.cession)
        },
        0
      )
    }
    // TODO: Fix any typing to correct type
    layer.filteredQuotes.forEach((e: any) => {
      e.quotedPrice = formatSmartCurrency(
        e.quotedPrice ? e.quotedPrice.toString() : ''
      )
      e.cession = formatSmartPercent(
        e.cession ? e.cession.toString() : ''
      )
      if (e.mgaArray) {
        // TODO: Fix any typing to correct type
        e.mgaArray.forEach((el: any) => {
          el.participation = formatSmartPercent(el.participation)
        })
      }
    })
    result.push({
      layer: layer.reinsuranceLayer,
      quotes: layer.filteredQuotes,
      totalShares,
      blendedPremium,
      totalFacSpend,
    })
  }
  result.sort((a, b) => a.layer.index - b.layer.index)
  return result
}

