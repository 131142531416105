import { createAction, props } from '@ngrx/store'
import { Auth } from '@auth/models'

export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<Auth>()
)

export const updateAuthToken = createAction(
  '[Auth] Update Token',
  props<{ token: string }>()
)
