import {
  FacData,
  ReinsuredAddress
} from '../../models'

export function getCertificateNameAndAddress(
  facData?: FacData | null,
  reinsuredAddress?: ReinsuredAddress | null
): string {

  const reinsuredName = facData?.reinsured ?? ''
  const facDataAddress = facData?.nameAndAddress

  if (facDataAddress && facDataAddress.trim().length > 0) {
    return facDataAddress
  } else if (reinsuredAddress?.streetAddress) {
    return `${reinsuredName}\n${reinsuredAddress?.streetAddress?.replace(/\r\n/, ' ')}\n${reinsuredAddress?.city}, ${reinsuredAddress?.stateCode} ${reinsuredAddress?.postalCode}\n${reinsuredAddress?.country}`
  }
  return `${reinsuredName}`
}
