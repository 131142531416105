import {
  DealSummary,
  FacDataVersion,
  Facultative,
  FacUser,
  FormCategoryConfig,
  DealSearchCriteria,
  NewFormFields,
  ReinsuredAddress,
  SalesforceAccount,
  SalesforceContact,
  DealsViewSettings
} from '../models'

import {
  FacBasisOfAcceptance,
  FacCurrency,
  FacReinsuranceConditions,
  FacReinsurer,
  FacTermsAndConditions
} from '@api/model'

export interface Pagination {
  currentPage: number
  totalPages: number
}

export const initialPagination: Pagination = {
  currentPage: 1,
  totalPages: 1,
}

export const initialSettings: DealsViewSettings = {
  selectedType: 'property',
  viewType: 'cardView',
  renewal: false,
}

export interface IFacultativeState {
  allFacUsers: readonly FacUser[],
  brokerFilterPicklist: readonly string[],
  basisOfAcceptance: FacBasisOfAcceptance[]
  coverageReinsured: string
  creatingFormVersion: boolean
  currency: FacCurrency[]
  headerTitle: string | null
  currentDeal: number | null
  currentDealName: string | null
  currentDealType: string | null
  currentFormCategory: string | null
  currentVersion: FacDataVersion | null
  currentViewPreference: string
  dealSummaries: readonly DealSummary[]
  deletingFormVersion: boolean
  dirty: boolean
  error?: string
  facSearchCriteria: DealSearchCriteria
  idsToUpdate: number[]
  isDirty: boolean
  isLoading: boolean
  isSaving: boolean
  isExporting: boolean
  isDealSidebarVisible: boolean
  pagination: Pagination
  reinsuranceConditions: FacReinsuranceConditions[]
  reinsurersList: readonly FacReinsurer[]
  salesforceAccounts: SalesforceAccount[]
  salesforceContacts: SalesforceContact[]
  saveDate: string
  selectedDeal: Facultative | null
  settings: DealsViewSettings
  termsAndConditions: FacTermsAndConditions[]
  newFormFields: NewFormFields | null
  formCategories: FormCategoryConfig[]
  reinsuredAddress: ReinsuredAddress | null
}

export const initialFacSearchCriteria: DealSearchCriteria = {
  coverageReinsured: '',
  dealName: '',
  facType: '',
  formIsActive: false,
  inceptionDate: '',
  ntuOptions: 'Both',
  renewal: false,
  selectedType: '',
  username: '',
  viewType: '',
  sortOption: {
    active: 'policyPeriodFrom',
    direction: 'desc',
  },
  page: 1
}

export const initialFacultativeState: IFacultativeState = {
  allFacUsers: [],
  brokerFilterPicklist: [],
  basisOfAcceptance: [],
  coverageReinsured: '',
  creatingFormVersion: false,
  currency: [],
  headerTitle: null,
  currentDeal: null,
  currentDealName: null,
  currentDealType: null,
  currentFormCategory: null,
  currentVersion: null,
  currentViewPreference: '',
  dealSummaries: [],
  deletingFormVersion: false,
  dirty: false,
  error: '',
  facSearchCriteria: initialFacSearchCriteria,
  idsToUpdate: [],
  isDirty: false,
  isLoading: false,
  isSaving: false,
  isExporting: false,
  isDealSidebarVisible: true,
  newFormFields: null,
  pagination: initialPagination,
  reinsuranceConditions: [],
  reinsurersList: [],
  salesforceAccounts: [],
  salesforceContacts: [],
  saveDate: '',
  selectedDeal: null,
  settings: initialSettings,
  termsAndConditions: [],
  formCategories: [
    { category: 'submission', title: 'Submission', icon: 'radio_button_unchecked' },
    { category: 'responses', title: 'Responses', icon: 'radio_button_unchecked' },
    { category: 'qtc', title: 'Quote to Client', icon: 'radio_button_unchecked' },
    { category: 'binder', title: 'Binder', icon: 'radio_button_unchecked' },
    { category: 'certificate', title: 'Certificate', icon: 'radio_button_unchecked' },
  ],
  reinsuredAddress: null
}
