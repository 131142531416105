import { FacUser } from '../models'
import { formatLocktonContactLines } from '../formatters'

export function generateCasualtyQuoteLetterText(recipientName: string, broker?: FacUser | null): string {
  const brokerName: string = broker?.name ?? 'Lockton Re'
  const brokerEmail: string = broker?.email ?? ''
  const brokerPhone: string = broker?.phone ?? ''
  const contactLines: string = formatLocktonContactLines(!!broker?.isUk, broker).join('\n')
  return `Dear ${recipientName},\n\nThank you for the opportunity. We are pleased to present the formal quoted terms for your review.\n\n`
    + `Please reach out with any questions. We look forward to your feedback to secure this placement.\n\n`
    + `Kind Regards,\n\n\n${brokerName}\n${contactLines}\nphone: ${brokerPhone}\nemail: ${brokerEmail}`
}
