import { FieldInterface } from '../../../models'
import { EnvValidation } from '../../../../../environments/environment-common'

export const casualtyQtcFields: FieldInterface = {
  name: 'qtc-1',
  type: 'casualty',
  fields: [
    [
      {
        id: 'reinsured',
        type: 'textarea',
        label: 'Ceding Company',
        readonly: 'true',
        validations: [],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
    ],
    [
      {
        id: 'reinsuredUnderwriter',
        type: 'textarea',
        label: 'Reinsured Underwriter',
        inputType: 'text',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Reinsured Underwriter Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: true,
      },
      {
        id: 'name',
        type: 'textarea',
        label: 'Named Insured',
        inputType: 'text',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Named Insured Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
    ],
    [
      {
        id: 'streetAddress',
        type: 'textarea',
        label: 'Address',
        inputType: 'text',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Street Address Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
    ],
    [
      {
        id: 'cityName',
        type: 'textarea',
        label: 'City',
        inputType: 'text',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'City Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
      {
        id: 'stateName',
        type: 'select',
        label: 'State',
        readonly: 'false',
        options: [
          '',
          'AK',
          'AL',
          'AR',
          'AS',
          'AZ',
          'CA',
          'CO',
          'CT',
          'DC',
          'DE',
          'FL',
          'GA',
          'GU',
          'HI',
          'IA',
          'ID',
          'IL',
          'IN',
          'KS',
          'KY',
          'LA',
          'MA',
          'MD',
          'ME',
          'MI',
          'MN',
          'MO',
          'MP',
          'MS',
          'MT',
          'NC',
          'ND',
          'NE',
          'NH',
          'NJ',
          'NM',
          'NV',
          'NY',
          'OH',
          'OK',
          'OR',
          'PA',
          'PR',
          'RI',
          'SC',
          'SD',
          'TN',
          'TX',
          'UM',
          'UT',
          'VA',
          'VI',
          'VT',
          'WA',
          'WI',
          'WV',
          'WY',
        ],
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'State Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
      {
        id: 'zipCode',
        type: 'textarea',
        label: 'Zip Code',
        inputType: 'text',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Zip Code Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
    ],
    [
      {
        id: 'policyPeriodFrom',
        type: 'date',
        label: 'Policy Inception',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Policy Inception Required',
          },
        ],
        value: '',
      },
      {
        id: 'policyPeriodTo',
        type: 'date',
        label: 'Policy Expiration',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Policy Expiration Required',
          },
        ],
        value: '',
      },
    ],
    [
      {
        id: 'reinsurancePeriodFrom',
        type: 'date',
        label: 'Reinsurance Inception',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Reinsurance Inception Required',
          },
        ],
        value: '',
      },
      {
        id: 'reinsurancePeriodTo',
        type: 'date',
        label: 'Reinsurance Expiration',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Reinsurance Expiration Required',
          },
        ],
        value: '',
      },
    ],
    [
      {
        id: 'coverageReinsured',
        type: 'select',
        label: 'Coverage Reinsured',
        readonly: 'false',
        options: [
          'Auto Liability',
          'Excess Auto Liability',
          'General Liability',
          'Umbrella / Excess Liability',
          "Workers' Compensation",
          'Umbrella / Excess Liability - General Liability Carve Out',
          'Umbrella / Excess Liability - Auto Liability Carve Out',
          'Cyber Liability',
          'Directors & Officers Liability',
          'Errors & Omissions Liability',
          'Miscellaneous Liability'
        ],
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Name Required',
          },
        ],
        value: '',
      },
    ],
    [
      {
        id: 'policyRetroDate',
        type: 'date',
        label: 'Policy Retro Date',
        readonly: 'readonly',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Policy Retro Date Required',
          },
        ],
        value: '',
      },
      {
        id: 'policyRetroComment',
        type: 'select',
        label: 'Policy Retro Date',
        readonly: 'readonly',
        options: [
          '',
          'Full Prior Acts',
          'Follow Form',
        ],
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Policy Retro Required',
          },
        ],
        value: ''
      },
      {
        id: 'ReinsuranceRetroDate',
        type: 'date',
        label: 'Reinsurance Retro Date',
        readonly: 'readonly',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Reinsurance Retro Date Required',
          },
        ],
        value: '',
      },
    ],
    [
      {
        id: 'policyType',
        type: 'select',
        label: 'Policy Type',
        readonly: 'false',
        options: [
          'Primary',
          'Excess',
        ],
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Name Required',
          },
        ],
        value: 'Excess',
      },
    ],
    [
      {
        id: 'policyLimit',
        type: 'input',
        label: 'Company Policy Limit',
        inputType: 'currency',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Policy Limit Value Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
        showCurrency: true,
      },
    ],
    [
      {
        id: 'programStructure',
        type: 'select',
        label: 'Program Structure',
        readonly: 'false',
        options: [
          'Guaranteed Cost',
          'Self-Insured Retention',
          'Deductible',
        ],
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Program Structure Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
      {
        id: 'retentionAmount',
        type: 'input',
        label: 'Retention Amount',
        inputType: 'currency',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Retention Required',
          },
        ],
        value: '',
        showCurrency: true,
        defaultValue: '0',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
    ],
    [
      {
        id: 'currency',
        type: 'select',
        label: 'Currency',
        value: '',
        readonly: 'false',
        options: [],
        validations: [],
        defaultValue: 'USD',
      },
    ],
    [
      {
        id: 'rateAdjustability',
        type: 'textarea',
        label: 'Subject to Audit',
        inputType: 'text',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Subject to Audit Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
        defaultValue: 'Rate Premium is Flat',
      },
    ],
    [
      {
        id: 'rate',
        type: 'textarea',
        label: 'Rate',
        inputType: 'text',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Rate Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
        defaultValue: '',
      },
    ],
    [
      {
        id: 'minimum',
        type: 'input',
        label: 'Minimum',
        inputType: 'percentage',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Minimum Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
        defaultValue: '100.00%',
      },
      {
        id: 'deposit',
        type: 'input',
        label: 'Deposit',
        inputType: 'percentage',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Deposit Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
        defaultValue: '100.00%',
      },
    ],
    [
      {
        id: 'reinsuranceCoverage',
        type: 'textarea',
        label: 'Reinsurance Coverage',
        inputType: 'text',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Reinsurance Coveragae Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
        defaultValue: 'Follow Form Company Policy',
      },
    ],
    [
      {
        id: 'additionalReinsuranceConditions',
        type: 'textarea',
        label: 'Additional Reinsurance Conditions',
        inputType: 'text',
        readonly: 'false',
        validations: [],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
        bullets: true,
        defaultValue: 'N/A',
      },
    ],
    [
      {
        id: 'brokerageCommissionSub',
        type: 'input',
        label: 'Brokerage Commission',
        inputType: 'percentage',
        readonly: 'false',
        validations: [],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
        defaultValue: '10.00%',
      },
    ],
    [
      {
        id: 'cedingCommission',
        type: 'input',
        label: 'Ceding Commission',
        inputType: 'percentage',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Please enter value less than or equal to 20%',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
    ],
    [
      {
        id: 'basisOfAcceptance',
        type: 'select',
        label: 'Basis of Acceptance',
        readonly: 'false',
        options: [],
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Basis of Acceptance Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
    ],
    [
      {
        id: 'allocatedLossAdjustmentExpense',
        type: 'select',
        label: 'ALAE',
        options: [
          'Outside Limit / Outside Certificate',
          'Inside Limit / Inside Certificate',
          'Capped',
          'Excluding Defense',
        ],
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'ALAE Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
    ],
    [
      {
        id: 'notableTerms',
        type: 'textarea',
        label: 'Notable Terms',
        inputType: 'text',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Notable Terms Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
    ],
    [
      {
        id: 'reinsuranceCertificate',
        type: 'textarea',
        label: 'Reinsurance Certificate',
        inputType: 'text',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Reinsurance Certificate Required',
          },
        ],
        value: '',
        defaultValue: 'Lockton Re Master Certificate',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
    ],
    [
      {
        id: 'termsAndConditions',
        type: 'select',
        label: 'General Conditions',
        readonly: 'false',
        options: [],
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Terms and Conditions Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
    ],

    [
      {
        id: 'paymentDue',
        type: 'textarea',
        label: 'Payment Due',
        readonly: 'false',
        inputType: 'text',
        validations: [] as EnvValidation[],
        value: '',
        defaultValue:
          '60 days from the end of the month in which the policy is effective.',
      },
    ],
  ],
}

export const propertyQtcFields: FieldInterface = {
  name: 'qtc-1',
  type: 'property',
  fields: [
    [
      {
        id: 'reinsuredUnderwriter',
        type: 'textarea',
        label: 'Reinsured Underwriter',
        inputType: 'text',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Reinsured Underwriter Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: true,
      },
      {
        id: 'name',
        type: 'textarea',
        label: 'Named Insured',
        inputType: 'text',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Named Insured Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
    ],
    [
      {
        id: 'reinsured',
        type: 'textarea',
        label: 'Reinsured',
        readonly: 'true',
        validations: [] as EnvValidation[],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
      {
        id: 'currency',
        type: 'select',
        label: 'Currency',
        value: '',
        readonly: 'false',
        validations: [] as EnvValidation[],
        // defaultValue: 'USD',
      },
    ],
    [
      {
        id: 'streetAddress',
        type: 'textarea',
        label: 'Insured Street Address',
        inputType: 'text',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Street Address Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
    ],
    [
      {
        id: 'cityName',
        type: 'textarea',
        label: 'City',
        inputType: 'text',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'City Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
      {
        id: 'stateName',
        type: 'select',
        label: 'State',
        readonly: 'false',
        options: [
          '',
          'AK',
          'AL',
          'AR',
          'AS',
          'AZ',
          'CA',
          'CO',
          'CT',
          'DC',
          'DE',
          'FL',
          'GA',
          'GU',
          'HI',
          'IA',
          'ID',
          'IL',
          'IN',
          'KS',
          'KY',
          'LA',
          'MA',
          'MD',
          'ME',
          'MI',
          'MN',
          'MO',
          'MP',
          'MS',
          'MT',
          'NC',
          'ND',
          'NE',
          'NH',
          'NJ',
          'NM',
          'NV',
          'NY',
          'OH',
          'OK',
          'OR',
          'PA',
          'PR',
          'RI',
          'SC',
          'SD',
          'TN',
          'TX',
          'UM',
          'UT',
          'VA',
          'VI',
          'VT',
          'WA',
          'WI',
          'WV',
          'WY',
        ],
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'State Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
      {
        id: 'zipCode',
        type: 'textarea',
        label: 'Zip Code',
        inputType: 'text',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Zip Code Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
    ],
    [
      {
        id: 'policyPeriodFrom',
        type: 'date',
        label: 'Policy Inception',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Policy Inception Required',
          },
        ],
        value: '',
      },
      {
        id: 'policyPeriodTo',
        type: 'date',
        label: 'Policy Expiration',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Policy Expiration Required',
          },
        ],
        value: '',
      },
    ],
    [
      {
        id: 'reinsurancePeriodFrom',
        type: 'date',
        label: 'Reinsurance Inception',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Reinsurance Inception Required',
          },
        ],
        value: '',
      },
      {
        id: 'reinsurancePeriodTo',
        type: 'date',
        label: 'Reinsurance Expiration',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Reinsurance Expiration Required',
          },
        ],
        value: '',
      },
    ],
    [
      {
        id: 'typeOfInsurance',
        type: 'select',
        label: 'Perils Insured',
        readonly: 'false',
        options: [
          'All Risks of Physical Loss or Damage',
          'All Risks',
          'Difference in Conditions',
          'Named Perils',
          'Only',
        ],
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Perils Insured Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
      {
        id: 'including',
        type: 'multiselect',
        label: 'Including',
        readonly: 'false',
        hideSelector: false,
        bullets: true,
        options: [
          'All Other Wind',
          'Boiler & Machinery',
          'California Earthquake',
          'Earth Movement',
          'Earthquake',
          'Equipment Breakdown',
          'Fire',
          'Flood',
          'Hail',
          'Named Storm',
          'Named Windstorm',
          'Storm Surge',
          'Terrorism',
          'Wind Driven Precipitation',
          'Wind',
          'Windstorm',
        ],
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Perils Insured Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
      {
        id: 'excluding',
        type: 'multiselect',
        label: 'Excluding',
        hideSelector: false,
        bullets: true,
        readonly: 'false',
        options: [
          'All Other Wind',
          'Boiler & Machinery',
          'California Earthquake',
          'Earth Movement',
          'Earthquake',
          'Equipment Breakdown',
          'Fire',
          'Flood',
          'Hail',
          'Named Storm',
          'Named Windstorm',
          'Storm Surge',
          'Terrorism',
          'Wind Driven Precipitation',
          'Wind',
          'Windstorm',
        ],
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Perils Insured Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
      {
        id: 'only',
        type: 'multiselect',
        label: 'Only',
        hideSelector: false,
        bullets: true,
        readonly: 'false',
        options: [
          'All Other Wind',
          'Boiler & Machinery',
          'California Earthquake',
          'Earth Movement',
          'Earthquake',
          'Equipment Breakdown',
          'Fire',
          'Flood',
          'Hail',
          'Named Storm',
          'Named Windstorm',
          'Storm Surge',
          'Terrorism',
          'Wind Driven Precipitation',
          'Wind',
          'Windstorm',
        ],
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Perils Insured Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
    ],
    [
      {
        id: 'policyLimit',
        type: 'input',
        label: 'Company Policy Limit',
        inputType: 'currency',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Policy Limit Value Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
        showCurrency: true,
      },
    ],
    [
      {
        id: 'companyRetentionNetOrTreaty',
        type: 'textarea',
        label: 'Company Retention Net and/or Treaty',
        inputType: 'text',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Company Retention Net and/or Treaty Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
    ],
    [
      {
        id: 'cedingCommission',
        type: 'input',
        label: 'Ceding Commission',
        inputType: 'percentage',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Please enter value less than or equal to 20%',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
    ],
    [
      {
        id: 'brokerageCommissionSub',
        type: 'input',
        label: 'Brokerage Commission',
        inputType: 'percentage',
        readonly: 'false',
        validations: [],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
        defaultValue: '10.00%',
      },
    ],
    [
      {
        id: 'basisOfAcceptance',
        type: 'select',
        label: 'Basis of Acceptance',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Basis of Acceptance Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
    ],
    [
      {
        id: 'additionalReinsuranceConditions',
        type: 'textarea',
        label: 'Additional Reinsurance Conditions',
        inputType: 'text',
        readonly: 'false',
        validations: [] as EnvValidation[],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
        bullets: true,
        defaultValue: 'N/A',
      },
    ],
    [
      {
        id: 'termsAndConditions',
        type: 'select',
        label: 'General Conditions',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Terms and Conditions Required',
          },
        ],
        value: '',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
    ],
    [
      {
        id: 'reinsuranceCertificate',
        type: 'textarea',
        label: 'Reinsurance Certificate',
        inputType: 'text',
        readonly: 'false',
        validations: [
          {
            name: 'required',
            validator: 'required',
            message: 'Reinsurance Certificate Required',
          },
        ],
        value: '',
        defaultValue: 'Lockton Re Master Certificate',
        hiddenToggle: true,
        hiddenToggleValue: false,
      },
    ],
  ],
}
