import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { HttpHeaders } from '@angular/common/http'
import { map, Observable } from 'rxjs'
import {
  ApiResponse,
  FacBasisOfAcceptance,
  FacBinder,
  FacCurrency,
  DealSummariesResultDto,
  FacMailingGroups,
  FacNamedInsured,
  FacPropertySubmission,
  FacReinsuranceConditions,
  FacReinsurer,
  FacTermsAndConditions,
  FacTypeOfInsurance,
  MaybeError,
  PostEmailElement,
  PostFacDataElement,
  UserDto,
} from '../model'
import { environment } from '@environments/environment'
import { catchAndHandleError, mapToMaybeData } from '../util'

import {
  FacData,
  FacDataVersion,
  Facultative,
  DealSearchCriteria,
  ISort,
  SalesforceAccount,
  SalesforceContact,
  SortDirection,
  StoredFile
} from '@deals/models'
import { escapeHtml } from '@shared/util'

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
}

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  constructor(private httpClient: HttpClient) {}

  getAllUsers(): ApiResponse<UserDto[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.userRoles}/facultative-contacts`
    return this.httpClient
      .get<UserDto[]>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('Users'))
  }

  getBrokerFilterList(): ApiResponse<string[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.facLookups}/dealBrokerNamesFilter`
    return this.httpClient
      .get<string[]>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('GET Deal Broker Names Filter'))
  }

  getSalesforceAccounts(): ApiResponse<SalesforceAccount[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.facData}/salesforceaccounts`
    return this.httpClient
      .get<SalesforceAccount[]>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('Salesforce Accounts'))
  }

  getSalesforceContacts(): ApiResponse<SalesforceContact[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.facData}/salesforceContacts`
    return this.httpClient
      .get<SalesforceContact[]>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('Salesforce Contacts'))
  }

  getFacDealSummaries(searchCriteria: DealSearchCriteria): Observable<DealSummariesResultDto> {
    const url = `${environment.internalApi.base}${environment.internalApi.facDealsummaries}`
    let params = new HttpParams()
    params = params.append('ntuOptions', searchCriteria.ntuOptions ?? 'Both')
    params = params.append('limit', 20)
    if (searchCriteria.dealName) {
      params = params.append("dealName", searchCriteria.dealName)
    }
    if (searchCriteria.facType) {
      params = params.append("facType", searchCriteria.facType)
    }
    if (searchCriteria.coverageReinsured && searchCriteria.facType?.toLowerCase() !== 'property') {
      params = params.append("coverageReinsured", searchCriteria.coverageReinsured)
    }
    if (searchCriteria.username) {
      params = params.append("username", searchCriteria.username)
    }    
    if (searchCriteria.inceptionDate) {
      params = params.append("inceptionDate", searchCriteria.inceptionDate)
    }
    if (searchCriteria.page) {
      params = params.append("page", searchCriteria.page)
    }
    const sortOption: ISort = searchCriteria.sortOption
    if (sortOption && sortOption.active) {
      const direction: SortDirection = sortOption.direction === 'desc' ? 'desc' : 'asc'
      params = params.append("sort", sortOption.active)
      params = params.append("direction", direction)
    }
    return this.httpClient.get<DealSummariesResultDto>(url, { params })
  }

  getFacDeal(id: string | null): ApiResponse<Facultative> {
    const url = `${environment.internalApi.base}${environment.internalApi.facData}/facDataDetailsWithVersions/${id}`
    return this.httpClient
    .get<Facultative>(url)
    .pipe(mapToMaybeData(), catchAndHandleError('Fac Deal'))
  }

  deleteFacData(id: number): Observable<any> {
    const url = `${environment.internalApi.base}${environment.internalApi.facData}/${id}`
    return this.httpClient
      .delete(url)
      .pipe(mapToMaybeData(), catchAndHandleError('DELETE Fac Data'))
  }

  postFacData(facData: PostFacDataElement): Observable<any> {
    const url = `${environment.internalApi.base}${environment.internalApi.facData}`
    return this.httpClient
      .post(url, facData)
      .pipe(catchAndHandleError('Create New Fac Data'))
  }

  putFacData(facData: FacData): Observable<any> {
    const url =
      `${environment.internalApi.base}${environment.internalApi.facData}` +
      '/' +
      facData.id
    return this.httpClient
      .put(url, facData)
      .pipe(catchAndHandleError('Put Fac Data'))
  }

  replicateFacData(
    id: string,
    body: {
      name: string
      user: string
      nameOfInsured: string
      reinsuredUW: string
      reinsuredName: string
      reinsuredId: string
      renewal?: boolean
    }
  ): Observable<any> {
    const url = `${environment.internalApi.base}${environment.internalApi.facData}/replicate/${id}`
    return this.httpClient
      .post<any>(url, body)
      .pipe(catchAndHandleError('Replicate Fac Data'))
  }

  markFacDataAsNTU(id: number, ntu: string): Observable<boolean> {
    const url = `${environment.internalApi.base}${environment.internalApi.facData}/ntu/${id}`
    return this.httpClient.put<any>(url, { ntu }).pipe(map(() => true))
  }

  updateDealBroker(id: number, brokerName: string): Observable<boolean> {
    const url = `${environment.internalApi.base}${environment.internalApi.facData}/${id}/broker`
    return this.httpClient.patch(url, { name: brokerName }).pipe(map(() => true))
  }

  postFacDataVersion(facDataVersion: FacDataVersion): Observable<any> {
    const url = `${environment.internalApi.base}${environment.internalApi.facDataVersions}`
    return this.httpClient
      .post(url, facDataVersion)
      .pipe(catchAndHandleError('Create New Fac Data Version'))
  }

  putFacDataVersion(facDataVersion: FacDataVersion): Observable<any> {
    const url =
      `${environment.internalApi.base}${environment.internalApi.facDataVersions}` +
      '/' +
      facDataVersion.id
    return this.httpClient
      .put(url, facDataVersion)
      .pipe(catchAndHandleError('Put Fac Data Version'))
  }

  deleteFacDataVersion(id: number): Observable<any> {
    const url = `${environment.internalApi.base}${environment.internalApi.facDataVersions}/${id}`
    return this.httpClient
      .delete(url)
      .pipe(catchAndHandleError('Delete Fac Data Version'))
  }

  postFacEmail(email: PostEmailElement): Observable<any> {
    const url = `${environment.internalApi.base}${environment.internalApi.sendEmail}`
    return this.httpClient
      .post(url, email)
      .pipe(catchAndHandleError('Send Email'))
  }

  // Upload attached fac files
  postFacFiles(fd: FormData): Observable<MaybeError> {
    const url = `${environment.internalApi.base}${environment.internalApi.facFiles}`
    return this.httpClient
      .post(url, fd)
      .pipe(catchAndHandleError('Upload Fac Documents'))
  }

  // Delete attached fac files
  deleteFacDocument(facId: number, fileName: string): Observable<MaybeError> {
    const url = `${environment.internalApi.base}${environment.internalApi.facFiles}${environment.internalApi.deleteFacDocument}/${facId}/${fileName}`
    return this.httpClient
      .delete(url)
      .pipe(catchAndHandleError('Delete Fac Documents'))
  }

  // Download attached fac files
  downloadFacFile(file: Readonly<StoredFile>): Observable<Blob> {
    const fileName = file.name.toString()
    const facId = file.id
    const url = `${environment.internalApi.base}${environment.internalApi.facFiles}/${facId}/${fileName}`
    return this.httpClient.get(url, { responseType: 'blob' })
  }

  getFacultativePropertySubmission(): ApiResponse<FacPropertySubmission[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.facPropertySubmission}`
    return this.httpClient
      .get<FacPropertySubmission[]>(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('GET Facultative Property Submission')
      )
  }

  getFacultativeReinsurer(): ApiResponse<FacReinsurer[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.facReinsurer}`
    return this.httpClient
      .get<FacReinsurer[]>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('GET Facultative Reinsurer'))
  }

  postFacultativeReinsurer(body: any) {
    const url = `${environment.internalApi.base}${environment.internalApi.facReinsurer}`
    return this.httpClient
      .post(url, body, httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('POST Facultative Reinsurer'))
  }

  putFacultativeReinsurer(id: any, body: any) {
    const url = `${environment.internalApi.base}${environment.internalApi.facReinsurer}/${id}`
    return this.httpClient
      .put(url, body, httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('PUT Facultative Reinsurer'))
  }

  deleteFacultativeReinsurer(id: any) {
    const url = `${environment.internalApi.base}${environment.internalApi.facReinsurer}/${id}`
    return this.httpClient
      .delete(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('DELETE Facultative Reinsurer')
      )
  }

  getFacultativeBasisOfAcceptance(): ApiResponse<FacBasisOfAcceptance[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.facbasisofacceptance}`
    return this.httpClient
      .get<FacBasisOfAcceptance[]>(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('GET Facultative Basis of Acceptance')
      )
  }

  postFacultativeBasisOfAcceptance(body: any) {
    const url = `${environment.internalApi.base}${environment.internalApi.facbasisofacceptance}`
    return this.httpClient
      .post(url, body, httpOptions)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('POST Facultative Basis of Acceptance')
      )
  }

  putFacultativeBasisOfAcceptance(id: any, body: any) {
    const url = `${environment.internalApi.base}${environment.internalApi.facbasisofacceptance}/${id}`
    return this.httpClient
      .put(url, body, httpOptions)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('PUT Facultative Basis of Acceptance')
      )
  }

  deleteFacultativeBasisOfAcceptance(id: any) {
    const url = `${environment.internalApi.base}${environment.internalApi.facbasisofacceptance}/${id}`
    return this.httpClient
      .delete(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('DELETE Facultative Basis of Acceptance')
      )
  }

  getFacultativeCurrency(): ApiResponse<FacCurrency[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.faccurrency}`
    return this.httpClient
      .get<FacCurrency[]>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('GET Facultative Currency'))
  }

  postFacultativeCurrency(body: any) {
    const url = `${environment.internalApi.base}${environment.internalApi.faccurrency}`
    return this.httpClient
      .post(url, body, httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('POST Facultative Currency'))
  }

  putFacultativeCurrency(id: any, body: any) {
    const url = `${environment.internalApi.base}${environment.internalApi.faccurrency}/${id}`
    return this.httpClient
      .put(url, body, httpOptions)
      .pipe(mapToMaybeData(), catchAndHandleError('PUT Facultative Currency'))
  }

  deleteFacultativeCurrency(id: any) {
    const url = `${environment.internalApi.base}${environment.internalApi.faccurrency}/${id}`
    return this.httpClient
      .delete(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('DELETE Facultative Currency')
      )
  }

  getFacultativeNamedInsured(): ApiResponse<FacNamedInsured[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.facnamedinsured}`
    return this.httpClient
      .get<FacNamedInsured[]>(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('GET Facultative Named Insured')
      )
  }

  postFacultativeNamedInsured(body: any) {
    const url = `${environment.internalApi.base}${environment.internalApi.facnamedinsured}`
    return this.httpClient
      .post(url, body, httpOptions)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('POST Facultative Named Insured')
      )
  }

  putFacultativeNamedInsured(id: any, body: any) {
    const url = `${environment.internalApi.base}${environment.internalApi.facnamedinsured}/${id}`
    return this.httpClient
      .put(url, body, httpOptions)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('PUT Facultative Named Insured')
      )
  }

  deleteFacultativeNamedInsured(id: any) {
    const url = `${environment.internalApi.base}${environment.internalApi.facnamedinsured}/${id}`
    return this.httpClient
      .delete(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('DELETE Facultative Named Insured')
      )
  }

  getFacultativeReinsuranceConditions(): ApiResponse<
    FacReinsuranceConditions[]
  > {
    const url = `${environment.internalApi.base}${environment.internalApi.facreinsuranceconditions}`
    return this.httpClient
      .get<FacReinsuranceConditions[]>(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('GET Facultative Reinsurance Conditions')
      )
  }

  postFacultativeReinsuranceConditions(body: any) {
    const url = `${environment.internalApi.base}${environment.internalApi.facreinsuranceconditions}`
    return this.httpClient
      .post(url, body, httpOptions)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('POST Facultative Reinsurance Conditions')
      )
  }

  putFacultativeReinsuranceConditions(id: any, body: any) {
    const url = `${environment.internalApi.base}${environment.internalApi.facreinsuranceconditions}/${id}`
    return this.httpClient
      .put(url, body, httpOptions)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('PUT Facultative Reinsurance Conditions')
      )
  }

  deleteFacultativeReinsuranceConditions(id: any) {
    const url = `${environment.internalApi.base}${environment.internalApi.facreinsuranceconditions}/${id}`
    return this.httpClient
      .delete(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('DELETE Facultative Reinsurance Conditions')
      )
  }

  getFacultativeTermsAndConditions(): ApiResponse<FacTermsAndConditions[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.factermsandconditions}`
    return this.httpClient
      .get<FacTermsAndConditions[]>(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('GET Facultative Terms and Conditions')
      )
  }

  postFacultativeTermsAndConditions(body: any) {
    const url = `${environment.internalApi.base}${environment.internalApi.factermsandconditions}`
    return this.httpClient
      .post(url, body, httpOptions)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('POST Facultative Terms and Conditions')
      )
  }

  putFacultativeTermsAndConditions(id: any, body: any) {
    const url = `${environment.internalApi.base}${environment.internalApi.factermsandconditions}/${id}`
    return this.httpClient
      .put(url, body, httpOptions)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('PUT Facultative Terms and Conditions')
      )
  }

  deleteFacultativeTermsAndConditions(id: any) {
    const url = `${environment.internalApi.base}${environment.internalApi.factermsandconditions}/${id}`
    return this.httpClient
      .delete(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('DELETE Facultative Terms and Conditions')
      )
  }

  getFacultativeTypeOfInsurance(): ApiResponse<FacTypeOfInsurance[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.factypeofinsurance}`
    return this.httpClient
      .get<FacTypeOfInsurance[]>(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('GET Facultative Type of Insurance')
      )
  }

  postFacultativeTypeOfInsurance(body: any) {
    const url = `${environment.internalApi.base}${environment.internalApi.factypeofinsurance}`
    return this.httpClient
      .post(url, body, httpOptions)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('POST Facultative Type of Insurance')
      )
  }

  putFacultativeTypeOfInsurance(id: any, body: any) {
    const url = `${environment.internalApi.base}${environment.internalApi.factypeofinsurance}/${id}`
    return this.httpClient
      .put(url, body, httpOptions)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('PUT Facultative Type of Insurance')
      )
  }

  deleteFacultativeTypeOfInsurance(id: any) {
    const url = `${environment.internalApi.base}${environment.internalApi.factypeofinsurance}/${id}`
    return this.httpClient
      .delete(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('DELETE Facultative Type of Insurance')
      )
  }

  getFacultativeBinder(): ApiResponse<FacBinder[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.facBinder}`
    return this.httpClient
      .get<FacBinder[]>(url)
      .pipe(mapToMaybeData(), catchAndHandleError('GET Facultative Binder'))
  }

  getFacultativeMailingGroup(): ApiResponse<FacMailingGroups[]> {
    const url = `${environment.internalApi.base}${environment.internalApi.facMailingGroups}`
    return this.httpClient
      .get<FacMailingGroups[]>(url)
      .pipe(
        mapToMaybeData(),
        catchAndHandleError('GET Facultative Mailing Groups')
      )
  }

  postHtml2Pdf(htmlText: string): Observable<ArrayBuffer> {
    const url = `${environment.internalApi.base}${environment.internalApi.facExport}/html2pdf`
    const excapedHtmlText: string = escapeHtml(htmlText)
    const body = {
      htmlText: excapedHtmlText
    }
    return this.httpClient.post(url, body, { responseType: 'arraybuffer' })
  }
}
