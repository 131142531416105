
export interface PdfPageOptions {
  /** Orientation of the PDF page. */
  orientation: 'portrait' | 'landscape'
  /** Width in millimeters of the PDF page. */
  width: number
  /** Height in millimeters of the PDF page. */
  height: number
  /** Margin in millimeters of the PDF page. */
  margin: number
  /** Gutter between columns in millimeters of the PDF page. */
  gutter: number
}

export interface PdfHeader {
  title: string
  subtitle?: string
  nameAndAddress?: string
  fields?: [string, string][]
  height: number
}

export interface PdfFontOptions {
  fontSize?: number
  fontStyle?: 'normal' | 'bold' | 'italic' | 'bolditalic'
  charSpace?: number
}

export interface PdfLine extends PdfFontOptions {
  text: string
  lineHeight: number
}

export interface PdfBaseItem extends PdfFontOptions {
  value: string | string[]
  colWidths?: number[]
  gutter?: number
  lineHeight?: number
  marginBottom?: number
  marginTop?: number
  asTable?: boolean
  noTrim?: boolean
  borders?: boolean
}

export interface PdfText extends PdfBaseItem {
  type: 'text'
}

export interface PdfField extends PdfBaseItem {
  type: 'field'
  label: string
  id?: string
  hidden?: boolean
}

export interface PdfHeading extends PdfBaseItem {
  type: 'heading'
  allCaps?: boolean
}

export interface PdfItemBoxOptions {
  lineWidth: number
  showImage: boolean
}

export type PdfItem = PdfText | PdfField | PdfHeading
export type PdfItemsOrText = string | (string | PdfItem | (PdfItem | string)[])[]

export interface PdfTextRow {
  type: 'text'
  items: PdfText[]
}

export interface PdfFieldRow {
  type: 'field'
  items: PdfField[]
}

export interface PdfHeadingRow {
  type: 'heading'
  items: PdfHeading[]
}

export type PdfRow = PdfTextRow | PdfFieldRow | PdfHeadingRow

export abstract class IPdfGenerator {
  abstract getBase64Contents(): string
  abstract addPage(header?: PdfHeader): void
  abstract addHeadingRow(row: PdfHeadingRow): number
  abstract addFieldRow(row: PdfFieldRow): number
  abstract addTextRow(row: PdfTextRow): number
  abstract addRow(row: PdfRow): number
  abstract save(pdfName: string, attachedFile?: ArrayBuffer): Promise<void>
  abstract mergeAndSave(pdfName: string, pdfsToMerges: ArrayBuffer[]): Promise<void>
}
