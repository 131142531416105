import { DefaultUrlSerializer, UrlTree } from '@angular/router'

export function getUrlSegmentValue(url: string, index: number): string | null{
  if (!url?.length) {
    return null
  }
  const urlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer()
  const urlTree: UrlTree = urlSerializer.parse(url)
  return urlTree.root.children.primary.segments.length > index
    ? urlTree.root.children.primary.segments[index].toString()
    : null
}
