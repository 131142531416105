<mat-form-field>
  <div class="wrapper">
    <mat-icon matPrefix [class.lit]="filterInput?.value">
      filter_list
    </mat-icon>
    <input
      #filterInput
      matInput
      cdkFocusInitial
      type="text"
      placeholder="Search..."
      disableRipple
      class="search-text"
      [value]="value"
      (keydown.escape)="onEscape($event)"
      (keydown.arrowup)="onNavKey($event, 'up')"
      (keydown.arrowdown)="onNavKey($event, 'down')"
      (keydown.enter)="onNavKey($event, 'enter')"
      (keydown.tab)="onNavKey($event, 'tab')"
      (keyup)="onChange($event)"
    />
    <mat-icon
      *ngIf="filterInput?.value"
      matPrefix
      class="clear"
      (click)="onClear($event)"
    >
      cancel
    </mat-icon>
  </div>
</mat-form-field>