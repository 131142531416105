import { routerReducer, RouterReducerState } from '@ngrx/router-store'
import {
  ActionReducer,
  ActionReducerMap,
  createReducer,
  MetaReducer,
  on,
  Action,
} from '@ngrx/store'
import { storeFreeze } from 'ngrx-store-freeze'
import { environment } from '@environments/environment'
import { setWidth, WindowSize } from './app.actions'

import * as fromAuth from './auth/auth.reducer'
import * as fromPermissions from './permissions/permissions.reducer'
import * as fromDeals from '@deals/store/facultative.reducer'

import { AUTHENTICATION_FEATURE_KEY, AuthState } from './auth/auth.state'
import { USER_PERMISSIONS_FEATURE_KEY, UserPermissionsState } from './permissions'
import { FAC_DEALS_FEATURE_KEY, IFacultativeState } from '@deals/store'
import { localStorageSync } from 'ngrx-store-localstorage'

export interface AppState {
  router: RouterReducerState
  window: WindowSize
  [AUTHENTICATION_FEATURE_KEY]: AuthState
  [USER_PERMISSIONS_FEATURE_KEY]: UserPermissionsState
  [FAC_DEALS_FEATURE_KEY]: IFacultativeState
}

const initialWindowSize: WindowSize = { width: 1000, height: 1000 }
const _widthReducer = createReducer(
  initialWindowSize,
  on(setWidth, (_, { size }) => size)
)

export function widthReducer(state: WindowSize, action: Action) {
  return _widthReducer(state, action)
}

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: [
      { [FAC_DEALS_FEATURE_KEY]: ['settings', 'facSearchCriteria'] }
    ],
    rehydrate: true,
  })(reducer)
}

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  window: widthReducer,
  [AUTHENTICATION_FEATURE_KEY]: fromAuth.reducer,
  [USER_PERMISSIONS_FEATURE_KEY]: fromPermissions.reducer,
  [FAC_DEALS_FEATURE_KEY]: fromDeals.reducer,
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? [
      (reducer: ActionReducer<AppState>) => reducer,
      localStorageSyncReducer,
      storeFreeze,
    ]
  : [localStorageSyncReducer]

export const selectWindowSize = (state: AppState) => state.window
