
export function objectToStrMap(obj: any): Map<string, any> {
  const strMap = new Map<string, any>()
  if (obj === undefined || obj === null) {
    return strMap
  }
  for (const k of Object.keys(obj)) {
    strMap.set(k, obj[k])
  }
  return strMap
}
