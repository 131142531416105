import { DatePipe, getCurrencySymbol } from '@angular/common'
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import * as XLSX from 'xlsx'
// import { Hotkey, HotkeysService } from 'angular2-hotkeys'

const STATES: string[] = [
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
]
export interface TableDialogOptions {
  message?: string
  submitLabel?: string
  date?: string
  year?: string
  type?: string
  coverageReinsured?: string
  noType?: boolean
  tableValue?: {
    columnData: { columns: string[]; types: string[] }
    data: any[]
  }
  fields: { name: string; value: string; type: string }[]
  selectedCurrency: string | undefined
}

const defaults: TableDialogOptions = {
  message: 'Are you sure you want to do this?',
  submitLabel: 'Confirm',
  tableValue: { columnData: { columns: [], types: [] }, data: [] },
  fields: [],
  selectedCurrency: undefined,
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-fleet',
  styleUrls: ['./table-dialog.component.scss'],
  templateUrl: './table-dialog.component.html',
})
export class TableDialogComponent {
  options: TableDialogOptions
  employer = "Employer's Liability"
  editableOther = 'Other'
  firstExposure = 'Gross Receipts'
  secondExposure = 'Payroll'
  thirdExposure = ''
  coverageReinsured = ''
  noType = false
  objectKeys = Object.keys
  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef
  files: any[] = []
  filteredExposureOptions: string[]
  exposureOptions: string[] = [
    'Gross Receipts',
    'Payroll',
    'Gross Sales',
    'Units',
    'Square Footage',
    'Tonnage',
    'Area',
  ]

  lobGeneral: string[] = [
    'Auto Liability',
    'General Liability',
    'Employer\'s Liability',
    'Employee Benefits Liability',
  ]

  lobLiquor: string[] = [
    'Liquor Liability',
    'Assault & Battery',
    'SML',
    'Lead Excess Liability',
    'Follow form Excess Liability',
  ]

  lobDirectors: string[] = [
    'SIR',
    'Deductible',
    'Primary Liability',
  ]

  lobOptions: string[] = [
    ...this.lobGeneral,
    ...this.lobLiquor,
  ]

  lobOptionsMap: {[key: string]: string[]} = {
    'Auto Liability': ['$1M CSL', '$2M CSL', '$3M CSL', '$4M CSL', '$5M CSL'],
    'General Liability': ['$1M/$2M/$1M', '$1M/$2M/$2M', '$2M/$2M/$2M', '$2M/$4M/$2M', '$2M/$4M/$4M', '$5M/$5M/$5M'],
    'Employer\'s Liability': ['$500K/$500K/$500K', '$1M/$1M/$1M', '$2M/$2M/$2M'],
    'Employee Benefits Liability': ['$1M/$2M'],
    'Excess Liability': ['$1M/$1M/$1M', '$2M/$2M/$2M', '$3M/$3M/$3M', '$4M/$4M/$4M', '$5M/$5M/$5M', '$6M/$6M/$6M', '$7M/$7M/$7M', '$8M/$8M/$8M', '$9M/$9M/$9M', '$10M/$10M/$10M', '$15M/$15M/$15M', '$20M/$20M/$20M', '$25M/$25M/$25M']
  }

  constructor(
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<
      TableDialogComponent,
      {
        choice: boolean
        fields: { name: string; value: string; type: string }[]
      }
    >,
    // private hotkeysService: HotkeysService,
    @Inject(MAT_DIALOG_DATA) public data: TableDialogOptions
  ) {
    // this.hotkeysService.add(
    //   new Hotkey(
    //     'enter',
    //     (_event: KeyboardEvent): boolean => {
    //       this.confirm(true)
    //       return false // Prevent bubbling
    //     },
    //     ['INPUT', 'SELECT', 'TEXTAREA', 'BUTTON']
    //   )
    // )
    if (data.coverageReinsured === 'Directors & Officers Liability' || data.coverageReinsured === 'Cyber Liability' ||
      data.coverageReinsured === 'Errors & Omissions Liability' || data.coverageReinsured === 'Miscellaneous Liability') {
      this.lobOptions = [...this.lobOptions, ...this.lobDirectors]
    }
    this.coverageReinsured = data.coverageReinsured

    if (data.fields && data.fields[0] && data.fields[0].name === 'Year' && data.fields[1] && data.fields[1].name === 'Units' && data.fields[2] && data.fields[2].name === 'Mileage' && data?.tableValue?.data && data?.tableValue?.data?.length > 0) {
      const columnHeaders = Object.keys(data.tableValue.data[0]).filter(h => h !== 'action')
      const otherColumn = columnHeaders[3]
      if (data.fields[3] && otherColumn && otherColumn !== data.fields[3]?.name) {
        data.fields[3].name = otherColumn
      }
    }
    if (data.date) {
      data.fields.forEach(
        field =>
          (field.value =
            field.type === 'Date' && data.date ? data.date : field.value)
      )
    }
    data.fields.forEach(
      field =>
        (field.value =
          (field.name === 'Year' || field.name === 'Years') && data.year
            ? data.year
            : field.value)
    )
    this.options = { ...defaults, ...data }
    this.noType = this.options.noType || false
    if (data.fields && data.fields[0] && data.fields[0].name === 'Year') {
      const datafields = this.options.fields.filter(f => f.name !== 'action') as { name: string; value: string; type: string }[]
      // const actionfield = this.options.fields.find(f => f.name === 'action') as { name: string; value: string; type: string }
      // eslint-disable-next-line no-self-assign
      datafields[1] && datafields[1]?.name ? (this.firstExposure = datafields[1].name) : (this.firstExposure = this.firstExposure)
      // eslint-disable-next-line no-self-assign
      datafields[2] && datafields[2]?.name ? (this.secondExposure = datafields[2].name) : (this.secondExposure = this.secondExposure)
      if (!datafields[3]) {
        datafields[3] = {
          name: 'Other',
          value: '',
          type: 'string'
        }
      }
      // eslint-disable-next-line no-self-assign
      datafields[3] && datafields[3]?.name ? (this.thirdExposure = datafields[3].name) : (this.thirdExposure = this.thirdExposure)
      this.options.fields = [...datafields].filter(Boolean)
      this.filterExposureOptions()
    }
  }

  filterExposureOptions() {
    this.filteredExposureOptions = this.exposureOptions.filter(o => ![this.firstExposure, this.secondExposure, this.thirdExposure].includes(o))
  }

  confirm(choice: boolean): void {
    for (const i of this.options.fields) {
      if (i.type === 'Date') {
        i.value = this.datePipe.transform(i.value, 'MM/dd/yyyy') ?? ''
      }
    }
    let fields
    if (this.options.tableValue) {
      const columns = Array.from(new Set(this.options.tableValue.data.map(d => Object.keys(d)).flat()))
      this.options.tableValue.data.map(d => {
        columns.forEach(c => {
          if (!d[c]) {
            d[c] = ''
          }
          if (c === 'action' && d[c] === '') {
            delete d[c]
          }
        })
        return d
      })
      fields = this.options.tableValue.data
    } else {
      fields = []
    }
    // this.options.tableValue
    //   ? (fields = this.options.tableValue.data)
    //   : (fields = [])
    fields.forEach((field: any) => {
      if (field['Val Date']) {
        field['Val Date'] =
          this.datePipe.transform(field['Val Date'], 'MM/dd/yyyy') ?? ''
      }
      if (field.DOL) {
        field.DOL = this.datePipe.transform(field.DOL, 'MM/dd/yyyy') ?? ''
      }
    })
    this.dialogRef.close({ choice, fields })
  }

  updateTotal(index: number, isTotal: boolean): string {
    let total = 0
    if (this.options?.tableValue?.data && this.options.tableValue.data[index]) {
      const tableRow = this.options.tableValue.data[index]
      if (isTotal) {
        tableRow.Local = ''
        tableRow.Intermediate = ''
        tableRow['Long Distance'] = ''
        total = tableRow.Total
      } else {
        const availableFields = Object.keys(tableRow).filter(
          f => f !== 'Total' && f !== 'Vehicle Type' && f !== 'Radius'
        )
        availableFields.forEach(field => {
          const fieldValue = parseFloat(
            (tableRow[field] ?? '0').replace(/[^0-9.]/g, '')
          )
          total += fieldValue ? fieldValue : 0
        })
      }
      tableRow.Total = total.toString()
    }
    const totalField = this.data.fields.find(field => field.type === 'total')
    if (totalField) {
      totalField.value = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    return total
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  getOptionsForLOB(lob: string): string[] {
    return this.lobOptionsMap[lob] || this.lobOptionsMap['Excess Liability'];
  }

  getColumnTotal(field: string) {
    let total = 0
    if (this.options?.tableValue?.data) {
      const tableData = this.options.tableValue.data
      const validRows = tableData.filter(
        d => d['Vehicle Type'] !== 'Total' && d.State !== 'Total'
      )
      const fieldValues = validRows.map(r => r[field] ? r[field].toString() : '0')
      total = fieldValues.reduce((acc, obj) => {
        const fieldValue = parseFloat((obj).replace(/[^0-9.]/g, ''))
        return acc + (fieldValue ? fieldValue : 0)
      }, 0)
      const totalRow = tableData.find(
        d => d['Vehicle Type'] === 'Total' || d.State === 'Total'
      )
      if (totalRow) {
        totalRow[field] = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
    }
    const  totalField = this.data.fields.find(f => f.type === 'total' || f.name === 'Total')
    if (totalField && totalField.value) {
      totalField.value = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    return total
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  parseStringToNumber(index: number, name: string, value: string): void {
    if (value && this.options?.tableValue) {
      const val = value.replace(/[^0-9.]/g, '')
      this.options.tableValue.data[index][name] = Number(val)
    }
  }

  addRow(): void {
    if (this.options.tableValue) {
      if (
        this.options.tableValue.data[0] &&
        this.options.tableValue.data[0].Year
      ) {
        this.options.year = (
          parseInt(
            this.options.tableValue.data[
              this.options.tableValue.data.length - 1
            ].Year,
            10
          ) - 1
        ).toString()
      } else if (
        this.options.tableValue.data[0] &&
        this.options.tableValue.data[0].Years
      ) {
        this.options.year = (
          parseInt(
            this.options.tableValue.data[
              this.options.tableValue.data.length - 1
            ].Years,
            10
          ) - 1
        ).toString()
      }
      if (
        this.options.tableValue.data[0] &&
        this.options.tableValue.data[0]['Val Date']
      ) {
        this.options.date =
          this.options.tableValue.data[this.options.tableValue.data.length - 1][
            'Val Date'
          ]
      }
      if (
        this.options.tableValue.data[0] &&
        this.options.tableValue.data[0].DOL
      ) {
        this.options.date =
          this.options.tableValue.data[
            this.options.tableValue.data.length - 1
          ].DOL
      }
      if (this.options.fields[0].name === 'LOB') {
        this.options.tableValue.data.push({
          LOB: '',
          Limits: '',
          Premium: '',
          Carrier: '',
          'See Attached U/L Schedule': false,
        })
      } else if (this.options.fields[0].name === 'DOL') {
        if (this.options.fields[1].name === 'LOB') {
          this.options.tableValue.data.push({
            LOB: this.coverageReinsured === 'Umbrella / Excess Liability - Auto Liability Carve Out' ? 'Auto' : '',
            DOL: this.options.date ?? '',
            'Total Incurred': '',
            Status: '',
            Description: '',
          })
        } else {
          this.options.tableValue.data.push({
            DOL: this.options.date ?? '',
            'Total Incurred': '',
            Status: '',
            Description: '',
          })
        }
      } else if (this.options.fields[0] && this.options.fields[0].name === 'Year' && this.options.fields[1] && this.options.fields[1]?.name === 'Units' && this.options.fields[2] && this.options.fields[2]?.name === 'Mileage') {
        this.options.tableValue.data.push({
          Year: this.options.year ?? '',
          Units: '',
          Mileage: '',
          [this.thirdExposure]: '',
        })
      } else if (this.options.fields[0].name === 'Year') {
        this.options.tableValue.data.push({
          Year: this.options.year ?? '',
          Claims: '',
          'Total Incurred': '',
          'Total Paid': '',
          'Val Date': this.options.date ?? '',
        })
      } else if (this.options.fields[0].name === 'State') {
        const totalIndex = this.options.tableValue.data.findIndex(
          d => d.State === 'Total'
        )
        this.options.tableValue.data.push({
          State: '',
          Vehicles: '',
        })
        this.options.tableValue.data.push(
          ...this.options.tableValue.data.splice(totalIndex, 1)
        )
      } else {
        this.options.tableValue.data.push({
          Years: this.options.year ?? '',
          Claims: '',
          'Total Incurred': '',
          'Total Paid': '',
          'Val Date': this.options.date ?? '',
        })
      }
    }
  }

  convertSmartNumber(input: string): string{
    if (input.endsWith('M') || input.endsWith('m')) {
      return (parseFloat(input.slice(0, -1)) * 1000000).toString()
    } else if (input.endsWith('B') || input.endsWith('b')) {
      return (parseFloat(input.slice(0, -1)) * 1000000000).toString()
    } else if (input.endsWith('T') || input.endsWith('t')) {
      return (parseFloat(input.slice(0, -1)) * 1000000000000).toString()
    } else {
      return input
    }
  }

  editableOtherChanged(event: string): void {
    this.options.tableValue?.data.forEach(e => {
      delete e[this.editableOther]
    })
    this.editableOther = event
  }

  firstExposureChanged(event: string): void {
    this.options.tableValue?.data.forEach(e => {
      e[event] = e[this.firstExposure]
      delete e[this.firstExposure]
      // delete e['Gross Receipts']
    })
    this.firstExposure = event
    this.filterExposureOptions()
  }

  secondExposureChanged(event: string): void {
    this.options.tableValue?.data.forEach(e => {
      e[event] = e[this.secondExposure]
      delete e[this.secondExposure]
      // delete e.Revenue
    })
    this.secondExposure = event
    this.filterExposureOptions()
  }

  thirdExposureChanged(newKey: string): void {
    this.options.tableValue?.data.forEach(e => {
      e[newKey] = e[this.thirdExposure]
      delete e[this.thirdExposure]
      // delete e.Other
    })
    this.thirdExposure = newKey
    this.filterExposureOptions()
  }

  getCurrencySign(): string {
    if (this.options.selectedCurrency) {
      return getCurrencySymbol(this.options.selectedCurrency, 'wide')
    } else {
      return '$'
    }
  }

  updateDate(date: Date, i: number): void {
    if (this.options.tableValue) {
      this.options.tableValue.data.forEach((d: any, index: number) => {
        if (index > i && this.options.tableValue) {
          d['Val Date'] = date.toLocaleDateString()
        }
      })
    }
  }

  removeCommas(index: number, name: any, value: any): void {
    if (value && this.options.tableValue) {
      let val = value.replace(/[^0-9.]/g, '')
      val = val.replace('%', '')
      this.options.tableValue.data[index][name] = val
    }
  }

  addCommas(index: number, name: any, value: any): void {
    if (value && this.options.tableValue) {
      const val = parseFloat(this.convertSmartNumber(value))
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      this.options.tableValue.data[index][name] = val
    }
  }

  addCommasCurrency(index: number, name: any, value: any): void {
    if (value && this.options.tableValue) {
      const val = parseFloat(
        this.convertSmartNumber(value).replace(/[^0-9.]/g, '')
      )
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      this.options.tableValue.data[index][name] = this.getCurrencySign() + val
    }
  }

  onFileChange(ev: any): void {
    let workBook: any = null
    let jsonData = null
    const reader = new FileReader()
    const file = ev.target.files[0]
    this.files = [file]
    this.options.tableValue
      ? (this.options.tableValue.data = [])
      // eslint-disable-next-line no-self-assign
      : (this.options.tableValue = this.options.tableValue)
    reader.onload = () => {
      const data = reader.result
      workBook = XLSX.read(data, { type: 'binary', cellDates: true })
      jsonData = workBook.SheetNames.reduce((initial: any, name: any) => {
        const sheet = workBook.Sheets[name]
        initial[name] = XLSX.utils.sheet_to_json(sheet)
        return initial
      }, {})
      if (jsonData.Sheet1) {
        jsonData.Sheet1.forEach((row: any) => {
          if (
            this.options.tableValue &&
            this.options.fields[0].name === 'LOB'
          ) {
            this.options.tableValue.data.push({
              LOB: row.LOB,
              Limits: row.Limits,
              Premium:
                this.getCurrencySign() +
                parseFloat(row.Premium)
                  .toFixed(0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              Carrier: row.Carrier,
              'See Attached U/L Schedule': false,
            })
          } else if (
            this.options.tableValue &&
            this.options.fields[0].name === 'Year'
          ) {
            this.options.tableValue.data.push({
              Year: row.Year,
              Claims: row.Claims,
              'Total Incurred':
                this.getCurrencySign() +
                parseFloat(row['Total Incurred'])
                  .toFixed(0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              'Total Paid':
                this.getCurrencySign() +
                parseFloat(row['Total Paid'])
                  .toFixed(0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              'Val Date': row['Val Date'],
            })
          } else if (
            this.options.tableValue &&
            this.options.fields[0].name === 'Years'
          ) {
            this.options.tableValue.data.push({
              Years: row.Years,
              Claims: row.Claims,
              'Total Incurred':
                this.getCurrencySign() +
                parseFloat(row['Total Incurred'])
                  .toFixed(0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              'Total Paid':
                this.getCurrencySign() +
                parseFloat(row['Total Paid'])
                  .toFixed(0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              'Val Date': row['Val Date'],
            })
          } else if (
            this.options.tableValue &&
            this.options.fields[0].name === 'DOL'
          ) {
            if (this.options.fields[1].name === 'LOB') {
              this.options.tableValue.data.push({
                DOL: row.DOL,
                LOB: row.LOB,
                'Total Incurred':
                  this.getCurrencySign() +
                  parseFloat(row['Total Incurred'])
                    .toFixed(0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                Status: row.Status,
                Description: row.Description,
              })
            } else {
              this.options.tableValue.data.push({
                DOL: row.DOL,
                'Total Incurred':
                  this.getCurrencySign() +
                  parseFloat(row['Total Incurred'])
                    .toFixed(0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                Status: row.Status,
                Description: row.Description,
              })
            }
          } else if (
            this.options.tableValue &&
            this.options.fields[0].name === 'State'
          ) {
            this.options.tableValue.data.push({
              State: row.State,
              Vehicles: row.Vehicles,
            })
          }
        })
      }
    }
    reader.readAsBinaryString(file)
  }

  getStateList(): string[] {
    const usedStates = this.options?.tableValue?.data?.map(d => d.State) || []
    return STATES.filter(s => usedStates.indexOf(s) < 0)
  }
}
