<div class="wrapper">
  <div class="header-left">
    <div class="menu-container">
      <button mat-icon-button class="menu-icon" id="menu" [matMenuTriggerFor]="menu">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onFacultativeClick()">
          <mat-icon>business_center</mat-icon>
          <span>Facultative</span>
        </button>
        <button mat-menu-item  (click)="onAdministrationClick()">
          <mat-icon>lock</mat-icon>
          <span>Facultative Administration</span>
        </button>
        <button *ngIf="getAllowDashboard" mat-menu-item  (click)="onDashboardClick()">
          <mat-icon>dashboard</mat-icon>
          <span>Dashboard</span>
        </button>        
      </mat-menu>

      <a class="logo" routerLink="/">
        <img src="assets/logos/fac-logo.png" alt="FacSAGE Logo" class="logo-image"/>
        <span class="color-tm">TM</span>
      </a>
    </div>
    <div class="title">
      <div class="main-title">{{ title }}</div>
      <div class="subtitle">{{ subtitle }}</div>
    </div>
  </div>

  <div class="header-right" *ngIf="isLoggedIn">
    <app-user-card
      [name]="userName"
      avatarID="assets/avatars/initials-placeholder-square.png"
    ></app-user-card>
    <button mat-icon-button color="accent" (click)="logout.emit()" matTooltip="Logout" matTooltipPosition="above">
      <mat-icon>logout</mat-icon>
    </button>
  </div>
</div>
