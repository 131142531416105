import { FacUser } from '../models'
import { formatLocktonContactLines } from '../formatters'

export function generatePropertyQuoteLetterText(
  dealName: string,
  recipientName: string,
  broker?: FacUser | null
): string {
  const brokerName: string = broker?.name ?? 'Lockton Re'
  const contactLines: string = formatLocktonContactLines(!!broker?.isUk, broker).join('\n')
  return `Dear ${recipientName},\n\nThank you for allowing Lockton Re the opportunity to assist in obtaining facultative support on ${dealName}.\n\n`
    + `We are pleased to present the formal quoted terms for your review.\n\n`
    + `After your review we are available to answer any questions and work on any additional requests or changes to the quoted terms you may require.\n\n`
    + `We look forward to feedback on these terms and working further with you on this placement.\n\n`
    + `Kind Regards,\n\n\n${brokerName}\n${contactLines}`
}
