import { Injectable } from '@angular/core'
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar'

export interface NotificationOptions {
  duration?: number
}


@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private static readonly DefaultDuration: number = 3000

  constructor(private snackbar: MatSnackBar) { }

  public info(message: string, options?: NotificationOptions): void {
    const config: MatSnackBarConfig = this.buildMatSnackBarConfig(options, 'notification-info')
    this.snackbar.open(message, 'X', config)
  }

  public error(message: string, options?: NotificationOptions): void {
    const config: MatSnackBarConfig = this.buildMatSnackBarConfig(options, 'notification-error')
    this.snackbar.open(message, 'X', config)
  }

  private buildMatSnackBarConfig(options?: NotificationOptions, panelClass?: string): MatSnackBarConfig {
    return {
      duration: options?.duration ?? NotificationService.DefaultDuration,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: panelClass
    }
  }
}
