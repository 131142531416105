import {FormGroup, Validators} from '@angular/forms'
import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'

import {
  FacDefaults,
  FacSubmission,
  FieldConfig,
  FieldInterface,
  FieldKey, ID_SUFFIX, SalesforceAccount, SalesforceContact,
} from '../models'
import {FacBasisOfAcceptance, FacCurrency, FacReinsuranceConditions, FacTermsAndConditions} from '../../api/model/backend.model'
import { cloneDeep } from '@utils'
import { isStarrPropertyDeal, isTravelers } from '@deals/utils'

@Injectable({
  providedIn: 'root',
})
export class FacFieldService {
  get submissionFields(): FacSubmission {
    return this.allFieldValuesMap
  }
  set submissionFields(submission: FacSubmission) {
    Object.entries(submission).forEach(([key, value]) => {
      this.setFieldValuesMapValue(key, value)
    })
  }
  clearSubmissionFields(): void {
    this.allFieldValuesMap = {}
    this.submissionFields = this.allFieldValuesMap
    this.configMap = new Map<FieldKey, FieldInterface>()
    this.fieldControls = new Map<string, FormGroup>()
    this.clearFieldValues()
  }

  private fieldControls = new Map<string, FormGroup>()
  endpointCalls = new Map<string, any>()
  private formReady = false
  private configMap = new Map<FieldKey, FieldInterface>()
  private allFieldsConfigMap = new Map<string, FieldConfig>()
  private allFieldValuesMap: Record<string, string> = {}
  private hiddenFields: string[] = []
  updatedField = new Subject<any>()
  private displayErrorDialog = (_errorMessage: any) => { console.error(_errorMessage) }
  selectedTabChange: Subject<string> = new Subject<string>()

  getSelectedTab(): Observable<string> {
    return this.selectedTabChange.asObservable()
  }

  setSelectedTab(selectedTab: string) {
    this.selectedTabChange.next(selectedTab)
  }
  private gfcList = [
    'Aspen Specialty Insurance Company',
    'Axis Surplus Insurance Company',
    'Endurance America Specialty Insurance Company',
    'GuideOne National Insurance Company',
    'Harleysville Insurance Company of New York on behalf of Scottsdale Insurance Company',
    'Homeland Insurance Co of New York',
    'IAT Insurance Group',
    'Indian Harbor Insurance company (XL Paper)',
    'Markel American Insurance Company',
    'N2G Worldwide insurance Services',
    'New York Marine and General Insurance Company',
    'StarStone Specialty Insurance Company',
    'Traverse Specialty Insurance Company',
    'Trisura Specialty Insurance Company',
    'XL Insurance America, Inc',
    'Zurich American Insurance Company',
    'Intact Insurance Specialty Property',
    'Nationwide on behalf of Scottsdale Insurance Company (Paragon Specialty Property)',
    'Trisura Specialty Insurance Company via reThought Insurance Company',
    'Venbrook Intermediate Holdings, LLC',
    'James River Insurance Company',
  ]
  private gfcCasualtyList = [
    'American Senterfitt Insurance Company Ltd',
    'Arch Specialty Insurance Company',
    'AXIS Insurance Company',
    'Axis Surplus Insurance Company',
    'Capitol Specialty Insurance Corporation',
    'Freedom Specialty Insurance Company',
    'GuideOne National Insurance Company',
    'HDI Global Insurance',
    'HDI Specialty Insurance',
    'James River Insurance Company',
    'National Casualty Company',
    'Scottsdale Insurance Company',
    'The Cincinnati Specialty Underwriters Insurance Company',
    'The Travelers Indemnity Company',
    'Upland Specialty Insurance Company',
    'XL Insurance Company SE',
    'XL Insurance America, Inc',
    'Texas Insurance Company',
    'Greenwich Insurance Company',
  ]
  private aigList = [
    'Lexington Specialty Insurance Agency, Inc.',
    'American Home Assurance Company',
    'Lexington Insurance Co',
    'AIG',
    'AIG Assurance Company',
    'AIG Property Casualty Company',
    'AIG Specialty Insurance Company',
    'AIG Specialty Insurance Company, Chicago',
    'AIU Insurance Company',
    'American Home Assurance Company',
    'American International Group, Inc.',
    'Commerce and Industry Insurance Company',
    'Granite State Insurance Company',
    'Illinois National Insurance Company',
    'Lexington Insurance Co',
    'National Union Fire Insurance Company of Pittsburgh, Pa.',
    'National Union Fire Insurance Company of Pittsburgh, Pa.',
    'The Insurance Company of the State of Pennsylvania',
    'Western World Insurance Company',
  ]
  private libertyList = [
    'Employers Insurance Co. of Wausau (Liberty Mutual paper)',
    'Liberty Mutual Insurance Company',
    'Liberty Mutual Fire Insurance Co. (Liberty Mutual)',
  ]
  private fmList = [
    'FM Global Fac Certificate Form CP2007 - 09-01-10',
    'Affiliated FM Insurance Company',
    'Factory Mutual Insurance Company',
  ]
  private cnaList = [
    'Continental Casualty Company, Inc.',
    'Continental Indemnity Company',
  ]
  private starrList = [
    'Starr Surplus Lines Insurance Company',
    'Lloyds of London Syndicate 1919 on behalf of Starr Surplus Lines',
  ]
  private chubbList = ['Ace American Insurance Company']
  private travelersList = [
    'Travelers Excess and Surplus Lines Company',
    'The Travelers Indemnity Company',
  ]
  private aigCasualtyList = [
    'AIG Insurance Company of Canada',
    'AIG Specialty Insurance Company',
    'American Home Assurance Company',
    'Insurance Company of the State of Pennsylvania (AIG paper)',
    'Lexington Insurance Co',
    'Lexington Specialty Insurance Agency, Inc.',
    'National Union Fire Insurance Company of Pittsburgh, Pa.',
  ]
  private alliedList = [
    'Allied World Assurance Company (U.S.), Inc.',
    'Allied World Assurance Company Limited',
  ]
  private chubbCasualtyList = [
    'Ace American Insurance Company',
    'ACE Property & Casualty Insurance Company',
    'Chubb Insurance Company of Canada',
    'Illinois Union Insurance Company',
    'Westchester Fire Insurance Company'
  ]
  private cnaCasualtyList = [
    'Everest National Insurance Company',
    'Continental Casualty Company, Inc.',
    'Continental Indemnity Company',
    'Columbia Casualty Company',
    'National Fire Insurance Company of Hartford',
    'The Continental Insurance Company',
    'Transportation Insurance Company',
    'American Casualty Company of Reading, Pennsylvania',
    'Columbia Casualty Company',
    'Valley Forge Insurance Company'
  ]
  private everestList = [
    'Everest National Insurance Company',
    'Everest Denali Insurance Company',
  ]
  private libertyCasualtyList = [
    'Liberty Mutual Insurance Company',
    'Liberty Mutual Fire Insurance Co. (Liberty Mutual)',
  ]
  private starrCasualtyList = [
    'Starr Indemnity & Liability Company',
    'Starr Specialty Lines Insurance Company',
    'Starr Surplus Lines Insurance Company',
  ]
  private sentryCompanyList = [
    'Florists\' Mutual Insurance Company',
    'Sentry Insurance Company',
  ]

  parseDate(dateString: string): Date | null {
    let dateToReturn: Date | null = null
    if (dateString) {
      try {
        if (dateString && dateString.length >= 10) {
          dateString = dateString.slice(0, 10)
          const dateArray = dateString.split('-')
          if (dateArray.length === 3) {
            dateString =
              parseInt(dateArray[1], 10) +
              '/' +
              parseInt(dateArray[2], 10) +
              '/' +
              dateArray[0]
          }
        }
        dateToReturn = new Date(Date.parse(dateString))
      } catch {
        this.displayErrorDialog(
          'Could not parse the date returned from server ' + dateString
        )
      }
    }
    return dateToReturn
  }

  getFieldsConfigMap(): Map<string, FieldConfig> {
    return this.allFieldsConfigMap
  }

  clearFieldValues(): void {
    this.resetHiddenFields()
    this.allFieldValuesMap = {}
    this.allFieldsConfigMap = new Map<string, FieldConfig>()
  }

  getFieldValuesAsJson(): string  {
    return JSON.stringify(this.allFieldValuesMap)
  }

  getFieldValuesMap(): ReadonlyMap<string, string> {
    const map = new Map<string, string>()
    Object.entries(this.allFieldValuesMap).forEach(([key, value]) => {
      map.set(key, value)
    })
    return map
  }

  getFieldValuesMapValue(name: string): string | undefined {
    return this.allFieldValuesMap[name]
  }

  getFieldCurrency() {
    const defaultCurrency = 'USD'
    const currency = this.allFieldsConfigMap.get('currency')
    return currency ? currency?.value || defaultCurrency : defaultCurrency
  }

  setFieldValuesMap(fieldValuesMap: ReadonlyMap<string, string>): void {
    fieldValuesMap.forEach((value, key) => {
      this.setFieldValuesMapValue(key, value)
    })
  }

  setFieldValuesMapValue(field: string, value: string): void {
    this.allFieldValuesMap[field] = value
  }

  resetHiddenFields(): void {
    this.hiddenFields.forEach(hiddenField => {
      this.setHiddenToggle(hiddenField, false)
    })
    this.hiddenFields = []
  }

  getHiddenFields(): string[] {
    return this.hiddenFields
  }

  setHiddenFields(existingHiddenFields: string[]): void {
    this.hiddenFields = existingHiddenFields
  }

  clearFields(fieldInterfaces: any[], ignoreFields: Set<string>): void {
    for (const fieldInterface of fieldInterfaces) {
      for (const fieldArray of fieldInterface.fields) {
        fieldArray.forEach(async (field: any) => {
          if (!ignoreFields.has(field.id) && field.readonly === 'false') {
            field.value = field.defaultValue ? field.defaultValue : ''
            this.setFieldValuesMapValue(field.id, field.defaultValue ? field.defaultValue : '')
          }
        })
      }
    }
  }

  updateField(_name: string, form: any): void {
    for (const field in form.value) {
      if (field) {
        this.setFieldValuesMapValue(field, form.value[field])
        this.updatedField.next({ field, value: form.value[field] })
      }
    }
  }

  addField(name: string, value: string): void {
    this.setFieldValuesMapValue(name, value)
  }

  fetchFieldArray(name: string) {
    const val = this.getFieldValuesMapValue(name)
    return val ? JSON.parse(val) as any[] : []
  }

  pushForm(form: any): void {
    for (const key in form.controls) {
      // eslint-disable-next-line no-prototype-builtins
      if (form.controls.hasOwnProperty(key)) {
        this.fieldControls.set(key, form.controls[key])
      }
    }
  }

  updateForm(): void {
    this.fieldControls.forEach((_value: any, key: string) => {
      const newVal = this.allFieldsConfigMap.get(key)
      if (newVal) {
        this.fieldControls.get(key)?.setValue(newVal.value ? newVal.value : '')
        this.fieldControls.get(key)?.markAsUntouched()
      }
    })
  }

  getForm(): Map<string, FormGroup> {
    return this.fieldControls
  }

  getConfigMap(): Map<FieldKey, FieldInterface> {
    return this.configMap
  }

  setHiddenToggle(fieldId: string, value: boolean): string[] {
    this.configMap.forEach(fieldVals => {
      fieldVals.fields.findIndex(fieldEntries => {
        fieldEntries.findIndex(fieldEntry => {
          if (fieldEntry.id === fieldId && fieldEntry.hiddenToggle) {
            fieldEntry.hiddenToggleValue = value
            const index = this.hiddenFields.indexOf(fieldId)
            if (index !== -1 && !value) {
              this.hiddenFields.splice(index, 1)
            } else if (index === -1 && value) {
              this.hiddenFields.push(fieldId)
            }
          }
        })
      })
    })
    return this.hiddenFields
  }

  getGfcList(): string[] {
    return this.gfcList
  }
  getLibertyList(): string[] {
    return this.libertyList
  }
  getFmList(): string[] {
    return this.fmList
  }
  getCnaList(): string[] {
    return this.cnaList
  }
  getStarrList(): string[] {
    return this.starrList
  }
  getChubbList(): string[] {
    return this.chubbList
  }
  getTravelersList(): string[] {
    return this.travelersList
  }
  getGfcCasualtyList(): string[] {
    return this.gfcCasualtyList
  }
  getAigCasualtyList(): string[] {
    return this.aigCasualtyList
  }
  getAlliedList(): string[] {
    return this.alliedList
  }
  getChubbCasualtyList(): string[] {
    return this.chubbCasualtyList
  }
  getCnaCasualtyList(): string[] {
    return this.cnaCasualtyList
  }
  getEverestList(): string[] {
    return this.everestList
  }
  getLibertyCasualtyList(): string[] {
    return this.libertyCasualtyList
  }
  getStarrCasualtyList(): string[] {
    return this.starrCasualtyList
  }
  getSentryCompanyList(): string[] {
    return this.sentryCompanyList
  }

  getAigList(): string[] {
    return this.aigList
  }

  getToolsItems(): { items: string[] } {
    return {
      items: [
        'Undo',
        'Redo',
        '|',
        'Bold',
        'Italic',
        'Underline',
        'StrikeThrough',
        '|',
        'FontName',
        'FontSize',
        'FontColor',
        'BackgroundColor',
        '|',
        'SubScript',
        'SuperScript',
        '|',
        'LowerCase',
        'UpperCase',
        '|',
        'Formats',
        'Alignments',
        '|',
        'OrderedList',
        'UnorderedList',
        '|',
        'Indent',
        'Outdent',
        '|',
        'CreateLink',
        'Image',
        '|',
        'ClearFormat',
        'Print',
        'SourceCode',
        '|',
        'FullScreen',
      ],
    }
  }

  getQuickTools = (): { image: string[] } => {
    return {
      image: [
        'Replace',
        'Align',
        'Caption',
        'Remove',
        'InsertLink',
        '-',
        'Display',
        'AltText',
        'Dimension',
      ],
    }
  }

  getFontFamily = (): object => {
    return {
      default: 'Helvetica',
      items: [
        {
          text: 'Helvetica',
          value: 'Helvetica',
          class: 'e-helvetica',
          command: 'Font',
          subCommand: 'fontName',
        },
      ],
    }
  }

  getPasteCleanupSettings = (): object => {
    return {
      prompt: false,
      plainText: false,
      keepFormat: false,
      deniedTags: ['a', 'h1', 'h2', 'h3'],
      deniedAttrs: ['class', 'level'],
      allowedStyleProps: ['color'],
    }
  }

  isFormReady(): boolean {
    return this.formReady
  }

  // getConfigMapFromSubmission(submissionFields: FacSubmission): Map<FieldKey, FieldInterface> {
  //   const configMap = new Map<FieldKey, FieldInterface>()
  //   submissionFields
  // }

  getFields(
    clearEndpoints: boolean,
    fields: FieldInterface,
    salesforceUsers: readonly SalesforceContact[] = [],
    salesforceAccounts: readonly SalesforceAccount[] = [],
    basisOfAcceptance: FacBasisOfAcceptance[] = [],
    termsAndConditions: FacTermsAndConditions[] = [],
    reinsuranceConditions: FacReinsuranceConditions[] = [],
    currency: FacCurrency[] = [],
  ): FieldInterface {
    // Commented out as potential other fixes going forward
    // Clearing all the field values before we iterate
    // for (const fieldArray of fields.fields) {
    //   for (const field of fieldArray) {
    //     field.value = ''
    //   }
    // }

    // Clearing the configMap completely before reinitializing
    // this.configMap = new Map<FieldKey, FieldInterface>()
    // this.configMap.set(
    //   { name: fields.name, type: fields.type },
    //   cloneDeep(fields)
    // )

    if (clearEndpoints) {
      this.endpointCalls = new Map<string, any>()
    }
    this.configMap = new Map<FieldKey, FieldInterface>()
    this.configMap.set(
      { name: fields.name, type: fields.type },
      cloneDeep(fields)
    )
    this.configMap.forEach(fieldVals => {
      if (fieldVals.name === fields.name && fieldVals.type === fields.type) {
        for (const fieldArray of fieldVals.fields) {
          for (const field of fieldArray) {
            this.allFieldsConfigMap.set(field.id, field)
            if (this.getFieldValuesMapValue(field.id)) {
              // set it as the value
              // @ts-ignore
              field.value = this.getFieldValuesMapValue(field.id)
            } else {
              this.setFieldValuesMapValue(field.id, field.value ? field.value : '')
            }
            if (field.type === 'date') {
              field.value = field.value ? String(this.parseDate(field.value)) : ''
            } else if (field.type === 'checkbox') {
              field.value = 'false'
              if (field.value === 'true') {
                field.value = 'true'
              }
            } else if (field.type === 'select' || field.type === 'multiselect') {
              // @ts-ignore
              if (field.endpoint || field.selector) {
                const optionsArray: string[] = []
                // @ts-ignore
                const options = field.endpoint
                  ? // @ts-ignore
                  field.endpoint.split('|')
                  : // @ts-ignore
                  field.selector.split('|')
                let optionReturn = []
                if (this.endpointCalls.has(options[0])) {
                  optionReturn = this.endpointCalls.get(options[0])
                } else {
                  // @ts-ignore
                  // if (field.endpoint) {
                  //   optionReturn = await this.backendService.getEndpoint(
                  //     options[0]
                  //   )
                  // } else if (options[0] === 'carrier') {
                  //   optionReturn = await this.store
                  //     .select(selectCarriers)
                  //     .pipe(take(1))
                  //     .toPromise()
                  // }
                  // this.endpointCalls.set(options[0], optionReturn)
                }
                optionReturn.forEach((optionReturnElement: any) => {
                  if (
                    optionReturnElement[options[1]] !== null &&
                    optionsArray.indexOf(optionReturnElement[options[1]]) === -1
                  ) {
                    optionsArray.push(optionReturnElement[options[1]])
                  }
                })
                // @ts-ignore
                field.options = optionsArray
              } else {
                if (
                  (fields.name === 'facLanding' ||
                    fields.name === 'submission-1') &&
                  (field.id === 'reinsured' || field.id === 'secondaryCedent')
                ) {
                  const reinsuredIdKey = `${field.id}${ID_SUFFIX}`
                  const reinsuredIdValue =
                    this.getFieldValuesMapValue(reinsuredIdKey)
                  this.setFieldValuesMapValue(
                    `${field.id}${ID_SUFFIX}`,
                    reinsuredIdValue ?? ''
                  )
                  // @ts-ignore
                  field.options = salesforceAccounts.map(x => ({
                    id: x.id,
                    label: x.name,
                  }))
                }
                if (
                  (fields.name === 'facLanding' ||
                    fields.name === 'submission-1') &&
                  field.id === 'reinsuredUnderwriter'
                ) {
                  const reinsuredIdKey = `${field.id}${ID_SUFFIX}`
                  const reinsuredIdValue =
                    this.getFieldValuesMapValue(reinsuredIdKey)
                  this.setFieldValuesMapValue(
                    reinsuredIdKey,
                    reinsuredIdValue ?? ''
                  )
                  // @ts-ignore
                  field.options = salesforceUsers.map(x => ({
                    id: x.id,
                    label: x.name,
                  }))
                }
                if (field.id === 'basisOfAcceptance') {
                  // @ts-ignore
                  field.options = basisOfAcceptance.map(x => x.basisOfAcceptance)
                }
                if (field.id === 'termsAndConditions') {
                  // @ts-ignore
                  field.options = termsAndConditions.map(x => x.termsAndConditions)
                }
                if (field.id === 'reinsuranceConditions') {
                  // @ts-ignore
                  field.options = reinsuranceConditions.map(x => x.reinsuranceConditions)
                }
                if (field.id === 'currency') {
                  // @ts-ignore
                  field.options = currency.map(x => x.currency)
                }
              }
            }
            // eslint-disable-next-line no-prototype-builtins
            if (field.hasOwnProperty('validations') && field.validations) {
              for (const validation of field.validations) {
                if (validation.name === 'required') {
                  // @ts-ignore
                  validation.validator = Validators.required
                } else if (validation.name === 'pattern') {
                  // @ts-ignore
                  validation.validator = Validators.pattern(validation.validator)
                }
              }
            }
            if ((fields.name === 'binder-1' || fields.name === 'certificate-1') &&
              field.id === 'streetAddress' && fields.type === 'property') {
              if (isTravelers(this.getFieldValuesMap().get('reinsured') ?? '')) {
                field.defaultValue = FacDefaults.TravelersStreetAddress
              }
            }
            if (field.id === 'termsAndConditions') {

              let defaultValue = ''
              const currentFormType = fields.type

              if (
                this.gfcList.includes(
                  this.getFieldValuesMap().get('reinsured') ?? ''
                ) ||
                (this.gfcCasualtyList.includes(
                    this.getFieldValuesMap().get('reinsured') ?? ''
                  ) &&
                  currentFormType === 'casualty')
              ) {
                defaultValue = 'Lockton Re LLC Certificate Form LP1002 - November 2022'
              } else if (
                (this.aigList.includes(
                    this.getFieldValuesMap().get('reinsured') ?? ''
                  ) ||
                  (this.aigCasualtyList.includes(
                    this.getFieldValuesMap().get('reinsured') ?? ''
                  ))) &&
                currentFormType === 'casualty'
              ) {
                defaultValue = 'AIG U.S. Casualty Certificate (04/16)'
              } else if (
                (this.aigList.includes(
                  this.getFieldValuesMap().get('reinsured') ?? ''
                )) &&
                currentFormType === 'property'
              ) {
                defaultValue = 'AIG U.S. Property Certificate (04/16)'
              } else if (
                this.libertyList.includes(
                  this.getFieldValuesMap().get('reinsured') ?? ''
                ) ||
                (this.libertyCasualtyList.includes(
                    this.getFieldValuesMap().get('reinsured') ?? ''
                  ) &&
                  currentFormType === 'casualty')
              ) {
                defaultValue = 'Liberty Mutual Insurance Company Form No. LM-1000-FC(Edition 12/31/2010)'
              } else if (
                this.fmList.includes(
                  this.getFieldValuesMap().get('reinsured') ?? ''
                )
              ) {
                defaultValue = 'FM Global Fac Certificate Form CP2007 – 09-01-10'
              } else if (
                this.cnaList.includes(
                  this.getFieldValuesMap().get('reinsured') ?? ''
                ) ||
                (this.cnaCasualtyList.includes(
                    this.getFieldValuesMap().get('reinsured') ?? ''
                  ) &&
                  currentFormType === 'casualty')
              ) {
                defaultValue = 'CNA General Conditions 092914'
              } else if (
                this.starrList.includes(
                  this.getFieldValuesMap().get('reinsured') ?? ''
                ) ||
                (this.starrCasualtyList.includes(
                    this.getFieldValuesMap().get('reinsured') ?? ''
                  ) &&
                  currentFormType === 'casualty')
                  || isStarrPropertyDeal(this.getFieldValuesMap().get('reinsured'), currentFormType)
              ) {
                defaultValue = 'Starr Facultative General Conditions US 01012023'
              } else if (
                this.chubbList.includes(
                  this.getFieldValuesMap().get('reinsured') ?? ''
                ) &&
                currentFormType === 'property'
              ) {
                defaultValue =
                  'Chubb Property Reinsurance General Conditions - FAC-39670a (08/13)'
              } else if (
                this.chubbCasualtyList.includes(
                  this.getFieldValuesMap().get('reinsured') ?? ''
                ) &&
                currentFormType === 'casualty'
              ) {
                defaultValue = 'Chubb Casualty Reinsurance General Conditions FAC39671a 0813'
              } else if (
                this.travelersList.includes(
                  this.getFieldValuesMap().get('reinsured') ?? ''
                )
              ) {
                defaultValue = 'Travelers– includes policy conditions, CP-6067'
              } else if (
                this.alliedList.includes(
                  this.getFieldValuesMap().get('reinsured') ?? ''
                )
              ) {
                defaultValue =
                  'Allied World Assurance Company General Terms & Conditions Ref: NACF00002 100 (10/17) (Edition October 2017)'
              } else if (
                this.everestList.includes(
                  this.getFieldValuesMap().get('reinsured') ?? ''
                )
              ) {
                if (this.getFieldValuesMap().get('name') === 'Munich Re') {
                  defaultValue = 'Everest Munich Re Fac Re Cert v.11.25.2019'
                } else if (
                  this.getFieldValuesMap().get('name') === 'Hannover Re'
                ) {
                  defaultValue = 'Everest Insurance v.4.2.26.2021'
                } else if (this.getFieldValuesMap().get('name') === 'Swiss Re') {
                  defaultValue = 'Everest Swiss Re Fac Cert'
                } else {
                  defaultValue = 'Everest Insurance v.3.19.2020'
                }
              }

              if (defaultValue){
                this.setFieldValuesMapValue(field.id, defaultValue)
              }
            }
            if (field.id === 'claimsNotification' && field.defaultValue) {
              this.setFieldValuesMapValue(field.id, field.defaultValue)
            }
          }
        }
      }
    })
    this.formReady = true
    return [...this.configMap.values()].find(Boolean) as FieldInterface
  }
}
