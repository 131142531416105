import { FacDataVersion, FacSubmission, Facultative, FormLayerResponse } from '../models'
import { getFacDataVersionId } from '@deals/utils/getFacDataVersionId'
import { saveFacultativeFormVersion } from '@deals/store'
import { Store } from '@ngrx/store'
import { AppState } from '@core/store'

export function updatePolicyNumber(facDataEntry: Facultative, policyNumber: string, submissionFields: FacSubmission, store: Store<AppState>) {
  const responsesForm = facDataEntry?.facDataVersions?.find(v => v.category == 'responses')
  const responsesFieldValues = JSON.parse(responsesForm?.fieldValues ?? '{}')
  if (responsesFieldValues && responsesFieldValues.marketResponses) {
    const marketResponses = JSON.parse(responsesFieldValues.marketResponses ?? '[]')
    let marketResponse = marketResponses.find((m: FormLayerResponse) => !!m.policyNumber)
    if (!marketResponse?.policyNumber) {
      marketResponse = marketResponses[0]
    }
    if (marketResponse && policyNumber !== marketResponse.policyNumber) {
      marketResponse.policyNumber = policyNumber
      const updatedFieldValues = {...responsesFieldValues, marketResponses: JSON.stringify(marketResponses)}
      const responsesVersion = getFacDataVersionId(facDataEntry, responsesForm.id)
      const newResponsesVersion = {
        ...responsesVersion,
        category: responsesVersion.category,
        id: responsesVersion.id,
        final: responsesVersion.final,
        fieldValues: JSON.stringify(updatedFieldValues),
        hiddenFields: responsesVersion.hiddenFields,
      } as FacDataVersion
      store.dispatch(
        saveFacultativeFormVersion({
          facDataVersion: newResponsesVersion,
        })
      )
    }
  }
  if (policyNumber !== submissionFields.policyNumber) {
    submissionFields.policyNumber = policyNumber
  }
}
