import { Injectable } from '@angular/core'
import {
  IPdfGenerator,
  PdfPageOptions
} from './pdf-generator.interface'
import { PdfGenerator } from './pdf-generator'

@Injectable({ providedIn: 'root' })
export class PdfGeneratorFactoryService {
  createGenerator(pageOptions: Partial<PdfPageOptions> = {}): IPdfGenerator {
    return new PdfGenerator(pageOptions)
  }
}
