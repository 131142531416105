import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { Router } from '@angular/router'
import { UserPermissionConstants } from '@auth/models'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-fac-header',
  styleUrls: ['./fac-header.component.scss'],
  templateUrl: './fac-header.component.html',
})
export class FacHeaderComponent {
  @Input() userName?: string
  @Input() title?: string
  @Input() subtitle?: string
  @Input() isLoggedIn = false;
  @Input() userPermissions: readonly string[];
  @Output() login = new EventEmitter<void>()
  @Output() logout = new EventEmitter<void>()

  constructor(private router: Router) {
  }

  get getAllowDashboard(): boolean {
    return this.userPermissions && this.userPermissions.includes(UserPermissionConstants.FacultativeDashboard)
  }

  onAdministrationClick(): void {
    this.router.navigate(['/admin'])
  }

  onFacultativeClick(): void {
    this.router.navigate(['/'])
  }

  onDashboardClick(): void {
    this.router.navigate(['/dashboard'])
  }
}
