import {
  CoverageReinsured,
  FacExportData,
  FieldTypes,
} from '../../models'
import {
  IReportGenerator,
  ReportResult
} from './report-generator.interface'
import {
  createBasicHeader,
} from './utils'
import {
  FacPdfHeader,
  IPdfGenerator,
  PdfHeading,
  PdfHeadingRow,
  PdfRow,
  PdfText,
  PdfTextRow,
} from '../pdf'
import { FilenameBuilderFactoryService } from './filename-builder.service'
import { generateCasualtyQuoteLetterText } from '../../utils'
import {
  IReportModelBuilder,
  ReportModelBuilderFactoryService
} from './report-model-builder.service'
import { formatLocktonContactLines } from '../../formatters'

export class ReportGeneratorCasualtyQtc extends IReportGenerator {
  private readonly reportTitle = 'Lockton Re Facultative Quote'
  private modelBuilder: IReportModelBuilder

  constructor(
    private pdf: IPdfGenerator,
    private filenameBuilderFactory: FilenameBuilderFactoryService,
    private reportModelBuilderFactory: ReportModelBuilderFactoryService
  ) {
    super()
  }

  async generate(data: FacExportData): Promise<ReportResult> {

    this.modelBuilder = this.reportModelBuilderFactory.create(data)
    const pdfName: string = this.buildReportFileName(data)

    this.addLetterPage(data)
    this.addHeader()

    this.modelBuilder
      .addFieldRowFromConfig('reinsured')
      .addFieldRow('name', 'Named Insured')
      .addFieldRowFromConfig('streetAddress')
      .addFieldRowFromConfig('cityName')
      .addFieldRowFromConfig('policyPeriodFrom')
      .addFieldRowFromConfig('reinsurancePeriodFrom')
      .addFieldRowFromConfig('coverageReinsured')
      .addPolicyRetroDate()
      .addPolicyType()
      .addFieldRowFromConfig('policyLimit')
      .addProgramStructure()
      .addCompanyRetention()
      .addReinsuranceLimits()
      .addReinsurancePremiums()
      .addFieldRowFromConfig('currency')
      .addFieldRow('rateAdjustability', 'Subject to Audit')
      .addFieldRowFromConfig('rate')
      .addMinimumAndDeposit()
      .addFieldRowFromConfig('reinsuranceCoverage')
      .addFieldRowFromConfig('additionalReinsuranceConditions')
      .addFieldRow('brokerageCommissionSub', 'Brokerage Commissions')
      .addFieldRowFromConfig('cedingCommission')
      .addFieldRowFromConfig('basisOfAcceptance')
      .addFieldRowFromConfig('allocatedLossAdjustmentExpense')
      .addNoteableTerms()
      .addFieldRowFromConfig('reinsuranceCertificate')
      .addFieldRowFromConfig('termsAndConditions')
      .addScheduleOfUnderlyingLimits()
      .addReinsurersTable()
      .addAdditionalResponsesTable()
      .addFieldRowFromConfig('paymentDue')

    const pdfFieldRows: PdfRow[] = this.modelBuilder.build()
    pdfFieldRows.forEach((row: PdfRow) => {
      this.pdf.addRow(row)
    })

    this.addFooter(data)

    await this.pdf.save(pdfName)
    return { contentType: 'pdf', contents: this.pdf.getBase64Contents(), reportName: pdfName, files: [] }
  }

  private buildReportFileName(data: FacExportData) {
    const coverageReinsured = data.fieldMap.get('coverageReinsured') as CoverageReinsured
    const marketName = data.reinsuranceData?.length ? data.reinsuranceData[0].reinsurers : ''
    const fileNameBuilder = this.filenameBuilderFactory.create()
    fileNameBuilder.setDealName(data.fieldMap.get('name') ?? '')
      .setDealType('casualty')
      .setFormType('qtc')
      .setCedentName(data.fieldMap.get('reinsured') ?? '')
      .setCoverageType(coverageReinsured)
      .setMarketName(marketName)
    return fileNameBuilder.build()
  }

  private addLetterPage(data: FacExportData): void {
    const title = this.reportTitle
    const nameInsured = data.fieldMap.get('name') ?? ''
    const street = data.fieldMap.get('streetAddress') ?? ''
    const city = data.fieldMap.get('cityName') ?? ''
    const state = data.fieldMap.get('stateName') ?? ''
    const zip = data.fieldMap.get('zipCode') ?? ''
    const nameAndAddress = `${nameInsured}\n${street}\n${city}, ${state} ${zip}`.trim()

    const header: FacPdfHeader = {
      title,
      subtitle: 'INSURED COMPANY AND ADDRESS',
      nameAndAddress: `${nameAndAddress}`,
      height: 56,
    }
    this.pdf.addPage(header)
    this.pdf.addTextRow(this.buildLetterBody(data))
  }

  private buildLetterBody(data: FacExportData): PdfTextRow {
    const reinsuredUnderwriter: string = data.fieldMap.get(FieldTypes.reinsuredUnderwriter) ?? ''

    const letterText = generateCasualtyQuoteLetterText(reinsuredUnderwriter, data.dealBroker)
    const bodyTextField: PdfText = {type: 'text', value: letterText }
    return { type: 'text', items: [bodyTextField] }
  }

  private addHeader() {
    this.pdf.addPage(createBasicHeader(this.reportTitle))
  }

  private addFooter(data: FacExportData) {
    this.pdf.addHeadingRow(this.createLocktonAddressFooter(data))
  }

  private createLocktonAddressFooter(data: FacExportData): PdfHeadingRow {
    const headingField: PdfHeading = {
      type: 'heading',
      value: formatLocktonContactLines(data.isUK, data.dealBroker),
      allCaps: false,
      charSpace: 0,
      fontSize: 10,
    }
    return {
      type: 'heading',
      items: [headingField]
    }
  }
}
