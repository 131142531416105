import { Injectable } from '@angular/core'
import { HttpErrorResponse } from '@angular/common/http'
import { EMPTY, Observable, throwError } from 'rxjs'
import { MatSnackBar } from '@angular/material/snack-bar'

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  private static DefaultErrorMessage = 'An unknown error occurred'

  constructor(private snackbar: MatSnackBar) { }

  handleError(error: any, contextMessage?: string, errorFactory?: () => any): Observable<never> {
    const body: string = contextMessage ?
      this.getErrorBodyWithContext(error, contextMessage) :
      this.getErrorBody(error)

    console.error(body)
    this.snackbar.open(body, 'X', { duration: 3000 })

    return errorFactory ? throwError(errorFactory) : EMPTY
  }

  getErrorBody(error: any): string {
    if (error instanceof HttpErrorResponse) {
      const httpError: HttpErrorResponse = error as HttpErrorResponse
      return `${httpError.status} ${httpError.statusText} - ${httpError.message}`
    } else if (error instanceof Error) {
      return error.message
    } else if (typeof (error) === 'string') {
      return error
    }
    return ErrorHandlerService.DefaultErrorMessage
  }

  getErrorBodyWithContext(error: any, contextMessage: string): string {
    if (error instanceof HttpErrorResponse) {
      const httpError: HttpErrorResponse = error as HttpErrorResponse
      return `${contextMessage} - ${httpError.status} ${httpError.statusText} - ${httpError.message}`
    } else if (error instanceof Error) {
      return `${contextMessage} - ${error.message}`
    } else if (typeof (error) === 'string') {
      return `${contextMessage} - ${error}`
    }

    return `${contextMessage} - ${ErrorHandlerService.DefaultErrorMessage}`
  }
}
