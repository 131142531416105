import { isExactCalendarYear } from '@utils'

export function isLongOrShortTerm(
  reinsurancePeriodFrom: string | Date | number | undefined,
  reinsurancePeriodTo: string | Date | number| undefined,
  policyPeriodFrom: string | Date | number | undefined,
  policyPeriodTo: string | Date | number| undefined,

) {
  const isReinsurancePeriodLongOrShort = !isExactCalendarYear(reinsurancePeriodFrom, reinsurancePeriodTo)
  const isPolicyPeriodLongOrShort = !isExactCalendarYear(policyPeriodFrom, policyPeriodTo)
  return isReinsurancePeriodLongOrShort || isPolicyPeriodLongOrShort
}
