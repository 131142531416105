import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '@environments/environment'
import { PermissionsResponse } from '@api/model'
import { Observable, of } from 'rxjs'
import { mergeMap } from 'rxjs/operators'

export interface UserPermissionsDto {
  readonly authenticatingUser: string
  readonly permissions: ReadonlyArray<string>
  readonly sageLogicalRole: string
}

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(private httpClient: HttpClient) { }

  public getUserPermissions(): Observable<UserPermissionsDto> {
    const url = `${environment.internalApi.base}${environment.internalApi.permissions}`
    return this.httpClient.get<PermissionsResponse>(url).pipe(
      mergeMap((response: PermissionsResponse) => {
          return of({
            authenticatingUser: response.authenticatingUser,
            permissions: response.specialPermissions,
            sageLogicalRole: response.sageLogicalRole
          })
        },
      )
    )
  }
}
