import {
  HttpErrorResponse,
} from '@angular/common/http'
import { isNil } from 'ramda'
import {
  Observable,
  of,
  OperatorFunction,
  pipe,
  UnaryFunction,
} from 'rxjs'
import {
  catchError,
  filter,
  map,
} from 'rxjs/operators'
import { ApplicationError, errorPayload } from '../error'
import { ApiResponse, MaybeData, MaybeError } from './model'

export const mapToMaybeData = <T>(): OperatorFunction<T, MaybeData<T>> =>
  map((res: T) => ({ data: res }))

export function handleError<T extends object>(op = 'API', result?: T) {
  return (err: unknown): Observable<T & MaybeError> => {
    let message = `${op} failed.`
    let details: string[] = []
    const reason = (err as any)?.reason
    if (!isNil(reason)) {
      details = [(err as any).reason]
    } else {
      if ((err as any)?.status === 403) {
        message = 'The user has no access to this Entity'
      }
      if ((err as any)?.status === 404) {
        message = 'No data found'
      }
      details = getDetailsMessage(err)
    }
    const text = getTextMessage(err)
    console.error(message, err)
    const error = errorPayload(message, details, { text })
    return of(Object.assign({}, { error }, result) as MaybeError & T)
  }
}

export const catchAndHandleError = <T, R extends object>(
  op?: string,
  result?: R
): OperatorFunction<T, T | (R & MaybeError)> =>
  catchError(handleError(op, result))

const getDetailsMessage = (e: unknown): string[] => {
  if (e instanceof HttpErrorResponse) {
    const messages = [e.message]
    const msg = e?.error?.message ?? e?.error?.Message
    if (msg) {
      messages.push(msg)
    }
    return messages
  } else {
    if (e instanceof Error) {
      const messages = [e.message]
      return messages
    }
  }
  return []
}

const getTextMessage = (e: unknown): string => {
  if (e instanceof HttpErrorResponse) {
    return e?.error?.text ?? ''
  } else if (e instanceof Error) {
    return e.message
  }
  return ''
}

export const rejectError = <T>(
  onErrorFn: (err: ApplicationError) => void
): UnaryFunction<ApiResponse<T>, Observable<NonNullable<T>>> =>
  // @ts-ignore
  pipe(
    // @ts-ignore
    filter((res: MaybeData<T> & MaybeError) => {
      if (res.error) {
        onErrorFn(res.error)
        return false
      }
      return true
    }),
    map(res => {
      // tslint:disable-next-line:no-non-null-assertion
      return res.data!
    })
  )
