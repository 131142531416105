import { CoverageReinsured, ReinsuredLayer } from '../models'
import { buildLayerLabel } from './buildLayerLabel'
import { formatPercentageOrNil } from './formatPercentageOrNil'
import { NIL_TEXT } from '@constants'
import { getlayerValue } from './getlayerValue'
import { stripNonNumeric } from './stripNonNumeric'
import { formatSmartCurrency } from './formatSmartCurrency'
import { getQuotaShareLanguage } from './getQuotaShareLanguage'

export function extractLimitLayer(
  // @ts-ignore
  index: number,
  layer: ReinsuredLayer,
  coverage: string,
  cessionType: string | undefined,
  coverageText: string | undefined,
  isQuotaShare?: any | undefined,
  partOfCoverageLimit?: any | undefined,
  partOfGeneralAggregate?: any | undefined,
  partOfProductOperationAggregate?: any | undefined
): string {
  let str = buildLayerLabel(layer.index - 1)
  const reinsuranceLayer = getlayerValue(layer.reinsuranceLayer)
  const reinsuranceLimit = getlayerValue(layer.reinsuranceLimit)
  const generalAgg = getlayerValue(layer.generalAgg)
  const generalAggAttachment = getlayerValue(layer.generalAggAttachment)
  const poca = getlayerValue(layer.poca)
  const pocaAttachment = getlayerValue(layer.pocaAttachment)

  let quotaShareLanguage = ''
  if (isQuotaShare)
    quotaShareLanguage = getQuotaShareLanguage(coverageText, partOfCoverageLimit, partOfGeneralAggregate, partOfProductOperationAggregate)
  let cessionInverseText = NIL_TEXT
  if (cessionType === '$') {
    const layerLimitValue = parseFloat(stripNonNumeric(reinsuranceLayer))
    const cessionValue = parseFloat(stripNonNumeric(layer.cessionNumber ?? 0))
    const diff = (layerLimitValue - cessionValue)
    if (diff > 0) {
      cessionInverseText = formatSmartCurrency(diff.toFixed().toString())
    }
  } else {
    let cessionStr = formatPercentageOrNil(parseFloat(layer.cession ?? ''))
    if (cessionStr === NIL_TEXT) {
      cessionStr = '0.00%'
    }
    cessionInverseText = (100 - parseFloat(cessionStr)).toFixed(2).toString() + '%'
    if (cessionInverseText === '0.00%') {
      cessionInverseText = NIL_TEXT
    }
  }

  if ([
    CoverageReinsured.general,
    CoverageReinsured.umbrella,
    CoverageReinsured.umbrellaGeneral,
    CoverageReinsured.umbrellaAuto,
    CoverageReinsured.excessAuto,
    CoverageReinsured.auto,
    CoverageReinsured.workersComp,
    CoverageReinsured.directorsOfficers,
    CoverageReinsured.errorsOmissions,
    CoverageReinsured.miscellaneous,
    CoverageReinsured.cyber
  ].indexOf(coverage as CoverageReinsured) < 0) {
    str += layer.reinsuranceLayer || '0'
    if (layer.basisOfAcceptance === 'Excess of Loss') {
      str += ' Excess of ' + (layer.reinsuranceLimit || '0')
    }
    str += ' Excess of Policy Deductibles'
  } else {
    str += `${cessionInverseText} of ${reinsuranceLayer} Each ${coverageText} ${CoverageReinsured.auto === coverage ? '' : '/ '}`
    if (CoverageReinsured.auto === coverage) {
      if (layer.index === 1 && cessionInverseText === NIL_TEXT) {
        str = ''
      } else {
        str += `Excess of ${reinsuranceLimit} Each ${coverageText}`
      }
    }
    if (CoverageReinsured.umbrella === coverage || CoverageReinsured.umbrellaGeneral === coverage || CoverageReinsured.umbrellaAuto === coverage || CoverageReinsured.excessAuto === coverage) {
      if (layer.index === 1 && cessionInverseText === NIL_TEXT) {
        str = ''
      } else {
        str += `${generalAgg} General Aggregate`
        if (poca && poca !== NIL_TEXT) {
          str += ` / ${poca} Products and Completed Operations Aggregate`
        }
        if (isQuotaShare) {
          str += quotaShareLanguage
        }
        if (index === 0) {
          str += ` Where Applicable Excess of Scheduled Underlying Limits and/or Self Insured Retention`
        } else {
          str += ` Where Applicable Excess of ${reinsuranceLimit} Each ${coverageText}`
        }
        if (generalAggAttachment && generalAggAttachment !== NIL_TEXT) {
          str += ` / ${generalAggAttachment} General Aggregate Where Applicable Excess of Scheduled Underlying Limits and/or Self Insured Retention`
        }

        if (pocaAttachment && pocaAttachment !== NIL_TEXT) {
          str += ` / ${pocaAttachment} Products and Completed Operations Aggregate Where Applicable Excess of Scheduled Underlying Limits and/or Self Insured Retention`
        }
      }
    }
    if (CoverageReinsured.cyber === coverage  || coverage === CoverageReinsured.directorsOfficers ||
      coverage === CoverageReinsured.errorsOmissions || coverage === CoverageReinsured.miscellaneous) {
      if (layer.index === 1 && cessionInverseText === NIL_TEXT) {
        str = ''
      } else {
        str += `${generalAgg} General Aggregate`
        if (isQuotaShare) {
          str += quotaShareLanguage
        }
        if (index === 0) {
          str += ` Where Applicable Excess of Scheduled Underlying Limits and/or Self Insured Retention`
        } else {
          str += ` Where Applicable Excess of ${reinsuranceLimit} Each ${coverageText}`
        }
        if (generalAggAttachment && generalAggAttachment !== NIL_TEXT) {
          str += ` / ${generalAggAttachment} General Aggregate Where Applicable Excess of Scheduled Underlying Limits and/or Self Insured Retention`
        }
      }
    }
    if (CoverageReinsured.general === coverage) {
      if (layer.index === 1 && cessionInverseText === NIL_TEXT) {
        str = ''
      } else {
        str += `${generalAgg} General Aggregate / `
        str += `${poca} Products and Completed Operations Aggregate Excess of `
        str += `${reinsuranceLimit} Each ${coverageText} / `
        str += `${generalAggAttachment} General Aggregate / `
        str += `${pocaAttachment} Products and Completed Operations Aggregate`
      }
    }
    if (CoverageReinsured.workersComp === coverage) {
      if (layer.index === 1 && cessionInverseText === NIL_TEXT) {
        str = ''
      } else {
        str += `${cessionInverseText} of ${layer.index === 1 ? 'the ' : ''}`
        str += `${layer.reinsuranceLayer || NIL_TEXT} Each ${coverageText} (Coverage A&B Where Applicable) Excess `
        str += `${layer.reinsuranceLimit || NIL_TEXT} Each ${coverageText} (Coverage A&B Where Applicable)`
      }
    }
  }
  return str.replace(/&nbsp;/g, ' ')
}
