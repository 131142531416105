export function getQuotaShareLanguage(coverageExpression: string, partOfCoverageLimit: string, partOfGeneralAgg: string, partOfProductsOperationsAgg: string): string {
    let quotaShareLanguage = ` part of `    
    if (partOfCoverageLimit?.length > 0)
      quotaShareLanguage += `${partOfCoverageLimit} Each ${coverageExpression}`

    if (partOfGeneralAgg?.length > 0) {
      if (partOfCoverageLimit?.length > 0)
        quotaShareLanguage += '/'
      quotaShareLanguage += `${partOfGeneralAgg} General Aggregate`
    }

    if (partOfProductsOperationsAgg?.length > 0) {
      if (partOfCoverageLimit?.length > 0 || partOfGeneralAgg?.length > 0)
        quotaShareLanguage += '/'
      quotaShareLanguage += `${partOfProductsOperationsAgg} Products and Completed Operations Aggregate`
    }

    return quotaShareLanguage
}