import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

export interface ConfirmationDialogOptions {
  message?: string
  submitLabel?: string
}

const defaults: ConfirmationDialogOptions = {
  message: 'Are you sure you want to do this?',
  submitLabel: 'Confirm',
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-confirmation',
  template: `
    <div>
      <h1 mat-dialog-title>
        {{ options.message }}
      </h1>
      <div mat-dialog-content></div>
      <div mat-dialog-actions>
        <button appButton accent (click)="confirm(true)">
          <span>{{ options.submitLabel }}</span>
        </button>
        <button appButton link (click)="confirm(false)">
          <span>Cancel</span>
        </button>
      </div>
    </div>
  `,
})
export class ConfirmationDialogComponent {
  options: ConfirmationDialogOptions
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogOptions
  ) {
    this.options = { ...defaults, ...data }
  }

  confirm(choice: boolean) {
    this.dialogRef.close(choice)
  }
}
