import {Injectable} from '@angular/core'
import {Actions, createEffect, ofType} from '@ngrx/effects'
import { catchError, concatMap, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators'
import {Action, select, Store} from '@ngrx/store'
import {selectAuthState} from '../../core/store/auth/auth.selectors'
import { selectCurrentVersion, selectedDeal, selectIdsToUpdate } from './facultative.selectors'
import {AppState} from '../../core/store'
import {rejectError} from '../../api/util'
import {BackendService} from '../../api/backend/backend.service'
import * as facActions from './facultative.actions'
import { setCurrentDealType, setCurrentFormCategory } from './facultative.actions'
import { FacData, FacDataVersion, Facultative, FacUser } from '../models'
import { UserDto } from '../../api/model/backend.model'
import { forkJoin, Observable, of } from 'rxjs'
import {getErrorMessage} from '../utils'
import {FacFieldService} from '../services'
import {Router} from '@angular/router'
import { errorPayload } from '../../error/model/error'
import {routerNavigationAction} from '@ngrx/router-store'
import {buildDealFormUrl, getDealTypeFromUrl, getFormCategoryFromUrl, isDealFormUrl} from '../routing'
import { selectUrl } from './router.selectors'
import { casualtySubmissionFields, propertySubmissionFields } from '../components/fac-form-categories/fac-submission/fields.constants'
import { casualtyBinderFields, propertyBinderFields } from '../components/fac-form-shells/fac-binder-shell/binder-fields.constants'
import { casualtyCertificateFields, propertyCertificateFields } from '../components/fac-form-shells/fac-certificate-shell/certificate-fields.constants'
import { casualtyQtcFields, propertyQtcFields } from '../components/fac-form-shells/fac-qtc-shell/qtc-fields.constants'
import { responsesFields } from '../components/fac-form-shells/fac-responses-shell/fields.constants'
import { MaybeData, MaybeError } from '@api/model'
import { NotificationService } from '@core/services'

@Injectable()
export class FacEffects {
  constructor(
    private actions$: Actions,
    private backendService: BackendService,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private fieldService: FacFieldService,
    private router: Router,
  ) {}

  routerDealFormNavigation$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerNavigationAction),
      filter(navigation => isDealFormUrl(navigation.payload.routerState.url)),
      concatMap(async (navigation) =>
        setCurrentFormCategory({ currentType: getFormCategoryFromUrl(navigation.payload.routerState.url) } )))
    })

  routerDealNavigation$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerNavigationAction),
      filter(navigation => isDealFormUrl(navigation.payload.routerState.url)),
      concatMap(async (navigation) =>
        setCurrentDealType({ currentDealType: getDealTypeFromUrl(navigation.payload.routerState.url) } )))
  })

  validateFacultativeDeal = createEffect(() => {
    return this.actions$.pipe(
      ofType(facActions.getFacultativeDealSuccess),
      withLatestFrom(
        this.store.pipe(select(selectUrl)),
      ),
      map(([{data}, url]) => {
        const categoryType = getFormCategoryFromUrl(url) ?? 'submission'
        const dealType = getDealTypeFromUrl(url)
        const hasCategory = data.facDataVersions.filter(v => v.category === categoryType)?.length > 0
        const category = hasCategory ? categoryType : 'submission'
        if (data.facData.type !== dealType && data.facData.id !== 0) {
          return of(this.router.navigate([buildDealFormUrl(data.facData.type, data.facData.id.toString(), category)]))
        }
      })
    )
  }, { dispatch: false })

  getFacultativeDeal = createEffect(() => {
    return this.actions$.pipe(
      ofType(facActions.getFacultativeDeal),
      withLatestFrom(
        this.store.pipe(select(selectUrl)),
      ),
      switchMap(([{dealId}, url]) => {
        if (dealId === 'new') {
          this.fieldService.clearSubmissionFields()
          const dealType = getDealTypeFromUrl(url)
          const casualtyForms = [casualtySubmissionFields, casualtyBinderFields, casualtyCertificateFields, casualtyQtcFields]
          const propertyForms = [propertySubmissionFields, propertyBinderFields, propertyCertificateFields, propertyQtcFields]
          if (dealType === 'casualty') {
            this.fieldService.clearFields([...casualtyForms, responsesFields], new Set<string>())
          } else if (dealType === 'property') {
            this.fieldService.clearFields([...propertyForms, responsesFields], new Set<string>())
          }
          const fieldMap = this.fieldService.getFieldValuesMap()
          const facData = JSON.parse(JSON.stringify(Object.fromEntries(fieldMap))) as FacData
          const response = {
            facData: {...facData, id: 0},
            facDataVersions: []
          } as Facultative
          return of({data: response})
        } else {
          return this.backendService.getFacDeal(dealId)
        }
      }),
      rejectError(error =>
        this.store.dispatch(facActions.getFacultativeDealFail({ errorMessage: error.message }))
      ),
      map(data => {
        return facActions.getFacultativeDealSuccess({data})
      })
    )
  })

  getSalesforceAccounts = createEffect(() => {
    return this.actions$.pipe(
      ofType(facActions.loadSalesforceAccounts),
      switchMap(() => {
        return this.backendService.getSalesforceAccounts()
      }),
      rejectError(error =>
        this.store.dispatch(facActions.loadSalesforceAccountsFailure({ errorMessage: error.message }))
      ),
      map(salesforceAccounts => {
        return facActions.loadSalesforceAccountsSuccess({salesforceAccounts})
      })
    )
  })

  loadBrokerFilterList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(facActions.loadBrokerFilterPicklist),
      switchMap(() => {
        return this.backendService.getBrokerFilterList().pipe(
          map((response: MaybeData<string[]> & MaybeError) => {
            if (response.error) {
              return facActions.loadBrokerFilterPicklistFailure({errorMessage: response.error.message})
            }
            const brokerPicklistItems = (response.data as string[]) ?? []
            return facActions.loadBrokerFilterPicklistSuccess({ brokerPicklistItems })
          })
        )
      }),
      catchError(error =>
        of(facActions.loadBrokerFilterPicklistFailure({ errorMessage: getErrorMessage(error) }))
      )
    )
  })

  loadAllUsers = createEffect(() => {
    return this.actions$.pipe(
      ofType(facActions.loadAllFacUsers),
      switchMap(() => {
        return this.backendService.getAllUsers().pipe(
          map((response) => {
              if (response.error) {
                return facActions.loadAllFacUsersFailure({ errorMessage: response.error.message })
              }
              const allFacUsers = response?.data
              const users: FacUser[] = allFacUsers?.map((userDto: UserDto) => {
                return {
                  id: userDto.id,
                  name: `${userDto.first_name} ${userDto.last_name}`,
                  email: userDto.email_id,
                  first_name: userDto.first_name,
                  last_name: userDto.last_name,
                  address: userDto.address,
                  phone: userDto.phone,
                  city: userDto.city,
                  country: userDto.country,
                  countrycode: userDto.countrycode,
                  postalcode: userDto.postalcode,
                  statecode: userDto.statecode,
                  street: userDto.street,
                  sage_logical_role: userDto.sage_logical_role,
                  isUk: userDto.countrycode === 'GB',
                  isBroker: userDto.sage_logical_role?.trim().toLocaleLowerCase() === 'broker fac'
                } as FacUser
              }) ?? []
              return facActions.loadAllFacUsersSuccess({ users })
            }
          ))
      }),
      catchError(error =>
        of(facActions.loadAllFacUsersFailure({ errorMessage: getErrorMessage(error) }))
      )
    )
  })

  getTermsAndConditions = createEffect(() => {
    return this.actions$.pipe(
      ofType(facActions.loadTermsAndConditions),
      switchMap(() => {
        return this.backendService.getFacultativeTermsAndConditions().pipe(
          map((response) => {
            if (response.error) {
              return facActions.backendRequestFailure({errorMessage: response.error.message})
            }
            return facActions.loadTermsAndConditionsSuccess({termsAndConditions: response?.data ?? []})
          }
        ))
      }),
      catchError(error =>
        of(facActions.backendRequestFailure({ errorMessage: getErrorMessage(error) }))
      )
    )
  })

  getReinsuranceConditions = createEffect(() => {
    return this.actions$.pipe(
      ofType(facActions.loadReinsuranceConditions),
      switchMap(() => {
        return this.backendService.getFacultativeReinsuranceConditions().pipe(
          map((response) => {
            if (response.error) {
              return facActions.backendRequestFailure({errorMessage: response.error.message})
            }
            return facActions.loadReinsuranceConditionsSuccess({ reinsuranceConditions: response?.data ?? []})
          }
        ))
      }),
      catchError(error =>
        of(facActions.backendRequestFailure({ errorMessage: getErrorMessage(error) }))
      )
    )
  })

  getFacReinsurerList = createEffect(() => {
    return this.actions$.pipe(
      ofType(facActions.loadReinsurers),
      switchMap(() => {
        return this.backendService.getFacultativeReinsurer().pipe(
          map((response) => {
              if (response.error) {
                return facActions.backendRequestFailure({errorMessage: response.error.message})
              }
              return facActions.loadReinsurersSuccess({ reinsurersList: response?.data ?? []})
            }
          ))
      }),
      catchError(error =>
        of(facActions.backendRequestFailure({ errorMessage: getErrorMessage(error) }))
      )
    )
  })

  getFacUnderwriterList = createEffect(() => {
    return this.actions$.pipe(
      ofType(facActions.getSalesforceContacts),
      switchMap(() => {
        return this.backendService.getSalesforceContacts().pipe(
          map((response) => {
              if (response.error) {
                return facActions.backendRequestFailure({errorMessage: response.error.message})
              }
              return facActions.getSalesforceContactsSuccess({ salesforceContacts: response?.data ?? []})
            }
          ))
      }),
      catchError(error =>
        of(facActions.backendRequestFailure({ errorMessage: getErrorMessage(error) }))
      )
    )
  })

  getCurrency = createEffect(() => {
    return this.actions$.pipe(
      ofType(facActions.loadCurrencies),
      switchMap(() => {
        return this.backendService.getFacultativeCurrency().pipe(
          map((response) => {
            if (response.error) {
              return facActions.backendRequestFailure({errorMessage: response.error.message})
            }
            return facActions.loadCurrenciesSuccess({ currency: response?.data ?? []})
          }
        ))
      }),
      catchError(error =>
        of(facActions.backendRequestFailure({ errorMessage: getErrorMessage(error) }))
      )
    )
  })

  getBasisOfAcceptance = createEffect(() => {
    return this.actions$.pipe(
      ofType(facActions.loadBasisOfAcceptance),
      switchMap(() => {
        return this.backendService.getFacultativeBasisOfAcceptance().pipe(
          map((response) => {
              if (response.error) {
                return facActions.backendRequestFailure({errorMessage: response.error.message})
              }
              return facActions.loadBasisOfAcceptanceSuccess({ basisOfAcceptance: response?.data ?? []})
            }
          ))
      }),
      catchError(error =>
        of(facActions.backendRequestFailure({ errorMessage: getErrorMessage(error) }))
      )
    )
  })

  deleteFacultativeData = createEffect(() => {
    return this.actions$.pipe(
      ofType(facActions.deleteFacultativeData),
      mergeMap(data => {
        return this.backendService.deleteFacData(data.id)
      }),
      map(res => {
        if (res.error) {
          this.notificationService.error('Error Deleting Deal.')
          return facActions.deleteFacultativeDataFailure({ error: res.error })
        } else {
          this.notificationService.info('Deal Successfully Deleted.')
          return facActions.deleteFacultativeDataSuccess({
            data: res.data,
          })
        }
      })
    )
  })

  replicateFacultativeData = createEffect(() => {
    return this.actions$.pipe(
      ofType(facActions.replicateFacultativeData),
      withLatestFrom(this.store.pipe(select(selectAuthState))),
      mergeMap(([facultativeData, auth]) => {
        return this.backendService.replicateFacData(facultativeData.id, {
          name: facultativeData.name,
          user: auth.username ? auth.username : '',
          nameOfInsured: facultativeData.nameOfInsured || '',
          reinsuredUW: facultativeData.reinsuredUW || '',
          reinsuredName: facultativeData.reinsuredName || '',
          reinsuredId: facultativeData.reinsuredId || '',
          renewal: facultativeData.renewal,
        })
      }),
      map(res => {
        if (res.error) {
          this.notificationService.error('Error Replicating Deal.')
          return facActions.replicateFacultativeDataFailure({
            error: res.error,
          })
        } else {
          this.notificationService.info('Deal Successfully Replicated.')
          return facActions.replicateFacultativeDataSuccess({
            data: res,
          })
        }
      })
    )
  })

  triggerDealSummariesRefresh$ = createEffect(() => this.actions$.pipe(
    ofType(facActions.replicateFacultativeDataSuccess),
    mergeMap(() => of(facActions.dealSummariesLoad()))
  ))



  markFacultativeDataAsNTU$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(facActions.markDealAsNTU),
      mergeMap(({ dealId, ntuComment }) => {
        return this.backendService.markFacDataAsNTU(dealId, ntuComment).pipe(
          mergeMap(() => of(facActions.markDealAsNTUSuccess({ dealId, ntuComment }))),
          catchError(error => of(facActions.markDealAsNTUFailure({ error: errorPayload(getErrorMessage(error)) })))
        )
      })
    )
  })

  updateDealBroker$ = createEffect(() => this.actions$.pipe(
    ofType(facActions.updateDealBroker),
    mergeMap(({dealId, brokerName}) => {
      return this.backendService.updateDealBroker(dealId, brokerName).pipe(
        mergeMap(() => of(facActions.updateDealBrokerSuccess({ dealId, brokerName }))),
        catchError(error => of(facActions.updateDealBrokerFailure({ error: errorPayload(getErrorMessage(error)) })))
      )
    })
  ))

  saveFacultativeFormVersion = createEffect(() => {
    return this.actions$.pipe(
      ofType(facActions.saveFacultativeFormVersion),
      withLatestFrom(
        this.store.select(selectedDeal),
        this.store.select(selectIdsToUpdate),
        this.store.pipe(select(selectUrl)),
      ),
      mergeMap(params => {
        const actionEvent = params[0]
        const currentDeal: Facultative | null = params[1]
        const idsToUpdate: number[] = params[2]
        const url: string = params[3]
        const facData = currentDeal?.facData
        const categoryType = getFormCategoryFromUrl(url) ?? 'submission'
        if (!facData) {
          return of(facActions.saveFacultativeFormVersionFailure({
            error: errorPayload('Current deal does not exist'),
          }))
        }
        const putHeader$ = this.backendService.putFacData(facData)
        const putVersion$ = this.backendService.putFacDataVersion(actionEvent.facDataVersion)
        const updateRequests = [putVersion$, putHeader$]
        for (const versionId of idsToUpdate) {
          const versionToUpdate = currentDeal?.facDataVersions?.find(v => v.id === versionId)
          if (versionToUpdate) {
            const putOtherVersion$ = this.backendService.putFacDataVersion(versionToUpdate)
            updateRequests.push(putOtherVersion$)
          }
        }
        return forkJoin(updateRequests).pipe(
          map(putResults => {
            const putVersionResults = putResults[0]
            const putHeaderResults = putResults[1]
            if (putVersionResults.error || putHeaderResults.error) {
              this.notificationService.error('Error Saving Form.')
              return facActions.saveFacultativeFormVersionFailure({
                error: putVersionResults.error ?? putHeaderResults.error,
              })
            } else {
              if (putVersionResults.category === categoryType) {
                this.notificationService.info('Form Successfully Saved.')
              }
              return facActions.saveFacultativeFormVersionSuccess({
                facData: putHeaderResults,
                facDataVersion: putVersionResults,
                skipRedirect: actionEvent.skipRedirect,
              })
            }
          })
        )
      }),
      catchError(error =>
        of(facActions.saveFacultativeFormVersionFailure({error: errorPayload(getErrorMessage(error))}))
      )
    )
  })

  createFacultativeData = createEffect(() => {
    return this.actions$.pipe(
      ofType(facActions.createFacultativeData),
      mergeMap(data => {
        return this.backendService.postFacData(data.dataEntry)
      }),
      map((data: any) => {
        if (data.error) {
          this.notificationService.error('Error Creating Deal.')
          return facActions.createFacultativeDataFailure({ error: data.error })
        } else {
          this.notificationService.info('Deal Successfully Created.')
          return facActions.createFacultativeDataSuccess({data})
        }
      })
    )
  })

  createFacultativeDataSuccess = createEffect(() => {
    return this.actions$.pipe(
      ofType(facActions.createFacultativeDataSuccess),
      withLatestFrom(this.store.select(selectCurrentVersion)),
      filter(params => !!params[1]),
      map(params => {
        const actionEvent = params[0]
        const facDataVersion: FacDataVersion = params[1] as FacDataVersion
        console.log(actionEvent)
        console.log(facDataVersion)
        return facActions.saveFacultativeFormVersion({ facDataVersion, skipRedirect: true })
      }),
    )
  })

  redirectNewFacultativeData = createEffect(() => {
    return this.actions$.pipe(
      ofType(facActions.saveFacultativeFormVersionSuccess),
      map(({facData, facDataVersion, skipRedirect}) => {
        if (skipRedirect) {
          return of(this.router.navigate([buildDealFormUrl(facData.type, facData.id.toString(), facDataVersion.category)]))
        }
      })
    )
  }, { dispatch: false })

  createFacultativeDataVersion = createEffect(() => {
    return this.actions$.pipe(
      ofType(facActions.createFacultativeFormVersion),
      mergeMap(data => {
        return this.backendService.postFacDataVersion(data.facDataVersion)
      }),
      withLatestFrom(
        this.store.pipe(select(selectUrl)),
      ),
      map(([data, url]) => {
        if (data.error) {
          this.notificationService.error('Error Creating Deal.')
          return facActions.createFacultativeFormVersionFailure({ error: data.error })
        } else {
          if (getFormCategoryFromUrl(url) !== 'responses') {
            this.notificationService.info('Version Successfully Created.')
          }
          return facActions.createFacultativeFormVersionSuccess({data})
        }
      })
    )
  })

  deleteFacultativeFormVersion = createEffect(() => {
    return this.actions$.pipe(
      ofType(facActions.deleteFacultativeFormVersion),
      concatMap(data => {
        return this.backendService.deleteFacDataVersion(data.versionId)
      }),
      withLatestFrom(
        this.store.pipe(select(selectCurrentVersion)),
      ),
      map(([res, currentVersion]) => {
        if (res.error) {
          this.notificationService.error('Error Deleting Form.')
          return facActions.deleteFacultativeFormVersionFailure({
            error: res.error,
          })
        } else {
          if (currentVersion?.id?.toString() === res.id) {
            this.notificationService.info('Form Successfully Deleted.')
          }
          return facActions.deleteFacultativeFormVersionSuccess({
            data: res,
          })
        }
      })
    )
  })

  getFacUsersConcurrent = createEffect(() => {
    return this.actions$.pipe(
      ofType(facActions.getFacUsersConcurrent),
      mergeMap(_ => {
        const actions = [
          facActions.loadBasisOfAcceptance(),
          facActions.loadSalesforceAccounts(),
          facActions.getSalesforceContacts(),
          facActions.loadTermsAndConditions(),
          facActions.loadAllFacUsers(),
          facActions.loadCurrencies(),
          facActions.loadReinsuranceConditions(),
        ]
        return actions
      })
    )
  })

  sendEmail = createEffect(() => {
    return this.actions$.pipe(
      ofType(facActions.sendEmail),
      mergeMap(data => {
        return this.backendService.postFacEmail(data.dataEntry)
      }),
      map(res => {
        if (res.error) {
          this.notificationService.error('Error Sending Email.')
          return facActions.sendEmailFailure({ error: res.error })
        } else {
          this.notificationService.info('Email Successfully Sent.')
          return facActions.sendEmailSuccess({ data: res })
        }
      })
    )
  })
}
