import { Injectable } from '@angular/core'
import {
  FacDealType,
  FacFormCategory
} from '../../models'
import { ReportGeneratorSubmission } from './report-generator-submission'
import { IReportGenerator } from './report-generator.interface'
import { FileDownloadService } from './file-download.service'
import { FilenameBuilderFactoryService } from './filename-builder.service'
import { ReportGeneratorCasualtyQtc } from './report-generator-casualty-qtc'
import { PdfGeneratorFactoryService } from '../pdf'
import { ReportModelBuilderFactoryService } from './report-model-builder.service'
import { Html2PdfService } from '@core/services'

@Injectable({ providedIn: 'root' })
export class ReportGeneratorFactoryService {

  constructor(
    private fileDownloadService: FileDownloadService,
    private filenameBuilderFactory: FilenameBuilderFactoryService,
    private reportModelBuilderFactory: ReportModelBuilderFactoryService,
    private pdfFactory: PdfGeneratorFactoryService,
    private html2PdfService: Html2PdfService
  ) {
  }

  create(dealType: FacDealType, category: FacFormCategory): IReportGenerator {
    const dealTypeAndCategory = `${dealType}-${category}`
    switch (dealTypeAndCategory) {
      case 'property-submission':
      case 'casualty-submission':
        return new ReportGeneratorSubmission(this.fileDownloadService, this.filenameBuilderFactory, this.html2PdfService)
      case 'casualty-qtc':
        return new ReportGeneratorCasualtyQtc(this.pdfFactory.createGenerator(), this.filenameBuilderFactory, this.reportModelBuilderFactory)
      default:
        throw new Error(`${category} is not a valid report generator category.`)
    }
  }
}
