import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService
} from '@azure/msal-angular'
import { MsalBootstrapService } from '@auth/services'


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MsalModule,
  ],
  providers: [
    { provide: MSAL_INSTANCE,  useFactory: MsalBootstrapService.msalInstanceFactory, deps: [MsalBootstrapService] },
    { provide: MSAL_GUARD_CONFIG, useFactory: MsalBootstrapService.msalGuardConfigFactory, deps: [MsalBootstrapService] },
    { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MsalBootstrapService.msalInterceptorConfigFactory, deps: [MsalBootstrapService] },
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [MsalRedirectComponent]
})
export class AuthModule { }
