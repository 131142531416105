import { FacExportFieldExtraProps } from '../../../models'
import { FacPdfItem } from '../../pdf'

export type FacExportField = FacPdfItem & FacExportFieldExtraProps

export const DEFAULT_FAC_EXPORT_TABLE_FIELD_PROPS: Partial<FacExportField> = {
  gutter: 1,
  fontSize: 9,
  fontStyle: 'bold',
  lineHeight: 3.5,
  marginBottom: 3,
  asTable: true,
}
