import { convertFromSINumber } from './convertFromSINumber'
import { stripNonNumeric } from './stripNonNumeric'

export function facFormatPercent(input: number, digits?: number): string {
  return input.toFixed(digits ?? 6).toString() + '%'
}

export function facFormatPercentVariable(input: number | string, digitsMax: number, digitsIfInt: number): string {
  const percentageValue: number = parseFloat(stripNonNumeric(input))
  const roundedValue: number = Number.parseFloat(percentageValue.toFixed(digitsMax))
  return roundedValue.toFixed(Number.isInteger(roundedValue) ? digitsIfInt : digitsMax) + '%'
}

export function formatSmartPercent(input: string): string {
  return facFormatPercent(parseFloat(convertFromSINumber(input)))
}
