import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store'
import { USER_PERMISSIONS_FEATURE_KEY, UserPermissionsState } from './permissions.state'

export const selectUserPermissionsState: MemoizedSelector<object, UserPermissionsState> = createFeatureSelector<UserPermissionsState>(USER_PERMISSIONS_FEATURE_KEY)

export const selectUserPermissions = createSelector(
  selectUserPermissionsState,
  state => state.permissions
)

export const selectSageLogicalRole = createSelector(
  selectUserPermissionsState,
  state => state.sageLogicalRole
)

export const selectUserPermissionsError = createSelector(
  selectUserPermissionsState,
  state => state.errorMessage
)

