import { CoverageReinsured, FacDealType } from '../models'
import { getQuotaShareLanguage } from './getQuotaShareLanguage'

export function formatFacPolicyLimit(
  formType: FacDealType,
  coverage?: string,
  value = '',
  generalAgg = '',
  otherAgg = '',
  coverageExpression = '',
  isQuotaShare ='',
  partOfCoverageLimit ='',
  partOfGeneralAgg ='',
  partOfProductsOperationsAgg = ''
): string {
  if (!value) {
    return ''
  }
  
  let quotaShareLanguage = ''

  if (isQuotaShare) {
    quotaShareLanguage = getQuotaShareLanguage(coverageExpression, partOfCoverageLimit, partOfGeneralAgg, partOfProductsOperationsAgg)
  } 

  switch (formType) {
    case 'casualty': {
      if (value.length > 20) {
        return value
      }
      switch (coverage) {
        case CoverageReinsured.general: {
          return (
            `${value} Each ${coverageExpression}/` +
            `${generalAgg} General Aggregate/` +
            `${otherAgg} Products and Completed Operations Aggregate`
          )
        }
        case CoverageReinsured.auto: {
          return (
            `${value} Each ${coverageExpression} ` + `Combined Single Limit`
          )
        }
        case CoverageReinsured.cyber:
        case CoverageReinsured.directorsOfficers:
        case CoverageReinsured.errorsOmissions:
        case CoverageReinsured.miscellaneous: {         
          return (
            `${value} Each ${coverageExpression}/` +
            `${generalAgg} General Aggregate` + `${quotaShareLanguage}` +
            ` Where Applicable Excess of Scheduled Underlying Limits and/or Self Insured Retention`
          )
        }
        case CoverageReinsured.umbrella:
        case CoverageReinsured.umbrellaGeneral:
        case CoverageReinsured.umbrellaAuto:
         {
          return (
            `${value} Each ${coverageExpression}/` +
            `${generalAgg} General Aggregate` +
            (otherAgg === '' ? '' : (coverage === CoverageReinsured.umbrellaAuto ? ' Where Applicable' : '') + `/${otherAgg} Products and Completed Operations Aggregate`) +
            `${quotaShareLanguage}` + ` Where Applicable Excess of Scheduled Underlying Limits and/or Self Insured Retention`
          )
        }
        default: {
          return value
        }
      }
    }
    case 'property':
    default: {
      return value
    }
  }
}
