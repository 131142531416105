import { facFormatCurrency, formatParticipationPercentage, stripNonNumeric } from '../../formatters'
import { FacDealType, ReinsuranceData } from '../../models'

export function calculateReinsurerParticipationTotal(
  reinsuranceCertificateData: ReinsuranceData[], 
  fieldId: keyof ReinsuranceData,
  dealType: FacDealType
): string {
  const allowedFieldIds: (keyof ReinsuranceData)[] = ['grossPremium', 'netPremium', 'participationAmount', 'participationPercentage', 'quotedPrice']
  if (!allowedFieldIds.includes(fieldId)) {
    return ''
  }
  let total = 0
  reinsuranceCertificateData?.forEach(dataElement => {
    const value: string | number = dataElement[fieldId] as string | number
    total += Number(stripNonNumeric(value))
  })
  if (fieldId === 'participationPercentage') {
    return formatParticipationPercentage(total, dealType)
  }
  return facFormatCurrency(total)
}
