
import {
  CoverageReinsured,
  FacDealType,
  FacExportData,
  FacFormID,
  FieldConfig,
  FieldTypes,
  ReinsuredLayer
} from '../../../models'
import {
  buildReinsurancePremiumLabel,
  formatLimits,
  formatLocktonContactLines,
  formatRetention
} from '../../../formatters'
import { intervalToDuration } from 'date-fns'
import { isLongOrShortTerm, isTravelers } from '../../../utils'
import { FacPdfHeader } from '../../pdf'
import { FacExportField } from '../models'
import { parseDate } from '@utils'

export const createBasicHeader = (
  title: string,
): FacPdfHeader => ({
  title,
  height: 32,
})

export const createLocktonAddressFooter = (data: FacExportData): FacExportField[] => [
  {
    type: 'heading',
    value: formatLocktonContactLines(data.isUK, data.dealBroker),
    allCaps: false,
    charSpace: 0,
    fontSize: 10,
  }
]

export function buildExportField(
  id: string,
  label: string,
  value: string | string[],
  hidden: boolean,
): FacExportField {
  return { id, type: 'field', label, value,  hidden }
}

export function buildTypeOfInsuranceRow(
  fieldArray: FieldConfig[],
  hiddenFields: string[],
): FacExportField[] {

  const fieldId: string = FieldTypes.typeOfInsurance
  const typeOfInsuranceField = fieldArray.find((field) => field.id === FieldTypes.typeOfInsurance)
  const includingField = fieldArray.find((field) => field.id === FieldTypes.including)
  const excludingField = fieldArray.find((field) => field.id === FieldTypes.excluding)
  let valueString: string = typeOfInsuranceField?.value ?? ''
  if (valueString === 'Only') {
    const onlyField = fieldArray.find((field) => field.id === FieldTypes.only)
    const onlyText: string = onlyField?.value.toString() ?? ''
    valueString = `${onlyText} Only`
  } else {
    const includingText: string = includingField?.value.toString().replace(/,/g, ', ')
    if (includingText) {
      valueString += ` including ${includingText}`
    }
    const excludingText: string = excludingField?.value.toString().replace(/,/g, ', ')
    if (excludingText) {
      valueString += ` excluding ${excludingText}`
    }
  }

  const label: string = typeOfInsuranceField?.label ?? ''
  const hidden = hiddenFields.includes(fieldId)
  const isEmpty = valueString.trim().length === 0

  return [buildExportField(fieldId, label, valueString.trim(), hidden || isEmpty)]
}

export function buildProgramStructureRow(fieldArray: FieldConfig[], hiddenFields: string[], getValue: (id?: string) => string): FacExportField[] {
  const fieldId = FieldTypes.programStructure
  const programStructureField = fieldArray.find((field) => field.id === fieldId)
  const programStructureValue: string = programStructureField?.value ?? ''

  const retentionAmountField = fieldArray.find((field) => field.id === FieldTypes.retentionAmount)
  const retentionAmountValue: string = retentionAmountField?.value ?? ''

  const umbrellaList = ['Umbrella / Excess Liability', 'Umbrella / Excess Liability - General Liability Carve Out', 'Umbrella / Excess Liability - Auto Liability Carve Out']
  const hidden = hiddenFields.includes(fieldId) || umbrellaList.includes(getValue('coverageReinsured'))
  const label: string = programStructureField?.label ?? ''

  if (programStructureValue === 'Guaranteed Cost') {
    return [buildExportField(fieldId, label, programStructureValue, hidden)]
  }
  const concatValue = `${programStructureValue}: ${retentionAmountValue}`
  return [buildExportField(fieldId, label, concatValue, hidden)]
}

export function buildPersonalLiabilityRows(fieldArray: FieldConfig[], hiddenFields: string[], getValue: (id?: string) => string): FacExportField[] {
  const coverageReinsured = getValue('coverageReinsured');
  const isPersonalLiability = (coverageReinsured === CoverageReinsured.cyber
    || coverageReinsured === CoverageReinsured.directorsOfficers
    || coverageReinsured === CoverageReinsured.errorsOmissions
    || coverageReinsured === CoverageReinsured.miscellaneous)

  if (isPersonalLiability) {
    return buildExportRowDefault(fieldArray, hiddenFields, getValue)
  }

}

export function buildSecondaryCedentRow(fieldArray: FieldConfig[], hiddenFields: string[], getValue: (id?: string) => string): FacExportField[] {
  const fieldId = FieldTypes.secondaryCedent
  const secondaryCedentField = fieldArray.find((field) => field.id === fieldId)
  const secondaryCedentValue: string = secondaryCedentField?.value ?? ''

  const hidden = secondaryCedentValue === '' || hiddenFields.includes(fieldId) || !(isTravelers(getValue('reinsured')) && getValue('type') === 'property')
  const label: string = 'Secondary Cedent'

  return [buildExportField(fieldId, label, secondaryCedentValue, hidden)]
}

export function buildExportRowDefault(
  fieldArray: FieldConfig[],
  hiddenFields: string[],
  getValue: (id?: string) => string
): FacExportField[] {
  const excludeFieldTypes = ['button']
  const excludeFieldIds = ['generalAgg', 'otherAgg', 'reinsuredUnderwriter', 'premiumType']
  const row: FacExportField[] = []
  fieldArray.forEach((c: FieldConfig) => {
    const valueString: string = getValue(c.id)
    const hidden = hiddenFields.includes(c.id) || excludeFieldIds.includes(c.id) || excludeFieldTypes.includes(c.type)
    const isEmpty = valueString.trim().length === 0
    row.push(buildExportField(c.id, c.label ?? '', valueString, hidden || isEmpty))
  })
  return row
}

export function buildExportRow(
  fieldArray: FieldConfig[],
  hiddenFields: string[],
  getValue: (id?: string) => string
): FacExportField[] {

  const firstFieldId: string = fieldArray.length > 0 ? fieldArray[0].id : ''

  switch (firstFieldId) {
    case FieldTypes.typeOfInsurance:
      return buildTypeOfInsuranceRow(fieldArray, hiddenFields)
    case FieldTypes.programStructure:
      return buildProgramStructureRow(fieldArray, hiddenFields, getValue)
    case FieldTypes.policyType:
    case FieldTypes.notableTerms:
      return buildPersonalLiabilityRows(fieldArray, hiddenFields, getValue)
    case FieldTypes.secondaryCedent:
      return buildSecondaryCedentRow(fieldArray, hiddenFields, getValue)
    default:
      return buildExportRowDefault(fieldArray, hiddenFields, getValue)
  }
}

export function buildExportFields(
  fieldDefinitions: ReadonlyArray<ReadonlyArray<Readonly<FieldConfig>>>,
  hiddenFields: string[],
  getValue: (id?: string) => string
) {

  const exportFields: FacExportField[][] = []
  fieldDefinitions.forEach((fieldArray: FieldConfig[]) => {
    const row: FacExportField[] = buildExportRow(fieldArray, hiddenFields, getValue)
    exportFields.push(row)
  })
  return exportFields
}

export interface ReinsuredLayersOptions {
  insertIndex?: number
  reinsuredLimitsLabel?: string
  overrideReinsuredLayers?: ReinsuredLayer[]
  premiumsFieldKey?: string
  sortPremiums?: boolean
}

export function buildReinsuredLayersFields(
  data: FacExportData,
  {
    insertIndex = 12,
    reinsuredLimitsLabel = 'Reinsurance Layers',
    overrideReinsuredLayers,
    premiumsFieldKey,
    sortPremiums,
  }: ReinsuredLayersOptions = {},
  formID: FacFormID
): (string | FacExportField | FacExportField[])[] {
  const { dealType, fieldMap, reinsuredLayers = [] } = data
  const fields: (string | FacExportField | FacExportField[])[] = []

  if (dealType === 'casualty' && formID === 'submission') {
    const retention = data.fieldMap.get('retention')?.replace(/<b>/g, '^').replace(/<[^>]*>?/gm, '') ?? ''
    const retentionValue = retention.split('^')
    fields.push({
      type: 'field',
      label: 'Company Retention Net and/or Treaty',
      value: retentionValue?.filter(Boolean),
      index: insertIndex + 3,
      ordinalSuffix: '(a)',
    })
  } else if (dealType === 'casualty') {
    const formattedRetentionLayers: string = formatRetention(
      fieldMap,
      dealType as FacDealType
    )
    const layers = formattedRetentionLayers.replace(/<b>/g, '^').replace(/<[^>]*>?/gm, '') ?? ''
    const layersArray = layers.split('^')
    fields.push({
      type: 'field',
      label: 'Company Retention Net and/or Treaty',
      value: layersArray?.filter(Boolean),
      index: insertIndex - 2,
      ordinalSuffix: '(a)',
    })
  }

  if (formID === 'submission') {
    const limits =
      data.fieldMap
        .get('limits')
        ?.replace(/<b>/g, '^')
        .replace(/<[^>]*>?/gm, '') ?? ''
    const limitsValue = limits.split('^')
    fields.push({
      type: 'field',
      label: reinsuredLimitsLabel,
      value: limitsValue?.filter(Boolean),
      index: dealType === 'casualty' ? insertIndex + 4 : insertIndex++,
      ordinalSuffix: dealType === 'casualty' ? '(b)' : '',
    })
  } else {
    const limitLayers = overrideReinsuredLayers ?? reinsuredLayers
    const formattedLimitLayers: string = formatLimits(
      fieldMap,
      dealType as FacDealType,
      undefined,
      limitLayers
    )
    const limits = formattedLimitLayers.replace(/<b>/g, '^').replace(/<[^>]*>?/gm, '') ?? ''
    const limitsValue = limits.split('^')
    fields.push({
      type: 'field',
      label: dealType === 'casualty' ? 'Reinsurance Limit(s) Hereon' : reinsuredLimitsLabel,
      value: limitsValue?.filter(Boolean),
      index: dealType === 'casualty' ? insertIndex - 1 : insertIndex++,
      ordinalSuffix: dealType === 'casualty' ? '(b)' : '',
    })
  }

  let premiums: string | string[] = ''
  if (premiumsFieldKey && fieldMap.has(premiumsFieldKey)) {
    // premiums = fieldMap.get(premiumsFieldKey)?.split(/[|]/) || []
    premiums = fieldMap.get(premiumsFieldKey)?.split(/\n/) || []
    premiums = premiums.map(element => {
      if (formID === 'binder' || formID === 'certificate') {
        return element.slice(0, 7) + ':' + element.slice(7)
      } else {
        return element
      }
    })
  } else {
    premiums = reinsuredLayers.map((layer: ReinsuredLayer) => {
      return `Layer ${String.fromCharCode(
        layer.index + 96
      ).toLocaleUpperCase()}: ${layer.reinsurancePremium || 'TBD'}`
    })
  }
  if (dealType === 'casualty') {
    premiums = premiums.map(element => {
      return element.slice(0, 5) + ' ' + element.slice(6, 7) + element.slice(7)
    })
  }
  if (sortPremiums) {
    premiums = premiums.sort()
  }
  if (
    (fieldMap.get('coverageReinsured') === 'Auto Liability' ||
      fieldMap.get('coverageReinsured') === 'General Liability' ||
      fieldMap.get('coverageReinsured') === 'Umbrella / Excess Liability' ||
      fieldMap.get('coverageReinsured') === 'Umbrella / Excess Liability - General Liability Carve Out' ||
      fieldMap.get('coverageReinsured') === 'Umbrella / Excess Liability - Auto Liability Carve Out') &&
    !fieldMap.get('customQTCReinsurancePremiums') &&
    !fieldMap.get('customReinsurancePremiums')
  ) {
    const cessions = reinsuredLayers.map((layer: any) => {
      return layer.cession
    })
    cessions.forEach((cession, i) => {
      if (cession === 100 && Array.isArray(premiums)) {
        if (premiums.length < cessions.length) {
          premiums.splice(i - (cessions.length - premiums.length), 1)
        } else {
          premiums.splice(i, 1)
        }
      }
    })
  }

  const format = 'MM/dd/yyyy'
  const reinsurancePeriodFrom = parseDate(fieldMap.get('reinsurancePeriodFrom') ?? '', format)
  const reinsurancePeriodTo = parseDate(fieldMap.get('reinsurancePeriodTo') ?? '', format)
  const policyPeriodFrom = parseDate(fieldMap.get('policyPeriodFrom') ?? '', format)
  const policyPeriodTo = parseDate(fieldMap.get('policyPeriodTo') ?? '', format)
  const longOrShortTerm = isLongOrShortTerm(
    reinsurancePeriodFrom,
    reinsurancePeriodTo,
    policyPeriodFrom,
    policyPeriodTo,
  )
  const premiumFieldLabel = buildReinsurancePremiumLabel(dealType, longOrShortTerm)
  fields.push({
    type: 'field',
    label: premiumFieldLabel,
    value: premiums.sort(),
    index:
      dealType === 'casualty' && formID === 'submission'
        ? insertIndex + 5
        : dealType === 'casualty'
          ? insertIndex
          : insertIndex++,
  })

  return fields
}

export function getPremiumField(dealType: FacDealType, fields: (FacExportField | FacExportField[])[]) {
  let longOrShortTerm = false
  const premiumExportField = fields[
    fields
      .map(e => (Array.isArray(e) ? e[0]?.label : e.label))
      .indexOf('Reinsurance Premium')
    ] as FacExportField[]

  if (premiumExportField && premiumExportField.length > 0) {
    const dateFields = fields.find(
      (field: FacExportField[]) => field[0]?.label === 'Reinsurance Inception'
    ) as FacExportField[]
    const start = Date.parse((dateFields[1]?.value as string) ?? '')
    const end = Date.parse((dateFields[0]?.value as string) ?? '')
    const durationDiff = intervalToDuration({ start, end })
    if (durationDiff.months) {
      longOrShortTerm = durationDiff.months < 12 || durationDiff.months > 12
    } else if (durationDiff.days) {
      longOrShortTerm = durationDiff.days > 2
    }
    premiumExportField[0].label = buildReinsurancePremiumLabel(dealType, longOrShortTerm)
  }
}
