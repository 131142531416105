import { Facultative, ReinsuredLayer } from '../../models'
import { FacFieldService } from '../../services'
import { getFinalForm } from '../getFinalForm'

export function getReinsuredLayers(category: string, facDataEntry: Facultative | null, fieldService: FacFieldService): ReinsuredLayer[] {
  const fieldLayers = fieldService.fetchFieldArray('reinsuredLayers')
  if (fieldLayers && fieldLayers.length > 0) {
    return fieldLayers
  } else {
    if (category === 'binder' || category === 'responses') {
      const finalForm = getFinalForm('submission', facDataEntry)
      if (finalForm) {
        return finalForm.reinsuredLayers
          ? JSON.parse(finalForm.reinsuredLayers)
          : []
      }
    } else if (category === 'certificate') {
      const finalForm = getFinalForm('binder', facDataEntry)
      if (finalForm) {
        return finalForm.reinsuredLayers
          ? JSON.parse(finalForm.reinsuredLayers)
          : []
      }
    }
  }
  return []
}
