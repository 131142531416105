import { FieldInterface } from './field.model'
import { FormLayerResponse } from './fac-form.model'

export type FacDealType = 'property' | 'casualty'
export type FacFormCategory = 'submission' | 'responses' | 'qtc' | 'binder' | 'certificate'

export const FAC_FORM_TYPES = ['property', 'casualty'] as const
export type FacFormType = typeof FAC_FORM_TYPES[number]
export const FAC_FORM_IDS = [
  'submission',
  'qtc',
  'binder',
  'certificate',
] as const
export type FacFormID = typeof FAC_FORM_IDS[number]

export interface ReinsuredAddress {
  streetAddress: string
  city: string
  stateCode: string
  postalCode: string
  country: string
}

export interface Facultative {
  facData: FacData
  facDataVersions: FacDataVersion[]
  reinsuredAddress?: ReinsuredAddress
}

export interface FacPagination {
  currentPage: number
  totalPages: number
}

export interface FacultativeResponse {
  combinedFacData: Facultative[]
  pagination: FacPagination
}

export interface UploadResult {
  versionName: string | undefined
  finalVersion: boolean | undefined
  renewal?: boolean | undefined
  newVersion?: string | boolean
  name?: string
}

export interface SalesforceAccount {
  id: string
  name: string
}

export interface SalesforceContact {
  id: string
  firstName?: string
  lastName?: string
  name: string
}

export interface FacData {
  cityName?: string | undefined
  coverageReinsured?: string
  createdBy?: string
  currency?: string
  id: number
  modifiedDate: string
  name: string
  nameAndAddress?: string  | null
  ntu?: string | undefined
  otherFacultative?: string | undefined
  perilsExcluding?: string | undefined
  perilsIncluding?: string | undefined
  perilsOnly?: string | undefined
  policyNumber?: string | undefined
  policyPeriodFrom?: Date | string
  policyPeriodTo?: Date | string
  quoteNeededBy?: Date | string
  reinsuranceCertificateNo?: string | undefined
  reinsurancePeriodFrom?: Date | string
  reinsurancePeriodTo?: Date | string
  reinsured?: string
  reinsuredId?: string
  reinsuredUnderwriter?: string
  reinsuredUnderwriterId?: string
  renewal?: boolean
  salesforceOppId?: string | undefined
  stateName?: string | undefined
  streetAddress?: string | undefined
  type: string
  typeOfInsurance?: string | undefined
  username: string
  zipCode?: string | undefined
}

export interface FacDataVersion {
  action?: string
  auditUpdatedBy?: string
  category?: string
  facDataId?: number
  final?: boolean
  fieldValues?: string
  formName?: string
  hiddenFields?: string
  id?: number
  modifiedDate?: string
  reinsuredLayers?: string
}

export interface FacSubmission {
  additionalInfo?: string
  additionalReinsuranceConditions?: string
  allocatedLossAdjustmentExpense?: string
  basisOfAcceptance?: string
  brokerageCommission?: string
  brokerageCommissionSub?: string
  cancellationClause?: string
  cedingCommission?: string
  certificateFields?: string
  cityName?: string
  claimsNotification?: string
  companyRetentionOrTreaty?: string
  coverageBasis?: string
  coverageExpression?: string
  coverageReinsured?: string
  currency?: string
  descriptionOfOperations?: string
  excluding?: string
  generalAgg?: string
  including?: string
  insuredValues?: string
  limits?: string
  lossHistory?: string
  minimumAndDeposit?: string
  name?: string
  occLimit?: string
  only?: string
  otherAgg?: string
  otherFacultative?: string
  policyType?: string
  notableTerms?: string
  policyLimit?: string
  policyNumber?: string
  policyPeriodFrom?: string
  policyPeriodTo?: string
  policyPremium?: string
  premiumComments?: string
  programStructure?: string
  quoteNeededBy?: string
  rateAdjustability?: string
  rate?: string
  reinsuranceCertificate?: string
  reinsuranceConditions?: string
  reinsurancePeriodFrom?: string
  reinsurancePeriodTo?: string
  reinsured?: string
  'reinsured--ID'?: string
  reinsuredLayers?: string
  reinsuredUnderwriter?: string
  'reinsuredUnderwriter--ID'?: string
  retention?: string
  retentionAmount?: string
  isQuotaShare?: any
  partOfCoverageLimit?: string
  partOfGeneralAggregate?: string
  partOfProductOperationAggregate?: string
  secondaryCedent?: string
  'secondaryCedent--ID'?: string
  stateName?: string
  storedFiles?: any
  streetAddress?: string
  termsAndConditions?: string
  typeOfInsurance?: string
  underlyingInsurance?: string
  websiteURL?: string
  zipCode?: string
  underwritingInformation?: string
  brokerComments?: string
  premiumType?: string
}

export interface ColumnFormat {
  actual: string[]
  display: string[]
}

export interface ReinsuredLayer {
  basisOfAcceptance: string
  reinsuranceLayer: string
  reinsuranceLimit: string
  reinsurancePremium: string
  currency: string
  id: string
  index: number
  cession?: string
  cessionNumber?: string
  generalAgg?: string
  generalAggAttachment?: string
  poca?: string
  pocaAttachment?: string
  layerIndex?: string | number
  isQuotaShare?: boolean
  partOfCoverageLimit?: string
  partOfGeneralAggregate?: string,
  partOfProductOperationAggregate?: string
}

export interface ReinsuredQuoteLayer {
  id: string
  cession: number
  attachment: number
  limit: number
  neededQuote: number
  reinsurer: string
  commission: number
  brokerage: number
  termsandconditions: string[]
  quotedPrice: number
  label: string
  termsconditions: string
  generalconditions: string
  qtcCheck?: boolean
  bindCheck?: boolean
  declineCheck?: boolean
  requoteCheck?: boolean
  isMGA?: boolean
  mgaArray?: MGADataArray[]
}

export interface MarketTableData {
  reinsurer: string
  quoted100: number
  differential: number
  shareOffered: number
  id: string
  layerQuote: ReinsuredQuoteLayer
  quotedPrice: number
  limit: number
  brokerage: number
  commission: number
  cession: number
}

export interface AdditionalResponses {
  reinsurer: string
  occLimit: string
  occAtt: string
  grossPremium: string
  netPremium: string
  termsAndConditions: string[]
  comments: string
  id: string
}

export interface MarketDialogData {
  reinsuredQuotes: ReinsuredQuoteLayer[]
  reinsuranceLayers: ReinsuredLayer[]
  name: string
  currentDeal: number
  currentVersion: FacDataVersion
  selectedCurrency: string
  binderData: FacDataVersion[]
  certificateData: FacDataVersion[]
  qtcData: FacDataVersion[]
  formType: string
}

export interface MGADataArray {
  name: string
  participation: number | string
  participationLimit?: string
  referenceNumber?: string
}

export interface CertificateFields {
  name: string
  value: object | string
}

export interface ReinsuranceData {
  grossPremium: string | number
  netPremium: string | number
  participationAmount: string | number
  participationPercentage: string | number
  referenceNo: string
  reinsurers: string
  layerIndex: string | number
  companyPaperName: string
  isMGA: boolean
  index?: number
  mgaArray: MGADataArray[]
  quotedPrice: string | number
  cedingCommission: number
  brokerageCommission: string | number
}

export interface BrokerData {
  id: number
  name: string
  email: string
  address: string
  telephone: string
}

export interface FacExportData {
  dealType: FacDealType
  formCategory: FacFormCategory
  fieldMap: Map<string, string>
  fieldConfigs: FieldInterface[]
  certificateFields?: CertificateFields[]
  reinsuredLayers?: ReinsuredLayer[]
  reinsuranceData?: ReinsuranceData[]
  reinsuranceDataMap?: Map<string, ReinsuranceData[]>
  currencySymbol: string
  brokerName?: string
  brokerEmail?: string
  isUK: boolean
  facData?: FacData
  reinsuredAddress?: ReinsuredAddress | null
  underwritingVisibility?: FacUnderwritingVisibility
  hiddenFields?: string[],
  isEmail?: boolean
  dealBroker?: FacUser | null
  formName?: string
}

export interface FacExportFieldExtraProps {
  index?: number
  ordinalSuffix?: string
  label?: string
}

export interface FacExportQtcData extends FacExportData {
  layersWithQuotes?: LayerWithQuotes[]
  marketResponses: FormLayerResponse[]
}


export interface BlendedPriceLayer {
  layerId: string
  blendedPrice: string
}

export interface FacGeneralLiabilityVisibility {
  generalLiabilitySalesBreakout: VisibilityItem
  generalLiabilityHistoricalExposure: VisibilityItem
  generalLiabilityLossHistory: VisibilityItem
  generalLiabilityExcessOf: VisibilityItem
  noLargeLosses: VisibilityItem
}

export interface FacAutoLiabilityVisibility {
  autoLiabilityExcessOf: VisibilityItem
  autoLiabilityFleetBreakout: VisibilityItem
  autoLiabilityMileage: VisibilityItem
  autoLiabilityRadius: VisibilityItem
  autoLiabilityHiredNonOwnedExposures: VisibilityItem
  autoLiabilityGarageLocations: VisibilityItem
  autoLiabilityHistoricalExposure: VisibilityItem
  autoLiabilityLossHistory: VisibilityItem
}

export interface FacExcessLiabilityVisibility {
  excessLiabilityScheduleOfUnderlyingLimits: VisibilityItem
  excessLiabilityYearEstimates: VisibilityItem
  excessLiabilityExposures: VisibilityItem
  excessAutoLiabilityFleetBreakout: VisibilityItem
  excessLiabilityHistoricalInformation: VisibilityItem
  excessLiabilityLossHistory: VisibilityItem
  excessLiabilityExcessOf: VisibilityItem
  generalLiabilityLossHistory: VisibilityItem
  autoLiabilityMileage: VisibilityItem
  autoLiabilityRadius: VisibilityItem
  autoLiabilityHiredNonOwnedExposures: VisibilityItem
  autoLiabilityGarageLocations: VisibilityItem
  autoLiabilityLossHistory: VisibilityItem
}

export interface FacExcessAutoLiabilityVisibility {
  excessLiabilityScheduleOfUnderlyingLimits: VisibilityItem
  excessLiabilityExposures: VisibilityItem
  excessAutoLiabilityFleetBreakout: VisibilityItem
  excessLiabilityHistoricalInformation: VisibilityItem
  excessLiabilityExcessOf: VisibilityItem
  autoLiabilityMileage: VisibilityItem
  autoLiabilityRadius: VisibilityItem
  autoLiabilityHiredNonOwnedExposures: VisibilityItem
  autoLiabilityGarageLocations: VisibilityItem
  autoLiabilityLossHistory: VisibilityItem
}

export interface FacPersonalLiabilityVisibility {
  generalLiabilityLossHistory: VisibilityItem
  excessLiabilityScheduleOfUnderlyingLimits: VisibilityItem
  excessLiabilityExcessOf: VisibilityItem
}

export interface FacExcessGeneralLiabilityVisibility {
  generalLiabilityLossHistory: VisibilityItem
  excessLiabilityScheduleOfUnderlyingLimits: VisibilityItem
  excessLiabilityExposures: VisibilityItem
  excessLiabilityYearEstimates: VisibilityItem
  excessLiabilityExcessOf: VisibilityItem
}

export interface FacWorkersCompVisibility {
  workersCompPayrollADC: VisibilityItem
  workersCompPayrollRDC: VisibilityItem
  totalPayroll: VisibilityItem
  workersCompUnmodifiedPremium: VisibilityItem
  workersCompHistoricalExposure: VisibilityItem
  workersCompLossHistoryADC: VisibilityItem
  workersCompLossHistoryRDC: VisibilityItem
  workersCompLossHistoryExcessOf: VisibilityItem
}

export interface FacExcessLiabilityCertVisibility {
  excessLiabilityScheduleOfUnderlyingLimits: VisibilityItem
}

export interface FacUnderwritingVisibility {
  autoLiabilityExcessOf: VisibilityItem
  autoLiabilityFleetBreakout: VisibilityItem
  autoLiabilityGarageLocations: VisibilityItem
  autoLiabilityHiredNonOwnedExposures: VisibilityItem
  autoLiabilityHistoricalExposure: VisibilityItem
  autoLiabilityLossHistory: VisibilityItem
  autoLiabilityMileage: VisibilityItem
  autoLiabilityRadius: VisibilityItem

  excessAutoLiabilityFleetBreakout: VisibilityItem
  excessLiabilityExcessOf: VisibilityItem
  excessLiabilityExposures: VisibilityItem
  excessLiabilityHistoricalInformation: VisibilityItem
  excessLiabilityLossHistory: VisibilityItem
  excessLiabilityScheduleOfUnderlyingLimits: VisibilityItem
  excessLiabilityYearEstimates: VisibilityItem

  generalLiabilityExcessOf: VisibilityItem
  generalLiabilityHistoricalExposure: VisibilityItem
  generalLiabilityLossHistory: VisibilityItem
  generalLiabilitySalesBreakout: VisibilityItem

  noLargeLosses: VisibilityItem
  totalPayroll: VisibilityItem

  workersCompHistoricalExposure: VisibilityItem
  workersCompLossHistoryADC: VisibilityItem
  workersCompLossHistoryExcessOf: VisibilityItem
  workersCompLossHistoryRDC: VisibilityItem
  workersCompPayrollADC: VisibilityItem
  workersCompPayrollRDC: VisibilityItem
  workersCompUnmodifiedPremium: VisibilityItem
}

export interface VisibilityItem {
  visibility: boolean
  name?: string
}

export interface DealsViewSettings {
  selectedType: string
  viewType: string
  renewal: boolean
}

export interface LayerWithQuotes {
  layer: any
  quotes: any[]
  totalShares: number | string
  blendedPremium: number | string
  totalFacSpend: number | string
}

export interface FacUser {
  id: number
  email: string
  name: string
  first_name?: string
  last_name?: string
  address?: string
  phone?: string
  street?: string
  city?: string
  statecode?: string
  postalcode?: string
  country?: string
  countrycode?: string
  isUk?: boolean
  isBroker?: boolean
  sage_logical_role?: string
}

export interface NewFormFields {
  name: string
  reinsured: string
  'reinsured--ID': string
  reinsuredUnderwriter: string
  'reinsuredUnderwriter--ID': string
}

export interface FormCategoryConfig {
  category: FacFormCategory
  title: string
  icon: string
}
