import { FacExportData } from '../../models'

export interface ReportResult {
  contentType: string
  contents: any
  files?: any[]
  reportName: string
}

export abstract class IReportGenerator {
  abstract generate(data: FacExportData): Promise<ReportResult>
}
