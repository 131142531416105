import { stripNumberFormat } from './stripNumberFormat'

export function formatFacPremiumRIString(
  quotedPrice: number | string,
  cession: number | string
): number {
  const q = !quotedPrice ? 0 : stripNumberFormat(quotedPrice)
  const c = !cession ? 0 : stripNumberFormat(cession)
  const n = q * (c / 100)
  return isNaN(n) ? 0 : n
}
