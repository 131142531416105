import {
  CoverageReinsured,
  FacDealType,
  ReinsuredLayer
} from '../models'
import { extractLimitLayer } from './extractLimitLayer'
import { FacFieldService } from '../services'

export function formatLimits(
  fieldMap: ReadonlyMap<string, string>,
  dealType: FacDealType,
  fieldService?: FacFieldService,
  layers?: ReinsuredLayer[]
): string {
  const layerStrings = formatLimitsAsArray(fieldMap, dealType, layers)
  const combinedLayerText = layerStrings.join('<br /><br />')
  if (fieldService) {
    fieldService.submissionFields.limits = combinedLayerText
    fieldService.setFieldValuesMapValue('limits', combinedLayerText)
  }
  return combinedLayerText
}

export function formatLimitsAsArray(
  fieldMap: ReadonlyMap<string, string>,
  dealType: FacDealType,
  layers: ReinsuredLayer[] | undefined
): string[] {
  const layerStrings: string[] = []
  if (!layers) {
    const layersString = fieldMap.get('reinsuredLayers')
    layers = JSON.parse(layersString ?? '[]') as ReinsuredLayer[]
  }
  if (layers?.length > 0) {
    const coverage = fieldMap.get('coverageReinsured')
    if (coverage && dealType === 'casualty') {
      const coverageExpression = fieldMap.get('coverageExpression') ?? 'Occurrence'
      const coverageText = coverage === CoverageReinsured.auto
        ? `${coverageExpression} Bodily Injury and Property Damage Combined Single Limit`
        : coverageExpression
      const cessionType = fieldMap.get('cessionType')
      const isQuotaShare = fieldMap.get('isQuotaShare')
      const partOfCoverageLimit = fieldMap.get('partOfCoverageLimit')
      const partOfGeneralAggregate = fieldMap.get('partOfGeneralAggregate')
      const partOfProductOperationAggregate = fieldMap.get('partOfProductOperationAggregate')
      layers.forEach((layer: ReinsuredLayer, index: number) => {
        layerStrings.push(
          extractLimitLayer(index, layer, coverage, cessionType, coverageText, isQuotaShare, partOfCoverageLimit, partOfGeneralAggregate, partOfProductOperationAggregate)
        )
      })
    } else {
      layers.forEach((layer: ReinsuredLayer, index: number) => {
        let str = '<b>Layer ' +
          String.fromCharCode(index + 97).toLocaleUpperCase() +
          ': </b>' +
          layer.reinsuranceLayer || '0'
        if (layer.basisOfAcceptance === 'Excess of Loss') {
          str += ' Excess of ' + (layer.reinsuranceLimit || '0')
        }
        str += ' Excess of Policy Deductibles'
        layerStrings.push(str)
      })
    }
  }
  return layerStrings.filter(Boolean)
}
