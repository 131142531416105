import { Facultative } from '../models'

export function getFinalForm(category: string, facDataEntry: Facultative | null) {
  if (facDataEntry) {
    const finalFacDataVersions = facDataEntry.facDataVersions.filter(
      facDataVersion => {
        return facDataVersion.category === category && facDataVersion.final
      }
    )
    if (finalFacDataVersions.length > 0) {
      return JSON.parse(finalFacDataVersions[0].fieldValues)
    } else {
      return null
    }
  }
  return null
}
