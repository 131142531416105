
export function formatFacTermsAndConditions(
  termsAndConditionsJson: string,
  otherTermsAndConditions: string[]
): string {
  if (termsAndConditionsJson) {
    const termsAndConditions = JSON.parse(termsAndConditionsJson) as string[]
    const combinedArray = [...otherTermsAndConditions, ...termsAndConditions]
    return combinedArray.join(',')
  }
  return ''
}
