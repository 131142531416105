import { convertFromSINumber } from './convertFromSINumber'
import { stripNonNumeric } from './stripNonNumeric'
import { formatValue } from '@deals/formatters/formatDecimals'

export function facFormatPercent(input: number, digits?: number): string {
  const formattedPercent = formatValue(input, digits)
  return (formattedPercent === 'NIL' ? '0' : formattedPercent) + '%'
}

export function facFormatPercentVariable(input: number | string, digitsMax?: number, _digitsIfInt?: number): string {
  const percentString = formatValue(stripNonNumeric(input), digitsMax)
  return (percentString && percentString.length > 0 ? percentString : 'NaN') + '%'
}

export function formatSmartPercent(input: string, digits?: number): string {
  return facFormatPercent(parseFloat(convertFromSINumber(input)), digits)
}
