import { ReinsuredLayer } from '../models/fac.model'
import { CoverageReinsured } from '../models/fac-form.model'
import { buildLayerLabel } from './buildLayerLabel'
import { formatPercentageOrNil } from './formatPercentageOrNil'
import { getlayerValue } from './getlayerValue'
import { NIL_TEXT, REGEX_TRAILING_SLASH_AND_WHITESPACE } from '@constants'
import { getQuotaShareLanguage } from './getQuotaShareLanguage'

export function extractRetentionLayer(
  index: number,
  layer: ReinsuredLayer,
  coverage: string,
  cessionType: string | undefined,
  coverageText: string | undefined,
  isQuotaShare?: any | undefined,
  partOfCoverageLimit?: any | undefined,
  partOfGeneralAggregate?: any | undefined,
  partOfProductOperationAggregate?: any | undefined
): string {
  let str = buildLayerLabel(index)
  let cession = 'N/A'

  if (layer.cession !== null) {
    const cessionNum = Number(layer.cession)
    if (!isNaN(cessionNum)) {
      cession =
        cessionNum >= 100 && index === 0
          ? '100.00%'
          : cessionNum === 0 && index === 0
            ? '0.00%'
            : `${100 - cessionNum}%`
    } else {
      cession = `${layer.cession}%`
    }
  }
  const percentText: string = formatPercentageOrNil(100 - parseFloat(cession))
  if (cession !== '100.00%' && cession !== '0.00%') {
    cession = percentText
  }

  if (cession === '0.00%') {
    cession = NIL_TEXT
  }
  const reinsuranceLayer = getlayerValue(layer.reinsuranceLayer)
  const reinsuranceLimit = getlayerValue(layer.reinsuranceLimit)
  const generalAgg = getlayerValue(layer.generalAgg)
  const generalAggAttachment = getlayerValue(layer.generalAggAttachment)
  const poca = getlayerValue(layer.poca)
  const pocaAttachment = getlayerValue(layer.pocaAttachment)

  let quotaShareLanguage = ''
  if (isQuotaShare)
    quotaShareLanguage = getQuotaShareLanguage(coverageText, partOfCoverageLimit, partOfGeneralAggregate, partOfProductOperationAggregate)

  if ([
    String(CoverageReinsured.general),
    String(CoverageReinsured.umbrella),
    String(CoverageReinsured.umbrellaGeneral),
    String(CoverageReinsured.umbrellaAuto),
    String(CoverageReinsured.excessAuto),
    String(CoverageReinsured.auto),
    String(CoverageReinsured.directorsOfficers),
    String(CoverageReinsured.errorsOmissions),
    String(CoverageReinsured.miscellaneous),
    String(CoverageReinsured.cyber)
  ].indexOf(coverage) > -1) {
    str +=
      cessionType === '$'
        ? `${layer.cessionNumber} of `
        : coverage === CoverageReinsured.auto
          ? `${cession} of `
          : index === 0
            ? `${cession} of `
            : `${percentText} of `
    str += `${reinsuranceLayer} Each ${coverageText}${CoverageReinsured.auto === coverage ? '' : ' / '}`
    if (coverage === CoverageReinsured.general) {
      str += `${generalAgg} General Aggregate / `
      str += `${poca} Products and Completed Operations Aggregate`
    }
    if (coverage === CoverageReinsured.umbrella || coverage === CoverageReinsured.umbrellaGeneral || coverage === CoverageReinsured.umbrellaAuto || coverage === CoverageReinsured.excessAuto) {
      str += `${generalAgg} General Aggregate`
      if (poca && poca !== NIL_TEXT) {
        str += ` / ${poca} Products and Completed Operations Aggregate`
      }
      if (isQuotaShare)
        str += quotaShareLanguage
      if (index === 0) {
        str += ` Where Applicable Excess of Scheduled Underlying Limits and/or Self Insured Retention`
      } else {
        str += ` Where Applicable Excess of ${reinsuranceLimit} Each ${coverageText}`
      }

      if (generalAggAttachment && generalAggAttachment !== NIL_TEXT) {
        str += ` / ${generalAggAttachment} General Aggregate Where Applicable Excess of Scheduled Underlying Limits and/or Self Insured Retention`
      }

      if (pocaAttachment && pocaAttachment !== NIL_TEXT) {
        str += ` / ${pocaAttachment} Products and Completed Operations Aggregate Where Applicable Excess of Scheduled Underlying Limits and/or Self Insured Retention`
      }
    }
    if (coverage === CoverageReinsured.cyber || coverage === CoverageReinsured.directorsOfficers ||
      coverage === CoverageReinsured.errorsOmissions || coverage === CoverageReinsured.miscellaneous) {
      str += `${generalAgg} General Aggregate`
      if (isQuotaShare)
        str += quotaShareLanguage
      if (index === 0) {
        str += ` Where Applicable Excess of Scheduled Underlying Limits and/or Self Insured Retention`
      } else {
        str += ` Where Applicable Excess of ${reinsuranceLimit} Each ${coverageText}`
      }

      if (generalAggAttachment && generalAggAttachment !== NIL_TEXT) {
        str += ` / ${generalAggAttachment} General Aggregate Where Applicable Excess of Scheduled Underlying Limits and/or Self Insured Retention`
      }
    }
    if ([CoverageReinsured.general, CoverageReinsured.auto].includes(coverage as CoverageReinsured)) {
      if (index !== 0) {
        str = str.trimEnd() + ` Excess of ${reinsuranceLimit} Each ${coverageText}`
        if (coverage === CoverageReinsured.general) {
          str += ` / ${generalAggAttachment} General Aggregate / `
          str += `${pocaAttachment} Products and Completed Operations Aggregate`
        }
      }
    }
  } else if (coverage === CoverageReinsured.workersComp) {
    if (index === 0) {
      str += `${cession} of ${layer.reinsuranceLayer || NIL_TEXT} Each ${coverageText} (Coverage A&B Where Applicable)`
    } else {
      str += `${percentText} of the ${layer.reinsuranceLayer || NIL_TEXT} Each ${coverageText} (Coverage A&B Where Applicable) Excess `
      str += `${layer.reinsuranceLimit || NIL_TEXT} Each ${coverageText} (Coverage A&B Where Applicable)`
    }
  }
  return str.replace(REGEX_TRAILING_SLASH_AND_WHITESPACE, '')
}
