import { NgModule } from '@angular/core'
import { MaterialModule } from './material.module'
import { EntryDialogService } from './services/entry-dialog.service'
import { EntryDialogComponent } from './entry-dialog.component'
import { SetNameDialogComponent } from './set-name-dialog.component'
import { ConfirmationDialogComponent } from './confirmation-dialog.component'
import { ConfirmationDialogService } from './services/confirmation-dialog.service'
import { TableDialogComponent } from './table-dialog/table-dialog.component'
import { TableDialogService } from './table-dialog/table-dialog.service'
import { CommonModule, DatePipe } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ScrollingModule } from '@angular/cdk/scrolling'
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor'
import { PipesModule } from './pipes/pipes.module'
import { FilterInputComponent } from "@shared/filter-input/filter-input.component";
import { TimeoutDialogComponent } from '@shared/timeout-dialog.component'

@NgModule({
  declarations: [
    ConfirmationDialogComponent,
    EntryDialogComponent,
    FilterInputComponent,
    SetNameDialogComponent,
    TableDialogComponent,
    TimeoutDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    PipesModule,
    ScrollingModule,
    ReactiveFormsModule,
    RichTextEditorModule,
  ],
  exports: [
    CommonModule,
    FilterInputComponent,
    FormsModule,
    MaterialModule,
    PipesModule,
    ReactiveFormsModule,
    RichTextEditorModule,
    ScrollingModule,
  ],
  providers: [
    ConfirmationDialogService,
    DatePipe,
    EntryDialogService,
    TableDialogService
  ],
  bootstrap: [],
  schemas: [],
})

export class SharedModule { }
