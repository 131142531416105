import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { PermissionsService } from '@auth/services'
import * as permissionActions from './permissions.actions'
import {
  catchError,
  map,
  switchMap,
} from 'rxjs/operators'
import { of } from 'rxjs'
import { loginSuccess } from '@core/store/auth/auth.actions'

@Injectable()
export class UserPermissionsEffects {
  constructor(
    private actions$: Actions,
    private authorizationService: PermissionsService,
  ) {
  }

  userAuthentication$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginSuccess),
      map(() => permissionActions.getUserPermissions())
      )
  })

  getUserPermissions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(permissionActions.getUserPermissions),
      switchMap(() => {
        return this.authorizationService.getUserPermissions().pipe(
          map(dto => {
            return permissionActions.getUserPermissionsSuccess({
              response: {
                authorizedUser: dto.authenticatingUser,
                permissions: dto.permissions,
                sageLogicalRole: dto.sageLogicalRole
              }
            })
          }),
          catchError(error => of(permissionActions.getUserPermissionsFail({ errorMessage: error.message }))
          ))
      }))
  })
}
