import { NgModule } from '@angular/core'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'

import { BrowserModule } from '@angular/platform-browser'
import { HttpClientModule } from '@angular/common/http'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { LayoutModule } from './layout/layout.module'
import { SharedModule } from '@shared/shared.module'

import {
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { metaReducers, reducers } from '@core/store'
import { environment } from '@environments/environment'
import { CustomSerializer } from '@core/store/custom-serializer'
import { EffectsModule } from '@ngrx/effects'
import { AuthModule } from '@auth/auth.module'
import { BnNgIdleService } from 'bn-ng-idle'
import { UserPermissionsEffects } from '@core/store/permissions'

const CONDITIONAL_IMPORTS = []
if (!environment.production) {
  CONDITIONAL_IMPORTS.push(
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 50, // Retains last 50 states
      logOnly: environment.production, // Restrict extension to log-only mode
    })
  )
} else {
  CONDITIONAL_IMPORTS.push(
    StoreModule.forRoot(reducers, {
      metaReducers,
    })
  )
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AuthModule,
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    LayoutModule,
    SharedModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    ...CONDITIONAL_IMPORTS,
    StoreRouterConnectingModule.forRoot({ serializer: CustomSerializer }),
    EffectsModule.forRoot([
      UserPermissionsEffects,
    ]),

  ],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    BnNgIdleService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
