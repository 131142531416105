import {FacDataVersion, Facultative} from '../models'
import {updateFacHeaderFields} from './updateFacHeaderFields'
import {updateFacDataVersion} from './updateFacDataVersion'

export function updateFacDeal(deal: Readonly<Facultative>, updatedVersion: Readonly<FacDataVersion>): Facultative {
  return {
    facData: updateFacHeaderFields(deal.facData, updatedVersion),
    facDataVersions: updateFacDataVersion(updatedVersion, deal.facDataVersions)
  }
}
