import { HttpClient } from '@angular/common/http'
import { Injectable, OnDestroy } from '@angular/core'
import { format } from 'date-fns/fp'
import { insert } from 'ramda'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import {
  convertFromSINumber,
  createFacFieldValueFormatter,
  formatLocktonContactLines,
  formatFacCertificateTotal,
  formatFacPremiumRIString,
  formatFacTermsAndConditions,
  formatThousandsCommas,
  stripNumberFormat,
  formatParticipationPercentage, formatValue,
} from '../../formatters'
import {
  AdditionalResponses,
  CoverageReinsured,
  FacDealType,
  FacExportData,
  FacExportFieldExtraProps,
  FacExportQtcData,
  FacFormID,
  FacUnderwritingVisibility,
  MGADataArray,
  ReinsuranceData,
  ReinsuredLayer,
} from '../../models'
import { buildFacExportQtcUnderwriting } from './fac-export-qtc-underwriting'
import {
  FacultativePDF,
  FacPdfField,
  FacPdfHeader,
  FacPdfHeading,
  FacPdfItem,
  FacPdfText,
} from '../pdf/fac-pdf'
import {
  buildReinsuredLayersFields,
  getPremiumField
} from './utils'
import {
  FilenameBuilderFactoryService
} from './filename-builder.service'
import {
  DEFAULT_FAC_EXPORT_TABLE_FIELD_PROPS,
  FacExportField
} from './models'
import { toArray } from '@utils'
import { LabelConstants } from '@constants'
import { isPalms, isTravelers } from '@deals/utils'

@Injectable({ providedIn: 'root' })
export class FacExportService implements OnDestroy {
  facVis: FacUnderwritingVisibility
  GFCProperty: ArrayBuffer
  GFCCasualty: ArrayBuffer
  starr: ArrayBuffer
  AIG: ArrayBuffer
  AIGROR: ArrayBuffer
  AIGRORMunich: ArrayBuffer
  AIGRORSwissCasualty: ArrayBuffer
  AIGRORSwissProperty: ArrayBuffer
  AIGRORTrans: ArrayBuffer
  FM: ArrayBuffer
  travelers: ArrayBuffer
  travelersEndorsement: ArrayBuffer
  travelersEndtService: ArrayBuffer
  travelersEndtFunding: ArrayBuffer
  chubb: ArrayBuffer
  liberty: ArrayBuffer
  CNA: ArrayBuffer
  chubbCasualty: ArrayBuffer
  AIGCasualty: ArrayBuffer
  AIGProperty: ArrayBuffer
  CNACasualty: ArrayBuffer
  everest: ArrayBuffer
  everestMunich: ArrayBuffer
  everestSwiss: ArrayBuffer
  libertyCasualty: ArrayBuffer
  allied: ArrayBuffer
  everestHannover: ArrayBuffer
  hartford: ArrayBuffer
  sentryCasualty: ArrayBuffer
  sentryAddendum: ArrayBuffer
  palms: ArrayBuffer
  palmsNI: ArrayBuffer
  private destroy$ = new Subject<void>()
  formatDate = format('MM/dd/yyyy')

  constructor(http: HttpClient, private filenameBuilderFactory: FilenameBuilderFactoryService) {
    http
      .get('assets/Lockton Re LLC Certificate Form LP1002 – September 2019.pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.GFCProperty = data))
    http
      .get('assets/Lockton Re LLC Certificate Form LP1002 - November 2022.pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.GFCCasualty = data))
    http
      .get('assets/AIG General Terms and Conditions.pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.AIG = data))
    http
      .get('assets/RoR-Confidentiality-Security Endorsement to AIG 0416 Fac Certs (01-23).pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.AIGROR = data))
    http
      .get('assets/RoR-Confidentiality-Security Endorsement to AIG 0416 Fac Certs (01-23) (Swiss Re Open Market Casualty).pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.AIGRORSwissCasualty = data))
    http
      .get('assets/RoR-Confidentiality-Security-Claims Endorsement to AIG 0416 Fac Certs (01-23) (Swiss Re Open Market Property).pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.AIGRORSwissProperty = data))
    http
      .get('assets/RoR-Confidentiality-Security Endorsement to AIG 0416 Fac Certs (Munich-AIG).pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.AIGRORMunich = data))
    http
      .get('assets/RoR-Confidentiality-Security Endorsement to AIG 0416 Fac Certs (01-23) (TransRe).pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.AIGRORTrans = data))
    http
      .get('assets/FM Global Fac General Conditions.pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.FM = data))
    http
      .get('assets/Travelers_CP-6067 3-99 NMA 2921 General Conditions.pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.travelers = data))
    http
      .get('assets/Travelers Endorsement to Funding Clause - Property.pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.travelersEndorsement = data))

    http
      .get('assets/Travelers_Rokstone_Endt to Funding Clause - Property.pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.travelersEndtFunding = data))

    http
      .get('assets/Travelers_Rokstone_Endt to Service of Suit Clause_ed9_10_24.pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.travelersEndtService = data))

    http
      .get('assets/Chubb Property General Conditions.pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.chubb = data))
    http
      .get('assets/Liberty General Conditions.pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.liberty = data))
    http
      .get('assets/CNA General Conditions 092914.pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.CNA = data))
    http
      .get(
        'assets/Chubb Casualty Reinsurance General Conditions FAC39671a 0813.pdf',
        {
          responseType: 'arraybuffer',
        }
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.chubbCasualty = data))
    http
      .get('assets/AIG U.S. Casualty Certificate (0416).pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.AIGCasualty = data))
    http
      .get('assets/AIG U.S. Property Certificate (0416).pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.AIGProperty = data))
    http
      .get('assets/CNA General Conditions 092914 casualty.pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.CNACasualty = data))
    http
      .get("assets/Everest Nat'l Reinsurance Conditions v3.19.2020.pdf", {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.everest = data))
    http
      .get('assets/Everest Munich Re Fac Re Cert v.11.25.2019.pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.everestMunich = data))
    http
      .get('assets/Everest Swiss Re Fac Cert 7.29.2020.pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.everestSwiss = data))
    http
      .get('assets/Liberty Mutual General Conditions.pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.libertyCasualty = data))
    http
      .get('assets/Starr Facultative General Conditions US 01012023.pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.starr = data))
    http
      .get('assets/Allied World Assurance Company Reinsurance Conditions.pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.allied = data))
    http
      .get('assets/Everest Fac Re - Hannover Re.v.4.2.26.2021 (002).pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.everestHannover = data))
    http
      .get('assets/The Hartford Reinsurance Conditions.pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.hartford = data))
    http
      .get('assets/Lockton Re GENERAL CONDITIONS 11.22 (with Wisconsin Governing Law Addendum).pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.sentryCasualty = data))
    http
      .get('assets/Wisconsin Governing Law Addendum to Lockton Re Casualty Fac Cert (LOCKTON).pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.sentryAddendum = data))
    http
      .get('assets/Palms Fac GCs_FINAL_20230701.pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.palms = data))
    http
      .get('assets/Palms Fac Cert_FINAL_20230701 - NI Edits4.1.2449.pdf', {
        responseType: 'arraybuffer',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: ArrayBuffer) => (this.palmsNI = data))
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  sendFacUnderwritingVis(facUnderwritingVisibility: FacUnderwritingVisibility) {
    this.facVis = facUnderwritingVisibility
  }

  getNetPremiumDueAtInceptionLabel(dealType: FacDealType): string {
    return dealType === 'casualty' ? 'Net Share Premium\nDue at Inception' : 'Net Premium\nDue at Inception'
  }

  private getCfgField(
    cfgFields: (
      | (FacPdfText & FacExportFieldExtraProps)
      | (FacPdfField & FacExportFieldExtraProps)
      | (FacPdfHeading & FacExportFieldExtraProps)
    )[][],
    name: string,
    index: number
  ) {
    return cfgFields?.[this.getFieldIndex(cfgFields, name, index)]
  }

  private getFieldIndex(
    cfgFields: (
      | (FacPdfText & FacExportFieldExtraProps)
      | (FacPdfField & FacExportFieldExtraProps)
      | (FacPdfHeading & FacExportFieldExtraProps)
    )[][],
    name: string,
    index: number
  ) {
    return cfgFields.findIndex(
      c => c[index] && c[index].label && c[index]?.label?.includes(name)
    )
  }

  exportBinder(
    data: FacExportData,
    premiumType: string,
    hiddenFields: string[]
  ) {
    const formID: FacFormID = 'binder'
    const [cfgFields] = useFieldConfig(formID, data, hiddenFields)
    const { dealType, currencySymbol } = data
    const pdf = new FacultativePDF()

    const typeName = dealType === 'property' ? 'Property' : 'Casualty'
    const insuranceTypeOnly = cfgFields.find(
      c =>
        c[0] &&
        c[0].label &&
        c[0].label.includes('Perils Insured') &&
        c[0].value === 'Only'
    )

    const umbrellaList = ['Umbrella / Excess Liability', 'Umbrella / Excess Liability - General Liability Carve Out', 'Umbrella / Excess Liability - Auto Liability Carve Out', 'Excess Auto Liability', 'Cyber Liability', 'Directors & Officers Liability', 'Errors & Omissions Liability', 'Miscellaneous Liability']
    if (typeName === 'Property') {
      if (insuranceTypeOnly) {
        if (this.getCfgField(cfgFields, 'Including', 1)) {
          this.getCfgField(cfgFields, 'Including', 1).splice(1, 1)
        }
        if (this.getCfgField(cfgFields, 'Excluding', 1)) {
          this.getCfgField(cfgFields, 'Excluding', 1).splice(1, 1)
        }
        if (this.getCfgField(cfgFields, 'Only', 1)) {
          const onlyValueIndex = cfgFields.findIndex(
            c => c[0] && c[0].label && c[0].value === 'Only'
          )
          const onlyList = cfgFields[onlyValueIndex][1].value as string
          cfgFields[onlyValueIndex][0].value = `${onlyList}`
          cfgFields[onlyValueIndex][0].label = `${cfgFields[onlyValueIndex][0].label} - Only`
          this.getCfgField(cfgFields, 'Only', 1).splice(1, 1)
        }
      } else {
        if (this.getCfgField(cfgFields, 'Only', 3)) {
          this.getCfgField(cfgFields, 'Only', 3).splice(3, 1)
        }
        if (this.getCfgField(cfgFields, 'Including', 1)) {
          if (this.getCfgField(cfgFields, 'Including', 1)?.[1]?.value !== '') {
            this.getCfgField(cfgFields, 'Including', 1)[0].value =
              `${this.getCfgField(cfgFields, 'Including', 1)[0].value} including ${this.getCfgField(cfgFields, 'Including', 1)[1].value}`
                .toString()
                .replace(/\n/g, ', ')
          }
          this.getCfgField(cfgFields, 'Including', 1).splice(1, 1)
        }
        if (this.getCfgField(cfgFields, 'Excluding', 1)) {
          if (this.getCfgField(cfgFields, 'Excluding', 1)?.[1]?.value !== '') {
            this.getCfgField(cfgFields, 'Excluding', 1)[0].value =
              `${this.getCfgField(cfgFields, 'Excluding', 1)[0].value} excluding ${this.getCfgField(cfgFields, 'Excluding', 1)[1].value}`
                .toString()
                .replace(/\n/g, ', ')
          }
          this.getCfgField(cfgFields, 'Excluding', 1).splice(1, 1)
        }
      }

      const secondaryCedentIndex = cfgFields.findIndex(
        c => c[0] && c[0].label && c[0].label.includes('Secondary Cedent Company')
      )
      const cfgSecondaryCedent = this.getCfgField(cfgFields, 'Secondary Cedent Company', 0)

      if (!isTravelers(data.fieldMap.get('reinsured')) || cfgSecondaryCedent?.[0]?.value === '') {
        cfgFields.splice(secondaryCedentIndex, 1)
      } else if (secondaryCedentIndex > -1 && cfgFields[secondaryCedentIndex] && cfgFields[secondaryCedentIndex].length > 0) {
        cfgFields[secondaryCedentIndex][0].label = 'Secondary Cedent'
      }
    } else {
      const isUmbrella = umbrellaList.includes(data.fieldMap.get('coverageReinsured'))
      const hideRetention = cfgFields.find(
        c =>
          c[0] &&
          c[0].label &&
          c[0].label.includes('Program Structure') &&
          c[0].value === 'Guaranteed Cost'
      )
      const cfgProgramStructure = this.getCfgField(cfgFields, 'Program Structure', 0)
      const programStructureIndex = cfgFields.findIndex(
        c => c[0] && c[0].label && c[0].label.includes('Program Structure')
      )
      if (cfgProgramStructure && cfgProgramStructure?.[0]?.value === '' || isUmbrella) {
        cfgFields.splice(programStructureIndex, 1)
      } else if (cfgProgramStructure && hideRetention) {
        cfgFields.splice(programStructureIndex, 1, cfgProgramStructure.splice(0, 1))
      }
    }

    const title = `${typeName} Facultative Binder`
    const header: FacPdfHeader = {
      title: title,
      subtitle: '',
      nameAndAddress: '',
      fields: [],
      height: 45,
    }

    const reinsuredField = this.getCfgField(cfgFields, 'Cedent Company', 0)
    const isPalmsCompany = isPalms(reinsuredField?.[0]?.value as string ?? '')

    if (isPalmsCompany) {
      pdf.addPage(header, isPalmsCompany)
    } else {
      pdf.addPage(createBasicHeader(title))
    }


    // Create fields that are not retrieved from the config fields
    const specialFields = buildReinsuredLayersFields(
      data,
      {
        insertIndex: data.dealType === 'property' ? 11 : 12,
        reinsuredLimitsLabel: 'Reinsurance Layers',
        premiumsFieldKey: 'customReinsurancePremiums',
      },
      formID
    )
    const showBoundTable = data.reinsuranceData
    if (data.dealType === 'property' && showBoundTable) {
      specialFields.push(
        createFacExportText('Reinsurer Bound Participation:', {
          index: 12,
          marginBottom: 0,
          fontStyle: 'bold',
        })
      )
    }

    const { fields } = insertFields(
      cfgFields,
      specialFields,
      data.dealType,
      'binder'
    )

    if (showBoundTable && data.dealType === 'property') {
      const tableFields: FacExportField[][] = []
      const formatTotal = createCertificateTotalFormatter(data)

      tableFields.push([
        {
          ...DEFAULT_FAC_EXPORT_TABLE_FIELD_PROPS,
          type: 'text',
          colWidths: [0.2, 0.2, 0.15, 0.2, 0.2],
          value: [
            data.dealType === 'property' ? '' : 'Reinsurer',
            'Participation Amount',
            'Participation %',
            'Premium 100% terms',
            "Premium RI's share",
          ],
        },
        ...(data.reinsuranceData ?? []).flatMap(d => {
          const rows: FacExportField[] = []
          const formatPremium = createPremiumFormatter(data.currencySymbol)
          rows.push({
            type: 'text',
            fontStyle: 'normal',
            // eslint-disable-next-line no-extra-boolean-cast
            value: !!d.mgaArray.find(mga => Number(mga.participation) > 0)
              ? [
                d.index
                  ? 'Layer ' +
                  String.fromCharCode(d.index + 96).toLocaleUpperCase() +
                  ' ' +
                  d.reinsurers
                  : d.reinsurers,
                ' ',
                ' ',
                ' ',
                ' ',
              ]
              : [
                d.index
                  ? 'Layer ' +
                  String.fromCharCode(d.index + 96).toLocaleUpperCase() +
                  ' ' +
                  d.reinsurers
                  : d.reinsurers,
                typeof d.participationAmount === 'string'
                  ? String(d.participationAmount)
                  : '$' +
                formatThousandsCommas(String(d.participationAmount)),
                formatParticipationPercentage(d.participationPercentage),
                '$' + formatThousandsCommas(String(d.quotedPrice)),
                formatPremium(d.quotedPrice, d.participationPercentage),
              ],
          })
          if (d.isMGA && !!d.mgaArray.find(mga => Number(mga.participation) > 0)) {
            rows.push({
              type: 'text',
              fontStyle: 'italic',
              value: ['On Behalf Of:', ' ', ' ', ' '],
            })
            if (d.mgaArray && d.mgaArray.length > 0) {
              const mgaRows = d.mgaArray
                .filter(
                  m =>
                    Number(
                      m.participation
                        .toString()
                        .replace(/[^0-9.]/g, '')
                        .replace('%', '')
                    ) > 0
                )
                .map((m): FacExportField => {
                  const value = [
                    m.name,
                    '$' +
                    Number(
                      String(
                        m.participationLimit
                          ? m.participationLimit.replace(/[^0-9.]/g, '')
                          : ''
                      )
                    ).toLocaleString(),
                    formatParticipationPercentage(m.participation),
                    formatPremium(d.quotedPrice, 100),
                    formatPremium(d.quotedPrice, m.participation),
                  ]
                  return { type: 'text', fontStyle: 'normal', value }
                })
              rows.push(...mgaRows)
            }
          }
          return rows
        }),
        {
          type: 'text',
          fontStyle: 'bold',
          value: [
            'Total',
            data.reinsuranceData?.length === 1 ? formatTotal('participationAmount') : '',
            data.reinsuranceData?.length === 1 ? formatTotal('participationPercentage') : '',
            formatTotal('quotedPrice'),
            formatTotal('netPremium'),
          ],
        },
        createFacExportText('', { lineHeight: 1 }),
      ])
      // Insert the table
      if (data.dealType === 'property') {
        fields.splice(
          fields
            .map(e => (Array.isArray(e) ? e[0].value : e.value))
            .indexOf('Reinsurer Bound Participation:') + 1,
          0,
          ...tableFields
        )
      } else {
        fields.splice(
          fields
            .map(e => (Array.isArray(e) ? e[0].label : e.label))
            .indexOf('Payment Due'),
          0,
          ...tableFields
        )
      }
    } else if (showBoundTable) {
      const tableFields: FacExportField[][] = []
      const formatTotal = createCertificateTotalFormatter(data)
      const reinsurersArray: (ReinsuranceData & ReinsuredLayer)[] = []
      data.reinsuranceData?.forEach(d => {
        if (data.reinsuredLayers && data.reinsuredLayers.find(l => l.index === d.index)) {
          const reinsuredLayer = data.reinsuredLayers.find(l => l.index === d.index)
          const reinsurersObj = Object.assign({}, reinsuredLayer, d)
          reinsurersArray.push(reinsurersObj)
        }
      })
      let premiumTypeLabel: string = premiumType?.trim().replace(' ', '\n')
      if (data.dealType == 'casualty' && (premiumTypeLabel.indexOf("Gross") > -1)) {
        premiumTypeLabel = "Gross Share\nPremium";
      }

      const headerValues = [
        'Reinsurer',
        'Reference\nNumber',
        'Participation\nAmount',
        'Participation\nPercentage',
        premiumTypeLabel,
        this.getNetPremiumDueAtInceptionLabel(dealType),
      ]

      const totalValues = [
        LabelConstants.Total.toUpperCase(),
        '',
        reinsurersArray.length === 1 ? formatTotal('participationAmount') : '',
        reinsurersArray.length === 1 ? formatTotal('participationPercentage') : '',
        premiumType === 'Gross Premium'
          ? formatTotal('grossPremium')
          : formatTotal('netPremium'),
        formatTotal('netPremium'),
      ]

      let tableColWidths = [0.23, 0.17, 0.15, 0.15, 0.12, 0.18]

      if (data.formName === 'Master') {
        headerValues.splice(6, 0, 'Brokerage')
        const brokerageTotal = reinsurersArray.length === 1 ? formatTotal('brokerageCommission') : ''
        totalValues.splice(6, 0, brokerageTotal)
        tableColWidths = [0.23, 0.17, 0.12, 0.12, 0.12, 0.18, 0.12]
      }

      tableFields.push([
        {
          ...DEFAULT_FAC_EXPORT_TABLE_FIELD_PROPS,
          type: 'text',
          colWidths: tableColWidths,
          value: headerValues,
        },
        ...(reinsurersArray ?? []).flatMap((d, i) => {
          if (
            !d?.index &&
            d?.reinsurers === reinsurersArray[i - 1]?.reinsurers
          ) {
            d.index = reinsurersArray[i - 1].index
          }
          const reinsurerName =
            'Reinsurance Premium Layer ' +
            (d.layerIndex
              ? String(d.layerIndex)
              : String.fromCharCode(d.index + 96).toLocaleUpperCase()) +
            ': ' +
            d.reinsurers
          const rowValues = [
            reinsurerName,
            d.referenceNo,
            String(currencySymbol + formatThousandsCommas(d.participationAmount)),
            formatParticipationPercentage(d.participationPercentage),
            String(currencySymbol + formatThousandsCommas(premiumType === 'Net Premium' ? d.netPremium : d.grossPremium).replace('$', '')),
            String(currencySymbol + formatThousandsCommas(d.netPremium).replace('$', '')),
          ]

          if (data.formName === 'Master') {
            rowValues.splice(6, 0, formatParticipationPercentage(d.brokerageCommission))
          }

          const rows: FacExportField[] = []
          rows.push({
            type: 'text',
            fontStyle: 'normal',
            value: rowValues,
          })

          if (d.isMGA) {
            rows.push({
              type: 'text',
              fontStyle: 'italic',
              value: ['On Behalf Of:', ' ', ' ', ' ', ' ', ' '],
            })
            if (d.mgaArray && d.mgaArray.length > 0) {
              const formatPremium = createPremiumFormatter('$')
              const mgaRows = d.mgaArray
                .filter(
                  m =>
                    Number(
                      m.participation
                        .toString()
                        .replace(/[^0-9.]/g, '')
                        .replace('%', '')
                    ) > 0
                )
                .map((m): FacExportField => {
                  m.participation = Number(
                    m.participation
                      .toString()
                      .replace(/[^0-9.]/g, '')
                      .replace('%', '')
                  )
                  const value = [
                    m.name,
                    String(m.referenceNumber ?? ''),
                    formatPremium(
                      d.participationAmount,
                      (100 * Number(m.participation)) /
                      (typeof d.participationPercentage === 'string'
                        ? parseFloat(d.participationPercentage)
                        : d.participationPercentage)
                    ),
                    formatParticipationPercentage(m.participation),
                    formatPremium(
                      premiumType === 'Gross Premium'
                        ? d.grossPremium
                        : d.netPremium,
                      (100 * Number(m.participation)) /
                      (typeof d.participationPercentage === 'string'
                        ? parseFloat(d.participationPercentage)
                        : d.participationPercentage)
                    ),
                    formatPremium(
                      d.netPremium,
                      (100 * Number(m.participation)) /
                      (typeof d.participationPercentage === 'string'
                        ? parseFloat(d.participationPercentage)
                        : d.participationPercentage)
                    ),
                  ]

                  if (data.formName === 'Master') {
                    value.splice(6, 0, '')
                  }

                  return { type: 'text', fontStyle: 'normal', value }
                })
              rows.push(...mgaRows)
            }
          }
          return rows
        }),
        {
          type: 'text',
          fontStyle: 'bold',
          value: totalValues,
        },
        createFacExportText('', { lineHeight: 1 }),
      ])
      fields.splice(
        fields
          .map(e => (Array.isArray(e) ? e[0].label : e.label))
          .indexOf('Payment Due'),
        0,
        ...tableFields
      )
      const reinsurer = tableFields[0][1].value[0].slice(9)
      // eslint-disable-next-line no-useless-escape
      const parsedOtherFac = data.fieldMap.get('otherFacultative')?.replace(/\<\/span\>/g, '').replace(/\<span\>/g, '').replace(/\<span class=\\"bolder\\"\>/g, '')
      const otherFacultative = typeof data.fieldMap.get('otherFacultative') === 'string' && !data.fieldMap.get('otherFacultative')?.includes('[') ? parsedOtherFac : JSON.parse(parsedOtherFac ?? '[]')
      const otherFac =
        otherFacultative.length > 0 ? otherFacultative.filter((e: string) => !e.includes(reinsurer)).join('\n')
          : 'N/A'
      if (otherFac !== '') {
        const otherFacField = createFacExportField(
          'Other Facultative',
          otherFac
        )
        fields.splice(
          fields
            .map(e => (Array.isArray(e) ? e[0].label : e.label))
            .indexOf('Other Facultative'),
          1,
          otherFacField
        )
      }
    }
    if (
      data.dealType === 'casualty' &&
      umbrellaList.includes(data.fieldMap.get('coverageReinsured'))
    ) {
      const uwFields = buildFacExportQtcUnderwriting(+
        18,
        data,
        formID,
        this.facVis,
      )
      fields.splice(
        fields
          .map(e => (Array.isArray(e) ? e[0].label : e.label))
          .indexOf('Minimum and Deposit'),
        0,
        ...uwFields
      )
    }

    const otherFacIndex = fields.map(e => (Array.isArray(e) ? e[0].label : e.label)).indexOf('Other Facultative')
    if (otherFacIndex > -1 && hiddenFields.includes('otherFacultative')) {
      fields.splice(otherFacIndex, 1)
    }

    const brokerageIndex = fields.map(e => (Array.isArray(e) ? e[0].label : e.label)).indexOf('Brokerage Commission')
    if (brokerageIndex > -1 && hiddenFields.includes('brokerageCommission')) {
      fields.splice(brokerageIndex, 1)
    }

    getPremiumField(data.dealType, fields)
    pdf.addItems(fields, formID)
    pdf.addItems(createLocktonAddressFooter(data), formID)

    const pdfName = this.buildPdfName(data)
    const wisconsinList = ['Florists\' Mutual Insurance Company', 'Sentry Insurance Company']
    if(reinsuredField && reinsuredField?.[0]?.value && wisconsinList.includes(reinsuredField?.[0]?.value as string ?? '')) {
      pdf.save(pdfName, this.sentryAddendum)
    } else {
      pdf.save(pdfName)
    }

  }

  private buildPdfName(data: FacExportData) {
    const coverageReinsured = data.fieldMap.get('coverageReinsured') as CoverageReinsured
    const marketName = data.reinsuranceData?.length ? data.reinsuranceData[0].reinsurers : ''
    const fileNameBuilder = this.filenameBuilderFactory.create()
    if (data.formName === 'Master') {
      fileNameBuilder.setDealName(data.fieldMap.get('name') ?? '')
      .setDealType(data.dealType)
      .setFormType(data.formCategory)
      .setCedentName(data.fieldMap.get('reinsured') ?? '')
      .setCoverageType(coverageReinsured)
      .setMarketName(data.formName)
    } else {
      fileNameBuilder.setDealName(data.fieldMap.get('name') ?? '')
      .setDealType(data.dealType)
      .setFormType(data.formCategory)
      .setCedentName(data.fieldMap.get('reinsured') ?? '')
      .setCoverageType(coverageReinsured)
      .setMarketName(marketName)
    }
    return fileNameBuilder.build()
  }

  exportPropertyQtc(data: FacExportQtcData, hiddenFields: string[]) {
    const formID: FacFormID = 'qtc'
    const [cfgFields, formatVal] = useFieldConfig(formID, data, hiddenFields)
    const pdf = new FacultativePDF()

    const brokerName: string = data.brokerName ?? 'Lockton Re'
    const contactLines: string[] = formatLocktonContactLines(data.isUK, data.dealBroker)
    const title = 'Facultative Quote to Client'
    const header: FacPdfHeader = {
      title,
      subtitle: 'INSURED COMPANY AND ADDRESS',
      nameAndAddress: `${formatVal('nameOfInsured')}\n${formatVal('addressOfInsured')}`,
      height: 77,
    }
    pdf.addPage(header)
    pdf.addItems(createQtcPropertyBody(formatVal, brokerName, contactLines), formID)
    pdf.addPage({ title, height: 30 })

    // Create fields that are not retrieved from the config fields
    const specialFields = buildReinsuredLayersFields(
      data,
      {
        insertIndex: 11,
        reinsuredLimitsLabel: 'Reinsurance Limit(s)',
        overrideReinsuredLayers: data.layersWithQuotes?.map(r => r.layer) ?? [],
        premiumsFieldKey: 'customQTCReinsurancePremiums',
        sortPremiums: true,
      },
      formID
    )
    const hideRetention = cfgFields.find(
      c =>
        c[0] &&
        c[0].label &&
        c[0].label.includes('Program Structure') &&
        c[0].value === 'Guaranteed Cost'
    )
    const minDeposit = cfgFields.find(
      c => c[0] && c[0].label && c[0].label === 'Minimum' && c[0].value !== ''
    )
    const coverageReinsured = cfgFields.find(
      c =>
        c[0] &&
        c[0].label &&
        c[0].label.includes('Coverage Reinsured') &&
        c[0].value
    )
    const insuranceTypeOnly = cfgFields.find(
      c =>
        c[0] &&
        c[0].label &&
        c[0].label.includes('Perils Insured') &&
        c[0].value === 'Only'
    )
    if (coverageReinsured) {
      const cfgRetentionAmount = this.getCfgField(
        cfgFields,
        'Retention Amount',
        1
      )
      if (hideRetention && cfgRetentionAmount) {
        cfgRetentionAmount.splice(1, 1)
      } else {
        cfgRetentionAmount[0].value = `${
          cfgRetentionAmount?.[0]?.value
          }: ${cfgRetentionAmount?.[1]?.value?.toString()}`
        cfgRetentionAmount.splice(1, 1)
      }
      if (minDeposit) {
        this.getCfgField(cfgFields, 'Minimum', 0)[0].label = 'Minimum & Deposit'
        const cfgMinDep = this.getCfgField(cfgFields, 'Minimum & Deposit', 0)
        cfgMinDep[0].value = `${cfgMinDep[0].value} Minimum; ${cfgMinDep[1].value} Deposit`
        this.getCfgField(cfgFields, 'Deposit', 1).splice(1, 1)
      }
    } else {
      if (insuranceTypeOnly) {
        if (this.getCfgField(cfgFields, 'Including', 1)) {
          this.getCfgField(cfgFields, 'Including', 1).splice(1, 1)
        }
        if (this.getCfgField(cfgFields, 'Excluding', 1)) {
          this.getCfgField(cfgFields, 'Excluding', 1).splice(1, 1)
        }
        if (this.getCfgField(cfgFields, 'Only', 1)) {
          const onlyValueIndex = cfgFields.findIndex(c => c[1] && c[1].label && c[1].label.includes('Only'))
          const onlyList = cfgFields[onlyValueIndex][1].value as string
          cfgFields[onlyValueIndex][0].value = `${onlyList}`
          cfgFields[onlyValueIndex][0].label = `${cfgFields[onlyValueIndex][0].label} - Only`
          this.getCfgField(cfgFields, 'Only', 1).splice(1, 1)
        }
      } else {
        if (this.getCfgField(cfgFields, 'Only', 3)) {
          this.getCfgField(cfgFields, 'Only', 3).splice(3, 1)
        }
        if (this.getCfgField(cfgFields, 'Including', 1)) {
          if (this.getCfgField(cfgFields, 'Including', 1)?.[1]?.value !== '') {
            this.getCfgField(cfgFields, 'Including', 1)[0].value =
              `${this.getCfgField(cfgFields, 'Including', 1)[0].value} including ${this.getCfgField(cfgFields, 'Including', 1)[1].value}`
                .toString()
                .replace(/\n/g, ', ')
          }
          this.getCfgField(cfgFields, 'Including', 1).splice(1, 1)
        }
        if (this.getCfgField(cfgFields, 'Excluding', 1)) {
          if (this.getCfgField(cfgFields, 'Excluding', 1)?.[1]?.value !== '') {
            this.getCfgField(cfgFields, 'Excluding', 1)[0].value =
              `${this.getCfgField(cfgFields, 'Excluding', 1)[0].value} excluding ${this.getCfgField(cfgFields, 'Excluding', 1)[1].value}`
                .toString()
                .replace(/\n/g, ', ')
          }
          this.getCfgField(cfgFields, 'Excluding', 1).splice(1, 1)
        }
      }
    }
    const { fields } = insertFields(
      cfgFields,
      specialFields,
      data.dealType,
      'QTC'
    )

    const commission = data.fieldMap.get('cedingCommission') ?? '0'
    const tableTitle = stripNumberFormat(commission) > 0 ? 'Gross' : 'Net'
    const formatPremium = createPremiumFormatter(data.currencySymbol)
    const tableFields = data.layersWithQuotes?.flatMap(d => {
      const rows: (FacExportField | FacExportField[])[] = []
      rows.push([
        {
          ...DEFAULT_FAC_EXPORT_TABLE_FIELD_PROPS,
          type: 'text',
          colWidths: [0.25, 0.75],
          value: [
            `Layer ${String.fromCharCode(
              d.layer.index + 96
            ).toLocaleUpperCase()}`,
            `${d.layer.reinsuranceLayer} per occ excess of ${d.layer.reinsuranceLimit}`,
          ],
        },
        createFacExportText([
          '',
          `Target price: ${d.layer.reinsurancePremium}`,
        ]),
      ])
      rows.push([
        createFacExportText(['', '', '', ''], {
          ...DEFAULT_FAC_EXPORT_TABLE_FIELD_PROPS,
          fontStyle: 'normal',
          colWidths: [0.18, 0.15, 0.35, 0.2],
        }),
        createFacExportText([
          'Reinsurer',
          'Share',
          'Premium 100% terms',
          "Premium RI's share",
        ]),
        ...d.quotes.flatMap(q => {
          const quoteRows: FacExportField[] = []
          quoteRows.push({
            type: 'text' as const,
            fontStyle: 'bold',
            value: [
              q.reinsurer,
              `${q.cession}`,
              q.quotedPrice.toString(),
              formatPremium(q.quotedPrice, q.cession),
            ],
          })
          if (q.isMGA && q.mgaArray && q.mgaArray.length > 0) {
            quoteRows.push(
              createFacExportText(['On Behalf of:', '', '', ''], {
                fontStyle: 'italic',
              })
            )
            quoteRows.push(
              ...q.mgaArray
                .filter(
                  (m: MGADataArray) =>
                    (Number(
                      String(m?.participation).replace(/[^0-9.]/g, '')
                    ) ?? 0) > 0
                )
                .map((m: MGADataArray) => {
                  return createFacExportText([
                    m.name,
                    `${formatValue(parseInt(m.participation.toString(), 10))}%`,
                    q.quotedPrice.toString(),
                    formatPremium(q.quotedPrice.toString(), m.participation),
                  ])
                })
            )
          }
          return quoteRows
        }),
        {
          type: 'text',
          fontStyle: 'bold',
          value: [
            `Total for Layer ${String.fromCharCode(
              d.layer.index + 96
            ).toLocaleUpperCase()}`,
            'Shared placed',
            `${tableTitle} Premium Blended in 100% terms`,
            'Total Fac Spend',
          ],
        },
        createFacExportText([
          '',
          `${d.totalShares}%`,
          formatPremium(d.blendedPremium),
          formatPremium(d.totalFacSpend),
        ]),
      ])
      rows.push([
        {
          ...DEFAULT_FAC_EXPORT_TABLE_FIELD_PROPS,
          type: 'text',
          colWidths: [0.25, 0.75],
          fontStyle: 'bold',
          value: ['Reinsurer specific conditions', ''],
        },
        ...d.quotes.map(q => ({
          type: 'text' as const,
          fontStyle: 'normal' as const,
          value: [
            q.reinsurer,
            formatFacTermsAndConditions(
              q.termsconditions,
              q.termsandconditions
            ),
          ],
        })),
      ])
      return rows
    })
    tableFields?.push(createFacExportText('', { lineHeight: 1 }))
    const tableFieldIndex = fields.findIndex(c =>
      Array.isArray(c)
        ? c[0] && c[0].label && c[0].label.includes('Reinsurance Premium')
        : -1
    )
    fields.splice(tableFieldIndex + 1, 0, ...(tableFields ?? []))

    getPremiumField(data.dealType, fields)

    pdf.addItems(fields, formID)

    const responsesStr = data.fieldMap.get('additionalResponses')
    const responses = JSON.parse(responsesStr ?? '[]') as AdditionalResponses[]
    if (Array.isArray(responses) && responses.length > 0) {
      pdf.addItems(
        [createFacExportText('Additional Responses Received:')],
        formID
      )
      pdf.addItems(
        [buildResponsesTable(responses, data.currencySymbol)],
        formID
      )
    }

    const pdfName = this.buildPdfName(data)
    pdf.save(pdfName)
  }

  async exportCertificate(
    data: FacExportData,
    premiumType: string,
    hiddenFields: string[]
  ) {
    const formID: FacFormID = 'certificate'
    const brokerName = data.brokerName ?? 'Nathan Taylor'
    const { dealType, currencySymbol } = data
    const [cfgFields, formatVal] = useFieldConfig(formID, data, hiddenFields)

    const pdf = new FacultativePDF()
    let reinsuranceCertificateNo = ''
    const certificateNo = data.certificateFields?.find(c => c.name === 'reinsuranceCertificateNo')
    if (certificateNo && certificateNo.value && certificateNo.value !== '') {
      reinsuranceCertificateNo = certificateNo.value as string
    }

    const header: FacPdfHeader = {
      title: 'Certificate of Facultative Reinsurance',
      subtitle: 'REINSURED COMPANY AND ADDRESS',
      nameAndAddress: `${formatVal('nameAndAddress')}`,
      fields: [
        ['', 'Attaching to and forming part of Facultative'],
        [
          'Reinsurance Certificate No.',
          !!data.facData?.reinsuranceCertificateNo && !formatVal('company').includes('Travelers') ? data.facData.reinsuranceCertificateNo : reinsuranceCertificateNo,
        ],
        ['Company', formatVal('company')],
        ['Name of Insured', formatVal('nameOfInsured')],
        ['Policy No.', formatVal('policyNumber')],
      ],
      height: 77,
    }

    const reinsuredField = this.getCfgField(cfgFields, 'Cedent Company', 0)
    const isPalmsCompany = isPalms(reinsuredField?.[0]?.value as string ?? '')

    pdf.addPage(header, isPalmsCompany)
    pdf.addItems(
      [
        data.isUK
          ? CERTIFICATE_BODY_1_UK
          : CERTIFICATE_BODY_1_US,
        brokerName,
      ],
      formID
    )
    pdf.addPage(header, isPalmsCompany)

    // Create fields that are not retrieved from the config fields
    const specialFields = buildReinsuredLayersFields(
      data,
      {
        insertIndex: dealType === 'property' ? 10 : 12,
        premiumsFieldKey: 'customReinsurancePremiums',
      },
      formID
    )

    specialFields.push({
      type: 'heading',
      value:
        'REINSURANCE AS DESCRIBED IN THESE DECLARATIONS HAS BEEN PLACED WITH AND ACCEPTED BY THE FOLLOWING REINSURERS',
      // Insert as 2nd to last field
      index: -2,
      lineHeight: 3,
    })
    if (dealType === 'casualty') {
      specialFields.push({
        type: 'heading',
        value:
          'The undersigned does hereby acknowledge and confirm its PARTICIPATION as set forth below and authorizes the Intermediary to notify the Company of its PARTICIPATION, and agrees that this signed Certificate may be retained by the Intermediary or may be delivered by it to the Company at the Company’s request.',
        // Insert as 2nd to last field
        index: -2,
        lineHeight: 3,
      })
    }

    const tableFields: FacExportField[][] = []
    const insuranceTypeOnly = cfgFields.find(
      c =>
        c[0] &&
        c[0].label &&
        c[0].label.includes('Perils Insured') &&
        c[0].value === 'Only'
    )
    const umbrellaList = ['Umbrella / Excess Liability', 'Umbrella / Excess Liability - General Liability Carve Out', 'Umbrella / Excess Liability - Auto Liability Carve Out', 'Excess Auto Liability', 'Cyber Liability', 'Directors & Officers Liability', 'Errors & Omissions Liability', 'Miscellaneous Liability']
    if (dealType === 'property') {
      if (insuranceTypeOnly) {
        if (
          cfgFields[cfgFields.findIndex(c => c[1] && c[1].label && c[1].label.includes('Including'))]
        ) {
          cfgFields[cfgFields.findIndex(c => c[1] && c[1].label && c[1].label.includes('Including'))].splice(1, 1)
        }
        if (
          cfgFields[cfgFields.findIndex(c => c[1] && c[1].label && c[1].label.includes('Excluding'))]
        ) {
          cfgFields[cfgFields.findIndex(c => c[1] && c[1].label && c[1].label.includes('Excluding'))].splice(1, 1)
        }
        const onlyValueIndex = cfgFields.findIndex(c => c[1] && c[1].label && c[1].label.includes('Only'))
        if (cfgFields[onlyValueIndex]) {
          const onlyList = cfgFields[onlyValueIndex][1].value as string
          cfgFields[onlyValueIndex][0].value = `${onlyList}`
          cfgFields[onlyValueIndex][0].label = `${cfgFields[onlyValueIndex][0].label} - Only`
          cfgFields[onlyValueIndex].splice(1, 1)
        }
      } else {
        if (
          cfgFields[cfgFields.findIndex(c => c[3] && c[3].label && c[3].label.includes('Only'))]
        ) {
          cfgFields[cfgFields.findIndex(c => c[3] && c[3].label && c[3].label.includes('Only'))].splice(3, 1)
        }
        if (
          cfgFields[cfgFields.findIndex(c => c[1] && c[1].label && c[1].label.includes('Including'))]
        ) {
          if (
            cfgFields[cfgFields.findIndex(c => c[1] && c[1].label && c[1].label.includes('Including'))][1].value !== ''
          ) {
            cfgFields[cfgFields.findIndex(c => c[1] && c[1].label && c[1].label.includes('Including'))][0].value =
              cfgFields[cfgFields.findIndex(c => c[1] && c[1].label && c[1].label.includes('Including'))][0].value +
              ' including ' +
              cfgFields[cfgFields.findIndex(c => c[1] && c[1].label && c[1].label.includes('Including'))][1].value.toString().replace(/\n/g, ', ')
          }
          cfgFields[cfgFields.findIndex(c => c[1] && c[1].label && c[1].label.includes('Including'))].splice(1, 1)
        }
        if (
          cfgFields[cfgFields.findIndex(c => c[1] && c[1].label && c[1].label.includes('Excluding'))]
        ) {
          if (
            cfgFields[cfgFields.findIndex(c => c[1] && c[1].label && c[1].label.includes('Excluding'))][1].value !== ''
          ) {
            cfgFields[cfgFields.findIndex(c => c[1] && c[1].label && c[1].label.includes('Excluding'))][0].value = cfgFields[cfgFields.findIndex(c => c[1] && c[1].label && c[1].label.includes('Excluding'))][0].value +
              ' excluding ' +
              cfgFields[cfgFields.findIndex(c => c[1] && c[1].label && c[1].label.includes('Excluding'))][1].value.toString().replace(/\n/g, ', ')
          }
          cfgFields[cfgFields.findIndex(c => c[1] && c[1].label && c[1].label.includes('Excluding'))].splice(1, 1)
        }
      }

      const secondaryCedentIndex = cfgFields.findIndex(
        c => c[0] && c[0].label && c[0].label.includes('Secondary Cedent Company')
      )
      const cfgSecondaryCedent = this.getCfgField(cfgFields, 'Secondary Cedent Company', 0)

      if (!isTravelers(data.fieldMap.get('reinsured')) || cfgSecondaryCedent?.[0]?.value === '') {
        cfgFields.splice(secondaryCedentIndex, 1)
      } else if (secondaryCedentIndex > -1 && cfgFields[secondaryCedentIndex] && cfgFields[secondaryCedentIndex].length > 0) {
        cfgFields[secondaryCedentIndex][0].label = 'Secondary Cedent'
      }
    } else {
      const isUmbrella = umbrellaList.includes(data.fieldMap.get('coverageReinsured'))
      const hideRetention = cfgFields.find(
        c =>
          (c[0] && c[0].label && c[0].label.includes('Program Structure') && c[0].value === 'Guaranteed Cost')
      )
      const cfgProgramStructure = this.getCfgField(
        cfgFields,
        'Program Structure',
        0
      )
      const programStructureIndex = this.getFieldIndex(
        cfgFields,
        'Program Structure',
        0
      )
      if (cfgProgramStructure && cfgProgramStructure?.[0]?.value === '' || isUmbrella) {
        cfgFields.splice(programStructureIndex, 1)
      } else if (cfgProgramStructure && hideRetention) {
        cfgFields.splice(programStructureIndex, 1, cfgProgramStructure.splice(0, 1))
      }
    }
    const formatTotal = createCertificateTotalFormatter(data)
    const reinsurersArray: (ReinsuranceData & ReinsuredLayer)[] = []
    data.reinsuranceData?.forEach(d => {
      if (data.reinsuredLayers && data.reinsuredLayers.find(l => l.index === d.index)) {
        const reinsuredLayer = data.reinsuredLayers.find(l => l.index === d.index)
        const reinsurersObj = Object.assign({}, reinsuredLayer, d)
        reinsurersArray.push(reinsurersObj)
      }
    })
    let premiumTypeLabel: string = premiumType?.trim().replace(' ', '\n')
    if (data.dealType == 'casualty' && (premiumTypeLabel.indexOf("Gross") > -1)) {
      premiumTypeLabel = "Gross Share\nPremium";
    }
    const headerValues = [
      'Reinsurer',
      'Reference\nNumber',
      'Participation\nAmount',
      'Participation\nPercentage',
      premiumTypeLabel,
      this.getNetPremiumDueAtInceptionLabel(dealType),
    ]

    const totalValues = [
      LabelConstants.Total.toUpperCase(),
      '',
      reinsurersArray.length === 1 ? formatTotal('participationAmount') : '',
      reinsurersArray.length === 1 ? formatTotal('participationPercentage') : '',
      premiumType === 'Gross Premium'
        ? formatTotal('grossPremium')
        : formatTotal('netPremium'),
      formatTotal('netPremium'),
    ]

    let tableColWidths = [0.23, 0.17, 0.15, 0.15, 0.12, 0.18]

    if (data.formName === 'Master') {
      headerValues.splice(6, 0, 'Brokerage')
      const brokerageTotal = reinsurersArray.length === 1 ? formatTotal('brokerageCommission') : ''
      totalValues.splice(6, 0, brokerageTotal)
      tableColWidths = [0.23, 0.17, 0.12, 0.12, 0.12, 0.18, 0.12]
    }

    tableFields.push([
      {
        ...DEFAULT_FAC_EXPORT_TABLE_FIELD_PROPS,
        type: 'text',
        colWidths: tableColWidths,
        value: headerValues,
      },
      ...(reinsurersArray ?? []).flatMap((d, i) => {
        if (!d?.index && d?.reinsurers === reinsurersArray[i - 1]?.reinsurers) {
          d.index = reinsurersArray[i - 1].index
        }
        const reinsurerText =
          (dealType === 'casualty' ? 'Reinsurance Premium Layer ' : 'Layer ') +
          (d.layerIndex
            ? String(d.layerIndex)
            : String.fromCharCode(d.index + 96).toLocaleUpperCase()) +
          ': ' +
          d.reinsurers

        const rowValues = [
          reinsurerText,
          d.referenceNo,
          String(currencySymbol + formatThousandsCommas(d.participationAmount)),
          formatParticipationPercentage(d.participationPercentage),
          String(currencySymbol + formatThousandsCommas(premiumType === 'Net Premium' ? d.netPremium : d.grossPremium).replace('$', '')),
          String(currencySymbol + formatThousandsCommas(d.netPremium).replace('$', '')),
        ]
        if (data.formName === 'Master') {
          rowValues.splice(6, 0, formatParticipationPercentage(d.brokerageCommission))
        }
        const rows: FacExportField[] = []
        rows.push({
          type: 'text',
          fontStyle: 'normal',
          value: rowValues,
        })
        if (d.isMGA) {
          rows.push({
            type: 'text',
            fontStyle: 'italic',
            value: ['On Behalf Of:', ' ', ' ', ' ', ' ', ' '],
          })
          if (d.mgaArray && d.mgaArray.length > 0) {
            const formatPremium = createPremiumFormatter(currencySymbol)
            const mgaRows = d.mgaArray
              .filter(
                m =>
                  Number(
                    m.participation
                      .toString()
                      .replace(/[^0-9.]/g, '')
                      .replace('%', '')
                  ) > 0
              )
              .map((m): FacExportField => {
                m.participation = Number(
                  m.participation
                    .toString()
                    .replace(/[^0-9.]/g, '')
                    .replace('%', '')
                )
                const value = [
                  m.name,
                  String(m.referenceNumber ?? ''),
                  formatPremium(
                    d.participationAmount,
                    (100 * Number(m.participation)) /
                    (typeof d.participationPercentage === 'string'
                      ? parseFloat(d.participationPercentage)
                      : d.participationPercentage)
                  ),
                  formatParticipationPercentage(m.participation),
                  formatPremium(
                    premiumType === 'Gross Premium'
                      ? d.grossPremium
                      : d.netPremium,
                    (100 * Number(m.participation)) /
                    (typeof d.participationPercentage === 'string'
                      ? parseFloat(d.participationPercentage)
                      : d.participationPercentage)
                  ),
                  formatPremium(
                    d.netPremium,
                    (100 * Number(m.participation)) /
                    (typeof d.participationPercentage === 'string'
                      ? parseFloat(d.participationPercentage)
                      : d.participationPercentage)
                  ),
                ]
                if (data.formName === 'Master') {
                  value.splice(6, 0, '')
                }
                return { type: 'text', fontStyle: 'normal', value }
              })
            rows.push(...mgaRows)
          }
        }
        return rows
      }),
      {
        type: 'text',
        fontStyle: 'bold',
        value: totalValues,
      },
      createFacExportText('', { lineHeight: 1 }),
    ])

    const { fields } = insertFields(
      cfgFields,
      specialFields,
      data.dealType,
      'certificate'
    )

    if (data.dealType === 'casualty') {
      fields.splice(
        fields.map(e => (Array.isArray(e) ? e[0].label : e.label)).indexOf('Brokerage Commission'), 1,
      )
      const reinsurer = tableFields[0][1].value[0].slice(9)
      // eslint-disable-next-line no-useless-escape
      const parsedOtherFac = data.fieldMap.get('otherFacultative')?.replace(/\<\/span\>/g, '').replace(/\<span\>/g, '').replace(/\<span class=\\"bolder\\"\>/g, '')
      const otherFacultative = typeof data.fieldMap.get('otherFacultative') === 'string' && !data.fieldMap.get('otherFacultative')?.includes('[') ? parsedOtherFac : JSON.parse(parsedOtherFac ?? '[]')
      const otherFac =
        otherFacultative.length > 0 ? otherFacultative.filter((e: string) => !e.includes(reinsurer)).join('\n')
          : 'N/A'

      if (otherFac !== '') {
        const otherFacField = createFacExportField(
          'Other Facultative',
          otherFac
        )
        fields.splice(
          fields
            .map(e => (Array.isArray(e) ? e[0].label : e.label))
            .indexOf('Other Facultative'),
          1,
          otherFacField
        )
      }

      if (
        umbrellaList.includes(data.fieldMap.get('coverageReinsured'))
      ) {
        const uwFields = buildFacExportQtcUnderwriting(
          19,
          data,
          formID,
          this.facVis,
        )
        fields.splice(
          fields
            .map(e => (Array.isArray(e) ? e[0].label : e.label))
            .indexOf('Minimum and Deposit'),
          0,
          ...uwFields
        )
      }
    }

    // Insert the table at second to last index
    fields.splice(fields.length - 1, 0, ...tableFields)
    if (fields.map(e => (Array.isArray(e) ? e[0].label : e.label)).indexOf('Payment Due') < 0) {
      const lastField = fields.splice(fields.length - 1, 1)
      let newIndex = fields.length - (data.dealType === 'casualty' ? 3 : 2)
      if (lastField.map(e => (Array.isArray(e) ? e[0].type : e.type)).indexOf('heading') > -1) {
        newIndex = newIndex + 1
      }
      fields.splice(newIndex, 0, ...lastField)
    }
    getPremiumField(data.dealType, fields)

    const otherFacIndex = fields.map(e => (Array.isArray(e) ? e[0].label : e.label)).indexOf('Other Facultative')
    if (otherFacIndex > -1 && hiddenFields.includes('otherFacultative')) {
      fields.splice(otherFacIndex, 1)
    }

    const brokerageIndex = fields.map(e => (Array.isArray(e) ? e[0].label : e.label)).indexOf('Brokerage Commission')
    if (brokerageIndex > -1 && hiddenFields.includes('brokerageCommission')) {
      fields.splice(brokerageIndex, 1)
    }

    pdf.addItems(
      [
        CERTIFICATE_BODY_2,
        { type: 'heading', value: 'DECLARATIONS' },
        ...fields,
      ],
      formID
    )

    if (data.formName !== 'Master') {
      const reinsurers = ['']
      reinsurers.forEach(key => {
        const suffix = key ? '-' + key : ''
        const brokerageCommission = formatVal('brokerageCommission')
          ? formatVal('brokerageCommission')
          : formatVal('brokerageCommissionSub')
        pdf.addBoxItems(
          [
            createFacExportText(
              data.isUK
                ? 'Lockton Re LLP'
                : 'Lockton Re LLC',
              { fontStyle: 'bold' }
            ),
            [
              'Signature & Date:',
              formatVal('locktonSignature' + suffix),
              formatVal('brokerSignature')
                ? this.formatDate(new Date(formatVal('brokerSignature')))
                : '',
            ],
            [
              createFacExportText('REINSURER:', {
                fontStyle: 'bold',
                colWidths: [0.15, 0.45, 0.4],
              }),
              createFacExportText(key || formatVal('behalfCompany')),
              createFacExportText(
                `BROKERAGE: ${brokerageCommission} of NET Premium`,
                { fontStyle: 'bold' }
              ),
            ],
            ['Signature & Date:', formatVal('reinsurerSignature' + suffix)],
          ],
          { showImage: true },
          brokerName
        )
      })
    }
    const pdfName = this.buildPdfName(data)

    const reinsuranceConditions = data.fieldMap.get("reinsuranceConditions")
    const hasMultiplePdfs = reinsuranceConditions.includes("Travelers Endorsement to Funding Clause-Property")
                         || reinsuranceConditions.includes("Travelers Endt to Service of Suit Clause for Lloyd's-CP-6067")
                         || reinsuranceConditions.includes("Travelers Funding Clause 16 - Reciprocal Jurisdiction Endorsement")

    if (hasMultiplePdfs) {

      const mergedPdfBuffers: ArrayBuffer[] = []

      if (data.fieldMap.get('termsAndConditions') === 'Travelers– includes policy conditions, CP-6067') {
        mergedPdfBuffers.push(this.travelers)
      }

      if (reinsuranceConditions.includes("Travelers Endorsement to Funding Clause-Property")) {
        mergedPdfBuffers.push(this.travelersEndorsement)
      }

      if (reinsuranceConditions.includes("Travelers Endt to Service of Suit Clause for Lloyd's-CP-6067")) {
        mergedPdfBuffers.push(this.travelersEndtService)
      }

      if (reinsuranceConditions.includes("Travelers Funding Clause 16 - Reciprocal Jurisdiction Endorsement")) {
        mergedPdfBuffers.push(this.travelersEndtFunding)
      }

      const finalPdf = await pdf.mergePdfs(mergedPdfBuffers)

      pdf.save(pdfName, finalPdf)

    }
    else if (
      data.fieldMap.get('termsAndConditions') === 'Lockton Re LLC Certificate Form LP1002 - November 2022'
    ) {
      pdf.save(pdfName, this.GFCCasualty)
    } else if (
      data.fieldMap.get('termsAndConditions') === 'Lockton Re LLC Certificate Form LP1002 – September 2019'
    ) {
      pdf.save(pdfName, this.GFCProperty)
    } else if (
      data.fieldMap.get('termsAndConditions') ===
      'FM Global Fac Certificate Form CP2007 – 09-01-10'
    ) {
      pdf.save(pdfName, this.FM)
    } else if (
      data.fieldMap.get('termsAndConditions') ===
      'Travelers– includes policy conditions, CP-6067'
    ) {
      pdf.save(pdfName, this.travelers)
    } else if (
      data.fieldMap.get('termsAndConditions') ===
      'Chubb Property Reinsurance General Conditions - FAC-39670a (08/13)'
    ) {
      pdf.save(pdfName, this.chubb)
    } else if (
      data.fieldMap.get('termsAndConditions') ===
      'Liberty Mutual Insurance Company Form No. LM-1000-FC(Edition 12/31/2010)'
    ) {
      pdf.save(pdfName, this.liberty)
    } else if (
      data.fieldMap.get('termsAndConditions') === 'CNA General Conditions 092914'
    ) {
      pdf.save(pdfName, this.CNA)
    } else if (
      data.fieldMap.get('termsAndConditions') ===
      'Chubb Casualty Reinsurance General Conditions FAC39671a 0813' &&
      data.fieldMap.get('coverageReinsured') !== ''
    ) {
      pdf.save(pdfName, this.chubbCasualty)
    } else if (
      data.fieldMap.get('termsAndConditions') === 'AIG U.S. Casualty Certificate (04/16)' ||
      data.fieldMap.get('termsAndConditions') === 'AIG U.S. Property Certificate (04/16)'
    ) {
      let additionalFiles = this.AIGROR
      if (data.formName) {
        if (data.formName.includes('Munich Reinsurance America')) {
          additionalFiles = this.AIGRORMunich
        } else if (data.formName.includes('Swiss Reinsurance America Corp')) {
          additionalFiles = data.dealType === 'casualty' ? this.AIGRORSwissCasualty : this.AIGRORSwissProperty
        } else if (data.formName === 'Transatlantic Reinsurance Company') {
          additionalFiles = this.AIGRORTrans
        }
      }
      pdf.save(pdfName, data.dealType === 'casualty' ? this.AIGCasualty : this.AIGProperty, [additionalFiles])
    } else if (
      // eslint-disable-next-line no-dupe-else-if
      data.fieldMap.get('termsAndConditions') === 'CNA General Conditions 092914' &&
      data.fieldMap.get('coverageReinsured') !== ''
    ) {
      pdf.save(pdfName, this.CNACasualty)
    } else if (
      data.fieldMap.get('termsAndConditions') ===
      'Everest Insurance v.3.19.2020'
    ) {
      pdf.save(pdfName, this.everest)
    } else if (
      data.fieldMap.get('termsAndConditions') ===
      'Everest Munich Re Fac Re Cert v.11.25.2019' &&
      data.fieldMap.get('coverageReinsured') !== ''
    ) {
      pdf.save(pdfName, this.everestMunich)
    } else if (
      data.fieldMap.get('termsAndConditions') === 'Everest Swiss Re Fac Cert' &&
      data.fieldMap.get('coverageReinsured') !== ''
    ) {
      pdf.save(pdfName, this.everestSwiss)
    } else if (
      // eslint-disable-next-line no-dupe-else-if
      data.fieldMap.get('termsAndConditions') ===
      'Liberty Mutual Insurance Company Form No. LM-1000-FC(Edition 12/31/2010)' &&
      data.fieldMap.get('coverageReinsured') !== ''
    ) {
      pdf.save(pdfName, this.libertyCasualty)
    } else if (
      // eslint-disable-next-line no-dupe-else-if
      data.fieldMap.get('termsAndConditions') === 'Lockton Re LLC Certificate Form LP1002 - November 2022' &&
      data.fieldMap.get('coverageReinsured') !== ''
    ) {
      pdf.save(pdfName, this.GFCCasualty)
    } else if (
      data.fieldMap.get('termsAndConditions') ===
      'Starr Facultative General Conditions US 01012023'
    ) {
      pdf.save(pdfName, this.starr)
    } else if (
      data.fieldMap.get('termsAndConditions') ===
      'Everest Insurance v.4.2.26.2021'
    ) {
      pdf.save(pdfName, this.everestHannover)
    } else if (
      data.fieldMap.get('termsAndConditions') ===
      'Allied World Assurance Company General Terms & Conditions Ref: NACF00002 100 (10/17) (Edition October 2017)' &&
      data.fieldMap.get('coverageReinsured') !== ''
    ) {
      pdf.save(pdfName, this.allied)
    } else if (
      data.fieldMap.get('termsAndConditions') ===
      'The Hartford Reinsurance Conditions'
    ) {
      pdf.save(pdfName, this.hartford)
    } else if (
      data.fieldMap.get('termsAndConditions') ===
      'Lockton Re LLC Certificate Form LP1002 - November 2022 (with Wisconsin Governing Law Addendum)'
    ) {
      pdf.save(pdfName, this.sentryCasualty)
    } else if (
      data.fieldMap.get('termsAndConditions') ===
      'Palms Certificate eff. 7/1/2023'
    ) {
      pdf.save(pdfName, this.palms)
    } else if (
      data.fieldMap.get('termsAndConditions') ===
      'Palms Certificate eff. 7/1/2023 – NI edits 4/1/24'
    ) {
      pdf.save(pdfName, this.palmsNI)
    } else {
      pdf.save(pdfName)
    }
  }
}

function buildResponsesTable(
  responses: AdditionalResponses[],
  currencySymbol: string
): FacExportField[] {
  function formatCurrency(siValueStr: string) {
    const rawValue = convertFromSINumber(siValueStr)
    const value = parseFloat(rawValue).toFixed(0)
    return `${currencySymbol}${formatThousandsCommas(value)}`
  }
  return [
    {
      ...DEFAULT_FAC_EXPORT_TABLE_FIELD_PROPS,
      type: 'text',
      colWidths: [0.2, 0.1, 0.1, 0.15, 0.15, 0.3],
      value: [
        'Reinsurers',
        'Occ Limit',
        'Occ Att',
        'Gross Premium',
        'Net Premium',
        'Terms & Conditions',
      ],
    },
    ...responses.map(res => {
      const f: FacExportField = {
        type: 'text' as const,
        fontStyle: 'normal',
        value: [
          res.reinsurer,
          res.occLimit,
          res.occAtt,
          formatCurrency(res.grossPremium),
          formatCurrency(res.netPremium),
          res.termsAndConditions.toString(),
        ],
      }
      return f
    }),
  ]
}
function useFieldConfig(
  formID: FacFormID,
  data: FacExportData,
  hiddenFields: string[]
) {
  const formatVal = createFacFieldValueFormatter(
    data.dealType,
    data.fieldMap,
    data.certificateFields,
    data.facData,
    data.reinsuredAddress,
  )
  const cfgFields = getConfigFields(
    formID,
    data,
    hiddenFields,
    formatVal
  ).filter(c => Array.isArray(c) && c.length !== 0)
  return [cfgFields, formatVal] as const
}

export function createFacExportField(
  label = '',
  value: string | string[] = '',
  options: FacExportFieldExtraProps &
    Omit<FacPdfItem, 'type' | 'value' | 'label'> = {}
): FacExportField {
  return { type: 'field', label, value, ...options }
}

export function createFacExportText(
  value: string | string[] = '',
  options: FacExportFieldExtraProps & Omit<FacPdfItem, 'type' | 'value'> = {}
): FacExportField {
  return { type: 'text', value, ...options }
}

const createBasicHeader = (
  title: string,
): FacPdfHeader => ({
  title,
  height: 32,
})

const createLocktonAddressFooter = (data: FacExportData): FacExportField[] => [
  {
    type: 'heading',
    value: formatLocktonContactLines(data.isUK, data.dealBroker),
    allCaps: false,
    charSpace: 0,
    fontSize: 10,
  },
]

/**
 * Extract form type's fields from config and map to Field items.
 */
function getConfigFields(
  formID: FacFormID,
  data: FacExportData,
  hiddenFields: string[],
  getValue: (id?: string) => string
) {
  const { dealType = 'property', fieldConfigs } = data
  return fieldConfigs
    .filter(
      cfg => cfg.name.startsWith(formID) && (!cfg.type || cfg.type === dealType)
    )
    .flatMap(cfg => cfg.fields)
    .map(fieldRow =>
      fieldRow
        .filter(
          c =>
            c.type !== 'button' &&
            !hiddenFields.includes(c.id) &&
            c.id !== 'generalAgg' &&
            c.id !== 'otherAgg' &&
            c.id !== 'reinsuredUnderwriter' &&
            c.id !== 'premiumType'
        )
        .map(c => createFacExportField(c.label, formatValue(getValue(c.id))))
    )
}

export function createFacExportField2(
  id: string,
  label: string,
  value: string | string[],
  hidden: boolean,
): FacExportField {
  return { id, type: 'field', label, value,  hidden }
}

function createCertificateTotalFormatter(data: FacExportData, key?: string) {
  const reinsuranceData =
    key != null ? data.reinsuranceDataMap?.get(key) : data.reinsuranceData
  return (id: Exclude<keyof ReinsuranceData, 'mgaArray' | 'isMGA'>) =>
    String(formatFacCertificateTotal(reinsuranceData, data.currencySymbol, id))
}

function createPremiumFormatter(currencySymbol: string) {
  function formatPremium(
    val: string | number,
    participation?: string | number
  ) {
    const premium =
      participation != null
        ? formatFacPremiumRIString(val, participation)
        : Number(val)
    const formatted = formatThousandsCommas(premium.toFixed(0))
    return currencySymbol + formatted
  }
  return formatPremium
}

interface InsertFieldsResult {
  fields: (FacExportField | FacExportField[])[]
  ordinal: number
}

function insertFields(
  fields: FacExportField[][],
  fieldsToInsert: (string | FacExportField | FacExportField[])[],
  formType: string,
  currentForm: string
): InsertFieldsResult {
  return (
    fieldsToInsert
      // Insert extra fields not present in the config map
      .reduce((acc, row) => {
        if (row) {
          const _row = typeof row === 'string' ? createFacExportText(row) : row
          const rowFields = toArray(_row)
          let label = 'Brokerage Commission'
          if (formType === 'casualty' && currentForm === 'submission') {
            label = 'Reinsurance Coverage'
          } else if (formType === 'property') {
            if (currentForm === 'binder') {
              label = 'Ceding Commission'
            } else if (currentForm === 'certificate') {
              label = 'Reinsurer Bound Participation'
            } else {
              label = 'Quote Needed By Date'
            }
          }
          let spliceIndex = rowFields[0]?.index ?? acc.length
          spliceIndex =
            (row as FacExportField).type === 'field'
              ? acc.map(e => (Array.isArray(e) ? e[0].label : 0)).indexOf(label)
              : rowFields[0]?.index ?? acc.length
          if (spliceIndex < 0) {
            spliceIndex = acc.length + spliceIndex + 1
          }
          return insert(spliceIndex, rowFields, acc)
        }
        return acc
      }, fields)
      // Remove empty items and prefix labels with an ordinal
      .filter(row => row[0].value.length > 0)
      .reduce(
        (acc, row, i, rows) => {
          // Don't increment ordinal if both last and current row has suffix
          if (
            !rows[i - 1]?.some(it => it.ordinalSuffix) ||
            !row.some(it => it.ordinalSuffix)
          ) {
            acc.ordinal++
          }
          acc.fields.push(row)
          return acc
        },
        { fields: [], ordinal: 0 } as InsertFieldsResult
      )
  )
}

const createQtcPropertyBody = (
  formatVal: (id?: string | undefined) => string,
  brokerName = 'Lockton Re',
  contactLines: string[]
): FacExportField[] => [
    createFacExportText(
      `Dear ${formatVal('reinsuredUnderwriter')},

Thank you for allowing Lockton Re the opportunity to assist in obtaining facultative support on ${formatVal(
        'name'
      )}.

We are pleased to present the formal quoted terms for your review.

After your review we are available to answer any questions and work on any additional requests or changes to the quoted terms you may require.

We look forward to feedback on these terms and working further with you on this placement.

Kind Regards,


${brokerName}

${contactLines.join('\n')}`,
      { noTrim: true }
    ),
  ]

const CERTIFICATE_BODY_1_US = `Dear Reinsurer,

Please find enclosed a copy of the Terms and Conditions of the final Certificate counter-signed by Lockton Re LLC and ready for your signature.

Examine these documents carefully and advise us immediately of any incorrect or missing information or if the documents otherwise do not meet your requirements.

All commissions in the enclosed Certificate, either payable to Ceding Company or to Lockton Re LLC, are disclosed as agreed upon at time of Binding.

We appreciate your support on this account and of Lockton Re LLC.

Kind Regards,
`

const CERTIFICATE_BODY_1_UK = `Dear Reinsurer,

Please find enclosed a copy of the Terms and Conditions of the final Certificate counter-signed by Lockton Re LLP and ready for your signature.

Examine these documents carefully and advise us immediately of any incorrect or missing information or if the documents otherwise do not meet your requirements.

All commissions in the enclosed Certificate, either payable to Ceding Company or to Lockton Re LLP, are disclosed as agreed upon at time of Binding.

We appreciate your support on this account and of Lockton Re LLP.

Kind Regards,
`

const CERTIFICATE_BODY_2 = `Please examine this document carefully and advise us immediately if it is incorrect or does not meet your requirements.

In consideration of these DECLARATIONS and payment of the reinsurance premium and subject to the GENERAL CONDITIONS attached to this Certificate, each of the Reinsurers listed in the Declarations agree to assume the PARTICIPATION entered opposite its name.`
