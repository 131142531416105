export const USER_PERMISSIONS_FEATURE_KEY = 'userPermissions'

export interface UserPermissionsState {
  readonly authorizedUser: string | null
  readonly permissions: ReadonlyArray<string> | null
  readonly sageLogicalRole: string | null
  readonly errorMessage: string | null
  readonly isLoading: boolean
}

export const initialUserPermissionsState: UserPermissionsState = {
  authorizedUser: null,
  permissions: null,
  sageLogicalRole: null,
  errorMessage: null,
  isLoading: false
}
