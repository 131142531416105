import {
  FacData,
  FacDataVersion,
  MGADataArray,
  ReinsuredLayer,
  SalesforceAccount
} from './fac.model'

export interface FormLayerResponse {
  Bound: boolean
  Capacity: string
  Declined: boolean
  GPM: string
  Layer: ReinsuredLayer
  Market: string
  Notes: string
  Quoted: boolean
  Indicated: boolean
  Underwriter: string
  additionalTerms: string
  brokerageCommission: string
  cedingCommission: string
  declinedReason: string
  declinedReasonOverride?: string
  grossPrice: string
  grossShare: string
  id: string
  marketRef: string
  mgaArray: MGADataArray[]
  netPrice: string
  netShare: string
  newTerms: string
  newPolicyNumber: string
  percentageShare: string
  policyNumber: string
  quoteDate: string
  reinsurer: string
  submissionDate: string
  termsAndConditions: string
}

export interface LayerGroupTotals {
  readonly totalParticipationAmount?: number
  readonly totalParticipationPercentage?: number
  readonly totalGross?: number
  readonly totalNet?: number
}

export interface LayerResponseGroup {
  readonly label: string
  readonly responses: ReadonlyArray<FormLayerResponse>
  readonly totals: LayerGroupTotals
}


export interface SendEmailResponse {
  emailAddress: string
  bcc: string
  subject: string
  body: string
}

export interface AddNewMarketResponse {
  layers?: ReinsuredLayer[]
  reinsurers?: string[]
}

export interface CertificateField {
  name: string
  value: string
}

export interface DuplicateFormData {
  otherNames?: string[]
  currentName?: string
  currentNameOfInsured?: string
  currentReinsured?: string
  currentReinsuredUW?: string
  category?: string
  salesforceAccounts: SalesforceAccount[]
  renewal?: boolean
}

export interface DuplicateFormResult {
  name: string
  nameOfInsured: string
  reinsuredUW: string
  reinsuredName: string
  reinsuredId: string
  renewal?: boolean
}

export const LABELS: Record<Subfield, string> = {
  coverageExpression: 'Coverage Expression',
  occLimit: 'Each Occurrence Limit',
  policyPremium: 'Annual Policy Premium',
  premiumComments: 'Premium Comments',
  generalAgg: 'General Aggregate',
  otherAgg: 'Products and Completed Operations Aggregate',
  programStructure: 'Program Structure',
  retentionAmount: 'Retention Amount',
  isQuotaShare: 'Quota Share',
  partOfCoverageLimit: 'Each Occurence Limit',
  partOfGeneralAggregate: 'General Aggregate',
  partOfProductOperationAggregate: 'Products and Completed Operations Aggregate',
}

export const SUBFIELDS = [
  'coverageExpression',
  'occLimit',
  'generalAgg',
  'otherAgg',
  'policyPremium',
  'premiumComments',
  'programStructure',
  'retentionAmount',
  'isQuotaShare',
  'partOfCoverageLimit',
  'partOfGeneralAggregate',
  'partOfProductOperationAggregate'
] as const

export type Subfield = typeof SUBFIELDS[number]

export type FacultativePolicyLimitDialogResult = Partial<
  Record<Subfield, string>
>

export interface FacultativePolicyLimitDialogData
  extends FacultativePolicyLimitDialogResult {
  currencySymbol: string
  showFields: Partial<Record<Subfield, boolean>>
  labels?: Partial<Record<Subfield, string>>
}

export enum CoverageReinsured {
  auto = 'Auto Liability',
  general = 'General Liability',
  umbrella = 'Umbrella / Excess Liability',
  workersComp = `Workers' Compensation`,
  professionalLiability = 'Professional Liability',
  umbrellaGeneral = 'Umbrella / Excess Liability - General Liability Carve Out',
  umbrellaAuto = 'Umbrella / Excess Liability - Auto Liability Carve Out',
  excessAuto = 'Excess Auto Liability',
  cyber = 'Cyber Liability',
  directorsOfficers = 'Directors & Officers Liability',
  errorsOmissions= 'Errors & Omissions Liability',
  miscellaneous = 'Miscellaneous Liability',
  excessAutoLiability = 'Excess Auto Liability'
}

export enum RateAdjustabilityDefaults {
  Adjustable = 'Rate Premium is Adjustable',
  Flat = 'Rate Premium is Flat',
}

export enum FieldTypes {
  cancellationClause = 'cancellationClause',
  reinsuranceCertificate = 'reinsuranceCertificate',
  additionalReinsuranceConditions = 'additionalReinsuranceConditions',
  brokerageCommission = 'brokerageCommissionSub',
  otherFacultative = 'otherFacultative',
  coverageBasis = 'coverageBasis',
  coverageReinsured = 'coverageReinsured',
  currency = 'currency',
  deposit = 'deposit',
  excluding = 'excluding',
  including = 'including',
  minimum = 'minimum',
  only = 'only',
  policyRetroDate = 'policyRetroDate',
  policyRetroComment = 'policyRetroComment',
  programStructure = 'programStructure',
  rate = 'rate',
  rateAdjustability = 'rateAdjustability',
  reinsuranceConditions = 'reinsuranceConditions',
  reinsuredUnderwriter = 'reinsuredUnderwriter',
  retentionAmount = 'retentionAmount',
  typeOfInsurance = 'typeOfInsurance',
  policyType = 'policyType',
  secondaryCedent = 'secondaryCedent',
  notableTerms = 'notableTerms',
}

export interface FacFormSaveEvent {
  facData: FacData,
  facDataVersion: FacDataVersion
}
