export function formatValue(value: string | number | null | undefined, digits?: number): string {
  // Handle null, undefined, and zero cases
  if (value === null || value === undefined || value === 0) {
    return 'NIL';
  }

  const stringValue = value.toString().trim();

  // If empty string, return original value
  if (stringValue === '') {
    return stringValue;
  }

  const isPercentage = stringValue.endsWith('%');
  const cleanValue = stringValue.replace(/[%\s]/g, '');
  const displayValue = Number(cleanValue);

  // If not a valid number, return original value
  if (isNaN(displayValue)) {
    return stringValue;
  }

  // Check if the number has decimal places
  const hasDecimals = displayValue % 1 !== 0;

  // Format the number
  let formattedNumber: string;
  if (hasDecimals) {
    formattedNumber = displayValue.toFixed(digits ?? 2);
  } else {
    formattedNumber = Number.parseFloat(displayValue.toFixed(0)).toString();
  }

  // Add percentage symbol if it was a percentage
  return isPercentage ? `${formattedNumber}%` : formattedNumber;
}
