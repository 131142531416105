import { Injectable } from '@angular/core'
import {
  CoverageReinsured,
  FacDealType,
  FacFormCategory,
} from '../../models'

export abstract class IFilenameBuilder {
  abstract build(): string
  abstract setCedentName(name: string): IFilenameBuilder
  abstract setCoverageType(type: CoverageReinsured): IFilenameBuilder
  abstract setDealName(name: string): IFilenameBuilder
  abstract setDealType(dealType: FacDealType): IFilenameBuilder
  abstract setFormType(formType: FacFormCategory): IFilenameBuilder
  abstract setMarketName(name: string): IFilenameBuilder
}

@Injectable({ providedIn: 'root' })
export class FilenameBuilderFactoryService {
  create(): IFilenameBuilder {
    return new FilenameBuilder()
  }
}

export class FilenameBuilder implements IFilenameBuilder {
  private fileNamePartDelim = '_'
  private lockton = 'Lockton Re '

  private _cedentName: string
  setCedentName(name: string): IFilenameBuilder {
    this._cedentName = name
    return this
  }

  private _dealName: string
  setDealName(name: string): IFilenameBuilder {
    this._dealName = name
    return this
  }

  private _dealType: FacDealType
  setDealType(dealType: FacDealType): IFilenameBuilder {
    this._dealType = dealType
    return this
  }

  private _formType: FacFormCategory
  setFormType(formType: FacFormCategory): IFilenameBuilder {
    this._formType = formType
    return this
  }

  private _marketName: string | undefined
  setMarketName(name: string): IFilenameBuilder {
    this._marketName = name
    return this
  }

  private _coverageType: CoverageReinsured | undefined
  setCoverageType(type: CoverageReinsured): IFilenameBuilder {
    this._coverageType = type
    return this
  }

  build(): string {
    switch (this._dealType) {
      case 'property':
        return this.buildPropertyFileName()
      case 'casualty':
        return this.buildCasualtyFileName()
      default:
        throw new Error(`${this._dealType} is not a valid deal type.`)
    }
  }

  private get fileNameExt(): string {
    return this._formType === 'responses' ? 'xlsx' : 'pdf'
  }

  private buildPropertyFileName() {
    // <deal name>_<cedent name>_[<market name>]_<deal type>_<lockton><whitespace><form type>
    const marketName = ['submission', 'qtc', 'responses'].includes(this._formType) ? '' : this._marketName
    const fileNameParts: string[] = [
      this._dealName,
      this._cedentName,
      marketName ?? '',
      'Property',
      this.getSuffixPart(),
    ]
    return fileNameParts.filter(Boolean).join(this.fileNamePartDelim) + '.' + this.fileNameExt
  }

  private buildCasualtyFileName() {
    // <deal name>_<cedent name>_[<market name>]_<coverage type>_<lockton><whitespace><form type>
    const marketName = ['submission', 'qtc', 'responses'].includes(this._formType) ? '' : this._marketName
    const fileNameParts: string[] = [
      this._dealName,
      this._cedentName,
      marketName ?? '',
      this.getCoverageTypePart(),
      this.getSuffixPart(),
    ]
    return fileNameParts.filter(Boolean).join(this.fileNamePartDelim) + '.' + this.fileNameExt
  }

  private getSuffixPart(): string {
    return `${this.lockton}${this.getFormTypePart()}`
  }

  private getCoverageTypePart(): string {
    switch (this._coverageType) {
      case CoverageReinsured.auto:
        return 'Auto Liability'
      case CoverageReinsured.general:
        return 'General Liability'
      case CoverageReinsured.umbrella:
        return 'Umbrella-Excess Liability'
      case CoverageReinsured.workersComp:
        return 'Workers Compensation'
      case CoverageReinsured.professionalLiability:
        return 'Professional Liability'
      case CoverageReinsured.umbrellaGeneral:
        return 'Umbrella-Excess Liability-General Liability Carve Out'
      case CoverageReinsured.umbrellaAuto:
        return 'Umbrella-Excess Liability-Auto Liability Carve Out'
      case CoverageReinsured.excessAuto:
        return 'Excess Auto Liability'
      case CoverageReinsured.cyber:
        return 'Cyber Liability'
      case CoverageReinsured.directorsOfficers:
        return 'Directors & Officers Liability'
      case CoverageReinsured.errorsOmissions:
        return 'Errors & Omissions Liability'
      case CoverageReinsured.miscellaneous:
        return 'Miscellaneous Liability'
      case CoverageReinsured.excessAutoLiability:
        return 'Excess Auto Liability'
      default:
        throw new Error(`${this._coverageType} is not a valid casualty coverage type.`)
    }
  }

  private getFormTypePart(): string {
    switch (this._formType) {
      case 'submission':
        return 'Submission'
      case 'responses':
        return 'Responses'
      case 'qtc':
        return 'Quote'
      case 'binder':
        return 'Binder'
      case 'certificate':
        return 'Certificate'
      default:
        throw new Error(`${this._formType} is not a valid form type.`)
    }
  }
}
