import { getUrlSegmentValue } from '@utils'

export class FacRouteParts {
  static readonly DEALS: string = 'deals'
}

const dealFormRegExp = /^\/deals\/(property|casualty)\/([0-9]+|new)\/(submission|responses|qtc|binder|certificate)$/

export function buildDealsUrl(): string {
  return `${FacRouteParts.DEALS}`
}

export function buildDealFormUrl(dealType: string, dealId: string, formType: string): string {
  return `${FacRouteParts.DEALS}/${dealType}/${dealId}/${formType}`
}

export function buildNewDealFormUrl(dealType: string, formType: string): string {
  return `${FacRouteParts.DEALS}/${dealType}/new/${formType}`
}

export function isDealFormUrl(url: string): boolean {
  return dealFormRegExp.test(url)
}

export function getFormCategoryFromUrl(url: string): string | null {
  return getUrlSegmentValue(url, 3)
}

export function getDealTypeFromUrl(url: string): string | null {
  return getUrlSegmentValue(url, 1)
}
