
import { Inject, Injectable } from '@angular/core'
import { AppState } from '@core/store'
import { Store } from '@ngrx/store'
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService
} from '@azure/msal-angular'
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  IPublicClientApplication,
  RedirectRequest
} from '@azure/msal-browser'
import { Observable } from 'rxjs'
import { filter, tap } from 'rxjs/operators'
import { loginSuccess, updateAuthToken } from '@core/store/auth/auth.actions'

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private readonly postLogoutRedirectUri = '/login/post-logout.html'

  accessToken: string | null = null
  currentUser: string | null = null

  constructor(
    private msal: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    @Inject(MSAL_INSTANCE) private msalInstance: IPublicClientApplication,
    private msalBroadcastService: MsalBroadcastService,
    private store: Store<AppState>,
  ) {
    this.initializeMsal()
  }

  login(): Observable<void> {
    if (this.msalGuardConfig.authRequest){
      return this.msal.loginRedirect({
        ...this.msalGuardConfig.authRequest,
        // redirectStartPage: '/deals'
      } as RedirectRequest)
    } else {
      return this.msal.loginRedirect()
    }
  }

  logout(): Observable<void> {
    return this.msal.logoutRedirect({
      postLogoutRedirectUri: this.postLogoutRedirectUri
    })
  }

  private checkAndSetActiveAccount() {
    const activeAccount = this.msalInstance.getActiveAccount()
    if (!activeAccount && this.msalInstance.getAllAccounts().length > 0) {
      const accounts = this.msalInstance.getAllAccounts()
      this.msalInstance.setActiveAccount(accounts[0])
    }
  }

  initializeMsal(): void {
    // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msal.instance.enableAccountStorageEvents()
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe(() => {
        if (this.msal.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/'
        }
      })

    this.msalBroadcastService.inProgress$
      .pipe(
        tap((status: InteractionStatus) => console.log(`Interaction status changed to ${status}`)),
        filter((status: InteractionStatus) => status === InteractionStatus.None),
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount()
      })

    this.msalBroadcastService.msalSubject$
      .subscribe((result: EventMessage) => {
        const loginPayload = result.payload as AuthenticationResult
        switch (result.eventType) {
          case EventType.ACQUIRE_TOKEN_SUCCESS:
          case EventType.SSO_SILENT_SUCCESS:
          case EventType.LOGIN_SUCCESS:
            if (this.accessToken !== loginPayload.accessToken) {
              this.dispatchAuthActions(loginPayload)
            }
            break
          case EventType.LOGOUT_SUCCESS:
            this.accessToken = null
            break
          default:
            break
        }
      })
  }

  private dispatchAuthActions(loginPayload: AuthenticationResult) {
    if (this.accessToken === loginPayload.accessToken && this.currentUser === loginPayload.account.username) {
      return
    }

    if (this.currentUser !== loginPayload.account.username) {
      console.log(`Dispatching auth actions: tokens ${this.accessToken} => ${loginPayload.accessToken.slice(0, 10)}`)
      const authenticatingUser = `${loginPayload.account.idTokenClaims.given_name} ${loginPayload.account.idTokenClaims.family_name}`
      this.store.dispatch(loginSuccess({
        username: loginPayload.account.username,
        redirectURL: window.location.origin,
        name: authenticatingUser,
        token: loginPayload.accessToken,
      }))
    } else if (this.accessToken!== loginPayload.accessToken) {
      this.store.dispatch(updateAuthToken({token: loginPayload.accessToken}))
    }

    this.accessToken = loginPayload.accessToken
    this.currentUser = loginPayload.account.username
  }
}
