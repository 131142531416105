import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store'
import { IFacultativeState } from './facultative.state'
import { FAC_DEALS_FEATURE_KEY } from './constants'
import { FacDataVersion, FacFormCategory, FacSubmission, Facultative, FacUser, StoredFileStatus } from '../models'
import { IFacFormAggregate } from '../models'
import { selectAuthState } from '@core/store/auth/auth.selectors'
import { AuthState } from '@core/store/auth/auth.state'

export const selectFacState: MemoizedSelector<object, IFacultativeState> = createFeatureSelector<IFacultativeState>(FAC_DEALS_FEATURE_KEY)

export const selectCurrentSearchCriteria = createSelector(
  selectFacState,
  state => state.facSearchCriteria
)

export const selectDealSummaries = createSelector(
  selectFacState,
  state => state.dealSummaries
)

export const selectCurrentDeal = createSelector(
  selectFacState,
  state => state.currentDeal
)

export const selectCurrentDealType = createSelector(
  selectFacState,
  state => state.currentDealType
)

export const selectCurrentVersion: MemoizedSelector<object, FacDataVersion | null> = createSelector(
  selectFacState,
  state => state.currentVersion
)

export const selectCurrentFormCategory = createSelector(
  selectFacState,
  state => state.currentFormCategory as FacFormCategory | null
)

export const selectCurrentVersionFieldValues: MemoizedSelector<object, FacSubmission | null> = createSelector(
  selectCurrentVersion,
  currentVersion => currentVersion?.fieldValues ? JSON.parse(currentVersion?.fieldValues) as FacSubmission : null
)

export const selectCurrentVersionStoredFiles: MemoizedSelector<object, StoredFileStatus[]> = createSelector(
  selectCurrentVersionFieldValues,
  fieldValues => fieldValues?.storedFiles ? JSON.parse(fieldValues.storedFiles) as StoredFileStatus[] : []
)

export const selectSettings = createSelector(
  selectFacState,
  state => state.settings
)

export const selectIsLoading = createSelector(
  selectFacState,
  state => state.isLoading
)

export const selectIsSaving = createSelector(
  selectFacState,
  state => state.isSaving
)

export const selectIsExporting = createSelector(
  selectFacState,
  state => state.isExporting
)

export const selectSaveDate = createSelector(
  selectFacState,
  state => state.saveDate
)

export const selectDirty = createSelector(
  selectFacState,
  state => state.dirty
)

export const selectCurrentViewPreference = createSelector(
  selectFacState,
  state => state.currentViewPreference
)

export const selectCurrentDealName = createSelector(
  selectFacState,
  state => state.currentDealName
)

export const selectSubtitles = createSelector(
  selectCurrentVersion,
  versionData => {
    if (versionData?.fieldValues) {
      const data = JSON.parse(versionData.fieldValues)
      return data.reinsured + ' / ' + data.name
    }
    return ''
  }
)

export const selectHeaderTitle = createSelector(
  selectFacState,
  state => state.currentDealName || state.headerTitle
)

export const selectCreatingFacultativeFormVersion = createSelector(
  selectFacState,
  state => state.creatingFormVersion
)

export const selectDeletingFacultativeFormVersion = createSelector(
  selectFacState,
  state => state.deletingFormVersion
)

export const selectAllCurrency = createSelector(
  selectFacState,
  state => state.currency
)

export const selectedCurrency = createSelector(
  selectCurrentVersion,
  versionData => {
    let currency = 'USD'
    if (versionData && versionData.fieldValues) {
      const data = JSON.parse(versionData.fieldValues)
      currency = data.currency ?? currency
    }
    return currency
  }
)

export const selectCoverageReinsured = createSelector(
  selectFacState,
  state => state.coverageReinsured
)

export const selectIdsToUpdate = createSelector(
  selectFacState,
  state => state.idsToUpdate
)

export const selectPagination = createSelector(
  selectFacState,
  state => state.pagination
)

export const selectBasisOfAcceptance = createSelector(
  selectFacState,
  state => state.basisOfAcceptance
)

export const selectSalesforceAccounts = createSelector(
  selectFacState,
  state => state.salesforceAccounts
)

export const selectSalesforceContacts = createSelector(
  selectFacState,
  state => state.salesforceContacts
)

export const selectTermsAndConditions = createSelector(
  selectFacState,
  state => state.termsAndConditions
)

export const selectReinsuranceConditions = createSelector(
  selectFacState,
  state => state.reinsuranceConditions
)

export const selectAllFacUsers = createSelector(
  selectFacState,
  state => state.allFacUsers
)

export const selectBrokerFilterPicklist = createSelector(
  selectFacState,
  state => state.brokerFilterPicklist
)

export const selectBrokerUsers = createSelector(
  selectFacState,
  state => state.allFacUsers.filter(u => u.isBroker)
)

export const selectedDeal = createSelector(
  selectFacState,
  state => state.selectedDeal
)

export const selectCurrentBroker = createSelector(
  selectAllFacUsers,
  selectAuthState,
  selectedDeal,
  (users: readonly FacUser[], authState: AuthState, deal: Facultative) => {
    if (!users || users.length === 0) {
      return null
    }

    if (deal?.facData?.username) {
      const dealBrokerName = deal?.facData?.username
      const dealBrokerUserLookup = users.find((u: FacUser) => u.name.toLowerCase() === dealBrokerName.toLowerCase())
      console.info(`dealBrokerUserLookup`, dealBrokerUserLookup)
      return dealBrokerUserLookup ?? { id: 0, email: '', name: dealBrokerName }
    } else if (deal?.facData?.id === 0) {
      const currentUserEmail = authState.username.toLowerCase()
      const currentUserLookup = users.find((u: FacUser) => u.email.toLowerCase() === currentUserEmail)
      console.info(`currentUserLookup`, currentUserLookup)
      return currentUserLookup
    }
    return null
  }
)

export const selectReinsurersList = createSelector(
  selectFacState,
  state => state.reinsurersList
)

export const selectFormCategories = createSelector(
  selectFacState,
  state => state.formCategories
)

export const selectReinsuredAddress = createSelector(
  selectFacState,
  state => state.reinsuredAddress
)

export const selectFacShellAggregate: MemoizedSelector<object, IFacFormAggregate> = createSelector(
  selectCurrentVersionFieldValues,
  selectBasisOfAcceptance,
  selectAllCurrency,
  selectReinsuranceConditions,
  selectSalesforceAccounts,
  selectTermsAndConditions,
  selectSalesforceContacts,
  (
    fieldValues,
    basisOfAcceptance,
    currencies,
    reinsuranceConditions,
    accounts,
    termsAndConditions,
    salesforceContacts
  ) => {
    const aggregate: IFacFormAggregate = {
      basisOfAcceptance,
      currencies,
      fieldValues,
      reinsuranceConditions,
      accounts,
      salesforceContacts,
      termsAndConditions,
    }
    return aggregate
  }
)

export const selectIsDealSidebarVisible = createSelector(
  selectFacState,
  state => state.isDealSidebarVisible
)
