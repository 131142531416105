import { NgModule } from '@angular/core'
import { SageCurrencyPipe } from './sage-currency.pipe'
import { StringTruncatemPipe } from './string-truncate.pipe'
import { ShortNumberPipe } from './short-number.pipe'
import { EmptyValuePipe } from './empty-value.pipe'
import { TitleCaseWithSpacePipe } from './titlecase-with-space.pipe'
import { DayMonthToMonthDay } from './day-month-to-month-day'
import { FullDateToYear } from './full-date-to-year'
import { TimeAgoPipe } from './time-ago.pipe'
import { CurrencyConversionPipe } from './currency-conversion.pipe'

export const PIPES = [
  ShortNumberPipe,
  StringTruncatemPipe,
  SageCurrencyPipe,
  EmptyValuePipe,
  TitleCaseWithSpacePipe,
  DayMonthToMonthDay,
  FullDateToYear,
  TimeAgoPipe,
  CurrencyConversionPipe,
]

@NgModule({
  declarations: [PIPES],
  exports: [PIPES],
})
export class PipesModule {}
