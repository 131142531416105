export function generateGUID(): string {
  function hex(s: string, b: number) {
    // tslint:disable-next-line:no-bitwise
    return s + (b >>> 4).toString(16) + (b & 0b1111).toString(16)
  }
  const r: Uint8Array = crypto.getRandomValues(new Uint8Array(16))
  // tslint:disable-next-line:no-bitwise
  r[6] = r[6] >>> 4 | 0b01000000 // Set type 4: 0100
  // tslint:disable-next-line:no-bitwise
  r[8] = r[8] >>> 3 | 0b10000000 // Set variant: 100

  return r.slice(0, 4).reduce(hex, '') +
    r.slice(4, 6).reduce(hex, '-') +
    r.slice(6, 8).reduce(hex, '-') +
    r.slice(8, 10).reduce(hex, '-') +
    r.slice(10, 16).reduce(hex, '-')
}
