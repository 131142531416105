import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router'
import { Observable, of } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { UserPermissionConstants } from '@auth/models'
import { PermissionsService, UserPermissionsDto } from '@auth/services'
import { MsalGuard } from '@azure/msal-angular'

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private msalGuard: MsalGuard,
    private authorizationService: PermissionsService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {

    const resp = this.msalGuard.canActivate(route, state)
    return resp.pipe(
      mergeMap(authGuardResponse => {
        if (typeof authGuardResponse === 'boolean' && authGuardResponse === true) {
          return this.isUserAuthorized(state.url).pipe(
            map((isUserAuthorized: boolean) => {
              return isUserAuthorized ? true : this.router.createUrlTree(['/unauthorized'])
            })
          )
        }
        else {
          return of(authGuardResponse)
        }
      })
    )
  }

  isUserAuthorized(url: string): Observable<boolean> {
    return this.authorizationService.getUserPermissions().pipe(
      map((dto: UserPermissionsDto) => url && url.includes('/dashboard') ? dto.permissions.includes(UserPermissionConstants.FacultativeDashboard) : dto.permissions.includes(UserPermissionConstants.Facultative))
    )
  }

}
