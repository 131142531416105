import { convertFromSINumber } from './convertFromSINumber'

export function facFormatCurrency(input: number, currencySymbol: string = '$'): string {
  return currencySymbol +
    input.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function formatSmartCurrency(input: string, currencySymbol: string = '$'): string {
  return facFormatCurrency(parseFloat(convertFromSINumber(input)), currencySymbol)
}
