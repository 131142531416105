import { Injectable } from '@angular/core'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { StoredFileBlob } from '../../models'

@Injectable({ providedIn: 'root' })
export class ZipFilesService {
  async createAndSaveZip(zipFileName: string, files: StoredFileBlob[]) {
    const zip = new JSZip()
    for (const file of files) {
      zip.file(file.name, file.blob)
    }
    zip.generateAsync({ type: 'blob' }).then(content => {
      saveAs(content, zipFileName)
    })
  }
}
