import {Component, Inject, OnDestroy, OnInit} from '@angular/core'
import { firstValueFrom, Observable, Subject } from 'rxjs'
import {
  MSAL_GUARD_CONFIG, MSAL_INSTANCE,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService
} from '@azure/msal-angular'
import {
  IPublicClientApplication,
} from '@azure/msal-browser'
import { Store } from '@ngrx/store'
import { AppState } from '@core/store'
import { selectCurrentDealName, selectHeaderTitle, selectSubtitles } from '@deals/store'
import { selectAuthenticatingUser, selectIsAuthenticated } from '@core/store/auth/auth.selectors'
import { AuthenticationService } from '@auth/services'
import { BnNgIdleService } from 'bn-ng-idle';
import { MatDialog } from '@angular/material/dialog'
import { TimeoutDialogComponent } from '@shared/timeout-dialog.component'
import { environment } from '@environments/environment'
import { selectUserPermissions } from '@core/store/permissions'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly destroying$ = new Subject<void>()
  private readonly currentDealName$: Observable<string | undefined>
  private readonly subtitle$: Observable<string | undefined>
  private readonly headerTitle$: Observable<string | undefined>

  readonly title = 'FacSAGE'
  isIframe = false
  readonly userName$: Observable<string | undefined>
  readonly isLoggedIn$: Observable<boolean | undefined>
  readonly userPermissions$: Observable<readonly string[] | undefined>
  dialogOpen = false

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    @Inject(MSAL_INSTANCE) private msalInstance: IPublicClientApplication,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public dialog: MatDialog,
    private store: Store<AppState>,
    private authenticationService: AuthenticationService,
    private bnIdle: BnNgIdleService,
  ) {
    this.currentDealName$ = this.store.select(selectCurrentDealName)
    this.subtitle$ = this.store.select(selectSubtitles)
    this.headerTitle$ = this.store.select(selectHeaderTitle)
    this.userName$ = this.store.select(selectAuthenticatingUser)
    this.isLoggedIn$ = this.store.select(selectIsAuthenticated)
    this.userPermissions$ = this.store.select(selectUserPermissions)
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener
    this.bnIdle.startWatching(environment.timeout).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        console.log('session expired');
        if (isTimedOut && !this.dialogOpen) {
          this.dialogOpen = true
          const dialogRef = this.dialog.open(TimeoutDialogComponent, {
            disableClose: true,
          })
          dialogRef.afterClosed().subscribe(async result => {
            this.dialogOpen = false
            if (result === 'Reset') {
              this.bnIdle.resetTimer()
            } else {
              await this.logout()
            }
          })
        }
      }
    });
  }

  get appHeaderTitle$(): Observable<string> {
    return this.headerTitle$
  }

  get appHeaderSubtitle$(): Observable<string> {
    return this.subtitle$
  }

  async logout() {
    await firstValueFrom(this.authenticationService.logout())

  }

  ngOnDestroy(): void {
    this.destroying$.next(undefined)
    this.destroying$.complete()
  }
}
