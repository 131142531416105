import {
  CertificateFields,
  FacData,
  Facultative,
  ReinsuredAddress
} from '../../models'
import { FacFieldService } from '../../services'

export function getCertificateValue(
  id: string,
  facDataEntry: Facultative,
  certificateFieldValues: CertificateFields[],
  fieldService: FacFieldService,
  _reinsuredAddress: ReinsuredAddress | null
): string | undefined {
  // setInitialNameAndAddress(facDataEntry, certificateFieldValues, fieldService, reinsuredAddress)
  if (id === 'brokerage') {
    return fieldService.getFieldValuesMap().get('brokerageCommission')
  } else {
    if (
      !fieldService.getFieldValuesMapValue('reinsured')?.includes('Travelers') &&
      (id === 'policyNumber' || id === 'reinsuranceCertificateNo') &&
      facDataEntry &&
      facDataEntry.facData &&
      facDataEntry.facData[id as keyof FacData]
    ) {
      return facDataEntry.facData[id as keyof FacData] as unknown as string
    }
    const certificateEntry = certificateFieldValues.filter(
      certificateFieldValue => {
        return certificateFieldValue.name === id
      }
    )
    if (certificateEntry.length > 0) {
      return certificateEntry[0].value as string
    } else {
      if (id === 'nameOfInsured') {
        if (
          facDataEntry &&
          facDataEntry.facData &&
          facDataEntry?.facData.name
        ) {
          return facDataEntry?.facData.name
        }
        const name = fieldService.getFieldValuesMap().get('name')
        if (name) {
          return name
        } else {
          return ''
        }
      } else if (id === 'company') {
        const reinsured = fieldService
        .getFieldValuesMap()
        .get('reinsured')
        if (reinsured) {
          return reinsured
        } else {
          return ''
        }
      } else if (id === 'addressOfInsured') {
        const street = fieldService
        .getFieldValuesMap()
        .get('streetAddress')
        const city = fieldService.getFieldValuesMap().get('cityName')
        const state = fieldService.getFieldValuesMap().get('stateName')
        const zip = fieldService.getFieldValuesMap().get('zipCode')
        if (street || city || state || zip) {
          return '' + street + ' ' + city + ' ' + state + ' ' + zip
        } else {
          return ''
        }
      } else {
        return ''
      }
    }
  }
}

