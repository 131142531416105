import {
  FormLayerResponse,
  LayerGroupTotals,
  LayerResponseGroup
} from '../../models'
import { getLetterFromIndex } from '../../formatters/getLetterFromIndex'
import { stripNonNumeric } from '../../formatters'

export function buildLayerGroups(responses: ReadonlyArray<FormLayerResponse>): ReadonlyArray<LayerResponseGroup> {
  if (!responses || responses.length === 0) {
    return []
  }
  const layerIndices: number[] = Array.from(new Set(responses.map(r => r.Layer.index).sort((a, b) => a - b)))
  const layerGroups: LayerResponseGroup[] = []
  layerIndices.forEach((layerIndex: number) => {
    const layerLabel = `Reinsurance Premium Layer ${getLetterFromIndex(layerIndex)}`
    const responsesForIndex = responses.filter(r => r.Layer.index === layerIndex)
    const layerGroup: LayerResponseGroup = {
      label: layerLabel,
      responses: responsesForIndex,
      totals: buildTotalsRow(responsesForIndex)
    }
    layerGroups.push(layerGroup)
  })
  return layerGroups
}

function buildTotalsRow(responses: ReadonlyArray<FormLayerResponse>): LayerGroupTotals {
  return {
    totalParticipationAmount: responses.map(r => r.Capacity).reduce((a, b) => a + toNumericValue(b), 0),
    totalParticipationPercentage: responses.map(r => r.percentageShare).reduce((a, b) => a + toNumericValue(b), 0),
    totalGross: responses.map(r => r.grossShare).reduce((a, b) => a + toNumericValue(b), 0),
    totalNet: responses.map(r => r.netShare).reduce((a, b) => a + toNumericValue(b), 0),
  }
}

function toNumericValue(valueString: string): number {
  const value = parseFloat(stripNonNumeric(valueString))
  return isNaN(value) ? 0 : value
}
