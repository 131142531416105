import { FacSubmission, FieldTypes } from '../models'

export function getVeryHiddenFieldIds(
  fieldValues: FacSubmission
): string[] {

  let veryHiddenFields: string[] = []

  if (fieldValues.typeOfInsurance !== 'Only') {
    veryHiddenFields.push('only')
  } else {
    veryHiddenFields.push('including')
    veryHiddenFields.push('excluding')
  }

  if (fieldValues.programStructure === 'Guaranteed Cost') {
    veryHiddenFields.push('retentionAmount')
  }

  if (
    fieldValues.coverageBasis && fieldValues.coverageBasis !== 'Claims Made'
  ) {
    if (!veryHiddenFields.includes(FieldTypes.policyRetroDate)) {
      veryHiddenFields.push(FieldTypes.policyRetroDate)
      veryHiddenFields.push('ReinsuranceRetroDate')
      veryHiddenFields.push(FieldTypes.policyRetroComment)
    }
  } else if (
    fieldValues.coverageBasis && fieldValues.coverageBasis === 'Claims Made'
  ) {
    if (veryHiddenFields.includes(FieldTypes.policyRetroDate)) {
      veryHiddenFields = veryHiddenFields.filter(
        (e: string) =>
          e !== FieldTypes.policyRetroDate && e !== 'ReinsuranceRetroDate' && e !== FieldTypes.policyRetroComment
      )
    }
  }
  return veryHiddenFields
}
