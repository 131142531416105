export interface Size {
  w: number
  h: number
}

export interface Coord {
  x: number
  y: number
}

export type Rect = Coord & Size

export interface ImageInfo extends Size {
  src: string
}

export const BOX_IMG = { src: 'assets/facultative/lockton.png', w: 404, h: 200 }
export const NATHAN_SIG = { src: 'assets/signatures/Nathan-Taylor-signature.png', w: 404, h: 200 }
export const CQF_SIG = { src: 'assets/signatures/Christine-Fejzic-signature.png', w: 404, h: 200 }
export const DAN_SIG = { src: 'assets/signatures/Daniel-Shea-signature.png', w: 404, h: 200 }
export const PATRICK_SIG = { src: 'assets/signatures/Patrick-Cullen-signature.png', w: 404, h: 200 }
export const REECE_SIG = { src: 'assets/signatures/Reece-Ryals-signature.png', w: 404, h: 200 }
export const ROB_SIG = { src: 'assets/signatures/Rob-McNamara-signature.png', w: 404, h: 200 }
export const WOOD_SIG = { src: 'assets/signatures/Sterrett-Woods-signature.png', w: 404, h: 200 }
export const DRM_SIG = { src: 'assets/signatures/Dennis-Marcickiewicz-signature.png', w: 404, h: 200 }
export const GULIK_SIG = { src: 'assets/signatures/James-Gulick-signature.png', w: 404, h: 200 }
export const CORRIGAN_SIG = { src: 'assets/signatures/Dan-Corrigan-signature.png', w: 404, h: 200 }
export const CHIRAKOS_SIG = { src: 'assets/signatures/Lichelle-Chirakos-signature.png', w: 404, h: 200 }
export const GEORGE_SIG = { src: 'assets/signatures/Olivia-George-signature.png', w: 404, h: 200 }
export const FIRLOTTE_SIG = { src: 'assets/signatures/Colleen-Firlotte-signature.png', w: 404, h: 200 }
export const WADMAN_SIG = { src: 'assets/signatures/Craig-Wadman-signature.png', w: 404, h: 200 }
export const SALZSIEDER_SIG = { src: 'assets/signatures/Jacob-Salzsieder-signature.png', w: 404, h: 200 }
export const ROMAGNOLI_SIG = { src: 'assets/signatures/Christina-romagnoli-signature.png', w: 404, h: 200 }
export const MAGUIRE_SIG = { src: 'assets/signatures/Pat-Maguire-signature.png', w: 404, h: 200 }
export const CUNNINGHAM_SIG = { src: 'assets/signatures/Gregg-Cunningham-signature.png', w: 404, h: 200 }
export const SEANMITCHELL_SIG = { src: 'assets/signatures/Sean-Mitchell-signature.png', w: 404, h: 200 }
export const MICHAELPOPE_SIG = { src: 'assets/signatures/Michael-Pope-signature.png', w: 404, h: 200 }
export const HANNAHBOND_SIG = { src: 'assets/signatures/Hannah-Bond-signature.png', w: 404, h: 200 }
export const BRIGIDFREYER_SIG = { src: 'assets/signatures/Brigid-Freyer-signature.png', w: 404, h: 200 }
export const FLETCHERJAMES_SIG = { src: 'assets/signatures/Fletcher-James-signature.png', w: 404, h: 200 }
export const AARONAUGUSTYNIAK_SIG = { src: 'assets/signatures/Aaron-Augustyniak-signature.png', w: 404, h: 200 }
export const CLAIREBARBOUR_SIG = { src: 'assets/signatures/Claire-Barbour-signature.png', w: 404, h: 200 }


export const SIGNATURE_DICT: { [name: string]: ImageInfo } = {
  'Christine Fejzic': CQF_SIG,
  'Nathan Taylor': NATHAN_SIG,
  'Daniel Shea': DAN_SIG,
  'Patrick Cullen': PATRICK_SIG,
  'Rob McNamara': ROB_SIG,
  'Reece Ryals': REECE_SIG,
  'Dennis Marcickiewicz': DRM_SIG,
  'Sterrett Woods': WOOD_SIG,
  'James Gulick': GULIK_SIG,
  'Dan Corrigan': CORRIGAN_SIG,
  'Lichelle Chirakos': CHIRAKOS_SIG,
  'Olivia George': GEORGE_SIG,
  'Colleen Firlotte': FIRLOTTE_SIG,
  'Craig Wadman': WADMAN_SIG,
  'Jacob Salzsieder': SALZSIEDER_SIG,
  'Christina Romagnoli': ROMAGNOLI_SIG,
  'Pat Maguire': MAGUIRE_SIG,
  'Gregg Cunningham': CUNNINGHAM_SIG,
  'Sean Mitchell': SEANMITCHELL_SIG,
  'Michael Pope': MICHAELPOPE_SIG,
  'Hannah Bond': HANNAHBOND_SIG,
  'Brigid Freyer': BRIGIDFREYER_SIG,
  'Fletcher James': FLETCHERJAMES_SIG,
  'Aaron Augustyniak': AARONAUGUSTYNIAK_SIG,
  'Claire Barbour': CLAIREBARBOUR_SIG
}
