import { FacDataVersion, Facultative } from '../models'

export function  getFacDataVersionId(
    currentDeal: Facultative,
    versionId: number
  ): FacDataVersion {
    return (
      currentDeal.facDataVersions.find(v => v.id === versionId) ??
      ({} as FacDataVersion)
    )
  }
