export function getVehicleTypeDataTemplate(): VehicleTypeModel[] {
  return [
    {
      'Vehicle Type': 'Private Passenger',
      Local: '',
      Intermediate: '',
      'Long Distance': '',
      Total: '',
    },
    {
      'Vehicle Type': 'Light Truck',
      Local: '',
      Intermediate: '',
      'Long Distance': '',
      Total: '',
    },
    {
      'Vehicle Type': 'Medium Truck',
      Local: '',
      Intermediate: '',
      'Long Distance': '',
      Total: '',
    },
    {
      'Vehicle Type': 'Heavy Truck',
      Local: '',
      Intermediate: '',
      'Long Distance': '',
      Total: '',
    },
    {
      'Vehicle Type': 'Extra Heavy Truck',
      Local: '',
      Intermediate: '',
      'Long Distance': '',
      Total: '',
    },
    {
      'Vehicle Type': 'Extra Heavy Tractor Trailer',
      Local: '',
      Intermediate: '',
      'Long Distance': '',
      Total: '',
    },
    {
      'Vehicle Type': 'Vans 1-8',
      Local: '',
      Intermediate: '',
      'Long Distance': '',
      Total: '',
    },
    {
      'Vehicle Type': 'Vans 9-15',
      Local: '',
      Intermediate: '',
      'Long Distance': '',
      Total: '',
    },
    {
      'Vehicle Type': 'Small School Bus',
      Local: '',
      Intermediate: '',
      'Long Distance': '',
      Total: '',
    },
    {
      'Vehicle Type': 'Large School Bus',
      Local: '',
      Intermediate: '',
      'Long Distance': '',
      Total: '',
    },
    {
      'Vehicle Type': 'Small Coach / Charter',
      Local: '',
      Intermediate: '',
      'Long Distance': '',
      Total: '',
    },
    {
      'Vehicle Type': 'Large Coach / Charter',
      Local: '',
      Intermediate: '',
      'Long Distance': '',
      Total: '',
    },
    {
      'Vehicle Type': 'Rideshare',
      Local: '',
      Intermediate: '',
      'Long Distance': '',
      Total: '',
    },
    {
      'Vehicle Type': 'Tow Trucks',
      Local: '',
      Intermediate: '',
      'Long Distance': '',
      Total: '',
    },
    {
      'Vehicle Type': 'Yard Vehicles',
      Local: '',
      Intermediate: '',
      'Long Distance': '',
      Total: '',
    },
    {
      'Vehicle Type': 'Trailers',
      Local: '',
      Intermediate: '',
      'Long Distance': '',
      Total: '',
    },
    {
      'Vehicle Type': 'Other',
      Local: '',
      Intermediate: '',
      'Long Distance': '',
      Total: '',
    },
    {
      'Vehicle Type': 'Total',
      Local: '',
      Intermediate: '',
      'Long Distance': '',
      Total: '',
    },
  ]
}

export interface VehicleTypeModel {
  'Vehicle Type': string
  Local: string
  Intermediate: string
  'Long Distance': string
  Total: string
}

export interface VehicleModel {
  Year?: string
  Years?: string
  Claims: string
  'Total Incurred': string
  'Total Paid': string
  'Val Date': string
}

export const VehicleTypeArray: string[] = ['Vehicle Type', 'Total', 'action']

export const AutoLiabilityCols: string[] = [
  'Vehicle Type',
  'Local',
  'Intermediate',
  'Long Distance',
  'Total',
  'action',
]

export const AutoLiabilityColTypes: string[] = [
  'string',
  'number',
  'number',
  'number',
  'total',
]
