import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { BrowserUtils } from '@azure/msal-browser'
import { RoleGuard } from '@core/guards/role-guard.service'
import { UnauthorizedComponent } from '@auth/components'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'deals',
  },
  {
    path: 'deals',
    loadChildren: () => import('@deals/fac-deals.module').then(m => m.FacDealsModule),
    canActivate: [RoleGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('src/app/dashboard/dashboard.module').then(m => m.FacDashboardModule),
    canActivate: [RoleGuard]
  },  
  {
    path: 'admin',
    loadChildren: () => import('@admin/fac-admin.module').then(m => m.FacAdminModule),
    canActivate: [RoleGuard],
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    // Don't perform initial navigation in iframes or popups
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
