import { FacDefaults } from '@deals/models'
import { getTravelersSubmissionDefaultConditions, isTravelersPropertyDeal } from '@deals/utils/getTravelersSpecificInformation'

export function defaultReinsuranceCertificate(reinsured: string, reinsuranceCertificate: string, dealType: string): string {
  if (reinsuranceCertificate && isPalms(reinsured) && dealType === 'property') {
    reinsuranceCertificate = 'Palms Master Certificate'
  }
  return reinsuranceCertificate
}

function isEmptyOrblank(reinsuranceConditions: string | string[]): boolean {
  return (reinsuranceConditions?.length === 0 || reinsuranceConditions === '' || typeof reinsuranceConditions === 'undefined' || reinsuranceConditions === null)
}

export function defaultReinsuranceConditions(reinsured: string, reinsuranceConditions: string | string[], dealType: string): string[] {
  const conditionsEmpty = isEmptyOrblank(reinsuranceConditions)
  if (conditionsEmpty && isTravelersPropertyDeal(reinsured, dealType)) {
    reinsuranceConditions = getTravelersSubmissionDefaultConditions()
  }
  return reinsuranceConditions as string[]
}

export function defaultStreetAddress(reinsured: string, streetAddress: string, dealType: string): string {
  if ((streetAddress === '' || typeof streetAddress === 'undefined' || streetAddress === null) && isTravelers(reinsured) && dealType === 'property') {
    streetAddress = FacDefaults.TravelersStreetAddress
  }
  return streetAddress
}
export function defaultCancellationClause(reinsured: string, cancellationClause: string, dealType: string): string {
  if (cancellationClause && isPalms(reinsured) && dealType === 'property') {
    cancellationClause = 'As per Palms General Conditions'
  }
  return cancellationClause
}

export function isPalms(reinsured: string): boolean {
  return ['Palms Insurance Company, Limited', 'Palms Specialty Insurance Company, Inc.'].includes(reinsured)
}

export function isTravelers(reinsured: string): boolean {
  return [
    'The Charter Oak Fire Insurance Company',
    'The Phoenix Insurance Company',
    'Travelers Excess and Surplus Lines Company',
    'The Travelers Indemnity Company',
    'The Travelers Indemnity Company of America',
    'The Travelers Lloyds Insurance Company',
    'Travelers Property Casualty Company of America'
  ].includes(reinsured)
}
