import { Action, createReducer, on } from '@ngrx/store'
import * as fromPermissionsActions from './permissions.actions'
import { initialUserPermissionsState, UserPermissionsState } from './permissions.state'

const permissionsReducer = createReducer(
  initialUserPermissionsState,
  on(fromPermissionsActions.getUserPermissions, (state: UserPermissionsState) => ({
    ...state,
    errorMessage: null,
    isLoading: true,
  })),

  on(fromPermissionsActions.getUserPermissionsSuccess, (state, { response }) => ({
    ...state,
    authorizedUser: response.authorizedUser,
    permissions: [...response.permissions],
    sageLogicalRole: response.sageLogicalRole,
    errorMessage: null,
    isLoading: false
  })),

  on(fromPermissionsActions.getUserPermissionsFail, (state, { errorMessage}) => ({
    ...state,
    authorizedUser: null,
    permissions: null,
    sageLogicalRole: null,
    errorMessage,
    isLoading: false
  })),
)

export function reducer(state: UserPermissionsState | undefined, action: Action): UserPermissionsState {
  return permissionsReducer(state, action)
}
