export interface Validator {
  name: string
  validator: any
  message: string
}

export interface FieldKey {
  name: string
  type: string | undefined
}

export interface FieldInterface {
  name: string
  type?: string
  fields: FieldConfig[][]
}

export interface FieldOption {
  id?: string
  label: string
}

export interface FieldConfig {
  label?: string
  id: string
  readonly?: string
  options?: string[] | FieldOption[]
  endpoint?: string
  inputType?: string
  collections?: any
  type: string
  value?: any
  validations?: Validator[]
  name?: string
  case?: string
  source?: string
  fieldInterface?: any
  showCurrency?: boolean
  hiddenToggle?: boolean
  hiddenToggleValue?: boolean
  bullets?: boolean
  hideSelector?: boolean
  defaultValue?: string
  casLabel?: string
  autocomplete?: boolean
}

export const ID_SUFFIX = '--ID'
