import { convertFromSINumber } from '../../formatters'
import { REGEX_NON_NUMERIC, REGEX_DUPE_DECIMALS } from '@constants'

export function getPremiumRIString(
  quotedPrice: string | number,
  cession: any,
  value?: string | number,
  ceding?: string
): number {
  let q = 0
  let v = 0
  if (value) {
    v = parseFloat(
      convertFromSINumber(
        value
          .toString()
          .replace(REGEX_NON_NUMERIC, '')
          .replace(REGEX_DUPE_DECIMALS, '$1')
      )
    )
  }
  if (quotedPrice) {
    q = parseFloat(
      convertFromSINumber(
        quotedPrice
          .toString()
          .replace(REGEX_NON_NUMERIC, '')
          .replace(REGEX_DUPE_DECIMALS, '$1')
      )
    )
  }
  let cessionFormatted = 0
  if (cession) {
    cessionFormatted = parseFloat(
      cession
        .toString()
        .replace(REGEX_NON_NUMERIC, '')
        .replace(REGEX_DUPE_DECIMALS, '$1')
    )
  }
  let n: number
  if (v !== 0) {
    n = ((q * 100) / v) * (cessionFormatted / 100)
  } else {
    n = q * (cessionFormatted / 100)
  }

  if (ceding) {
    ceding = ceding.replace('%', '')
    n = n * (1 - parseFloat(ceding) / 100)
  }

  return isNaN(n) ? 0 : n
}
