// This file will replace `environment.ts` using the command:
//    `ng build --configuration=development`
//
// The list of file replacements are found in `angular.json`,
// and the build commands are found in `package.json`.

import { mergeDeepRight } from 'ramda'
import { commonEnvironment } from './environment-common'

const dev = {
  auth: {
    clientId: '18bc9895-e6ba-4e6a-8535-4cf242952d79',
    authority: 'https://locktonglobalredev.b2clogin.com/locktonglobalredev.onmicrosoft.com/b2c_1_signupsignin_federated_lockton_v1',
    knownAuthorities: ['locktonglobalredev.b2clogin.com'],
  },
  internalApi: {
    base: 'https://dev.sage.lockton.com/api',
  },
}

export const environment = mergeDeepRight(commonEnvironment, dev)

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`,
 * `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a
 * negative impact on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error' // Included with Angular CLI.
