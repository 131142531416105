import * as dateFns from 'date-fns'

export function addYears(date: Date, numberOfYears: number): Date {
  return dateFns.addYears(date, numberOfYears)
}

export function differenceInCalendarDays(
  dateLeft: Date | number,
  dateRight: Date | number
): number {
  return dateFns.differenceInCalendarDays(dateLeft, dateRight)
}

export function isExactCalendarYear(
  start: string | Date | number | undefined,
  end: string | Date | number| undefined
): boolean {
  if (!start || !end) {
    return false
  }

  const startDate = toDate(start)
  const endDate = toDate(end)
  if (!isValidDate(startDate) || !isValidDate(endDate)) {
    return false
  }

  const startDatePlusYear = addYears(startDate, 1)
  const daysDiff = Math.abs(differenceInCalendarDays(endDate, startDatePlusYear))
  return daysDiff === 0
}

export function parseDate(dateStr: string, formatStr: string): Date {
  return dateFns.parse(dateStr, formatStr, new Date())
}

export function parseISODateOnly(dateStr: string): Date | null {
  if (dateStr === undefined || dateStr === null) {
    return null
  }
  const dt: Date = dateFns.parse(dateStr, 'yyyy-MM-dd', new Date())
  return isValidDate(dt) ? dt : null
}

export function parseISODate(value: string): Date {
  return dateFns.parseISO(value)
}

export function isValidDate(value: any): boolean {
  return dateFns.isValid(value)
}

export function isDate(value: any): boolean {
  return dateFns.isDate(value)
}

export function canBeDate(it: any): boolean {
  if (dateFns.isDate(it)) {
    return true
  }
  return dateFns.isValid(new Date(it))
}

export function formatISODateOnly(value: Date): string {
  return dateFns.formatISO(value, { representation: 'date' })
}

export function formatDateTime(value: Date | string, pattern: string): string {
  if (!value) {
    return ''
  }
  const dateFnsInput: Date = (typeof value === 'string') ? parseISODate(value) : value
  return dateFns.format(dateFnsInput, pattern)
}

export function startOfDay(value: Date): Date {
  return dateFns.startOfDay(value)
}

export function toDate(value: Date | number | string): Date {
  return typeof value === 'string' ? parseISODate(value) : new Date(value)
}
