// TODO: Use in fac-form::convertSmartNumber
export function convertFromSINumber(value: string): string {
  let response = value
  if (value && value.trim().length > 0) {
    const parsedFloat = parseFloat(value.slice(0, -1))
    if (value.endsWith('M') || value.endsWith('m')) {
      response = (parsedFloat * 1000000).toString()
    } else if (value.endsWith('B') || value.endsWith('b')) {
      response = (parsedFloat * 1000000000).toString()
    } else if (value.endsWith('T') || value.endsWith('t')) {
      response = (parsedFloat * 1000000000000).toString()
    }
  }
  return response
}
