import { NgModule } from '@angular/core'
import { FacAppLogoComponent } from './fac-app-logo.component'
import { CommonModule } from '@angular/common'
import { MaterialModule } from '../shared/material.module'
import { FacHeaderComponent } from './fac-header/fac-header.component'
import { UserCardComponent } from "./user-card.component";

@NgModule({
  declarations: [
    FacAppLogoComponent,
    FacHeaderComponent,
    UserCardComponent
  ],
  exports: [
    FacAppLogoComponent,
    FacHeaderComponent
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  providers: [
  ],
})
export class LayoutModule { }
