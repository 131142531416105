const ALLOWED_CURRENCY_VALUES = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '.',
  'M',
  'B',
  'T',
]

export function validateCurrencyInputKey(key: string, currentValue: string) {
  if (key === '.') {
    // Only allow '.' if value doesn't already contain a decimal
    return !currentValue.match(/\./g)?.length;
  }
  return ALLOWED_CURRENCY_VALUES.includes(key.toUpperCase());
}
