import { Action, ActionReducer, createReducer, on } from '@ngrx/store'
import {
  CertificateFields,
  CoverageReinsured,
  FacData,
  FacDataVersion,
  Facultative,
  FormLayerResponse, ID_SUFFIX, ReinsuredLayer,
  VehicleModel,
} from '../models'
import { updateFacDeal } from '../utils/updateFacDeal'
import { formatPercentageOrNil } from '../formatters/formatPercentageOrNil'
import { stripNumberFormat } from '../formatters'
import { IFacultativeState, initialFacSearchCriteria, initialFacultativeState } from './facultative.state'
import * as facultativeActions from './facultative.actions'
import { defaultCancellationClause, defaultReinsuranceCertificate, defaultReinsuranceConditions, defaultStreetAddress, isPalms } from '@deals/utils/getDefaultReinsuranceCertificate'


function setAddReinsuranceConditions(field: any, coverageReinsured: string, isProperty: boolean): any {
  const isUmbrella = (coverageReinsured === CoverageReinsured.auto || coverageReinsured === CoverageReinsured.general || coverageReinsured === CoverageReinsured.umbrella || coverageReinsured === CoverageReinsured.umbrellaGeneral || coverageReinsured === CoverageReinsured.umbrellaAuto)
  return !field || field === '' || (field && field.length === 0) ? (isUmbrella || isProperty ? 'N/A' : '') : field
}

function setIfBlank(isQtc: boolean, versionField: any, headerField: any) {
  let fieldIsValid = !!versionField
  if (typeof versionField === 'object') {
    fieldIsValid = versionField?.length > 0
  } else if (typeof versionField === 'string') {
    fieldIsValid = versionField !== '' && versionField !== '[]' && versionField !== '{}'
  }
  return isQtc && fieldIsValid ? versionField : headerField
}

function extractFieldValues(data: Facultative, currentVersion?: FacDataVersion) {
  let fieldValues = ''
  const currentFacData = JSON.parse(JSON.stringify(data))
  const formType = currentFacData.facData.type ?? ''
  const isProperty = formType === 'property'
  const isCasualty = formType === 'casualty'
  const isQtc = currentVersion?.category === 'qtc'
  const defaultCurrency = 'USD'
  const defaultPolicyType = 'Excess'
  if (currentVersion) {
    const versionFacData = JSON.parse(currentVersion?.fieldValues ?? '')

    if (currentVersion.category !== 'submission') {
      let submissionVersion = currentFacData.facDataVersions?.find((v: any) => v.category === 'submission' && v.final === true)
      if (!submissionVersion) {
        submissionVersion = currentFacData.facDataVersions?.find((v: any) => v.category === 'submission')
      }
      if (submissionVersion && submissionVersion.fieldValues) {
        const submissionFields = JSON.parse(submissionVersion.fieldValues)
        if (submissionFields.coverageExpression) {
          versionFacData.coverageExpression = submissionFields.coverageExpression
        }
        if (submissionFields.reinsuredLayers) {
          versionFacData.reinsuredLayers = submissionFields.reinsuredLayers
        }
        if (currentVersion.category === 'responses') {
          versionFacData.programStructure = submissionFields.programStructure
        }
      }
    }

    if (isProperty) {
      if (!versionFacData.termsAndConditions && isPalms(versionFacData.reinsured)) {
        versionFacData.termsAndConditions = defaultTermsAndCondition(currentFacData.facData.type, versionFacData.reinsured, currentVersion.formName)
      }
      versionFacData.reinsuranceConditions = defaultReinsuranceConditions(versionFacData.reinsured, versionFacData.reinsuranceConditions, currentFacData.facData.type)
      versionFacData.reinsuranceCertificate = defaultReinsuranceCertificate(versionFacData.reinsured, versionFacData.reinsuranceCertificate, currentFacData.facData.type)
      versionFacData.cancellationClause = defaultCancellationClause(versionFacData.reinsured, versionFacData.cancellationClause, currentFacData.facData.type)
    }

    if (currentVersion.category === 'binder' || currentVersion.category === 'certificate') {
      const responsesVersion = currentFacData.facDataVersions.find((v: any) => v.category === 'responses')
      if (responsesVersion && responsesVersion.fieldValues) {
        const responsesFields = JSON.parse(responsesVersion.fieldValues ?? '{}')
        if (responsesFields && responsesFields.marketResponses && responsesFields.marketResponses !== '' && typeof responsesFields.marketResponses === 'string' && responsesFields.marketResponses.includes('[')) {
          let marketResponses = JSON.parse(responsesFields.marketResponses ?? '[]') as FormLayerResponse[]
          const reinsuranceLayers = JSON.parse(versionFacData.reinsuredLayers ?? '[]') as ReinsuredLayer[]
          if (marketResponses) {
            if (versionFacData.reinsurerParticipation && typeof versionFacData.reinsurerParticipation === 'string' && versionFacData.reinsurerParticipation !== '') {
              versionFacData.reinsurerParticipation = versionFacData.reinsurerParticipation.split(/\n/).sort().join('\n')
            }
            if (reinsuranceLayers && reinsuranceLayers.length > 0) {
              const layerIds = reinsuranceLayers.map((r: ReinsuredLayer) => r.id)
              marketResponses = marketResponses.filter(m => layerIds.includes(m.Layer.id))
            }
            const market = marketResponses.filter(m => m.reinsurer === currentVersion.formName && m.Bound)
            const otherMarkets = marketResponses.filter(m => m.reinsurer !== currentVersion.formName && m.Bound)
            versionFacData.marketResponses = JSON.stringify(marketResponses)
            if (otherMarkets.length > 0) {
              const otherFacultativeObj: { [k: string]: string[] } = {}
              let otherFacultative: any[] = []
              if (otherMarkets[0].Layer.index) {
                const maxIndex = otherMarkets.length - 1
                otherMarkets.forEach(m => {
                  if (!otherFacultativeObj[m.Layer.index || maxIndex]) {
                    otherFacultativeObj[m.Layer.index || maxIndex] = []
                  }
                  otherFacultativeObj[m.Layer.index || maxIndex].push(`${m.reinsurer} ${m.percentageShare} ${m.Capacity}`)
                })
                otherFacultative = Object.keys(otherFacultativeObj).map(k => {
                  const key = parseInt(k, 10) || maxIndex
                  const layerIndex = ` ${String.fromCharCode(key + 96).toLocaleUpperCase()}`
                  return `<span class="bolder">Layer${layerIndex}:</span> <span>${otherFacultativeObj[key].join(', ')}</span>`
                })
              } else {
                otherMarkets.forEach(m => {
                  if (!otherFacultativeObj[m.Layer.id]) {
                    otherFacultativeObj[m.Layer.id] = []
                  }
                  otherFacultativeObj[m.Layer.id].push(`${m.reinsurer} ${m.percentageShare} ${m.Capacity}`)
                })
                otherFacultative = Object.keys(otherFacultativeObj).map((k, i) => {
                  const layerIndex = otherMarkets.length === 1 ? '' : ` ${String.fromCharCode((i + 1) + 96).toLocaleUpperCase()}`
                  return `<span class="bolder">Layer${layerIndex}:</span> <span>${otherFacultativeObj[k].join(', ')}</span>`
                })
              }
              versionFacData.otherFacultative = JSON.stringify(otherFacultative)
            } else {
              versionFacData.otherFacultative = JSON.stringify([])
            }
            if (market.length > 0 && market[0]) {
              const certFields = JSON.parse(versionFacData.certificateFields ?? '[]') as CertificateFields[]
              const hasCertFields = certFields && certFields.length > 0
              if (market[0].brokerageCommission) {
                versionFacData.brokerageCommissionSub = market[0].brokerageCommission
              }
              if (market[0].cedingCommission) {
                versionFacData.cedingCommission = formatPercentageOrNil(stripNumberFormat(market[0].cedingCommission))
              }
              if (market[0].policyNumber && hasCertFields) {
                const policyField = certFields.find(c => c.name === 'policyNumber')
                const otherCertFields = certFields.filter(c => c.name !== 'policyNumber')
                if (policyField) {
                  const newPolicy = {...policyField, value: market[0].policyNumber}
                  const newCertFields = [...otherCertFields, newPolicy] as CertificateFields[]
                  versionFacData.certificateFields = JSON.stringify(newCertFields)
                }
              }
            }
          }
        }
      }
      if (versionFacData.reinsurerParticipation && typeof versionFacData.reinsurerParticipation === 'string' && versionFacData.reinsurerParticipation !== '') {
        versionFacData.reinsurerParticipation = versionFacData.reinsurerParticipation.split(/\n/).sort().join('\n')
      }
    }

    if (isCasualty && (currentVersion.category === 'binder' || currentVersion.category === 'certificate')) {
      const responsesVersion = currentFacData.facDataVersions.find((v: any) => v.category === 'responses')
      if (responsesVersion && responsesVersion.fieldValues) {
        const responsesFields = JSON.parse(responsesVersion.fieldValues ?? '{}')
        if (responsesFields && responsesFields.marketResponses && responsesFields.marketResponses !== '' && typeof responsesFields.marketResponses === 'string' && responsesFields.marketResponses.includes('[')) {
          const marketResponses = JSON.parse(responsesFields.marketResponses ?? '[]') as FormLayerResponse[]
          const marketResponse = marketResponses.find(m => !!m.policyNumber)
          if (marketResponse && marketResponse.policyNumber) {
            const certFields = JSON.parse(versionFacData.certificateFields ?? '[]') as CertificateFields[]
            const policyField = certFields?.find(c => c.name === 'policyNumber')
            policyField.value = marketResponse.policyNumber
            versionFacData.certificateFields = JSON.stringify(certFields)
            versionFacData.policyNumber = marketResponse.policyNumber
          }
        }
      }
    }

    if (!versionFacData.premiumType && (!!versionFacData.premiumTypecas || !!versionFacData.premiumTypepro)) {
      versionFacData.premiumType = isProperty && versionFacData.premiumTypepro ? versionFacData.premiumTypepro : versionFacData.premiumTypecas
    }
    const certNo = JSON.parse(versionFacData.certificateFields ?? '[]').find(
      (e: CertificateFields) => e.name === 'reinsuranceCertificateNo'
    )
      ? JSON.parse(versionFacData.certificateFields ?? '[]').find(
        (e: CertificateFields) => e.name === 'reinsuranceCertificateNo'
      ).value
      : ''
    versionFacData.reinsuredUnderwriter = currentFacData.facData.reinsuredUnderwriter
    versionFacData['reinsuredUnderwriter' + ID_SUFFIX] = currentFacData.facData['reinsuredUnderwriter' + ID_SUFFIX]
    versionFacData.reinsured = currentFacData.facData.reinsured
    versionFacData['reinsured' + ID_SUFFIX] = currentFacData.facData['reinsured' + ID_SUFFIX]
    versionFacData.name = currentFacData.facData.name
    versionFacData.inceptionDate = currentFacData.facData.inceptionDate
    versionFacData.nameAndAddress = currentFacData.facData.nameAndAddress
    if (currentVersion.category !== 'binder' && currentVersion.category !== 'certificate') {
      versionFacData.policyNumber = versionFacData.policyNumber ? versionFacData.policyNumber : currentFacData.facData.policyNumber
    }
    versionFacData.reinsuranceCertificateNo = versionFacData.reinsured?.includes('Travelers') ? certNo : currentFacData.facData.reinsuranceCertificateNo
    // im so sorry
    const binderOrCert = currentVersion.category === 'binder' || currentVersion.category === 'certificate'
    const diffTypeOfInsurance = versionFacData.typeOfInsurance !== currentFacData.facData.typeOfInsurance
    if (!(binderOrCert && diffTypeOfInsurance)) {
      versionFacData.typeOfInsurance = currentFacData.facData.typeOfInsurance
    }
    const diffIncluding = versionFacData.including !== currentFacData.facData.perilsIncluding
    if (!(binderOrCert && diffIncluding)) {
      versionFacData.including = setIfBlank(isQtc, versionFacData.including, currentFacData.facData.perilsIncluding)
    }
    const diffExcluding = versionFacData.excluding !== currentFacData.facData.perilsExcluding
    if (!(binderOrCert && diffExcluding)) {
      versionFacData.excluding = setIfBlank(isQtc, versionFacData.excluding, currentFacData.facData.perilsExcluding)
    }
    const diffOnly = versionFacData.only !== currentFacData.facData.perilsOnly
    if (!(binderOrCert && diffOnly)) {
      versionFacData.only = currentFacData.facData.perilsOnly
    }
    if (isProperty && binderOrCert) {
      versionFacData.streetAddress = defaultStreetAddress(versionFacData.reinsured, versionFacData.streetAddress, currentFacData.facData.type)
    } else {
      versionFacData.streetAddress = currentFacData.facData.streetAddress
    }
    versionFacData.cityName = currentFacData.facData.cityName
    versionFacData.stateName = currentFacData.facData.stateName
    versionFacData.zipCode = currentFacData.facData.zipCode

    if (isQtc) {
      const submissionVersion = currentFacData.facDataVersions?.find((v: FacDataVersion) => v.category === 'submission' && v.final) as FacDataVersion
      const submissionFields = JSON.parse(submissionVersion?.fieldValues ?? '{}')
      if (submissionVersion && submissionFields) {
        versionFacData.policyLimit = setIfBlank(isQtc, versionFacData.policyLimit, submissionFields.policyLimit)
        versionFacData.reinsuredLayers = setIfBlank(isQtc, versionFacData.reinsuredLayers, submissionFields.reinsuredLayers)
        versionFacData.overrideReinsuredLayers = setIfBlank(isQtc, versionFacData.overrideReinsuredLayers, submissionFields.overrideReinsuredLayers)
        versionFacData.limits = setIfBlank(isQtc, versionFacData.limits, submissionFields.limits)
        versionFacData.basisOfAcceptance = setIfBlank(isQtc, versionFacData.basisOfAcceptance, submissionFields.basisOfAcceptance)
        versionFacData.termsAndConditions = setIfBlank(isQtc, versionFacData.termsAndConditions, submissionFields.termsAndConditions)
      }

      const responsesVersion = currentFacData.facDataVersions.find((v: FacDataVersion) => v.category === 'responses') as FacDataVersion
      const responsesFields = JSON.parse(responsesVersion?.fieldValues ?? '{}')
      const marketResponses = typeof responsesFields?.marketResponses === 'object' ? responsesFields?.marketResponses : JSON.parse(responsesFields?.marketResponses ?? '[]') as FormLayerResponse[]
      if (responsesVersion && responsesFields && marketResponses && marketResponses.length > 0) {
        const layerA = marketResponses[0]
        const cedingCommission = layerA.cedingCommission ?? versionFacData.cedingCommission
        const brokerageCommission = layerA.brokerageCommission ?? versionFacData.brokerageCommissionSub
        versionFacData.cedingCommission = setIfBlank(isQtc, versionFacData.cedingCommission, cedingCommission)
        versionFacData.brokerageCommissionSub = setIfBlank(isQtc, versionFacData.brokerageCommissionSub, brokerageCommission)
      }
    }

    versionFacData.perilsOnly = currentFacData.facData.perilsOnly
    versionFacData.policyPeriodFrom =
      currentFacData.facData.policyPeriodFrom
    versionFacData.policyPeriodTo =
      currentFacData.facData.policyPeriodTo
    versionFacData.reinsurancePeriodFrom =
      currentFacData.facData.reinsurancePeriodFrom
    versionFacData.reinsurancePeriodTo =
      currentFacData.facData.reinsurancePeriodTo
    // versionFacData.otherFacultative =
    //   currentFacData.facData.otherFacultative ?? versionFacData.otherFacultative
    if (versionFacData.quoteNeededBy) {
      if (versionFacData.quoteNeededBy.split('T').length > 1) {
        versionFacData.quoteNeededBy = new Date(
          versionFacData.quoteNeededBy.replace(/-/g, '/').split('T')[0]
        )
      } else {
        versionFacData.quoteNeededBy = new Date(
          versionFacData.quoteNeededBy.replace(/-/g, '/')
        )
      }
    }

    if (
      versionFacData?.underwritingInformation &&
      versionFacData?.underwritingInformation !== ''
    ) {
      const underwritingInformation = JSON.parse(
        versionFacData?.underwritingInformation
      )
      const lossHistory = underwritingInformation?.generalLiabilityLossHistory
      if (
        lossHistory &&
        typeof lossHistory === 'object' &&
        lossHistory?.length > 0
      ) {
        underwritingInformation.generalLiabilityLossHistory = lossHistory.map(
          (item: VehicleModel) => {
            if (item.Year) {
              item.Years = item.Year
              delete item.Year
            }
            return item
          }
        )
        versionFacData.underwritingInformation = JSON.stringify(
          underwritingInformation
        )
      }
    }

    if (versionFacData.brokerComments) {
      versionFacData.brokerComments =
        versionFacData.brokerComments
          .replace(/\n\s+(<[^>]*?>)/g, '$1')
          .replace(/\n(\s)?/g, '')
          .replace(/<br>/g, '')
    } else {
      versionFacData.brokerComments = ''
    }

    if (typeof versionFacData.reinsuranceConditions === 'string') {
      versionFacData.reinsuranceConditions =
        versionFacData.reinsuranceConditions === '' || !versionFacData.reinsuranceConditions
          ? []
          : JSON.parse(versionFacData.reinsuranceConditions)
    }

    if (versionFacData.coverageReinsured || isProperty) {
      versionFacData.additionalReinsuranceConditions = setAddReinsuranceConditions(versionFacData.additionalReinsuranceConditions, versionFacData.coverageReinsured, isProperty)
    }

    if (typeof versionFacData.reinsuredQuotes === 'string') {
      versionFacData.reinsuredQuotes =
        versionFacData.reinsuredQuotes === ''
          ? '[]'
          : versionFacData.reinsuredQuotes
    }

    if (typeof versionFacData.excluding === 'string') {
      versionFacData.excluding =
        versionFacData.excluding === ''
          ? []
          : JSON.parse(versionFacData.excluding)
    }

    if (typeof versionFacData.including === 'string') {
      versionFacData.including =
        versionFacData.including === ''
          ? []
          : JSON.parse(versionFacData.including)
    }

    if (isProperty && typeof versionFacData.only === 'string') {
      versionFacData.only = versionFacData.only === '' ? [] :
        versionFacData.only.includes('[') ? JSON.parse(versionFacData.only) : [versionFacData.only]
    }

    if (typeof versionFacData.currency === 'string' && versionFacData.currency === '') {
      versionFacData.currency = defaultCurrency
    }

    if (Array.isArray(versionFacData.renewalFor)) {
      versionFacData.renewalFor = ''
    }

    fieldValues = JSON.stringify(versionFacData)
  } else if (currentFacData) {
    const defaultTC = defaultTermsAndCondition(currentFacData.facData.type, currentFacData.facData.reinsured)
    const defaultCClause = defaultCancellationClause(currentFacData.facData.reinsured, currentFacData.facData.cancellationClause, currentFacData.facData.type)
    const defaultAddress = currentFacData.facData.id === 0 ? '' : defaultStreetAddress(currentFacData.facData.reinsured, currentFacData.facData.streetAddress, currentFacData.facData.type)
    const defaultClaims = 'As per General Conditions'

    fieldValues = JSON.stringify({
      ...currentFacData.facData,
      termsAndConditions: defaultTC,
      claimsNotification: defaultClaims,
      cancellationClause: defaultCClause,
      currency: defaultCurrency,
      reinsuranceCertificate: defaultReinsuranceCertificate(currentFacData.facData.reinsured, currentFacData.facData.reinsuranceCertificate, currentFacData.facData.type),
      policyType: defaultPolicyType,
      streetAddress: defaultAddress,
    })
  }

  return fieldValues
}

function buildFormCategories(facDataVersions: FacDataVersion[], state: IFacultativeState) {
  const categoryList = facDataVersions.map(v => v.category)
  const uniqueCategories = [...new Set(categoryList)]
  const finalFormsList = facDataVersions?.filter(v => v.final).map(v => v.category)
  const uniqueFinalCategories = [...new Set(finalFormsList)]
  return state.formCategories.map(c => {
    const hasCategory = uniqueCategories.includes(c.category)
    const isFinal = uniqueFinalCategories.includes(c.category)
    return { ...c, icon: hasCategory ? (isFinal ? 'check_circle' : 'lens') : 'radio_button_unchecked' }
  })
}

function defaultTermsAndCondition(dealType: string, reinsured: string, formName?: string) {

  const gfcList = [
    'Aspen Specialty Insurance Company',
    'Axis Surplus Insurance Company',
    'Endurance America Specialty Insurance Company',
    'GuideOne National Insurance Company',
    'Harleysville Insurance Company of New York on behalf of Scottsdale Insurance Company',
    'Homeland Insurance Co of New York',
    'IAT Insurance Group',
    'Indian Harbor Insurance company (XL Paper)',
    'Markel American Insurance Company',
    'N2G Worldwide insurance Services',
    'New York Marine and General Insurance Company',
    'StarStone Specialty Insurance Company',
    'Traverse Specialty Insurance Company',
    'Trisura Specialty Insurance Company',
    'XL Insurance America, Inc',
    'Zurich American Insurance Company',
    'Intact Insurance Specialty Property',
    'Nationwide on behalf of Scottsdale Insurance Company (Paragon Specialty Property)',
    'Trisura Specialty Insurance Company via reThought Insurance Company',
    'Venbrook Intermediate Holdings, LLC',
    'James River Insurance Company',
  ]
  const gfcCasualtyList = [
    'American Senterfitt Insurance Company Ltd',
    'Arch Specialty Insurance Company',
    'AXIS Insurance Company',
    'Axis Surplus Insurance Company',
    'Capitol Specialty Insurance Corporation',
    'Freedom Specialty Insurance Company',
    'GuideOne National Insurance Company',
    'HDI Global Insurance',
    'HDI Specialty Insurance',
    'James River Insurance Company',
    'National Casualty Company',
    'Scottsdale Insurance Company',
    'The Cincinnati Specialty Underwriters Insurance Company',
    'The Travelers Indemnity Company',
    'Upland Specialty Insurance Company',
    'XL Insurance Company SE',
    'XL Insurance America, Inc',
    'Texas Insurance Company',
    'Greenwich Insurance Company',
  ]
  const aigList = [
    'American Home Assurance Company',
    'Lexington Insurance Co',
    'Lexington Specialty Insurance Agency, Inc.',
  ]
  const libertyList = [
    'Employers Insurance Co. of Wausau (Liberty Mutual paper)',
    'Liberty Mutual Insurance Company',
    'Liberty Mutual Fire Insurance Co. (Liberty Mutual)',
  ]
  const fmList = [
    'FM Global Fac Certificate Form CP2007 - 09-01-10',
    'Affiliated FM Insurance Company',
    'Factory Mutual Insurance Company',
  ]
  const cnaList = [
    'Continental Casualty Company, Inc.',
    'Continental Indemnity Company',
  ]
  const starrList = [
    'Starr Surplus Lines Insurance Company',
    'Lloyds of London Syndicate 1919 on behalf of Starr Surplus Lines',
  ]
  const chubbList = ['Ace American Insurance Company']
  const travelersList = [
    'Travelers Excess and Surplus Lines Company',
    'The Travelers Indemnity Company',
  ]
  const aigCasualtyList = [
    'AIG Insurance Company of Canada',
    'AIG Specialty Insurance Company',
    'American Home Assurance Company',
    'Insurance Company of the State of Pennsylvania (AIG paper)',
    'Lexington Insurance Co',
    'Lexington Specialty Insurance Agency, Inc.',
    'National Union Fire Insurance Company of Pittsburgh, Pa.',
  ]
  const alliedList = [
    'Allied World Assurance Company (U.S.), Inc.',
    'Allied World Assurance Company Limited',
  ]
  const chubbCasualtyList = [
    'Ace American Insurance Company',
    'ACE Property & Casualty Insurance Company',
    'Chubb Insurance Company of Canada',
    'Illinois Union Insurance Company',
    'Westchester Fire Insurance Company'
  ]
  const cnaCasualtyList = [
    'Continental Casualty Company, Inc.',
    'Continental Indemnity Company',
    'Columbia Casualty Company',
    'National Fire Insurance Company of Hartford',
    'The Continental Insurance Company',
    'Transportation Insurance Company',
    'American Casualty Company of Reading, Pennsylvania',
    'Columbia Casualty Company',
    'Valley Forge Insurance Company'
  ]
  const everestList = [
    'Everest National Insurance Company',
    'Everest Denali Insurance Company',
  ]
  const libertyCasualtyList = [
    'Liberty Mutual Insurance Company',
    'Liberty Mutual Fire Insurance Co. (Liberty Mutual)',
  ]
  const starrCasualtyList = [
    'Starr Indemnity & Liability Company',
    'Starr Specialty Lines Insurance Company',
    'Starr Surplus Lines Insurance Company',
  ]
  const sentryCompanyList = [
    'Florists\' Mutual Insurance Company',
    'Sentry Insurance Company',
  ]
  let defaultValue = ''
  const currentFormType = dealType

  if (
    gfcList.includes(
      reinsured ?? ''
    ) ||
    (gfcCasualtyList.includes(
      reinsured ?? ''
    ) &&
      currentFormType === 'casualty')
  ) {
    defaultValue = 'Lockton Re LLC Certificate Form LP1002 - November 2022'
  } else if (
    (aigList.includes(
      reinsured ?? ''
    ) ||
      (aigCasualtyList.includes(
        reinsured ?? ''
      ))) &&
    currentFormType === 'casualty'
  ) {
    defaultValue = 'AIG U.S. Casualty Certificate (04/16)'
  } else if (
    (aigList.includes(
      reinsured ?? ''
    )) &&
    currentFormType === 'property'
  ) {
    defaultValue = 'AIG U.S. Property Certificate (04/16)'
  } else if (
    libertyList.includes(
      reinsured ?? ''
    ) ||
    (libertyCasualtyList.includes(
      reinsured ?? ''
    ) &&
      currentFormType === 'casualty')
  ) {
    defaultValue = 'Liberty Mutual Insurance Company Form No. LM-1000-FC(Edition 12/31/2010)'
  } else if (
    fmList.includes(reinsured ?? '')
  ) {
    defaultValue = 'FM Global Fac Certificate Form CP2007 – 09-01-10'
  } else if (
    cnaList.includes(
      reinsured ?? ''
    ) ||
    (cnaCasualtyList.includes(
      reinsured ?? ''
    ) &&
      currentFormType === 'casualty')
  ) {
    defaultValue = 'CNA General Conditions 092914'
  } else if (
    starrList.includes(
      reinsured ?? ''
    ) ||
    (starrCasualtyList.includes(
      reinsured ?? ''
    ) &&
      currentFormType === 'casualty')
  ) {
    defaultValue = 'Starr Facultative General Conditions US 01012023'
  } else if (
    chubbList.includes(
      reinsured ?? ''
    ) &&
    currentFormType === 'property'
  ) {
    defaultValue =
      'Chubb Property Reinsurance General Conditions - FAC-39670a (08/13)'
  } else if (
    chubbCasualtyList.includes(
      reinsured ?? ''
    ) &&
    currentFormType === 'casualty'
  ) {
    defaultValue = 'Chubb Casualty Reinsurance General Conditions FAC39671a 0813'
  } else if (
    travelersList.includes(
      reinsured ?? ''
    )
  ) {
    defaultValue = 'Travelers– includes policy conditions, CP-6067'
  } else if (
    alliedList.includes(
      reinsured ?? ''
    )
  ) {
    defaultValue =
      'Allied World Assurance Company General Terms & Conditions Ref: NACF00002 100 (10/17) (Edition October 2017)'
  } else if (
    everestList.includes(
      reinsured ?? ''
    )
  ) {
    if (reinsured === 'Munich Re') {
      defaultValue = 'Everest Munich Re Fac Re Cert v.11.25.2019'
    } else if (
      reinsured === 'Hannover Re'
    ) {
      defaultValue = 'Everest Insurance v.4.2.26.2021'
    } else if (reinsured === 'Swiss Re') {
      defaultValue = 'Everest Swiss Re Fac Cert'
    } else {
      defaultValue = 'Everest Insurance v.3.19.2020'
    }
  } else if (
    isPalms(reinsured) && currentFormType === 'property'
  ) {
    defaultValue = 'Palms Certificate eff. 7/1/2023'
    if (formName?.includes('National Indemnity Company') || formName?.includes('National Liability and Fire – US')) {
      defaultValue = 'Palms Certificate eff. 7/1/2023 – NI edits 4/1/24'
    }
  } else if (
    sentryCompanyList.includes(
      reinsured ?? ''
    )
  ) {
    defaultValue =
      'Lockton Re LLC Certificate Form LP1002 - November 2022 (with Wisconsin Governing Law Addendum)'
  }
  return defaultValue
}

const facultativeStateReducer: ActionReducer<IFacultativeState, Action> =
  createReducer(
    initialFacultativeState,
    on(facultativeActions.dealSummariesLoad, (state: IFacultativeState) => ({
      ...state,
      isLoading: true,
      isSaving: false,
      error: '',
      currentDeal: null as number | null,
      selectedDeal: null as Facultative | null,
      currentVersion: null as FacDataVersion | null,
      currentDealName: null as string | null,
      headerTitle: null
    })),
    on(
      facultativeActions.dealSummariesLoadSuccess,
      (state: IFacultativeState, { data }) => {
        return {
          ...state,
          isLoading: false,
          isSaving: false,
          isDirty: false,
          error: '',
          dealSummaries: data.dealSummaries,
          pagination: data.pagination,
        }
      }
    ),
    on(
      facultativeActions.dealSummariesLoadFail,
      (state: IFacultativeState, { errorMessage }) => ({
        ...state,
        isLoading: false,
        isSaving: false,
        error: errorMessage,
      })
    ),
    on(
      facultativeActions.loadAllFacUsersSuccess,
      (state: IFacultativeState, { users }) => ({
        ...state,
        allFacUsers: users,
      })
    ),
    on(
      facultativeActions.loadAllFacUsersFailure,
      (state: IFacultativeState, { errorMessage }) => ({
        ...state,
        allFacUsers: [],
        error: errorMessage,
      })
    ),
    on(
      facultativeActions.loadBrokerFilterPicklistSuccess,
      (state: IFacultativeState, { brokerPicklistItems }) => ({
        ...state,
        brokerFilterPicklist: brokerPicklistItems,
      })
    ),
    on(
      facultativeActions.loadBrokerFilterPicklistFailure,
      (state: IFacultativeState, { errorMessage }) => ({
        ...state,
        brokerFilterPicklist: [],
        error: errorMessage,
      })
    ),
    on(
      facultativeActions.getSalesforceContactsSuccess,
      (state: IFacultativeState, { salesforceContacts }) => ({
        ...state,
        salesforceContacts,
      })
    ),
    on(
      facultativeActions.setNewFormFields,
      (state: IFacultativeState, { fields }) => ({
        ...state,
        newFormFields: fields,
      })
    ),
    on(
      facultativeActions.getFacultativeDeal,
      (state: IFacultativeState, { dealId }) => ({
        ...state,
        isLoading: true,
        isSaving: false,
        currentDeal: parseInt(dealId === 'new' ? '0' : dealId, 10),
        selectedDeal: null,
        currentVersion: null,
        currentDealName: null,
        reinsuredAddress: null,
        headerTitle: null,
      })
    ),
    on(
      facultativeActions.getFacultativeDealSuccess,
      (state: IFacultativeState, { data }) => {
        let facDataVersions = data.facDataVersions
        let selectedDeal = { ...data }
        let sortedVersions = [...facDataVersions].sort(
          (a, b) =>
            new Date(b.modifiedDate).getTime() -
            new Date(a.modifiedDate).getTime()
        )
        if (state.currentDealType === 'casualty' && (state.currentFormCategory === 'certificate' || state.currentFormCategory === 'binder')) {
          const hasMaster = sortedVersions.some(
            v => v.category === state.currentFormCategory && v.formName === 'Master'
          )
          if (hasMaster) {
            const masterForm = sortedVersions.find(v => v.category === state.currentFormCategory && v.formName === 'Master')
            sortedVersions = [masterForm, ...sortedVersions.filter(v => v.id !== masterForm.id)]
          }
        }
        let currentVersion =
          sortedVersions.find(
            v =>
              v.category ===
              (state.currentFormCategory
                ? state.currentFormCategory
                : 'submission')
          ) || null
        const finalSubmission = sortedVersions.find(
          v => v.category === 'submission' && v.final
        )
        if (
          currentVersion &&
          state.currentFormCategory &&
          state.currentFormCategory === 'responses' &&
          finalSubmission
        ) {
          const submissionFields = JSON.parse(
            finalSubmission.fieldValues ?? '[]'
          )
          const marketResponses =
            JSON.parse(currentVersion?.fieldValues ?? '[]')?.marketResponses ??
            []
          const newFieldValues = JSON.stringify({
            ...submissionFields,
            marketResponses,
          })
          currentVersion = {
            ...currentVersion,
            fieldValues: newFieldValues,
            category: 'responses',
          } as FacDataVersion
        }
        const isCreateForm = state.newFormFields && data.facData.id === 0

        const facData = isCreateForm
          ? {
            ...data.facData,
            ...state.newFormFields,
            type: state.settings.selectedType,
          }
          : ({ ...data.facData, type: state.currentDealType } as FacData)
        selectedDeal = { ...data, facData }
        if (currentVersion) {
          const fieldValues = extractFieldValues(selectedDeal, currentVersion)
          const newCurrentVersion = {
            ...(currentVersion ?? {}),
            fieldValues,
          } as FacDataVersion
          const otherVersions = selectedDeal.facDataVersions?.filter(
            v => v.id !== newCurrentVersion.id
          )
          facDataVersions = [...otherVersions, newCurrentVersion]
          currentVersion = { ...newCurrentVersion }
        }
        const newSelectedDeal = {
          ...selectedDeal,
          facDataVersions,
        } as Facultative
        const newFormCategories = buildFormCategories(
          newSelectedDeal.facDataVersions,
          state
        )
        return {
          ...state,
          isLoading: false,
          isSaving: false,
          isDirty: false,
          error: '',
          currentDeal: data.facData.id,
          selectedDeal: newSelectedDeal,
          currentVersion: currentVersion
            ? currentVersion
            : ({
              hiddenFields: '[]',
              category:
                data.facData.id === 0
                  ? 'submission'
                  : state.currentFormCategory,
              fieldValues: extractFieldValues(selectedDeal),
            } as FacDataVersion),
          currentDealName: data.facData.name,
          salesforceOppId: data.facData.salesforceOppId,
          currentDealType: data?.facData?.type
            ? data.facData.type
            : state.currentDealType,
          saveDate: '',
          newFormFields: null,
          formCategories: newFormCategories,
          reinsuredAddress: data.reinsuredAddress ?? null,
        }
      }
    ),
    on(
      facultativeActions.createFacultativeFormVersionSuccess,
      (state, { data, skipRedirect }) => {
        const currentFacData = JSON.parse(
          JSON.stringify(state.selectedDeal)
        ) as Facultative
        currentFacData.facDataVersions.push({
          category: data.category,
          facDataId: data.facDataId,
          fieldValues: data.fieldValues,
          hiddenFields: data.hiddenFields,
          final: data.final,
          formName: data.formName,
          id: data.id,
          modifiedDate: data.modifiedDate,
        })

        let newVersion = {
          category: data.category,
          facDataId: data.facDataId,
          fieldValues: data.fieldValues,
          hiddenFields: data.hiddenFields,
          final: data.final,
          formName: data.formName,
          id: data.id,
          modifiedDate: data.modifiedDate,
        } as FacDataVersion
        const fieldValues = extractFieldValues(currentFacData, newVersion)
        const category = data.category ?? state.currentFormCategory
        const newCurrentVersion = {
          ...(newVersion ?? {}),
          fieldValues,
          category,
          hiddenFields: '[]',
        } as FacDataVersion
        const otherVersions = currentFacData.facDataVersions?.filter(
          v => v.id !== newCurrentVersion.id
        )
        const facDataVersions = [...otherVersions, newCurrentVersion]
        const selectedDeal = { ...currentFacData, facDataVersions }
        const formCategories = buildFormCategories(
          selectedDeal.facDataVersions,
          state
        )

        if (
          state.currentVersion &&
          state.currentVersion.id &&
          data.id !== state.currentVersion.id
        ) {
          newVersion = currentFacData.facDataVersions.find(
            (v: any) => v.id === state?.currentVersion?.id
          ) as FacDataVersion
        }

        if (skipRedirect) {
          return {
            ...state,
            facData: currentFacData,
            creatingFormVersion: false,
            isSaving: false,
            selectedDeal,
            formCategories,
          }
        } else {
          return {
            ...state,
            facData: currentFacData,
            creatingFormVersion: false,
            currentVersion: newVersion,
            dirty: false,
            isSaving: false,
            saveDate: new Date().toString(),
            selectedDeal,
            formCategories,
          }
        }
      }
    ),
    on(
      facultativeActions.saveFacultativeFormVersionSuccess,
      (state, { facData, facDataVersion, skipRedirect }) => {
        const currentFacData: Facultative = {
          ...state.selectedDeal,
          facData: {
            ...facData,
          },
        } as Facultative

        const newVersion = { ...facDataVersion } as FacDataVersion
        const fieldValues = extractFieldValues(currentFacData, newVersion)
        const category = newVersion.category ?? state.currentFormCategory
        let newCurrentVersion = {
          ...(newVersion ?? {}),
          fieldValues,
          category,
        } as FacDataVersion
        const otherVersions =
          currentFacData.facDataVersions?.filter(
            v => v.id !== newCurrentVersion.id
          ) ?? []
        const facDataVersions = [...otherVersions, newCurrentVersion]
        const selectedDeal = { ...currentFacData, facDataVersions }
        const formCategories = buildFormCategories(
          selectedDeal.facDataVersions,
          state
        )

        if (
          state.currentVersion &&
          state.currentVersion.id &&
          newCurrentVersion.id !== state.currentVersion.id
        ) {
          newCurrentVersion = currentFacData.facDataVersions.find(
            (v: any) => v.id === state?.currentVersion?.id
          ) as FacDataVersion
        }

        if (skipRedirect) {
          return {
            ...state,
            facData,
            selectedDeal,
            currentVersion: newCurrentVersion,
            currentDealName: facData.name,
            formCategories,
            isSaving: false,
            dirty: false,
            idsToUpdate: [],
            saveDate: new Date().toString(),
          }
        } else {
          return {
            ...state,
            facData,
            selectedDeal,
            currentVersion: newCurrentVersion,
            currentDealName: facData.name,
            formCategories,
            isSaving: false,
            dirty: false,
            idsToUpdate: [],
            saveDate: new Date().toString(),
          }
        }
      }
    ),
    on(
      facultativeActions.getFacultativeDealFail,
      (state: IFacultativeState, { errorMessage }) => ({
        ...state,
        isLoading: false,
        isSaving: false,
        error: errorMessage,
      })
    ),
    on(
      facultativeActions.updateReinsuredLayers,
      (state: IFacultativeState, { reinsuredLayers }) => {
        let selectedDeal = { ...state.selectedDeal } as Facultative
        const facDataVersions = [
          ...(selectedDeal?.facDataVersions ?? []),
        ] as FacDataVersion[]
        const finalSubmission = facDataVersions?.find(
          v => v.category === 'submission' && v.final
        )
        let idsToUpdate = [...state.idsToUpdate]
        if (finalSubmission) {
          const submissionFields = JSON.parse(
            finalSubmission.fieldValues ?? '{}'
          )
          const newFieldValues = JSON.stringify({
            ...submissionFields,
            reinsuredLayers: JSON.stringify(reinsuredLayers),
          })
          const newSubmissionVersion = {
            ...finalSubmission,
            fieldValues: newFieldValues,
          }
          const otherVersions = facDataVersions.filter(
            v => v.id !== newSubmissionVersion.id
          )
          const newVersions = [...otherVersions, newSubmissionVersion]
          selectedDeal = {
            ...selectedDeal,
            facDataVersions: newVersions,
          } as Facultative
          idsToUpdate = [...idsToUpdate, finalSubmission.id] as number[]
        }
        return {
          ...state,
          selectedDeal,
          idsToUpdate,
        }
      }
    ),
    on(
      facultativeActions.updateReinsuredNameAndAddress,
      (state: IFacultativeState, { nameAndAddress }) => {
        if (!state.selectedDeal?.facData) {
          return state
        }
        const selectedDeal = { ...state.selectedDeal } as Facultative
        const updatedDeal: Facultative = {
          ...selectedDeal,
          facData: {
            ...selectedDeal.facData,
            nameAndAddress,
          },
        }
        return {
          ...state,
          selectedDeal: updatedDeal,
        }
      }
    ),
    on(
      facultativeActions.loadBasisOfAcceptanceSuccess,
      (state: IFacultativeState, { basisOfAcceptance }) => ({
        ...state,
        basisOfAcceptance,
      })
    ),
    on(
      facultativeActions.loadSalesforceAccountsSuccess,
      (state: IFacultativeState, { salesforceAccounts }) => ({
        ...state,
        salesforceAccounts,
      })
    ),
    on(
      facultativeActions.loadTermsAndConditionsSuccess,
      (state: IFacultativeState, { termsAndConditions }) => ({
        ...state,
        termsAndConditions,
      })
    ),
    on(
      facultativeActions.loadReinsuranceConditionsSuccess,
      (state: IFacultativeState, { reinsuranceConditions }) => ({
        ...state,
        reinsuranceConditions,
      })
    ),
    on(
      facultativeActions.loadReinsurersSuccess,
      (state: IFacultativeState, { reinsurersList }) => ({
        ...state,
        reinsurersList,
      })
    ),
    on(
      facultativeActions.loadCurrenciesSuccess,
      (state: IFacultativeState, { currency }) => ({
        ...state,
        currency,
      })
    ),
    on(facultativeActions.updateDealBrokerSuccess, (state, { dealId, brokerName }) => {
      const updatedDealSummaries = [...state.dealSummaries]
      const summaryIndex: number = updatedDealSummaries.findIndex(d => d.dealId === dealId)
      if (summaryIndex != -1) {
        updatedDealSummaries[summaryIndex] = {
          ...updatedDealSummaries[summaryIndex],
          brokerName
        }
      }
      return {
        ...state,
        dealSummaries: updatedDealSummaries
      }
    }),
    on(facultativeActions.markDealAsNTUSuccess, (state, { dealId, ntuComment }) => {
      const updatedDealSummaries = [...state.dealSummaries]
      const summaryIndex: number = updatedDealSummaries.findIndex(d => d.dealId === dealId)
      if (summaryIndex != -1) {
        updatedDealSummaries[summaryIndex] = {
          ...updatedDealSummaries[summaryIndex],
          ntuComment
        }
      }
      return {
        ...state,
        dealSummaries: updatedDealSummaries
      }
    }),
    on(facultativeActions.deleteFacultativeDataSuccess, (state, { data }) => {
      const dealId: number = Number(data.id)
      const updatedDealSummaries = [...state.dealSummaries]
      const summaryIndex: number = updatedDealSummaries.findIndex(d => d.dealId === dealId)
      if (summaryIndex != -1) {
        updatedDealSummaries.splice(summaryIndex, 1)
      }
      return {
        ...state,
        dealSummaries: updatedDealSummaries
      }
    }),
    on(facultativeActions.setCurrentDealName, (state, { currentDealName }) => {
      let selectedDeal = { ...state.selectedDeal } as Facultative
      if (
        selectedDeal &&
        selectedDeal.facData &&
        selectedDeal.facData.type !== currentDealName
      ) {
        const facData = {
          ...selectedDeal.facData,
          type: currentDealName,
        } as FacData
        selectedDeal = { ...selectedDeal, facData }
      }
      return {
        ...state,
        currentDealName,
        selectedDeal,
      }
    }),

    on(facultativeActions.setCurrentFormCategory, (state, { currentType }) => {
      let currentVersion = state.currentVersion
      if (
        state.selectedDeal?.facDataVersions &&
        state.selectedDeal?.facDataVersions?.length > 0
      ) {
        const sortedVersions = [...state.selectedDeal.facDataVersions].sort(
          (a, b) =>
            new Date(b.modifiedDate).getTime() -
            new Date(a.modifiedDate).getTime()
        )
        const newCurrentVersion =
          sortedVersions.find(v => v.category === currentType) ||
          sortedVersions[0]
        const fieldValues = extractFieldValues(
          state.selectedDeal,
          newCurrentVersion
        )
        currentVersion = {
          ...(newCurrentVersion ?? {}),
          fieldValues,
        } as FacDataVersion
      }
      if (!currentVersion?.category) {
        currentVersion = {
          ...currentVersion,
          category: currentType ?? 'submission',
        } as FacDataVersion
      }
      return {
        ...state,
        currentVersion,
        currentFormCategory: currentType,
        isDealSidebarVisible: true,
      }
    }),

    // @ts-ignore
    on(facultativeActions.setCurrentVersion, (state, { currentVersion }) => {
      const currentFacData = JSON.parse(
        JSON.stringify(state.selectedDeal)
      ) as Facultative
      if (!currentVersion) {
        return {
          ...state,
          currentVersion,
          saveDate: '',
        }
      }
      const fieldValues = extractFieldValues(currentFacData, currentVersion)
      const newCurrentVersion = Object.assign({}, currentVersion ?? {}, {
        fieldValues,
      })
      let versionList = currentFacData.facDataVersions
      if (
        currentFacData?.facDataVersions &&
        currentFacData?.facDataVersions?.length > 0
      ) {
        versionList = [...currentFacData.facDataVersions].sort(
          (a, b) =>
            new Date(b.modifiedDate).getTime() -
            new Date(a.modifiedDate).getTime()
        )
      }
      const otherVersions = versionList.filter(v => v.id !== currentVersion.id)
      const newSelectedDeal = {
        ...currentFacData,
        facDataVersions: [...otherVersions, newCurrentVersion],
      }
      return {
        ...state,
        currentVersion: newCurrentVersion,
        selectedDeal: newSelectedDeal,
        saveDate: '',
      }
    }),

    // @ts-ignore
    on(facultativeActions.updateFiles, (state, { storedFiles }) => {
      let fieldValues = ''

      if (state.currentVersion) {
        const versionFacData = JSON.parse(
          state.currentVersion?.fieldValues ?? ''
        )
        versionFacData.storedFiles = storedFiles

        fieldValues = JSON.stringify(versionFacData)
      } else {
        return {
          ...state,
        }
      }
      // const newVersionData: FacDataVersion = Object.assign({}, versionFacData, ...currentFacData[index].facData)
      return {
        ...state,
        currentVersion: Object.assign({}, state.currentVersion ?? {}, {
          fieldValues,
        }),
      }
    }),

    on(
      facultativeActions.setCurrentDeal,
      (state, { currentDeal, currentDealType }) => ({
        ...state,
        currentDeal,
        currentDealType,
      })
    ),

    on(facultativeActions.setCurrentDealType, (state, { currentDealType }) => {
      let selectedDeal = { ...(state.selectedDeal ?? {}) } as Facultative
      if (selectedDeal && selectedDeal.facData && !selectedDeal.facData.type) {
        const facData = {
          ...selectedDeal.facData,
          type: currentDealType,
        } as FacData
        selectedDeal = { ...selectedDeal, facData } as Facultative
      }
      return {
        ...state,
        currentDealType,
        selectedDeal,
      }
    }),

    on(facultativeActions.setDirty, (state, { dirty }) => ({
      ...state,
      dirty,
    })),

    on(facultativeActions.setExportingPdf, (state, { exporting }) => ({
      ...state,
      isExporting: exporting
    })),

    on(facultativeActions.updateCurrentSettings, (state, { setting }) => {
      const newSettings = Object.assign({}, state.settings, {
        [setting.key]: setting.value,
      })
      return {
        ...state,
        settings: newSettings,
      }
    }),

    on(
      facultativeActions.setCurrentViewPreference,
      (state, { currentViewPreference }) => {
        const newSettings = Object.assign({}, state.settings, {
          viewType: currentViewPreference,
        })
        return {
          ...state,
          currentViewPreference,
          settings: newSettings,
        }
      }
    ),

    on(
      facultativeActions.setSidebarCategories,
      (state, { facDataVersions }) => {
        const newFormCategories = buildFormCategories(facDataVersions, state)
        return {
          ...state,
          formCategories: newFormCategories,
        }
      }
    ),

    on(facultativeActions.createFacultativeDataSuccess, (state, { data }) => {
      const currentVersion = {
        id: data.versionID,
        facDataId: data.dealID,
        category: data.category,
        final: data.final,
        fieldValues: data.fieldValues,
        modifiedDate: data.modifiedDate,
        formName: data.versionName,
        hiddenFields: data.hiddenFields,
      }
      const fieldValues = JSON.parse(data.fieldValues)
      const selectedDeal = {
        facData: {
          id: data.dealID,
          username: data.username,
          name: data.dealName,
          modifiedDate: data.modifiedDate,
          type: data.type,
          createdBy: data.createdBy,
          salesforceOppId: data.salesforceId,
          isDeleted: false,
          inceptionDate: fieldValues?.inceptionDate,
          typeOfInsurance: fieldValues?.typeOfInsurance ?? '',
          streetAddress: fieldValues?.streetAddress,
          cityName: fieldValues?.cityName,
          stateName: fieldValues?.stateName,
          zipCode: fieldValues?.zipCode,
          perilsIncluding: fieldValues?.perilsIncluding ?? '[]',
          perilsExcluding: fieldValues?.perilsExcluding ?? '[]',
          perilsOnly: fieldValues?.perilsOnly ?? '[]',
          policyPeriodFrom: fieldValues?.policyPeriodFrom,
          policyPeriodTo: fieldValues?.policyPeriodTo,
          reinsurancePeriodFrom: fieldValues?.reinsurancePeriodFrom,
          reinsurancePeriodTo: fieldValues?.reinsurancePeriodTo,
          otherFacultative: fieldValues?.otherFacultative,
        },
        facDataVersions: [currentVersion],
      } as Facultative
      return {
        ...state,
        // facData: [...state.deals, selectedDeal],
        selectedDeal,
        currentDeal: data.dealID,
        currentDealName: data.dealName,
        salesforceOppId: data.salesforceId,
        currentVersion,
        currentType: data.category,
        dirty: false,
        isSaving: false,
        saveDate: new Date().toString(),
      }
    }),

    on(facultativeActions.formSaveStarted, state => {
      return {
        ...state,
        isSaving: true,
      }
    }),

    on(facultativeActions.createFacultativeFormVersion, state => ({
      ...state,
      creatingFormVersion: true,
      isSaving: true,
    })),

    on(facultativeActions.createFacultativeData, state => ({
      ...state,
      isSaving: true,
    })),

    on(facultativeActions.createFacultativeFormVersionFailure, state => ({
      ...state,
      creatingFormVersion: false,
      isSaving: false,
    })),

    on(facultativeActions.createFacultativeDataFailure, state => ({
      ...state,
      isSaving: false,
    })),
    on(
      facultativeActions.saveFacultativeFormVersion,
      (state, { facDataVersion }) => {
        if (!state.selectedDeal) {
          return {
            ...state,
          }
        }
        let newVersion = { ...facDataVersion } as FacDataVersion
        if (
          state.currentVersion &&
          state.currentVersion.id &&
          newVersion.id !== state.currentVersion.id
        ) {
          newVersion = state.selectedDeal.facDataVersions.find(
            (v: any) => v.id === state?.currentVersion?.id
          ) as FacDataVersion
        }
        let updatedDeal: Facultative = updateFacDeal(
          state.selectedDeal,
          facDataVersion
        )
        if (
          facDataVersion?.category === 'binder' ||
          facDataVersion?.category === 'certificate'
        ) {
          const linkedVersion = updatedDeal.facDataVersions.find(
            v =>
              v.id !== facDataVersion.id &&
              v.formName === facDataVersion.formName
          )
          if (linkedVersion) {
            const versionTargetIndex = updatedDeal.facDataVersions.findIndex(
              v => v.id === linkedVersion.id
            )
            const versionTargetFacData = JSON.parse(
              updatedDeal.facDataVersions[versionTargetIndex]?.fieldValues ??
              '{}'
            )
            const versionSourceIndex = updatedDeal.facDataVersions.findIndex(
              v => v.id === facDataVersion.id
            )
            const versionSourceFacData = JSON.parse(
              facDataVersion?.fieldValues ?? '{}'
            )
            versionTargetFacData.coverageReinsured =
              versionSourceFacData.coverageReinsured
            const targetVersion = {
              ...updatedDeal.facDataVersions[versionTargetIndex],
              fieldValues: JSON.stringify(versionTargetFacData),
            }
            const sourceVersion = {
              ...updatedDeal.facDataVersions[versionSourceIndex],
              fieldValues: JSON.stringify(versionSourceFacData),
            }
            const filteredVersions = updatedDeal.facDataVersions.filter(
              v => ![linkedVersion.id, facDataVersion.id].includes(v.id)
            )
            updatedDeal = {
              ...updatedDeal,
              facDataVersions: [
                ...filteredVersions,
                sourceVersion,
                targetVersion,
              ],
            }
          }
        }
        return {
          ...state,
          selectedDeal: updatedDeal,
          currentVersion: {
            ...newVersion,
          },
          isSaving: true,
        }
      }
    ),
    on(facultativeActions.saveFacultativeFormVersionFailure, state => ({
      ...state,
      isSaving: false,
    })),
    on(facultativeActions.deleteFacultativeFormVersion, state => ({
      ...state,
      deletingFormVersion: true,
    })),
    on(facultativeActions.deleteFacultativeFormVersionFailure, state => ({
      ...state,
      deletingFormVersion: false,
      isSaving: false,
    })),
    on(
      facultativeActions.deleteFacultativeFormVersionSuccess,
      (state, { data }) => {
        const currentFacData: Facultative = JSON.parse(
          JSON.stringify(state.selectedDeal)
        )
        const newVersions = currentFacData.facDataVersions.filter(
          v => v.id?.toString() !== data.id
        )
        const newSelectedDeal = {
          ...currentFacData,
          facDataVersions: newVersions,
        }
        let newCurrentVersion = null
        if (newSelectedDeal.facDataVersions.length > 0) {
          const categoryVersions = newSelectedDeal.facDataVersions.filter(
            v => v.category === state.currentFormCategory
          )
          if (categoryVersions.length > 0) {
            const newVersion = categoryVersions[categoryVersions.length - 1]
            const fieldValues = extractFieldValues(currentFacData, newVersion)
            newCurrentVersion = Object.assign({}, newVersion ?? {}, {
              fieldValues,
            })
          }
        }
        const formCategories = buildFormCategories(
          newSelectedDeal.facDataVersions,
          state
        )
        return {
          ...state,
          selectedDeal: newSelectedDeal,
          currentVersion: newCurrentVersion
            ? newCurrentVersion
            : ({
              category: state.currentFormCategory ?? 'submission',
              fieldValues: extractFieldValues(newSelectedDeal),
            } as FacDataVersion),
          deletingFormVersion: false,
          dirty: false,
          formCategories,
        }
      }
    ),

    on(facultativeActions.setSearchCriteria, (state, { searchCriteria }) => {
      let newSearchCriteria = Object.assign({}, searchCriteria)
      if (
        state.facSearchCriteria !== searchCriteria &&
        state.facSearchCriteria.page === searchCriteria.page
      ) {
        newSearchCriteria = Object.assign({}, newSearchCriteria, { page: 1 })
      }
      return {
        ...state,
        facSearchCriteria: newSearchCriteria,
      }
    }),

    on(facultativeActions.resetSearchCriteria, (state) => {
      return {
        ...state,
        facSearchCriteria: {
          ...initialFacSearchCriteria
        }
      }
    }),

    on(facultativeActions.setCoverageType, (state, { coverageReinsured }) => {
      return { ...state, coverageReinsured }
    }),

    // @ts-ignore
    on(facultativeActions.setSharedFieldValues, (state, { field, value }) => {
      const { facDataId: id, formName } = state.currentVersion as FacDataVersion
      let versions = state.selectedDeal?.facDataVersions ?? []
      let binderVersion = versions.find(
        v =>
          v.facDataId === id &&
          v.formName === formName &&
          v.category === 'binder'
      )
      const binderId = binderVersion?.id ?? 0
      if (binderVersion) {
        const fieldValues = JSON.parse(binderVersion.fieldValues ?? '{}')
        const newFieldvalues = { ...fieldValues, [field]: value }
        binderVersion = {
          ...binderVersion,
          fieldValues: JSON.stringify(newFieldvalues),
        }
        const otherVersions = versions.filter(v => v.id !== binderId)
        versions = [...otherVersions, binderVersion]
      }
      let certVersion = versions.find(
        v =>
          v.facDataId === id &&
          v.formName === formName &&
          v.category === 'certificate'
      )
      const certId = certVersion?.id ?? 0
      if (certVersion) {
        const fieldValues = JSON.parse(certVersion.fieldValues ?? '{}')
        const newFieldvalues = { ...fieldValues, [field]: value }
        certVersion = {
          ...certVersion,
          fieldValues: JSON.stringify(newFieldvalues),
        }
        const otherVersions = versions.filter(v => v.id !== certId)
        versions = [...otherVersions, certVersion]
      }
      const selectedDeal = { ...state.selectedDeal, facDataVersions: versions }
      const idsToUpdate =
        state.currentFormCategory === 'certificate'
          ? [binderId, certId]
          : [certId, binderId]
      return { ...state, selectedDeal, idsToUpdate }
    }),
    on(facultativeActions.toggleDealSidebar, state => {
      return {
        ...state,
        isDealSidebarVisible: !state.isDealSidebarVisible,
      }
    }),
    on(facultativeActions.setHeaderTitle, (state, { headerTitle }) => {
      return {
        ...state,
        headerTitle,
      }
    })
  )

export function reducer(state: IFacultativeState | undefined, action: Action): IFacultativeState {
  return facultativeStateReducer(state, action)
}
